import Decimal from 'decimal.js';

const clamp = (number, min, max) => Math.min(Math.max(number, min), max);
const sum = (...numbers) => Number(numbers.reduce(
  (a, c) => a.add(c && !Number.isNaN(Number(c)) ? c : 0), new Decimal(0),
));
const add = (a, b) => sum(a, b);

export {
  add,
  clamp,
  sum,
};
