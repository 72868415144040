import { API } from 'aws-amplify';
import { notification } from 'antd';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { createErrorMessage } from '../context/globalStateHelper';
import { ROLES } from '../constants/marketParties';
import { STATUS } from '../constants/status';
import { STATES } from '../constants/deliveryRelationships';
import { startOfGasDay } from './gasday';

const moment = extendMoment(Moment);
const MINIMUM_DAYS_TO_START = 3;

/**
 * Check if user belongs to delivery relationship and his market party in shipper role.
 *
 * @param {String} marketPartyId - Market party identifier.
 * @param {Array<Object>} ownMarketParties - Array of user's market parties.
 *
 * @returns {Boolean} - Result of check.
 */
const isUserAllowedToModify = (marketPartyId, ownMarketParties) => {
  const usersMarketParty = ownMarketParties.find(marketParty => marketParty.id === marketPartyId);

  return usersMarketParty !== undefined && usersMarketParty.roles.includes(ROLES.SHIPPER);
};

/**
 * Patch a delivery relationship's status.
 *
 * @param {String} status - Delivery relationship's new status.
 * @param {String} deliveryRelationshipId - Id of delivery relationship to be modified.
 * @param {String} marketPartyId - Market party where delivery relationship belongs to.
 * @param {Function} translate - i18next translate function.
 */
const patchStatus = async (status, deliveryRelationshipId, marketPartyId, translate) => {
  if (
    status !== undefined
    && status !== STATUS.PENDING
    && deliveryRelationshipId !== undefined
    && marketPartyId !== undefined
  ) {
    try {
      await API.patch('FINTSO', `/marketparties/${marketPartyId}/deliveryrelationships/${deliveryRelationshipId}`, {
        body: {
          status,
        },
      });

      notification.success({
        message: translate('deliveryRelationship.modal.message.success'),
        description: '',
      });
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: translate('deliveryRelationship.modal.message.error'),
        description: createErrorMessage(error),
      });
    }
  }
};

/**
* Check if timestamp overlaps with existing DRS.
*
* @param {Object} timestamp - Moment datetime.
* @param {Array<Object>} deliveryRelationships - Existing delivery relationships.
* @param {String} meteringSiteId - Metering site's identifier.
*
* @returns {Boolean} - Result of checking.
*/
const isOverlappingWithExistingDeliveryRelationship = (
  timestamp,
  deliveryRelationships,
  meteringSiteId,
) => {
  const rangeExist = deliveryRelationships
    .filter(
      relationship => relationship.status !== STATUS.DECLINED
      && relationship.meteringSiteId === meteringSiteId,
    )
    .find(relationship => moment
      .range(
        moment(relationship.start).startOf('day'),
        // TODO: Need to be revisited...
        // when posibility to make DRS without end date will be implemented!
        moment(relationship.end).endOf('day'),
      )
      .contains(timestamp));

  return rangeExist !== undefined;
};

/**
* Check if start date earlier than `MINIMUM_DAYS_TO_START` from now.
*
* @param {Object} timestamp - Moment datetime.
*
* @returns {Boolean} - Result of checking.
*/
const isBeforeAllowedStartDate = (timestamp) => {
  const now = moment();
  const threeDaysBefore = timestamp
    .clone() // Have to clone it... antd reuse it
    .subtract(MINIMUM_DAYS_TO_START, 'days') // Going to past
    .format('YYYY-MM-DD');
  const deadline = startOfGasDay(threeDaysBefore); // Adding start time of gasday

  return now.isSameOrAfter(deadline); // Check if now in future
};

/**
 * Return state if it is in the past, on going, in the future
 * depending on the status
 *
 * @param {String} status - pending, accepted or declined
 * @param {String} start - start date
 * @param {String} end - end date
 */
const getState = (status, start, end) => {
  if (status === STATUS.DECLINED
    || (status === STATUS.PENDING && moment().isBetween(start, end))
  ) {
    return '';
  }

  if (moment().isBetween(start, end)) {
    return STATES.ONGOING;
  }

  if (moment().isBefore(start)) {
    return STATES.FUTURE;
  }

  return STATES.PAST;
};

export {
  isUserAllowedToModify,
  isOverlappingWithExistingDeliveryRelationship,
  isBeforeAllowedStartDate,
  patchStatus,
  getState,
};
