import { get } from 'lodash';

const createErrorMessage = (error, printStack = false) => {
  const message = get(error, 'response.data.error.message', 'Unknown error');
  const code = get(error, 'response.data.error.code', '');
  const { stack } = error;
  return `${message}${code ? ` (code ${code})` : ''}${printStack ? `\n\n${stack}` : ''}`;
};

const mockApiError = (message, code = '') => ({
  response: {
    data: {
      error: {
        code,
        message,
      },
    },
  },
});

const getDefaultFunctions = functions => Object.keys(functions)
  .reduce((result, key) => ({
    ...result,
    [key]: () => { },
  }), {});

export {
  createErrorMessage,
  getDefaultFunctions,
  mockApiError,
};
