import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Col,
  Row,
  Icon,
} from 'antd';

import './CRTTableTransferItem.less';

const CRTTableTransferItem = (props) => {
  const {
    marketPartyId,
    marketParties,
    item,
  } = props;

  const MARKETPARTY_NOT_FOUND = { name: 'Not exists' }; // TODO: Translation need to be added
  const getMarketPartyById = id => marketParties.find(party => party.id === id)
    || MARKETPARTY_NOT_FOUND;
  const seller = getMarketPartyById(item.sellerId);
  const buyer = getMarketPartyById(item.buyerId);
  const isSeller = marketPartyId === item.sellerId;
  const isBuyer = marketPartyId === item.buyerId;

  return (
    <Row>
      <Col span={4}>
        <Row className="capacity-rights-transfer__icon">
          <Icon
            type="arrow-down"
            className="direction-icon-buyer"
          />
        </Row>
      </Col>
      <Col span={20}>
        <Row
          className={
            classnames(
              'capacity-rights-transfer__seller',
              {
                'capacity-rights-transfer--bold': isSeller,
              },
            )
          }
        >
          { seller.name }
        </Row>
        <Row
          className={
            classnames(
              'capacity-rights-transfer__buyer',
              {
                'capacity-rights-transfer--bold': isBuyer,
              },
            )
          }
        >
          { buyer.name }
        </Row>
      </Col>
    </Row>
  );
};

CRTTableTransferItem.displayName = 'CRTTableTransferItem';
CRTTableTransferItem.propTypes = {
  marketPartyId: PropTypes.string,
  marketParties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  item: PropTypes.shape({
    buyerId: PropTypes.string.isRequired,
    sellerId: PropTypes.string.isRequired,
  }).isRequired,
};
CRTTableTransferItem.defaultProps = {
  marketPartyId: '',
};

export default CRTTableTransferItem;
