import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import './statsModal.less';

import Context from '../../context';
import { formatNumber } from '../../utils/i18n';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';
import { startOfGasDay, getGasDay } from '../../utils/gasday';
import { getDateString } from '../../utils/capacityAndNominationsHelper';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { naturalSort } from '../../utils/sort';
import ModalWrapper from '../../components/ModalWrapper';

const DATA_HEADERS = [
  {
    title: 'shipper',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => naturalSort(a.name, b.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'bookedCapacity',
    dataIndex: 'sum',
    key: 'sum',
    align: 'right',
    render: sum => `${formatNumber(sum)} kWh`,
    sorter: (a, b) => a.sum - b.sum,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
];

class StatsModal extends Component {
  componentDidMount() {
    const {
      match: {
        params: {
          date,
        },
      },
    } = this.props;
    this.fetchData(date);
  }

  fetchData = async (date) => {
    const {
      totalCapacities,
      updateTotalCapacities,
    } = this.context;

    if (!totalCapacities[date]) {
      updateTotalCapacities(date, date);
    }
  }

  handleChangeSelectedDate = (datetime) => {
    const {
      basePath,
      history,
    } = this.props;
    const date = getGasDay(datetime);
    history.push(`${basePath}/exitZone/${date}`);
    this.fetchData(date);
  }

  renderDateSelector = () => {
    const {
      match: {
        params: {
          date,
        },
      },
    } = this.props;

    return (
      <StatsDatePicker
        selectedDate={startOfGasDay(date)}
        onSelectedDateChange={this.handleChangeSelectedDate}
      />
    );
  }

  getMarketPartyById = (id) => {
    const { marketPartyIndex } = this.context;
    const marketParty = marketPartyIndex.find(mp => (mp.id === id));

    return marketParty || { name: '-' };
  }

  renderTable = () => {
    const {
      t,
      match: {
        params: {
          date,
        },
      },
    } = this.props;

    const { totalCapacities } = this.context;
    const stats = totalCapacities[date] || [];

    const data = stats.map(item => ({
      id: item.marketPartyId,
      name: this.getMarketPartyById(item.marketPartyId).name,
      sum: item.exitZone.sum,
    }));

    return (
      <Table
        className="stats-modal__table"
        data-testid="stats-modal__table"
        columns={getTranslatedTableHeaders(DATA_HEADERS, t)}
        dataSource={data}
        pagination={false}
        rowKey={(record, index) => `${record.id}_${index}`}
      />
    );
  }

  handleClose = () => {
    const { basePath, history } = this.props;
    history.push(basePath);
  }

  renderCloseButton = () => {
    const { t } = this.props;
    return [
      <Button
        className="stats-modal__close-button"
        key="close-button"
        type="primary"
        onClick={this.handleClose}
      >
        {t('common.button.close')}
      </Button>,
    ];
  }

  render = () => {
    const {
      t,
      match: {
        params: {
          date,
        },
      },
    } = this.props;
    const title = t('monitoring.capacityAndNominations.modal.title');
    const formatedTitle = `${title} ${getDateString(date, t)}`;

    return (
      <ModalWrapper
        modalClassName="stats-modal"
        title={formatedTitle}
        handleClose={this.handleClose}
        actionButtons={this.renderCloseButton()}
      >
        <>
          { this.renderDateSelector() }
          { this.renderTable() }
        </>
      </ModalWrapper>
    );
  }
}

StatsModal.propTypes = {
  basePath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};

StatsModal.displayName = 'StatsModal';
StatsModal.contextType = Context;

const Translated = withTranslation()(StatsModal);
export default withRouter(Translated);
export {
  StatsModal as PureComponent,
};
