import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
  Row,
  Col,
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import { API, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../StatusIcon';
import { STATUS } from '../../constants/status';
import { BALANCE_WRITE_PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';
import ModalWrapper from '../ModalWrapper';

import './index.less';

const logger = new Logger('components:BalanceGroupMemberView');

class BalanceGroupMemberView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingStatus: '',
    };
  }

  componentDidMount = () => {
    const {
      balanceGroupMembers,
      updateBalanceGroupMembers,
    } = this.context;

    if (balanceGroupMembers === null) updateBalanceGroupMembers();
  }

  handleClose = () => {
    const { history } = this.props;

    return history.push('/balancegroups');
  };

  isActionAllowed = (balanceGroupMembership) => {
    const { selectedMarketPartyId } = this.context;
    const { marketPartyId, status } = balanceGroupMembership;
    return selectedMarketPartyId === marketPartyId
      && status === STATUS.PENDING
      && userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS);
  }

  handleBalanceGroupMembershipStatusChange = async (status, balanceGroupMembership) => {
    if (balanceGroupMembership === null) return;

    const {
      id,
      marketPartyId,
      balanceGroupId,
    } = balanceGroupMembership;

    const { handleDbChange } = this.context;
    const { t } = this.props;

    try {
      this.setState({ sendingStatus: status });
      const init = {
        body: { status },
      };
      const url = `/marketparties/${marketPartyId}/balancegroups/${balanceGroupId}/members/${id}`;
      const updatedMembership = await API.patch('FINTSO', url, init);
      handleDbChange('BalanceGroupMember', 'update', updatedMembership);

      notification.success({
        message: t('balanceContainer.membership.message.updatedSuccessfully'),
        description: '',
      });

      this.handleClose();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('balanceContainer.membership.message.errorUpdating'),
        description: createErrorMessage(e),
      });

      logger.error(createErrorMessage(e, true));
    } finally {
      this.setState({ sendingStatus: '' });
    }
  }

  getBalanceGroupMembership = () => {
    const { match: { params: { id: membershipId } } } = this.props;
    const { balanceGroupMembers } = this.context;

    return (membershipId !== undefined && balanceGroupMembers !== null)
      ? balanceGroupMembers.find(item => item.id === membershipId)
      : null;
  };

  getMarketParty = (balanceGroupMembership) => {
    const { marketPartyIndex, balanceGroups } = this.context;
    const balanceGroupOwner = balanceGroups.find(
      ({ id }) => id === balanceGroupMembership.balanceGroupId,
    );

    return marketPartyIndex.find(({ id }) => id === balanceGroupOwner.marketPartyId);
  }

  isDataLoaded = () => {
    const { balanceGroups, balanceGroupMembers } = this.context;

    return balanceGroups && balanceGroupMembers;
  }

  renderContent = () => {
    const { t } = this.props;
    const balanceGroupMembership = this.getBalanceGroupMembership();

    if (!this.isDataLoaded() || balanceGroupMembership === null) return null;

    const marketparty = this.getMarketParty(balanceGroupMembership);

    return (
      <div data-testid="balance-group-view__content" className="balance-group-view__content">
        <Row>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.balanceGroupOwner')}
            </p>
            <p className="balance-group-view__content__text">
              {marketparty !== undefined ? marketparty.name : ''}
            </p>
          </Col>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.status')}
            </p>
            <p className="balance-group-view__content__text">
              <StatusIcon status={balanceGroupMembership.status} />
              {balanceGroupMembership.status}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.startDate')}
            </p>
            <p className="balance-group-view__content__text">
              {balanceGroupMembership.start}
            </p>
          </Col>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.endDate')}
            </p>
            <p className="balance-group-view__content__text">
              {balanceGroupMembership.end !== undefined ? balanceGroupMembership.end : '-'}
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  renderActionButtons = () => {
    const { sendingStatus } = this.state;
    const { t } = this.props;
    const balanceGroupMembership = this.getBalanceGroupMembership();
    const actionButtonDisabled = balanceGroupMembership
      ? !this.isActionAllowed(balanceGroupMembership)
      : true;

    return ([
      <Button
        className="balance-group-view__close-button"
        key="close"
        onClick={this.handleClose}
      >
        {t('common.button.close')}
      </Button>,
      <Button
        className="balance-group-view__decline-button"
        key="decline"
        disabled={actionButtonDisabled}
        onClick={
          () => this.handleBalanceGroupMembershipStatusChange(
            STATUS.DECLINED,
            balanceGroupMembership,
          )
        }
        loading={sendingStatus === STATUS.DECLINED}
      >
        {t('common.button.decline')}
      </Button>,
      <Button
        className="balance-group-view__accept-button"
        key="accept"
        type="primary"
        disabled={actionButtonDisabled}
        onClick={
          () => this.handleBalanceGroupMembershipStatusChange(
            STATUS.ACCEPTED,
            balanceGroupMembership,
          )
        }
        loading={sendingStatus === STATUS.ACCEPTED}
      >
        {t('common.button.accept')}
      </Button>,
    ]);
  }

  render = () => {
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="balance-group-view"
        title={t('balanceContainer.title.balanceGroup')}
        actionButtons={this.renderActionButtons()}
        handleClose={this.handleClose}
        isLoading={!this.isDataLoaded()}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

BalanceGroupMemberView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
      marketPartyId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

BalanceGroupMemberView.displayName = 'BalanceGroupMemberView';
BalanceGroupMemberView.contextType = Context;

const BalanceGroupMemberViewTranslated = withTranslation()(BalanceGroupMemberView);

export default withRouter(BalanceGroupMemberViewTranslated);
export {
  BalanceGroupMemberView as PureComponent,
};
