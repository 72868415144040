import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  notification,
  Row,
  Select,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import './newDeliveryRelationship.less';

import Context from '../../context';
import {
  isOverlappingWithExistingDeliveryRelationship,
  isBeforeAllowedStartDate,
} from '../../utils/deliveryRelationshipHelpers';
import { localeDateFormat } from '../../utils/i18n';
import { isShipper } from '../../utils/marketparty';
import { createErrorMessage } from '../../context/globalStateHelper';

const logger = new Logger('new-delivery-relation-ship:form');

class NewDeliveryRelationship extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
    };
  }

  componentDidMount = () => {
    const {
      deliveryRelationships,
      updateDeliveryRelationships,
    } = this.context;

    if (deliveryRelationships.length === 0) {
      updateDeliveryRelationships();
    }
  }

  /**
   * Check if date already booked.
   *
   * @param {Object} timestamp - Moment datetime.
   *
   * @returns {Boolean} - Result of checking.
   */
  isDateAlreadyBooked = (timestamp) => {
    const { deliveryRelationships } = this.context;
    const { meteringSiteId } = this.props;

    return isBeforeAllowedStartDate(timestamp)
    || isOverlappingWithExistingDeliveryRelationship(
      timestamp,
      deliveryRelationships,
      meteringSiteId,
    );
  }

  /**
   * Checks if start date selected.
   *
   * @return {Boolean} - Result of check.
   */
  isStartDateEmpty = () => {
    const { form: { getFieldValue } } = this.props;
    const value = getFieldValue('start');
    return value === undefined || value === null;
  }

  /**
   * Render shippers data
   *
   * @returns {JSX} - Rendered part of the form.
   */
  renderShippers = () => {
    const { marketPartyIndex } = this.context;
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    return (
      <Form.Item
        label="Shipper"
        colon={false}
      >
        {getFieldDecorator('marketPartyId', {
          rules: [{ required: true, message: t('common.form.emptyInputFieldErrorMessage') }],
        })(
          <Select
            placeholder="Choose shipper"
            disabled={this.isStartDateEmpty()}
          >
            { marketPartyIndex.filter(isShipper).map(party => (
              <Select.Option
                value={party.id}
                key={party.id}
              >
                {party.name}
              </Select.Option>
            )) }
          </Select>,
        )}
      </Form.Item>
    );
  }

  renderStartDate = () => {
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    return (
      <Form.Item
        label="Start date"
        colon={false}
      >
        {getFieldDecorator('start', {
          rules: [{ required: true, message: t('common.form.dateErrorMessage') }],
        })(
          <DatePicker
            className="delivery-relationship__start-date__input"
            format={localeDateFormat()}
            disabledDate={this.isDateAlreadyBooked}
            data-testid="delivery-relationship__start-date__input"
          />,
        )}
      </Form.Item>
    );
  }

  renderEndDate = () => {
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    return (
      <Form.Item
        label="End date"
        colon={false}
      >
        {getFieldDecorator('end', {
          // TODO: Should be changed (not mandatory field)?
          rules: [{ required: true, message: t('common.form.dateErrorMessage') }],
        })(
          <DatePicker
            className="delivery-relationship__end-date__input"
            format={localeDateFormat()}
            disabledDate={this.isDateAlreadyBooked}
            disabled={this.isStartDateEmpty()}
            data-testid="delivery-relationship__end-date__input"
          />,
        )}
      </Form.Item>
    );
  }

  /**
   * Render title of component.
   *
   * @returns {JSX} - Rendered part.
   */
  renderHeader = () => {
    const { t } = this.props;
    return (
      <h3 className="new-delivery-relationship__form__header-text">
        { t('deliveryRelationship.new.title') }
      </h3>
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { selectedMarketPartyId: marketPartyId } = this.context;
    const {
      form,
      meteringSiteId,
      t,
    } = this.props;

    form.validateFields(async (error, body) => {
      if (!error && marketPartyId !== undefined) {
        this.setState({ isSending: true });

        try {
          const dateFormatAcceptedByBE = 'YYYY-MM-DD';
          await API.post('FINTSO', `/marketparties/${marketPartyId}/deliveryrelationships`, {
            body: {
              ...body,
              start: body.start.format(dateFormatAcceptedByBE),
              end: body.end.format(dateFormatAcceptedByBE),
              meteringSiteId,
            },
          });

          // Display notification to user
          notification.success({
            message: `${t('deliveryRelationship.new.title')} ${t('common.status.successfullyCreated')}`,
            description: '',
          });
          form.resetFields();
          this.setState({ isSending: false });
        } catch (err) {
          notification.error({
            className: 'notification-error',
            message: `${t('common.notifications.errorCreating')} ${t('deliveryRelationship.new.title').toLowerCase()}`,
            // TODO: translatable error message
            description: createErrorMessage(err),
          });
          logger.error(createErrorMessage(err, true));
          this.setState({ isSending: false });
        }
      }
    });
  }

  render = () => {
    const {
      form: {
        getFieldsValue,
      },
      t,
    } = this.props;
    const { isSending } = this.state;
    const isButtonDisabled = () => {
      const {
        start,
        end,
        marketPartyId,
      } = getFieldsValue();

      // TODO: `end` field check should be deleted (not mandatory field)!
      return start === undefined || end === undefined || marketPartyId === undefined;
    };

    return (
      <>
        { this.renderHeader() }
        <Form
          className="new-delivery-relationship__form"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col span={5}>
              { this.renderStartDate() }
            </Col>
            <Col span={1}>
              <div className="delivery-relationship__input-divider">
                -
              </div>
            </Col>
            <Col span={5}>
              { this.renderEndDate() }
            </Col>
            <Col span={1} />
            <Col span={12}>
              { this.renderShippers() }
            </Col>
          </Row>
          <Row className="delivery-relationship__form__action-row">
            <Form.Item>
              <Col span={7}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isButtonDisabled()}
                  loading={isSending}
                  block
                >
                  { isSending ? `${t('common.status.wait')}...` : t('deliveryRelationship.new.submit.button.title') }
                </Button>
              </Col>
              <Col span={1} />
              <Col span={16}>
                { t('deliveryRelationship.new.submit.action.description') }
              </Col>
            </Form.Item>
          </Row>
        </Form>
        <Divider />
      </>
    );
  }
}

NewDeliveryRelationship.displayName = 'NewDeliveryRelationship';
NewDeliveryRelationship.contextType = Context;
NewDeliveryRelationship.propTypes = {
  meteringSiteId: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const TRANSLATED = withTranslation()(NewDeliveryRelationship);
const FORM = Form.create({ name: 'newDeliveryRelationship' })(TRANSLATED);

export default FORM;
export {
  NewDeliveryRelationship as PureComponent,
};
