import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import { RESOLUTION_NAMES, RESOLUTIONS } from '../../constants/meteringSites';
import { UNIT_NAMES } from '../../constants/units';
import {
  BASE_CLASS,
  getFirstColumn,
  getRowClassName,
  getRowKey,
  getTimeColumns,
  remap,
  TABLE_CLASS,
} from './utils';

const ConsumptionsPerMeteringSite = ({
  dataSource,
  isLoading,
  start,
  end,
  resolution,
  unit,
}) => {
  const { t } = useTranslation();

  return (
    <div className={BASE_CLASS}>
      <h3 className={`${BASE_CLASS}__title`}>
        {t('meteringSites.table.title.gasConsumptionPerMeteringSite', { unit })}
      </h3>
      {
        dataSource.length
          ? (
            <Table
              className={TABLE_CLASS}
              columns={[
                getFirstColumn(t),
                ...getTimeColumns(start, end, resolution),
              ]}
              dataSource={remap(dataSource, unit, resolution)}
              indentSize={0}
              loading={isLoading}
              pagination={false}
              rowClassName={getRowClassName}
              rowKey={getRowKey}
              scroll={{ x: 'max-content' }}
            />
          )
          : <Empty />
      }
    </div>
  );
};

const timeSeriesArray = PropTypes.arrayOf(
  PropTypes.shape({
    timestamp: PropTypes.string,
    value: PropTypes.string,
  }),
);

ConsumptionsPerMeteringSite.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      meteringSiteId: PropTypes.string,
      name: PropTypes.string,
      values: PropTypes.shape({
        energy: timeSeriesArray,
        heat: timeSeriesArray,
        volume: timeSeriesArray,
      }),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resolution: PropTypes.oneOf(RESOLUTION_NAMES),
  start: PropTypes.string,
  end: PropTypes.string,
  unit: PropTypes.oneOf(UNIT_NAMES).isRequired,
};
ConsumptionsPerMeteringSite.defaultProps = {
  start: '',
  end: '',
  resolution: RESOLUTIONS.DAY,
};
ConsumptionsPerMeteringSite.displayName = 'ConsumptionsPerMeteringSite';

export default ConsumptionsPerMeteringSite;
