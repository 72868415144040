import React from 'react';

import StatusIcon from '../StatusIcon';
import { dateSort, naturalSort } from '../../utils/sort';
import { formatNumber, formatDateTimeStr } from '../../utils/i18n';
import { getUpdateTimestamp } from '../../utils/capacityHelpers';
import { STATUS } from '../../constants/status';

const getTableHeaders = (t, { includeMarketPartyNames, includeTimestamps }) => [
  ...includeMarketPartyNames ? [{
    title: 'marketPartyName',
    dataIndex: 'marketPartyName',
    key: 'marketPartyName',
    sorter: (a, b) => naturalSort(a.marketPartyName, b.marketPartyName),
    sortDirections: ['ascend', 'descend'],
  }] : [],
  ...includeTimestamps ? [{
    title: 'created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => dateSort(a.createdAt, b.createdAt),
    render: formatDateTimeStr,
    sortDirections: ['ascend', 'descend'],
  }] : [],
  {
    title: 'connectionPoint',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => naturalSort(a.type, b.type),
    render: type => t(`capacity.point.${type}`),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'reservedFor',
    dataIndex: 'start',
    key: 'start',
    render: start => start.map(formatDateTimeStr).join(', '),
    sorter: (a, b) => dateSort(a.start[0], b.start[0]),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    title: 'capacity',
    dataIndex: 'capacity',
    key: 'capacity',
    sorter: (a, b) => naturalSort(a.capacity, b.capacity),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'product',
    dataIndex: 'product',
    key: 'product',
    sorter: (a, b) => naturalSort(a.product, b.product),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    className: 'content--centered',
    sorter: (a, b) => naturalSort(a.status, b.status),
    render: status => <StatusIcon status={status} />,
    sortDirections: ['ascend', 'descend'],
  },
  ...includeTimestamps ? [{
    title: 'processed',
    dataIndex: 'statusChangedAt',
    key: 'statusChangedAt',
    sorter: (a, b) => dateSort(a.statusChangedAt, b.statusChangedAt),
    render: timestamp => formatDateTimeStr(timestamp, '-'),
    sortDirections: ['ascend', 'descend'],
  }] : [],
];


const remapReservationsForTable = (reservations, marketPartyIndex, visibleElementsCount) => {
  if (!Array.isArray(reservations)) {
    return null;
  }

  const { pending, rest } = reservations
    .reduce((output, item) => {
      const data = item.products.reduce((accum, product) => ({
        capacity: accum.capacity + product.capacity,
        types: [...accum.types, product.type],
        start: [...accum.start, product.start],
        products: [...accum.products, product.period],
      }), {
        capacity: 0,
        types: [],
        start: [],
        products: [],
      });
      const marketParty = marketPartyIndex.find(({ id }) => id === item.marketPartyId);
      const marketPartyName = marketParty ? marketParty.name : '';
      const newItem = {
        id: item.id,
        marketPartyId: item.marketPartyId,
        marketPartyName,
        type: [...new Set(data.types)].join(', '),
        start: [...new Set(data.start)].sort(dateSort),
        capacity: formatNumber(data.capacity),
        product: [...new Set(data.products)].join(', '),
        status: item.status,
        createdAt: item.createdAt,
        statusChangedAt: getUpdateTimestamp(item),
      };

      if (item.status === STATUS.PENDING) {
        output.pending.push(newItem);
      } else {
        output.rest.push(newItem);
      }

      return output;
    }, { pending: [], rest: [] });

  const items = pending
    .concat(rest)
    .sort((a, b) => dateSort(a.start[0], b.start[0]))
    .reverse();

  return visibleElementsCount
    ? items.slice(0, visibleElementsCount)
    : items;
};

export {
  getTableHeaders,
  remapReservationsForTable,
};
