import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, notification, Icon,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { generateNotificationFromCognitoError } from '../../utils/errorHelpers';
import VerificationForm from './verificationForm';
import { attributeMap, getValidationRule } from './util';
import { updateState, verificationState } from './state';

const UserAttributeForm = (props) => {
  const {
    form: {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      validateFields,
    },
    attributeName,
    goBack,
    onUpdate,
    user,
  } = props;
  const currentValue = user[attributeName];
  const { t } = useTranslation();
  const [state, setState] = useState(updateState.NOT_UPDATED);
  const attributeText = t(`common.user.${attributeName}`);

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields(async (error, fields) => {
      if (!error) {
        const key = attributeMap[attributeName];
        const value = fields[attributeName];
        if (value === currentValue) {
          notification.info({
            message: t('settings.userInformation.change.info.noChange', { value: attributeText }),
          });
          return;
        }

        try {
          setState(updateState.UPDATE_REQUESTED);
          const currentUser = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(currentUser, { [key]: value });
          if (attributeName === 'phone') {
            // Phone number change doesn't get verified automatically
            await Auth.verifyCurrentUserAttribute('phone_number');
          }
          setState(updateState.NOT_VERIFIED);
        } catch (err) {
          setState(updateState.NOT_UPDATED);
          notification.error(generateNotificationFromCognitoError(t, err));
        }
      }
    });
  };

  const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);
  const fieldError = isFieldTouched(attributeName) && getFieldError(attributeName);
  const isUpdated = Object.keys(verificationState).includes(state);

  const handleClick = () => {
    if (isUpdated) {
      onUpdate();
    }
    goBack();
  };

  return (
    <>
      <h2>{t('settings.userInformation.change.title', { value: attributeText.toLowerCase() })}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Item
          label={t('settings.userInformation.change.label', { value: attributeText.toLowerCase() })}
          validateStatus={fieldError ? 'error' : ''}
          help={fieldError || ''}
        >
          {getFieldDecorator(attributeName, {
            initialValue: currentValue,
            rules: [
              {
                ...getValidationRule(attributeName),
                message: t('settings.userInformation.change.error.invalid'),
              },
              {
                required: true,
                message: t('settings.userInformation.change.error.required'),
              },
            ],
          })(<Input placeholder={attributeText} disabled={isUpdated} />)}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={state === updateState.UPDATE_REQUESTED}
            disabled={hasErrors(getFieldsError()) || isUpdated}
          >
            {t('common.button.submit')}
          </Button>
        </Form.Item>
      </Form>

      {isUpdated && (
        <VerificationForm
          attribute={attributeMap[attributeName]}
          attributeText={attributeText}
          state={state}
          setState={setState}
        />
      )}
      <Button
        type="link"
        className="link"
        onClick={handleClick}
      >
        <Icon type="double-left" />
        {t('common.button.back')}
      </Button>
    </>
  );
};

UserAttributeForm.displayName = 'UserAttributeForm';
UserAttributeForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldError: PropTypes.func,
    isFieldTouched: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  attributeName: PropTypes.oneOf(['email', 'phone']).isRequired,
  goBack: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default Form.create({ name: 'userAttributeForm' })(UserAttributeForm);
