import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  withRouter,
} from 'react-router-dom';
import joi from 'joi';
import { cloneDeep, isEmpty } from 'lodash';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import NominationForm from '../NominationForm';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';
import getNominationSchema from '../../schemas/nominations';
import {
  LOCATION_POINTS,
  DIRECTIONS,
} from '../../constants/nominations';
import { isDateAllowed, getEarliestValidTime } from '../../utils/nominationHelpers';
import { userHasPermission } from '../../utils/userHelpers';
import { isDateInRange } from '../../utils/dateTimeHelpers';
import { STATUS } from '../../constants/status';
import { PERMISSIONS } from '../../constants/users';
import {
  startOfGasDay, hoursInGasDay, getGasDay, startOfOngoingGasDay,
} from '../../utils/gasday';
import { getMarketPartyEIC } from '../../utils/marketparty';

const logger = new Logger('new-nomination:form');

class NewNomination extends Component {
  constructor(props) {
    super(props);

    const length = hoursInGasDay(getGasDay(startOfOngoingGasDay()));
    const { nominationData, canViewOnly } = props;

    const nomination = this.isRenomination() || canViewOnly
      ? nominationData
      : { amounts: Array.from({ length }, () => null) };

    this.state = {
      errors: [],
      isSaving: false,
      nomination,
    };
  }

  componentDidMount() {
    const {
      balanceGroupMembers,
      updateBalanceGroupMembers,
      updateRelationships,
      relationships,
    } = this.context;
    if (!balanceGroupMembers) {
      updateBalanceGroupMembers();
    }
    if (!relationships) {
      updateRelationships();
    }
  }

  isRenomination = () => {
    const { nominationData, canViewOnly } = this.props;
    return !isEmpty(nominationData) && !canViewOnly;
  }

  isBalanceGroupMemberOnDate = (date) => {
    const { balanceGroupMembers } = this.context;
    return balanceGroupMembers && !!balanceGroupMembers.find(({ status, start, end }) => (
      status === STATUS.ACCEPTED && isDateInRange(getGasDay(date), start, end)
    ));
  }

  isButtonDisabled = () => {
    const {
      errors,
      nomination: {
        amounts,
        date,
        onBehalfOfMarketPartyId,
      },
    } = this.state;
    return errors.length > 0
      || !date
      || !isDateAllowed(date)
      || (!onBehalfOfMarketPartyId && !this.isBalanceGroupMemberOnDate(startOfGasDay(date)))
      || amounts.includes(null)
      || amounts.length !== hoursInGasDay(date);
  }

  closeModal = () => {
    const { history } = this.props;

    return history.push('/nominations');
  };

  handleCancel = () => {
    const { t } = this.props;

    return this.isButtonDisabled()
      ? this.closeModal()
      : modalConfirmClose(t, this.closeModal);
  }


  getNominationData = () => {
    const { nomination } = this.state;
    const { amounts, date, point } = nomination || { point: '' };

    const updatedObject = cloneDeep(nomination);

    // remove pointDirection as they are separated now.
    delete updatedObject.pointDirection;
    // stripe out eic code form imatra
    if (point === LOCATION_POINTS.IMATRA) {
      updatedObject.shipperEIC = undefined;
    }

    const earliestValidTime = getEarliestValidTime();
    amounts.forEach((amount, index) => {
      const time = startOfGasDay(date).add(index, 'hours');
      if (point !== LOCATION_POINTS.VTP && time.isBefore(earliestValidTime)) {
        updatedObject.amounts[index] = 0;
      }
    });

    return updatedObject;
  }

  handleCreate = async () => {
    const {
      selectedMarketPartyId,
      handleDbChange,
      isReadOnlyAdmin,
    } = this.context;

    if (selectedMarketPartyId === undefined || isReadOnlyAdmin()) return;

    const data = this.getNominationData();
    delete data.nomresAmounts;
    delete data.matchedAmounts;

    const { t } = this.props;
    const nominationSchema = getNominationSchema(data.point);
    const { error } = joi.object(nominationSchema).validate(data);


    if (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.incorrectInput'),
        description: error.toString(),
      });
      return;
    }

    const { onBehalfOfMarketPartyId } = data;
    const createUrl = `/marketparties/${onBehalfOfMarketPartyId || selectedMarketPartyId}/nominations/nomint`;
    try {
      this.setState({ isSaving: true });
      delete data.onBehalfOfMarketPartyId; // this is not supposed to be sent in the body

      const createdNomint = await API.post('FINTSO', createUrl, {
        body: {
          ...data,
          createdByMarketPartyId: selectedMarketPartyId,
        },
      });

      handleDbChange('NomInt', 'create', createdNomint);

      notification.success({
        message: t('nominations.message.createdSuccessfully'),
        description: '',
      });
      this.closeModal();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('nominations.message.errorCreating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
      this.setState({ isSaving: false });
    }
  }

  updateChangedFields = (field, updatedObj, error) => {
    const { errors, nomination } = this.state;
    const updatedErrors = errors.filter(err => err !== field);

    if (error) {
      updatedErrors.push(field);
    }

    const resetZerosToNull = nomination.amounts.map((v) => (v || null));
    const shouldReset = field === 'pointDirection' && updatedObj.point === LOCATION_POINTS.VTP;

    this.setState({
      errors: updatedErrors,
      nomination: {
        ...updatedObj,
        ...(shouldReset ? { amounts: resetZerosToNull } : undefined),
      },
    });

    return !!error;
  };

  handleAmountsChange = (field, value) => {
    const { nomination } = this.state;
    const index = field.split('_')[1];
    const { amounts } = nomination;
    const { error } = joi.number().validate(value);

    amounts[index] = value;
    const updatedObj = {
      ...nomination,
      amounts,
    };

    this.updateChangedFields(field, updatedObj, error);
  }

  handleChange = (field, value) => {
    const { nomination } = this.state;
    const { selectedMarketPartyId } = this.context;
    const { pointDirection } = nomination;
    const [point] = pointDirection ? pointDirection.split('-') : [''];
    const nominationSchema = getNominationSchema(point);

    // special handling for amounts field
    if (field.startsWith('amounts')) {
      this.handleAmountsChange(field, value);

      return;
    }

    const { error } = nominationSchema[field].validate(value);
    const updatedObj = {
      ...nomination,
      [field]: value,
    };

    if (field === 'pointDirection') {
      const [pointValue, directionValue] = value ? value.split('-') : [];
      updatedObj.point = pointValue;
      updatedObj.direction = directionValue;
      updatedObj.onBehalfOfMarketPartyId = undefined; // clear onBehalfOfMarketPartyId when point-direction changes

      if (pointValue === LOCATION_POINTS.IMATRA) {
        delete updatedObj.shipperEIC; // Clear ´Counterparty` value from state
        updatedObj.direction = DIRECTIONS.ENTRY; // Select `Entry` by default for `Imatra` point
      }
    }

    if (field === 'onBehalfOfMarketPartyId') {
    // when onBehalfOfMarketPartyId is selected, by default set selectedMarketPartyId's EIC
      const shipperEIC = value && point !== LOCATION_POINTS.IMATRA // shipperEIC is forbidden in BE for Imatra
        ? getMarketPartyEIC(this.context, selectedMarketPartyId)
        : '';
      updatedObj.shipperEIC = shipperEIC;
      // clear the date selection when onBehalfOfMarketPartyId changes
      // As every marketparty has their own valid start/end of relationship authorization
      updatedObj.date = null;
    }

    // If the previously selected gas day had more hours than the new gas day
    // due to a DST transition, the amounts corresponding to the extra hours
    // are discarded.
    if (field === 'date') {
      const length = hoursInGasDay(value);
      updatedObj.amounts = updatedObj.amounts.slice(0, length);
    }

    // Update BC summary for nomination dialog
    if (updatedObj.point === LOCATION_POINTS.BALTIC_CONNECTOR) {
      const {
        updateCapacityBCSummary,
      } = this.context;

      if (updatedObj.date) {
        // values available for few next gasdays
        const date = startOfOngoingGasDay();
        const start = date.format('YYYY-MM-DD');
        const end = date.add(7, 'days').format('YYYY-MM-DD');
        updateCapacityBCSummary(start, end);
      }
    }

    this.updateChangedFields(field, updatedObj, error);
  }

  renderActionButtons = () => {
    const { t, canViewOnly } = this.props;
    const { isSaving } = this.state;
    const saveLabelKey = this.isRenomination() ? 'sendRenomination' : 'sendNomination';
    const cancelLabelKey = canViewOnly ? 'close' : 'cancel';

    const buttons = [
      <Button
        className="new-nomination__cancel-button"
        key="cancel"
        onClick={this.handleCancel}
      >
        {t(`common.button.${cancelLabelKey}`)}
      </Button>,
    ];
    if (!canViewOnly) {
      buttons.push((
        <Button
          className="new-nomination__create-button"
          key="save"
          loading={isSaving}
          type="primary"
          onClick={this.handleCreate}
          disabled={this.isButtonDisabled()}
        >
          {t(`nominations.button.${saveLabelKey}`)}
        </Button>
      ));
    }
    return buttons;
  }

  isValidRelationshipDate = (date) => {
    const {
      nomination: {
        onBehalfOfMarketPartyId,
      },
    } = this.state;
    const { relationships } = this.context;
    const isValid = (relationship) => date.isSameOrBefore(
      moment(relationship.end, 'YYYY-MM-DD'), 'day',
    ) && date.isSameOrAfter(
      moment(relationship.start, 'YYYY-MM-DD'), 'day',
    );
    // Enable all dates for which the relationship is valid.
    return relationships.some(r => r.marketPartyIdA === onBehalfOfMarketPartyId && isValid(r));
  }

  checkIfNominationDateIsAllowed = (date) => {
    const {
      nomination: {
        onBehalfOfMarketPartyId,
      },
    } = this.state;

    if (!isDateAllowed(date)) return false;

    // if nomination is done onBehalfOfMarketPartyId, date is allowed without balancegroup check
    if (onBehalfOfMarketPartyId) {
      return this.isValidRelationshipDate(date);
    }
    return this.isBalanceGroupMemberOnDate(date);
  }

  render = () => {
    const {
      t,
      nominationData,
      canViewOnly,
    } = this.props;
    const { nomresAmounts } = nominationData;
    const {
      errors,
      nomination,
    } = this.state;
    const {
      marketPartyIndex,
      relationships,
      selectedMarketPartyId,
      getSelectedMarketParty,
    } = this.context;

    let titleKey = this.isRenomination() ? 'renomination' : 'newNomination';
    if (canViewOnly) {
      titleKey = 'nomination';
    }

    return (
      <ModalWrapper
        modalClassName="new-nomination"
        title={t(`nominations.title.${titleKey}`)}
        handleClose={this.handleCancel}
        actionButtons={this.renderActionButtons()}
        width="60rem"
      >
        <NominationForm
          errors={errors}
          isDateAllowed={this.checkIfNominationDateIsAllowed}
          onChange={this.handleChange}
          nomination={nomination}
          nomresAmounts={nomresAmounts}
          marketPartyIndex={marketPartyIndex}
          isShipper={userHasPermission(this.context, [PERMISSIONS.SHIPPER])}
          relationships={relationships}
          selectedMarketPartyId={selectedMarketPartyId}
          getSelectedMarketParty={getSelectedMarketParty}
          isRenomination={this.isRenomination()}
          viewOnly={canViewOnly}
        />
      </ModalWrapper>
    );
  }
}

NewNomination.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  nominationData: PropTypes.shape({
    amounts: PropTypes.arrayOf(PropTypes.number),
    nomresAmounts: PropTypes.shape({}),
    date: PropTypes.string,
    direction: PropTypes.string,
    point: PropTypes.string,
    shipperEIC: PropTypes.string,
  }),
  canViewOnly: PropTypes.bool,
};
NewNomination.defaultProps = {
  nominationData: {},
  canViewOnly: false,
};

NewNomination.displayName = 'NewNomination';
NewNomination.contextType = Context;

const TRANSLATED = withTranslation()(NewNomination);

export default withRouter(TRANSLATED);
export {
  NewNomination as PureComponent,
};
