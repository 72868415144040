import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  notification,
} from 'antd';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import { generateNotificationFromCognitoError } from '../../utils/errorHelpers';

import './index.less';

const logger = new Logger('forgot-password:form');

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      form,
      t,
      setUserEmail,
    } = this.props;

    this.setState({
      isLoading: true,
    });

    form.validateFields(async (err, body) => {
      if (!err) {
        try {
          const { email } = body;
          const {
            CodeDeliveryDetails: {
              DeliveryMedium: deliveryMedium,
            },
          } = await Auth.forgotPassword(email);

          const mediumType = (deliveryMedium || '').toLowerCase();

          notification.success({
            message: t(`forgotPassword.form.message.${mediumType}VerificationCode`),
            description: '',
          });
          setUserEmail(email);
        } catch (error) {
          logger.error(error);
          const message = generateNotificationFromCognitoError(t, error);
          notification.error(message);
        }
      }

      this.setState({
        isLoading: false,
      });
    });
  }

  render = () => {
    const {
      isLoading,
    } = this.state;
    const { t, form } = this.props;

    return (
      <>
        <Form
          className="forgot-password-form"
          onSubmit={this.handleSubmit}
        >
          <Form.Item
            label={t('forgotPassword.form.label.email')}
            colon={false}
          >
            {
              form.getFieldDecorator(
                'email',
                {
                  rules: [
                    { required: true, message: t('common.form.emptyInputFieldErrorMessage') },
                    { type: 'email', message: t('forgotPassword.form.message.emailType') },
                  ],
                },
              )(<Input />)
            }
          </Form.Item>
          <Form.Item className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {isLoading ? t('common.status.sending') : t('common.button.submit')}
            </Button>
          </Form.Item>
        </Form>
        <p className="forgot-password__login-container">
          <a href="/">
            {t('common.button.backToSignIn')}
          </a>
        </p>
      </>
    );
  }
}

ForgotPasswordForm.displayName = 'ForgotPasswordForm';
ForgotPasswordForm.propTypes = {
  setUserEmail: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const WrappedForgotPasswordForm = Form.create({ name: 'forgot-password' })(ForgotPasswordForm);
export default withTranslation()(WrappedForgotPasswordForm);
export {
  WrappedForgotPasswordForm as PureComponent,
};
