import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import {
  Select,
  Form,
} from 'antd';

import {
  getHoursInAGasDay,
  getNextAvailableHour,
} from '../../utils/capacityHelpers';
import {
  CAPACITY_DATE_TYPES,
  CAPACITY_DATE_TYPES_NAMES,
} from '../../constants/capacities';
import { getGasDay, getNextDay } from '../../utils/gasday';
import { formatDate } from '../../utils/i18n';

import './CapacityHourPicker.less';
import { isSetDailyCapacityOpen } from '../../utils/capacityAndNominationsHelper';

const { Option } = Select;

const getNumberOfHours = (type, gasDay) => {
  const numberOfHours = getHoursInAGasDay(gasDay);

  if (type === CAPACITY_DATE_TYPES.START) {
    return numberOfHours.slice(0, -1);
  }

  return numberOfHours;
};

const isHourDisabled = (type, hour, start) => {
  const disabled = hour.isBefore(getNextAvailableHour());
  if (type === CAPACITY_DATE_TYPES.END && start) {
    return disabled || hour.isSameOrBefore(start);
  }

  return disabled;
};

const renderHourPicker = (type, gasDay, start, handler) => {
  const numberOfHours = getNumberOfHours(type, gasDay);

  return (
    <Select
      key={`select-${type}-${gasDay}-date`}
      className={`capacityhourpicker__${type}-hour__select`}
      onChange={handler}
    >
      {
        numberOfHours.map((hourInMoment, index) => (
          <Option
            // eslint-disable-next-line react/no-array-index-key
            key={`option-${type}-hour-${index}`}
            // value format is yyyy-mm-ddThh:mm:ssZ
            value={hourInMoment.clone().utc().format()}
            disabled={isHourDisabled(type, hourInMoment, start)}
          >
            {hourInMoment.format('HH:mm')}
          </Option>
        ))
      }
    </Select>
  );
};

const renderHourPickerFormItem = (type, t, errors, gasDay, start, handler) => (
  <Form.Item
    key={`form-item-${type}-date`}
    label={t(`capacityProductInstance.label.${type}Hour`)}
    className={classNames(
      'capacityhourpicker__item',
      `capacityhourpicker__${type}-hour`,
      { 'has-error': errors.find(error => error.field === type) },
    )}
  >
    {renderHourPicker(type, gasDay, start, handler)}
  </Form.Item>
);

const renderDayPicker = (type, t, start, handler, stateGasDay) => {
  const currentGasDay = getGasDay();
  const dayHeadGasDay = getNextDay(currentGasDay);

  // Give next day option only when daily capacity is closed per market rules
  const gasDays = [currentGasDay, dayHeadGasDay];

  const [selectedGasDay, setGasDay] = stateGasDay;

  // Day picker is a virtual picker changing start value's day part
  // so we just modify the current start value
  const handleGasDayChange = (value, ...rest) => {
    // value is gasday = yyyy-mm-dd, the handler expects yyyy-mm-ddThh:mm:ssZ
    if (start) {
      const newValue = value + start.slice(10);
      handler(newValue, ...rest);
    }
    setGasDay(value);
  };

  const dateSuffix = (gasDay) => {
    switch (gasDay) {
      case currentGasDay:
        return `(${t('common.date.today')})`;
      case dayHeadGasDay:
        return `(${t('common.date.tomorrow')})`;
      default:
        return '';
    }
  };

  return (
    <Select
      key={`select-gasday-${type}-date`}
      className={`capacityhourpicker__${type}-gasday__select`}
      onChange={handleGasDayChange}
      defaultValue={selectedGasDay}
    >
      {
        gasDays.map((gasDay, index) => (
          <Option
            // eslint-disable-next-line react/no-array-index-key
            key={`option-${type}-gasday-${index}`}
            value={gasDay}
            disabled={isSetDailyCapacityOpen(gasDay)}
          >
            {`${formatDate(gasDay)} ${dateSuffix(gasDay)}`}
          </Option>
        ))
      }
    </Select>
  );
};


const renderDayPickerFormItem = (type, t, start, handler, stateGasDay) => (
  <Form.Item
    label={t('capacityProductInstance.label.date')}
    className={classNames(
      'capacityhourpicker__item',
      'capacityhourpicker__date',
    )}
  >
    {renderDayPicker(
      type,
      t,
      start,
      handler,
      stateGasDay,
    )}
  </Form.Item>
);

const CapacityHourPicker = (props) => {
  const {
    errors,
    start,
    t,
    handleStartChange,
    handleEndChange,
  } = props;

  const stateGasDay = useState(getGasDay());
  const [selectedGasDay] = stateGasDay;

  return (
    <>
      {renderDayPickerFormItem(
        CAPACITY_DATE_TYPES.START,
        t,
        start,
        handleStartChange,
        stateGasDay,
      )}
      {
        CAPACITY_DATE_TYPES_NAMES.map(type => (
          renderHourPickerFormItem(
            type,
            t,
            errors,
            selectedGasDay,
            start,
            type === CAPACITY_DATE_TYPES.START ? handleStartChange : handleEndChange,
          )
        ))
      }
    </>
  );
};

CapacityHourPicker.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  start: PropTypes.string,
  handleStartChange: PropTypes.func.isRequired,
  handleEndChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CapacityHourPicker.defaultProps = {
  start: null,
};

export default withTranslation()(CapacityHourPicker);
export {
  CapacityHourPicker as PureComponent,
  isHourDisabled,
};
