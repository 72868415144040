import joi from 'joi';
import joiDateExtensions from '@joi/date';

import { CAPACITY_TYPES } from '../constants/capacities';

const joiDate = joi.extend(joiDateExtensions);
const timestamp = joiDate.date().utc();
const date = timestamp.format('YYYY-MM-DD').raw();
const datetime = timestamp.format('YYYY-MM-DDTHH:mm:ssZ').raw();

const STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};
const STATUS_NAMES = Object.values(STATUS);

const PERIODS = {
  YEAR: 'annual',
  QUARTER: 'quarterly',
  MONTH: 'monthly',
  DAY: 'daily',
  HOUR: 'hourly',
};
const PERIOD_NAMES = Object.values(PERIODS);

const TYPES = { ...CAPACITY_TYPES };
const TYPE_NAMES = Object.values(TYPES);
const PRODUCT_SCHEMA = {
  type: joi.string().valid(...TYPE_NAMES),
  period: joi.string().valid(...PERIOD_NAMES),
  // Gas market will be opened in the beginning of 2020
  // Restrict earlier bookings in production
  start: joi.when('period', {
    is: PERIODS.HOUR,
    then: datetime,
    otherwise: date.greater('now'),
  }),
  quantity: joi.number().integer().min(1),
  capacity: joi.number().min(0),
};

const getProductSchema = period => ({
  ...PRODUCT_SCHEMA,
  start: period === PERIODS.HOUR ? datetime : date.greater('now'),
});

const product = joi.object().keys(Object.assign(
  ...Object.entries(PRODUCT_SCHEMA).map(([key, value]) => ({
    [key]: value.required(),
  })),
));

const PRODUCT_DATE_SCHEMA = joi.object().keys({
  period: PRODUCT_SCHEMA.period,
  start: joi.when('period', {
    is: PERIODS.YEAR,
    then: joi.string().regex(/-01$/, 'start of the month'),
  }).when('period', {
    is: PERIODS.QUARTER,
    then: joi.string().regex(/-(01|04|07|10)-01$/, 'start of the quartal'),
  }).when('period', {
    is: PERIODS.MONTH,
    then: joi.string().regex(/-01$/, 'start of the month'),
  }).when('period', {
    is: PERIODS.DAY,
    // TODO: verify start date is far enough in the future
    then: joi.string(),
  })
    .when('period', {
      is: PERIODS.HOUR,
      then: joi.string().regex(/T(0[0-9]|1[0-9]|2[0-3]):[0][0]:[0][0]Z/, 'start of the hour'),
    }),
}).unknown();

const CAPACITYRESERVATION_SCHEMA = joi.object({
  id: joi.string().uuid(),
  marketPartyId: joi.string().uuid(),
  status: joi.string().valid(...STATUS_NAMES),
  products: joi.array().min(1).items(product),
});

export {
  TYPES,
  PERIODS,
  PRODUCT_DATE_SCHEMA,
  CAPACITYRESERVATION_SCHEMA,
  getProductSchema,
};
