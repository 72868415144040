import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Row,
  Col,
} from 'antd';

import classNames from 'classnames';

import { BALANCE_PRICE_TYPE_NAMES } from '../../constants/balance';

import SummaryTable from '../SummaryTable';
import {
  formatDateTimeStr,
  formatNumber,
  formatTime,
} from '../../utils/i18n';

import Context from '../../context';

import DateRangePicker from '../DateRangePicker';

import './index.less';

const BalancePricesTable = () => {
  const middleOfCurrentGasDay = moment.utc().hour(12).startOf('hour');
  const defaultStart = middleOfCurrentGasDay.clone().subtract(4, 'days');
  const defaultEnd = middleOfCurrentGasDay.clone();

  const { t } = useTranslation();

  const {
    balancePrices,
    updateBalancePrices,
    isLoading,
  } = useContext(Context);

  const DATA_HEADERS = [
    {
      title: 'gasDay',
      key: 'date',
      render: ({ timestamp, date, isOverridden }) => {
        const label = t(isOverridden ? 'balanceGasPricesTable.overriddenAt' : 'common.label.updatedAt');
        return timestamp ? `${date} (${label} ${timestamp})` : date;
      },
    },
    {
      title: 'balanceGasPrice',
      align: 'right',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const handleDatesSelected = (startDate, endDate) => {
    updateBalancePrices(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  };

  const renderBalanceGasPrices = () => (
    BALANCE_PRICE_TYPE_NAMES.map((type) => {
      const data = balancePrices
        ? balancePrices.map((price) => {
          const { override } = price;
          const isOverridden = !!(override && override[type]);
          return {
            date: formatDateTimeStr(price.date),
            price: formatNumber(isOverridden ? override[type] : price[type], 3, 3),
            timestamp: price.timestamp && formatTime(
              isOverridden ? override.timestamp : price.timestamp,
            ),
            isOverridden,
          };
        }).reverse()
        : [];

      const rowClassName = ({ timestamp, isOverridden }) => classNames({
        'preliminary-price': timestamp && !isOverridden,
        'overridden-price': isOverridden,
      });

      const isOverridden = data.length && !!data[0].isOverridden;
      const priceType = t(`balanceGasPricesTable.${isOverridden ? 'overridden' : 'preliminary'}`);

      return (
        <Col
          key={type}
          className="frontpage__balance-price"
          span={6}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
        >
          <SummaryTable
            className="balance-prices-table"
            tableHeaders={DATA_HEADERS}
            tableData={data}
            title={t(`balanceGasPricesTable.title.${type}`)}
            subTitle={t(`balanceGasPricesTable.subTitle.${type}`, { priceType })}
            isLoading={isLoading}
            rowClassName={rowClassName}
          />
        </Col>
      );
    })
  );

  return (
    <>
      <Row>
        <DateRangePicker
          maxDays={31}
          onValidDateSelected={handleDatesSelected}
          defaultStart={defaultStart}
          defaultEnd={defaultEnd}
        />
      </Row>
      <Row>
        { renderBalanceGasPrices() }
      </Row>
    </>
  );
};

export default BalancePricesTable;
