import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
} from 'antd';
import { API } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { ROLE_FIELDS } from '../../constants/marketParties';
import { createErrorMessage } from '../../context/globalStateHelper';

import './index.less';

const USER_FIELDS = [
  {
    label: 'firstName',
    propertyName: 'firstname',
    span: 11,
    offset: 0,
    rules: {
      default: [{ required: true, message: 'firstNameRequired' }],
    },
  },
  {
    label: 'lastName',
    propertyName: 'lastname',
    span: 12,
    offset: 1,
    rules: {
      default: [{ required: true, message: 'lastNameRequired' }],
    },
  },
  {
    label: 'email',
    propertyName: 'email',
    span: 11,
    offset: 0,
    rules: {
      default: [
        { required: true, message: 'emailRequired' },
        { type: 'email', message: 'emailType' },
      ],
    },
  },
  {
    label: 'phoneNumber',
    propertyName: 'phone',
    span: 12,
    offset: 1,
    rules: {
      default: [
        { required: true, message: 'phoneNumberRequired' },
        { type: 'string', pattern: /^\+[0-9]{7,15}$/, message: 'phoneNumberType' },
      ],
    },
  },
];

const EIC_TYPE_RULES = [{
  type: 'string', pattern: /^[0-9A-Z-]{16}$/, message: 'eicxType',
}];
const EICREQUIREDRULES = [
  ...EIC_TYPE_RULES,
  { required: true, message: 'eicxRequired' },
];
const GS1REQUIREDRULE = [{ required: true, message: 'gs1GlnRequired' }];

const MARKETPARTY_FIELDS = [
  {
    label: 'companyName',
    propertyName: 'company',
    span: 15,
    offset: 0,
    rules: {
      default: [{ required: true, message: 'companyNameRequired' }],
    },
  }, {
    label: 'vatNumber',
    propertyName: 'vatId',
    span: 8,
    offset: 1,
    rules: {
      default: [{ required: true, message: 'vatNumberRequired' }],
    },
  }, {
    label: 'streetAddress',
    propertyName: 'street',
    span: 24,
    offset: 0,
    rules: {
      default: [{ required: true, message: 'streetAddressRequired' }],
    },
  }, {
    label: 'city',
    propertyName: 'city',
    span: 11,
    offset: 0,
    rules: {
      default: [{ required: true, message: 'cityRequired' }],
    },
  }, {
    label: 'postalCode',
    propertyName: 'postalCode',
    span: 12,
    offset: 1,
    rules: {
      default: [
        { required: true, message: 'postalCodeRequired' },
        {
          type: 'string', min: 5, pattern: /^(?:[A-Z]{2}-)?[0-9]{4,5}$/, message: 'postalCodeType',
        },
      ],
    },
  }, {
    label: 'country',
    propertyName: 'country',
    span: 24,
    offset: 0,
    rules: {
      default: [{ required: true, message: 'countryRequired' }],
    },
  }, {
    label: 'eicx',
    propertyName: 'eic',
    span: 24,
    offset: 0,
    rules: {
      shipper: EICREQUIREDRULES,
      trader: EICREQUIREDRULES,
      tso: EICREQUIREDRULES,
      default: [
        ...EIC_TYPE_RULES,
        { required: false },
      ],
    },
  }, {
    label: 'retailerGs1Gln',
    propertyName: 'gs1Retailer',
    span: 11,
    offset: 0,
    rules: {
      retailer: GS1REQUIREDRULE,
      default: [{ required: false }],
    },
  }, {
    label: 'dsoGs1Gln',
    propertyName: 'gs1Dso',
    span: 12,
    offset: 1,
    rules: {
      dso: GS1REQUIREDRULE,
      default: [{ required: false }],
    },
  },
];

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditRatingChecked: false,
      isLoading: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      form,
      isRegistrationSuccesful,
      t,
    } = this.props;

    this.setState({
      isLoading: true,
    });

    form.validateFields(async (err, body) => {
      if (!err) {
        try {
          const remappedBody = {
            ...body,
            roles: body.roles.map(role => ROLE_FIELDS.find(item => t(`register.form.label.${item.title}`) === role).type),
          };
          // TODO: We need to add this fields to BE?
          delete remappedBody.checkCreditRating; // Not allowed yet by BE

          await API.post('FINTSO-noauth', '/register', { body: remappedBody });
          isRegistrationSuccesful(true);
        } catch (error) {
          notification.error({
            className: 'notification-error',
            message: t('common.notifications.failedToSave'),
            description: createErrorMessage(error),
          });
        } finally {
          this.setState({
            isLoading: false,
          });
        }
      }
    });
  }

  renderTypeOfMarketParty = () => {
    const { form, t } = this.props;
    return (
      <Form.Item
        label={t('register.form.label.typeOfMarketParty')}
        colon={false}
      >
        {form.getFieldDecorator('roles', {
          initialValue: [],
          rules: [{ required: true, message: t('register.form.message.selectTypeOfMarketParty') }],
        })(
          <Checkbox.Group options={ROLE_FIELDS.map(item => t(`register.form.label.${item.title}`))} />,
        )}
      </Form.Item>
    );
  }

  /**
   * Remap input field's rules according on user type.
   *
   * @param {Array<Object>} rules - Array of rules for input field.
   * @returns {Array<Object>} - Result of repmapping.
   */
  getRulesByUserType = (rules) => {
    const { form, t } = this.props;

    return [...new Set(form
      // Check what usertype is selected
      .getFieldValue('roles')
      // Remap usertype's titles with type
      .map(role => ROLE_FIELDS.find(userType => t(`register.form.label.${userType.title}`) === role).type)
      // Here magic happen, remap rules according to usertype, start with `default` rule
      .reduce((combinedRules, userType) => {
        const userRule = rules[userType];
        return userRule
          ? combinedRules.concat(userRule)
          : combinedRules;
      }, rules.default))];
  };

  getTranslatedRuleMessage = (rules) => {
    const { t } = this.props;
    return rules.map((rule) => {
      const translatedRule = { ...rule };
      if (rule.message && !this.isMessageTranslated(rule.message)) {
        translatedRule.message = t(`register.form.message.${rule.message}`);
      }
      return translatedRule;
    });
  }

  isMessageTranslated = ruleMessage => (ruleMessage.split(' ').length > 1)

  renderColumn = ({
    span,
    offset,
    propertyName,
    label,
    rules,
  }) => {
    const { form, t } = this.props;
    return (
      <Col
        span={span}
        offset={offset}
        key={propertyName}
      >
        <Form.Item
          label={t(`register.form.label.${label}`)}
          colon={false}
        >
          {
            form.getFieldDecorator(
              propertyName,
              { rules: this.getTranslatedRuleMessage(this.getRulesByUserType(rules)) },
            )(<Input />)
          }
        </Form.Item>
      </Col>
    );
  }

  renderUserData = () => (
    <>
      <Row>
        {USER_FIELDS.slice(0, 2).map(field => this.renderColumn(field))}
      </Row>
      <Row>
        {USER_FIELDS.slice(2, 4).map(field => this.renderColumn(field))}
      </Row>
    </>
  )

  renderCompanyData = () => (
    <Row>
      {MARKETPARTY_FIELDS.map(field => this.renderColumn(field))}
    </Row>
  )

  renderDivider = () => (
    <Row>
      <Col span={3} />
      <Col span={18}>
        <Divider />
      </Col>
      <Col span={3} />
    </Row>
  )

  handleCheckCreditRating = (event) => {
    this.setState({
      creditRatingChecked: event.target.checked,
    });
  }

  renderCheckCreditRating = () => {
    const {
      form,
      t,
    } = this.props;

    return (
      <Row>
        <Col>
          <Form.Item>
            {form.getFieldDecorator('checkCreditRating', {
              valuePropName: 'checked',
              initialValue: false,
            })(
              <Checkbox onChange={this.handleCheckCreditRating}>
                {t('register.form.label.dataConsent')}
              </Checkbox>,
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }

  renderExtraInfo = () => {
    const {
      t,
    } = this.props;

    return (
      <Row>
        <Col>
          <i>
            <br />
            <p>
              {t('register.form.extraInfo.line1.part1')}
              <a
                href={t('register.form.extraInfo.line1.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('register.form.extraInfo.line1.linkText')}
              </a>
              {t('register.form.extraInfo.line1.part2')}
            </p>
            <p>
              {t('register.form.extraInfo.line2')}
            </p>
            <p>
              {t('register.form.extraInfo.address.company')}
              <br />
              {t('register.form.extraInfo.address.recipient')}
              <br />
              {t('register.form.extraInfo.address.streetAddress')}
              <br />
              {t('register.form.extraInfo.address.postalAddress')}
              <br />
              {t('register.form.extraInfo.address.country')}
            </p>
            <p>{t('register.form.extraInfo.line3')}</p>
            <p>{t('register.form.extraInfo.line4')}</p>
            <p>
              {t('register.form.extraInfo.line5')}
              <a href="mailto:commercial@gasgrid.fi">commercial@gasgrid.fi</a>
            </p>
            <p>
              {t('register.form.extraInfo.line6.part1')}
              <a
                href={t('register.form.extraInfo.line6.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('register.form.extraInfo.line6.linkText')}
              </a>
              {t('register.form.extraInfo.line6.part2')}
            </p>
          </i>
        </Col>
      </Row>
    );
  }

  renderSubmitButton = () => {
    const {
      creditRatingChecked,
      isLoading,
    } = this.state;
    const { t } = this.props;

    return (
      <Form.Item
        wrapperCol={{ offset: 10 }}
        className="submit-button"
      >
        <Button
          type="primary"
          htmlType="submit"
          disabled={!creditRatingChecked}
          loading={isLoading}
        >
          {isLoading ? t('common.status.sending') : t('common.button.startRegistration')}
        </Button>
      </Form.Item>
    );
  }

  render = () => (
    <Form
      className="registrationform"
      onSubmit={this.handleSubmit}
    >
      {this.renderTypeOfMarketParty()}
      {this.renderDivider()}
      {this.renderUserData()}
      {this.renderDivider()}
      {this.renderCompanyData()}
      {this.renderDivider()}
      {this.renderCheckCreditRating()}
      {this.renderExtraInfo()}
      {this.renderSubmitButton()}
    </Form>
  )
}

RegistrationForm.displayName = 'RegistrationForm';
RegistrationForm.propTypes = {
  isRegistrationSuccesful: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);
export default withTranslation()(WrappedRegistrationForm);
export {
  WrappedRegistrationForm as PureComponent,
  ROLE_FIELDS,
  USER_FIELDS,
  MARKETPARTY_FIELDS,
};
