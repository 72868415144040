import React, {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Table, Icon } from 'antd';
import {
  Link,
  Route,
  withRouter,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import { STATES } from '../../constants/deliveryRelationships';
import { STATUS } from '../../constants/status';
import { TYPES, TYPES_NAMES } from '../../constants/meteringSites';
import DeliveryRelationship from '../DeliveryRelationship';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import {
  isUserAllowedToModify,
  patchStatus,
} from '../../utils/deliveryRelationshipHelpers';
import ActionButton from '../ActionButton';
import StatusIcon from '../StatusIcon';
import { localeDateFormat } from '../../utils/i18n';
import { dateSort, naturalSort } from '../../utils/sort';
import { getAnalyserName } from '../../utils/meteringSitesHelpers';
import { getGasDay } from '../../utils/gasday';
import { userHasPermission } from '../../utils/userHelpers';
import { PERMISSIONS } from '../../constants/users';

const checkMark = bool => (bool ? <Icon type="check" className="check-icon" /> : null);

const getDataHeaders = (renderEditColumn, isTSOAdmin) => ([
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => naturalSort(a.id, b.id),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    width: 250,
  },
  {
    title: 'meteringSite',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => naturalSort(a.name, b.name),
    sortDirections: ['ascend', 'descend'],
    width: 250,
  },
  {
    title: 'owner',
    dataIndex: 'owner',
    key: 'owner',
    sorter: (a, b) => naturalSort(a.owner, b.owner),
    sortDirections: ['ascend', 'descend'],
    width: 280,
  },
  {
    title: 'ownerCode',
    dataIndex: 'ownerCode',
    key: 'ownerCode',
    width: 100,
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
  },
  {
    title: 'analyser',
    dataIndex: 'analyser',
    key: 'analyser',
    sorter: (a, b) => naturalSort(a.analyser, b.analyser),
    sortDirections: ['ascend', 'descend'],
    width: 100,
  },
  {
    title: 'taxed',
    dataIndex: 'taxed',
    key: 'taxed',
    render: checkMark,
    width: 100,
  },
  {
    title: 'emissionTrade',
    dataIndex: 'emissionTrade',
    key: 'emissionTrade',
    render: checkMark,
    width: 100,
  },
  {
    title: 'deliveryStartTime',
    dataIndex: 'start',
    key: 'start',
    render: start => (start ? start.format(localeDateFormat()) : ''),
    width: 150,
  },
  {
    title: 'deliveryEndTime',
    dataIndex: 'end',
    key: 'end',
    render: end => (end ? end.format(localeDateFormat()) : ''),
    width: 150,
  },
  ...(isTSOAdmin ? [{
    title: 'updatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: updatedAt => (updatedAt ? moment(updatedAt).format(localeDateFormat()) : ''),
    defaultSortOrder: 'descend',
    sorter: (a, b) => (obj1, obj2) => {
      if (!obj1) return 1;
      if (!obj2) return -1;
      return dateSort(a.updatedAt, b.updatedAt);
    },
    width: 150,
  }] : []),
  {
    title: 'shipper',
    dataIndex: 'marketPartyName',
    key: 'marketPartyName',
    width: 280,
  },
  {
    title: 'state',
    dataIndex: 'state',
    key: 'state',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => naturalSort(a.state, b.state),
  },
  (renderEditColumn) ? {
    title: 'edit',
    dataIndex: 'edit',
    key: 'edit',
    width: 100,
  } : {},
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 150,
  },
]);

const byState = (state, selectedState) => {
  if (selectedState === 'all') {
    return true;
  }
  return state === selectedState;
};

const getState = (delivery) => {
  const { status, start, end } = delivery;
  if (status === STATUS.DECLINED) {
    return '';
  }
  if (status !== STATUS.PENDING && moment().isBetween(start, end)) {
    return STATES.ONGOING;
  }
  if (moment().isBefore(start)) {
    return STATES.FUTURE;
  }
  return STATES.PAST;
};

const MeteringSitesTable = (props) => {
  const context = useContext(Context);
  const {
    deliveryRelationships,
    updateDeliveryRelationships,
    marketPartyIndex,
    currentUser: {
      ownMarketParties,
    },
    meteringSites,
    loadingMeteringSites,
    selectedMarketPartyId,
    isLoading,
    meteringPointAnalysers,
    meteringPointAnalysersLoading,
    meteringPointOwnerships,
    meteringPointOwnershipsLoading,
    isAdmin,
    isReadOnlyAdmin,
    isAdminAsMarketParty,
  } = context;
  const { t } = useTranslation();

  useEffect(() => {
    updateDeliveryRelationships();
  }, [selectedMarketPartyId]);

  const loading = isLoading
    || meteringPointAnalysersLoading
    || meteringPointOwnershipsLoading
    || loadingMeteringSites;

  /**
   * Prepare/remap delivery relation ships data.
   *
   * @param {String} siteId - Metering site identifier.
   *
   * @returns {Array<Object>} - Array of delivery relationships.
   */
  const prepareDRSData = siteId => (
    deliveryRelationships
      .filter(delivery => (
        delivery.meteringSiteId === siteId && byState(getState(delivery), props.selectedState)
      ))
      .map((delivery) => {
        const data = [];
        const deliveryRelationshipMarketParty = marketPartyIndex
          .find(marketParty => marketParty.id === delivery.marketPartyId);

        const state = getState(delivery);

        if (
          isUserAllowedToModify(delivery.marketPartyId, ownMarketParties)
            && delivery.status === STATUS.PENDING
        ) {
          data.push({
            type: 'button',
            text: t('common.button.accept'),
            onClick: () => patchStatus(
              STATUS.ACCEPTED,
              delivery.id,
              delivery.marketPartyId,
              t,
            ),
          },
          {
            type: 'button',
            text: t('common.button.decline'),
            onClick: () => patchStatus(
              STATUS.DECLINED,
              delivery.id,
              delivery.marketPartyId,
              t,
            ),
          });
        }

        const action = data.length > 0 && <ActionButton data={data} />;

        return {
          ...delivery,
          key: delivery.id,
          start: moment.utc(delivery.start),
          end: moment.utc(delivery.end),
          marketPartyName: deliveryRelationshipMarketParty
            ? deliveryRelationshipMarketParty.name
            : t('common.status.notExists'),
          state,
          status: <StatusIcon
            status={delivery.status}
            withTitle
            title={capitalize(delivery.status)}
          />,
          action,
        };
      })
      .sort((a, b) => dateSort(a.start, b.start))
  );

  const renderEditLink = (meteringSiteId) => {
    const {
      match: {
        url,
      },
    } = props;
    const to = `${url}/${meteringSiteId}`;

    return (
      <Link to={to}>
        { t('common.button.edit') }
      </Link>
    );
  };

  const isEditAllowed = () => (
    isAdmin(true)
    || isAdminAsMarketParty()
    || userHasPermission(context, [PERMISSIONS.PRODUCER, PERMISSIONS.ENDUSER])
  );

  /**
   * Remapp data according to <Table /> `DATA_HEADERS`.
   *
   * @returns {Array<Object>} - Result of remapping.
   */
  const prepareData = () => {
    const { typesToShow } = props;

    // Return empty until context is ready
    if (meteringSites === null || loading) return [];

    const today = getGasDay();

    return meteringSites.reduce((result, site) => {
      if (!typesToShow.includes(site.type)) return result;

      const siteDRSData = prepareDRSData(site.id);
      const ownership = meteringPointOwnerships.find(item => item.meteringPointId === site.id
        && item.start <= today && (!item.end || item.end >= today)) || {};
      if (
        !isAdmin()
        && siteDRSData.length === 0
        && ownership.marketPartyId !== selectedMarketPartyId
      ) return result;

      const ongoing = siteDRSData.length === 1
        ? siteDRSData[0]
        : siteDRSData.find(item => item.state === STATES.ONGOING);
      const children = siteDRSData
        .filter(item => byState(item.state, props.selectedState)
        && (
          ongoing === undefined // If all DRS is past
            ? true
            : item.id !== ongoing.id
        ))
        .map(item => ({
          ...item,
          key: item.id,
          id: '-',
          name: '-',
          address: '-',
        }));

      const siteOwner = marketPartyIndex
        .find(marketParty => marketParty.id === ownership.marketPartyId);
      const analyser = meteringPointAnalysers.find(item => item.meteringPointId === site.id);

      const editColumn = isEditAllowed()
        ? {
          edit: ownership.marketPartyId === selectedMarketPartyId
            && site.type !== TYPES.CITYGATE // city gates does not have relationships that needs to be edited.
            ? renderEditLink(site.id) : '',
        }
        : {};

      return result.concat({
        ...site,
        owner: siteOwner !== undefined ? siteOwner.name : '',
        ownerCode: siteOwner ? siteOwner.code : '',
        analyser: getAnalyserName(analyser),
        type: t(`common.label.${site.type}`),
        ...editColumn,
        marketPartyName: ongoing !== undefined ? ongoing.marketPartyName : null,
        start: ongoing !== undefined ? ongoing.start : null,
        end: ongoing !== undefined ? ongoing.end : null,
        state: ongoing !== undefined ? ongoing.state : null,
        status: ongoing !== undefined ? ongoing.status : null,
        action: ongoing !== undefined ? ongoing.action : null,
        children: children.length > 0 ? children : null,
      });
    }, []);
  };

  const renderEditColumn = isEditAllowed();
  const isTSOAdmin = isReadOnlyAdmin(true) || isAdmin(true);

  return (
    <>
      <Table
        className="metering-sites-table"
        columns={getTranslatedTableHeaders(getDataHeaders(renderEditColumn, isTSOAdmin), t)}
        scroll={{ y: 'calc(100vb - 260px)', x: '100%' }}
        rowKey={({ key, id }) => `${key}_${id}`}
        dataSource={prepareData()}
        pagination={false}
        loading={loading}
      />
      {renderEditColumn && (
        <>
          <Route
            path="/deliveryrelationships/:id"
            component={DeliveryRelationship}
          />
          <Route
            path="/biogasentrypoints/:id"
            component={DeliveryRelationship}
          />
        </>
      )}
    </>
  );
};

MeteringSitesTable.propTypes = {
  selectedState: PropTypes.string,
  typesToShow: PropTypes.arrayOf(
    PropTypes.oneOf(TYPES_NAMES),
  ).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};
MeteringSitesTable.defaultProps = {
  selectedState: 'all',
};
MeteringSitesTable.displayName = 'MeteringSitesTable';

export default withRouter(MeteringSitesTable);
export { MeteringSitesTable as PureComponent };
