import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Avatar,
  Button,
} from 'antd';
import { withTranslation } from 'react-i18next';

import CapacityProductInstance from './CapacityProductInstance';

import { CAPACITY_PRODUCTS } from '../../constants/capacities';
import { startOfOngoingGasDay } from '../../utils/gasday';
import { getActivePricesForCapacityType } from '../../utils/capacityHelpers';
import { formatPrice } from '../../utils/i18n';
import './CapacityProduct.less';

const renderInstances = (
  instances,
  errors,
  onChange,
  onClose,
  capacityPrices,
) => instances.map(instance => (
  <CapacityProductInstance
    capacityPrices={capacityPrices}
    errors={errors.filter(error => error.id === instance.id)}
    key={instance.id}
    instance={instance}
    onChange={onChange}
    onClose={onClose}
  />
));

const CapacityProduct = (props) => {
  const {
    capacityPrices,
    errors,
    name,
    instances,
    onChange,
    onClose,
    onProductQuantityChange,
    type = null,
    t,
  } = props;
  const translatedProductName = t(`capacity.label.${CAPACITY_PRODUCTS[name]}`);
  const now = startOfOngoingGasDay().toISOString();
  const prices = getActivePricesForCapacityType(type, capacityPrices, now);
  const productPrice = prices[CAPACITY_PRODUCTS[name]];

  return (
    <div className="capacityproduct">
      <div className="capacityproduct__header">
        <Avatar
          className={classNames(
            'capacityproduct__header__avatar',
            { 'capacityproduct__header__avatar--active': instances.length > 0 },
          )}
        >
          {translatedProductName ? translatedProductName[0] : name[0]}
        </Avatar>
        <div className="capacityproduct__header__title">
          <h1 className="capacityproduct__header__title__text">{translatedProductName}</h1>
          <span className="capacityproduct__header__title__price">
            {productPrice !== undefined && `${formatPrice(productPrice, 6)}${t('capacityProductInstance.label.price')}`}
            &nbsp;
          </span>
        </div>
        <div className="capacityproduct__header__quantity">
          {/* <InputNumber
            defaultValue={0}
            min={0}
            onChange={quantity => onProductQuantityChange(name, quantity)}
          /> */}
          <Button
            className="capacityproduct__header__quantity__button"
            onClick={() => onProductQuantityChange(name)}
            type="primary"
            disabled={!type}
          >
            +1
          </Button>

        </div>
      </div>
      <div className="capacityproduct__instances">
        {renderInstances(instances, errors, onChange, onClose, capacityPrices)}
      </div>
    </div>
  );
};

CapacityProduct.propTypes = {
  capacityPrices: PropTypes.arrayOf(PropTypes.any).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      start: PropTypes.string,
      capacity: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProductQuantityChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CapacityProduct.defaultProps = {
  type: null,
};

CapacityProduct.displayName = 'CapacityProduct';

export default withTranslation()(CapacityProduct);
export {
  CapacityProduct as PureComponent,
};
