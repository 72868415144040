import React from 'react';
import { I18n } from 'aws-amplify';
import { View, useAuthenticator, Button } from '@aws-amplify/ui-react';
import { Typography } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo/index';

I18n.putVocabulariesForLanguage('en', {
  'Reset your password': 'Forgot your password?',
  'Send code': 'Submit',
  'Back to Sign In': 'Back to Login',
  'Signing in': 'Login...',
});

const { Text, Title } = Typography;

const getLoginConfig = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return ({
    components: {
      SignIn: {
        Header() {
          return (
            <View textAlign="center">
              <Logo
                className="login__container__logo"
                dark
              />
              <br />
              <Text type="secondary">{t('login.description.signInToPortal')}</Text>
            </View>
          );
        },
        Footer() {
          const { toResetPassword } = useAuthenticator();
          return (
            <div>
              <p className="login__container__forgot-password">
                <Button className="login__container__forgot-password__button" type="link" onClick={toResetPassword}>
                  {t('common.button.forgotPassword')}
                </Button>
              </p>
              <div className="ant-divider ant-divider-horizontal" />
              <p className="login__container__register__description">
                {t('login.description.registerAsMarketParty')}
                <Button
                  className="login__container__register__button"
                  href="/register"
                  as="a"
                >
                  {t('common.button.startRegistration')}
                </Button>
              </p>
            </div>
          );
        },
      },
      ConfirmSignIn: {
        Header() {
          return (
            <View textAlign="center">
              <Title level={3}>
                Confirm SMS Code
              </Title>
            </View>
          );
        },
      },
      ResetPassword: {
        Header() {
          return (
            <View textAlign="center">
              <Title level={3}>
                RECOVER PASSWORD
              </Title>
              <Typography>
                Submit your email address and we&apos;ll send you a verification code to reset your password.
              </Typography>
            </View>
          );
        },
      },
      ConfirmResetPassword: {
        Header() {
          return (
            <View textAlign="center">
              <Title level={3}>
                RECOVER PASSWORD
              </Title>
            </View>
          );
        },
      },
      Registration: {
        Header() {
          return (
            <div className="login__container__register">
              <p className="login__container__register__description">
                {t('login.description.registerAsMarketParty')}
              </p>
              <Button
                className="login__container__register__button"
                href="/register"
              >
                {t('common.button.startRegistration')}
              </Button>
            </div>
          );
        },
      },
    },

    formFields: {
      signIn: {
        username: {
          label: 'Username:',
          placeholder: '',
          'data-testid': 'username-input',
        },
        password: {
          label: 'Password:',
          placeholder: '',
          'data-testid': 'password-input',
        },
      },
      resetPassword: {
        username: {
          label: 'Email:',
          placeholder: '',
        },
      },
      confirmResetPassword: {
        confirmation_code: {
          label: 'Verification code:',
          placeholder: 'Code',
          isRequired: true,
        },
        confirm_password: {
          label: 'Confirm password:',
          placeholder: 'Password Again',
          isRequired: true,
        },
      },
      confirmSignIn: {
        confirmation_code: {
          label: 'Code: *',
          placeholder: '',
          isRequired: true,
          'data-testid': 'comfirm-signin-code-input',
        },
      },
    },
  });
};

export default getLoginConfig;
