import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Link,
  withRouter,
} from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Layout,
} from 'antd';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import PageHeaderBar from '../../components/PageHeaderBar';
import CapacityReservationsTable from '../../components/CapacityReservationsTable';
import CapacityRightsTransfersTable from '../../components/CapacityRightsTransfersTable';
import CapacityReservation from '../../components/CapacityReservation';
import CapacityTransfer from '../../components/CapacityTransfer';
import CapacityBookingSummaryTable from '../../components/CapacityBookingSummaryTable';
import { CAPACITY_WRITE_PERMISSIONS } from '../../constants/users';
import { userIsAllowedToWrite } from '../../utils/userHelpers';

import './index.less';

const Capacity = ({ history }) => {
  const {
    currentUser,
    selectedMarketPartyId,
    capacityOverruns,
    capacityPrices,
    updateCapacityPrices,
  } = useContext(Context);

  const { t } = useTranslation();
  const [newCapacityReservation, setNewCapacityReservation] = useState(null);
  const [newCapacityTransfer, setNewCapacityTransfer] = useState(null);

  const hasCapacityWritePermission = () => (
    userIsAllowedToWrite(
      { currentUser, selectedMarketPartyId },
      CAPACITY_WRITE_PERMISSIONS,
    )
  );

  useEffect(() => {
    if (!capacityPrices) updateCapacityPrices();
  }, []);

  const handleCloseCapacityReservation = (newReservation) => {
    if (newReservation !== undefined) {
      setNewCapacityReservation(newReservation);
    }

    history.push('/capacity');
  };

  const handleCloseCapacityTransfer = (newTransfer) => {
    if (newTransfer !== undefined) {
      setNewCapacityTransfer(newTransfer);
    }

    history.push('/capacity');
  };

  /**
   * Reservation <Button /> click handler.
   */
  const handleReservationClick = () => {
    history.push('/capacity/new-booking');
  };

  /**
   * Transfer <Button /> click handler.
   */
  const handleRightTransferClick = () => {
    history.push('/capacity/new-transfer');
  };

  const renderButtons = () => {
    if (!hasCapacityWritePermission()) {
      return null;
    }

    return (
      <>
        <Button
          className="page__header__buttons__reservation"
          type="primary"
          onClick={handleReservationClick}
        >
          {t('capacity.button.newCapacityReservation')}
        </Button>
        <Button
          className="page__header__buttons__transfer"
          type="primary"
          onClick={handleRightTransferClick}
        >
          {t('capacity.button.newCapacityRightsTransfer')}
        </Button>
      </>
    );
  };

  const renderCapacityReservation = () => (
    hasCapacityWritePermission()
      ? <CapacityReservation onClose={handleCloseCapacityReservation} />
      : null
  );


  const renderCapacityTransfer = () => (
    hasCapacityWritePermission()
      ? <CapacityTransfer onClose={handleCloseCapacityTransfer} />
      : null
  );


  const renderCapacityReservationsTableFooter = () => (
    <Link to="/capacitybookings">
      {t('capacity.link.viewAllCapacityReservation')}
      &nbsp;&#62;&#62;
    </Link>
  );

  const renderCapacityRightsTransfersTableFooter = () => (
    <Link to="/capacityrightstransfers">
      {t('capacity.link.viewAllCapacityRightsTransfer')}
      &nbsp;&#62;&#62;
    </Link>
  );

  return (
    <>
      <PageHeaderBar
        title={t('capacity.title.capacityOverview')}
        actionItems={renderButtons()}
      />
      <Layout.Content className="layout__container__page__content capacity">
        <Row
          className="capacity__tables"
          gutter={{ xl: 24 }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={12}
          >
            <CapacityReservationsTable
              title={t('capacity.title.capacityReservations')}
              subTitle={t('capacity.subtitle.capacityReservations')}
              footer={renderCapacityReservationsTableFooter()}
              visibleElementsCount={3}
              newCapacityReservation={newCapacityReservation}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={12}
          >
            <CapacityRightsTransfersTable
              title={t('capacity.title.capacityRightsTransfer')}
              subTitle={t('capacity.subtitle.capacityRightsTransfer')}
              footer={renderCapacityRightsTransfersTableFooter()}
              visibleElementsCount={3}
              newCapacityTransfer={newCapacityTransfer}
            />
          </Col>
        </Row>
        <Row className="capacity__summary">
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={24}
          >
            <CapacityBookingSummaryTable
              capacityOverruns={capacityOverruns || {}}
            />
          </Col>
        </Row>
      </Layout.Content>
      <Route
        path="/capacity/new-booking"
        render={renderCapacityReservation}
      />
      <Route
        path="/capacity/new-transfer"
        render={renderCapacityTransfer}
      />
    </>
  );
};

Capacity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Capacity.displayName = 'Capacity';

export default withRouter(Capacity);

export {
  Capacity as PureComponent,
};
