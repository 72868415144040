import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import {
  Amplify,
  Auth,
  Logger,
} from 'aws-amplify';
import {
  Authenticator,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import getLoginConfig from './containers/Login';
import GlobalState from './context/globalState';
import Loading from './components/Loading';
import i18n from './i18n';
// To make css smaller, we call this
import './misc/antdComponentStyles';

if (process.env.NODE_ENV === 'development' && process.env.LOG_LEVEL) window.LOG_LEVEL = process.env.LOG_LEVEL;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5b1f73677d924ffebddf3b82a8bf5be6@o1193385.ingest.sentry.io/4504099903766528',
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.GASGRID_PROFILE === 'tso-prod' ? 0.1 : 1.0,
    environment: process.env.GASGRID_PROFILE,
  });
}

const App = lazy(() => import('./app'));
const RegisterMarketParty = lazy(() => import('./containers/Register'));
const ForgotPassword = lazy(() => import('./containers/ForgotPassword'));
const DetectBrowser = lazy(() => import('./components/DetectBrowser'));

const logger = new Logger('index');
// parcel does not support process.env object destructuring!
const region = process.env.AWS_REGION;
const endpoint = process.env.API_BASE_URL;
const apiKey = process.env.API_KEY;
const userPoolId = process.env.COGNITO_USER_POOL_ID;
const userPoolWebClientId = process.env.COGNITO_APP_CLIENT_ID;
const identityPoolId = process.env.COGNITO_IDENTITY_POOL_ID;

/**
 * Below if block is needed to make the live reload work
 * https://esbuild.github.io/api/#live-reload
 */
if (window.IS_LOCAL) {
  new EventSource('/esbuild').addEventListener('change', () => window.location.reload());
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId,
  },
  API: {
    endpoints: [
      {
        name: 'FINTSO-noauth',
        endpoint,
        region,
        custom_header: () => ({
          'X-Api-Key': apiKey,
        }),
      },
      {
        name: 'FINTSO',
        endpoint,
        region,
        // https://aws-amplify.github.io/docs/js/api#custom-request-headers
        custom_header: async () => {
          try {
            const session = await Auth.currentSession();
            const token = session.idToken.jwtToken;
            return {
              'X-Api-Key': apiKey,
              Authorization: token,
            };
          } catch (error) {
            logger.error('tried to make authenticated API call without credentials!');
            return {};
          }
        },
      },
      {
        name: 'localhost',
        endpoint: 'http://localhost:3000',
        region,
        // https://aws-amplify.github.io/docs/js/api#custom-request-headers
        custom_header: async () => {
          try {
            const session = await Auth.currentSession();
            const token = session.idToken.jwtToken;
            return {
              'X-Api-Key': apiKey,
              Authorization: token,
            };
          } catch (error) {
            logger.error('tried to make authenticated API call without credentials!');
            return {};
          }
        },
      },
    ],
  },
});

const AppWithAuthenticator = () => {
  const { components, formFields } = getLoginConfig();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  return (
    <Authenticator
      className="login"
      components={components}
      formFields={formFields}
    >
      {(signOut, user) => (
        <App user={user} authState={authStatus} />
      )}

    </Authenticator>
  );
};

const mainScreen = () => (
  <Authenticator.Provider>
    <Sentry.ErrorBoundary showDialog>
      <Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <DetectBrowser />
          <GlobalState>
            <Router>
              <Switch>
                <Route
                  path="/register"
                  render={() => <RegisterMarketParty />}
                  exact
                />
                <Route
                  path="/forgot-password"
                  render={() => <ForgotPassword />}
                  exact
                />
                <Route render={() => (<AppWithAuthenticator />)} />
              </Switch>
            </Router>
          </GlobalState>
        </I18nextProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  </Authenticator.Provider>
);

ReactDOM.render(
  mainScreen(),
  document.getElementById('app'),
);
