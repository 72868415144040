import React from 'react';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

/**
 * Format date for UI.
 *
 * @param {String} date - ISO date string.
 * @returns {String | null} - Formatted date.
 */
const getDate = date => (
  date !== undefined && date !== null
    ? moment(date).format('L')
    : null
);

/**
 * Format time for given date for UI.
 *
 * @param {String} date - ISO date string.
 * @returns {String | null} - Formatted time.
 */
const getTime = date => (
  date !== undefined && date !== null
    ? moment(date).format('LT')
    : null
);

/**
 * Format month name for given date for UI.
 *
 * @param {String} date - ISO date string.
 * @returns {String | null} - Month name.
 */
const getMonth = date => (
  date !== undefined && date !== null
    ? moment(date).format('MMMM')
    : null
);

/**
 * Format date and time for UI presentation.
 *
 * @param {String} date - ISO date string.
 * @param {String} nameOfClass - Name that will be added to className.
 * @returns {JSX | null} - Rendered fragment.
 */
const getDateAndTime = (date, nameOfClass) => (
  date !== undefined && date !== null
    ? (
      <>
        <div className={`${nameOfClass}-date`}>{getDate(date)}</div>
        <div className={`${nameOfClass}-time`}>{getTime(date)}</div>
      </>
    )
    : null
);

const getDateAndTimeStr = (date) => (
  `${getDate(date)} ${getTime(date)}`
);


const getDatesInRange = (start, end) => {
  const currentMoment = moment(start);
  const endMoment = moment(end);

  if (
    end === undefined
    || currentMoment > endMoment
  ) return [currentMoment.format('YYYY-MM-DD')];

  const dates = Array
    .from(moment.range(start, end).by('day'))
    .map(date => date.format('YYYY-MM-DD'));

  return dates;
};

const getHoursInRange = (start, end) => Array
  .from(moment.range(
    moment.tz(start, 'Europe/Helsinki'),
    moment.tz(end, 'Europe/Helsinki'),
  ).by('hour'))
  .map(time => time.format('HH:mm'));

const isDateInRange = (date, start, end) => moment.range(moment.utc(start), end && moment.utc(end))
  .contains(moment.utc(date), {
    excludeStart: false,
    excludeEnd: false,
  });

const getStartOfNthPeriod = (comparedTo, period, n = 1) => moment
  .utc(comparedTo)
  .add(n, period)
  .startOf(period);

const getLatestDate = (dates = []) => moment.unix(
  Math.max(...dates.filter(date => !!date).map(date => moment(date).unix()), 0),
).format('YYYY-MM-DD');

/**
 * @param {Number} timestamp - Unix timestamp in seconds or milliseconds
 * @returns Datetime string formatted for UI
 */
const unixToDateTimeStr = (timestamp) => {
  const dt = moment.unix(timestamp);
  const date = getDate(dt);
  const time = getTime(dt);
  return `${date} ${time}`;
};

export {
  getDate,
  getDatesInRange,
  getHoursInRange,
  getTime,
  getDateAndTime,
  getDateAndTimeStr,
  getMonth,
  getLatestDate,
  getStartOfNthPeriod,
  isDateInRange,
  unixToDateTimeStr,
};
