import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Table,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import getTranslatedTableHeaders from '../../utils/translationHelpers';
import './index.less';

/**
 * Render action buttons on the top right sidebar of the table
 *
 * @param {string|JSX} sidebarActions - string/component to be displayed on top right sidebar
 *
 * @returns {JSX} - Result of rendering.
 */
const renderSidebarActions = (sidebarActions, stacked = false) => (
  <Col
    xs={24}
    sm={24}
    md={24}
    xl={stacked ? 24 : 14}
    className="summarytable__header__action-buttons"
  >
    {sidebarActions}
  </Col>
);

/**
 * Render header of table.
 *
 * @param {string} title - Title of whole table.
 * @param {string} subTitle - Extra line of text.
 *
 * @returns {JSX} - Result of rendering.
 */
const renderHeader = (title, subTitle, headerActions, stacked = false) => (
  <Layout.Header className="summarytable__header">
    <Row>
      <Col
        xs={24}
        sm={24}
        md={24}
        xl={!stacked && headerActions ? 10 : 24}
      >
        {title !== '' && <div className="summarytable__header__title">{title}</div>}
        <span className="summarytable__header__subtitle">{subTitle}</span>
      </Col>
      { !stacked && headerActions && renderSidebarActions(headerActions) }
    </Row>
    { stacked && headerActions && renderSidebarActions(headerActions, stacked) }
  </Layout.Header>
);

/**
 * Render footer part.
 *
 * @param {string|JSX} footer - string/component to see all items.
 *
 * @returns {JSX} - Result of rendering.
 */
const renderFooter = footer => (
  <Layout.Footer className="summarytable__footer">
    {footer}
  </Layout.Footer>
);

const SummaryTable = (props) => {
  const {
    title,
    subTitle,
    tableHeaders,
    tableData,
    footer,
    isLoading,
    onSelectRow,
    className,
    headerActions,
    stackedHeader,
    rowKey,
    expandIcon,
    expandedRowRender,
    rowClassName,
  } = props;
  const { t } = useTranslation();

  const defaultRowKey = (record, index) => (
    record.id !== undefined
      ? `${record.id}_${index}`
      : `record_${index}`
  );

  return (
    <Layout className="summarytable">
      { title !== null && renderHeader(title, subTitle, headerActions, stackedHeader) }
      <div className="summarytable__container">
        <Layout.Content className="summarytable__content">
          <Table
            columns={getTranslatedTableHeaders(tableHeaders, t)}
            rowKey={rowKey || defaultRowKey}
            className={classnames(
              'summarytable__table',
              {
                [className]: className !== undefined,
              },
            )
            }
            dataSource={tableData}
            loading={isLoading}
            pagination={false}
            onRow={onSelectRow}
            expandIcon={expandIcon}
            expandedRowRender={expandedRowRender}
            rowClassName={rowClassName}
          />
        </Layout.Content>
        { footer && renderFooter(footer) }
      </div>
    </Layout>
  );
};

SummaryTable.displayName = 'SummaryTable';
SummaryTable.propTypes = {
  tableHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.node,
      key: PropTypes.node,
    }),
  ).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  footer: PropTypes.node,
  isLoading: PropTypes.bool,
  onSelectRow: PropTypes.func,
  headerActions: PropTypes.node,
  rowKey: PropTypes.func,
  expandIcon: PropTypes.func,
  expandedRowRender: PropTypes.func,
  stackedHeader: PropTypes.bool,
  rowClassName: PropTypes.func,
};
SummaryTable.defaultProps = {
  className: undefined,
  tableData: [],
  title: null,
  subTitle: null,
  isLoading: false,
  footer: null,
  headerActions: null,
  onSelectRow: () => {},
  rowKey: undefined,
  expandIcon: undefined,
  expandedRowRender: undefined,
  stackedHeader: false,
  rowClassName: () => '',
};

export default SummaryTable;
