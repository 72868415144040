/**
 * This file contains color codes in JS.
 * Please verify the color codes are also present in the ~/src/index.less file
 */
const COLOR_GREEN = '#53C41A';
const COLOR_YELLOW = '#FAAD15';

export {
  COLOR_GREEN,
  COLOR_YELLOW,
};
