import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import moment from 'moment';

import { createErrorMessage } from './globalStateHelper';
import { getGasDay } from '../utils/gasday';
import { LOCATION_POINT_NAMES, DIRECTION_NAMES } from '../constants/nominations';

const NOW = moment.utc();

/**
 * Fetch and update NOMINTs.
 */
async function updateNomints(
  start = getGasDay(NOW.clone().subtract(30, 'days')),
  end = getGasDay(NOW.clone().add(1, 'day')),
) {
  const log = new Logger('context:nomints');
  const { t } = this.props;
  try {
    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const init = {
      queryStringParameters: {
        start,
        end,
      },
    };
    const url = isAdmin() || isReadOnlyAdmin()
      ? '/admin/nominations/nomint'
      : `/marketparties/${selectedMarketPartyId}/nominations/nomint`;
    const { items: nomints } = await API.get('FINTSO', url, init);

    this.setState({
      nomints,
      isNomintsLoaded: true,
    });
    log.info('NOMINTs updated with data:', nomints);
  } catch (error) {
    this.setState({
      nomints: [],
      isNomintsLoaded: true,
    });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingNominations'),
      description,
    });
    log.error('error fetching NOMINTs:', error.message, description);
  }
}

/**
 * Fetch and update NOMRESes.
 */
async function updateNomreses(
  start = getGasDay(NOW.clone().subtract(30, 'days')),
  end = getGasDay(NOW.clone().add(1, 'day')),
) {
  const log = new Logger('context:nomreses');
  const { t } = this.props;

  try {
    const options = {
      queryStringParameters: { start, end },
    };

    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const url = isAdmin() || isReadOnlyAdmin()
      ? '/admin/nominations/nomres'
      : `/marketparties/${selectedMarketPartyId}/nominations/nomres`;
    const { items: nomreses } = await API.get('FINTSO', url, options);

    this.setState({
      nomreses,
      isNomresesLoaded: true,
    });
    log.info('NOMRESes updated with data:', nomreses);
  } catch (error) {
    this.setState({
      nomreses: [],
      isNomresesLoaded: true,
    });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingNominations'),
      description,
    });
    log.error('error fetching NOMRESes:', error.message, description);
  }
}

/**
 * Fetch nominations summary
 */
async function updateNominationsSummary(startDate, endDate) {
  const log = new Logger('context:nominationsSummary');
  const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
  const { t } = this.props;
  const emptySummary = {
    nomint: [],
    nomres: [],
  };

  if (isAdmin() || isReadOnlyAdmin()) {
    this.setState({ nominationsSummary: emptySummary });
    return;
  }

  try {
    const start = startDate || getGasDay(NOW.clone().subtract(5, 'days'));
    const end = endDate || getGasDay(NOW.clone().add(5, 'day'));
    const options = {
      queryStringParameters: {
        start,
        end,
        point: LOCATION_POINT_NAMES,
        direction: DIRECTION_NAMES,
      },
    };

    const url = `/marketparties/${selectedMarketPartyId}/nominations/summary`;
    const {
      nomint: { items: nomint },
      nomres: { items: nomres },
    } = await API.get('FINTSO', url, options);
    const nominationsSummary = { nomint, nomres };

    this.setState({ nominationsSummary });
    log.info('nominations summary updated with data:', nominationsSummary);
  } catch (error) {
    this.setState({ nominationsSummary: emptySummary });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingNominationsSummary'),
      description,
    });
    log.error('error fetching nominations summary:', error.message, description);
  }
}

const nominationsContext = {
  nomints: [],
  isNomintsLoaded: false,
  updateNomints: () => {},
  nomreses: [],
  isNomresesLoaded: false,
  updateNomreses: () => {},
  nominationsSummary: null,
  updateNominationsSummary: () => {},
};

const functions = {
  updateNomints,
  updateNomreses,
  updateNominationsSummary,
};

const nominations = {
  ...nominationsContext,
  ...functions,
};

export {
  nominationsContext,
  nominations,
};
