import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  notification,
  Modal,
  Row,
  Col,
  Table,
  Avatar,
  Icon,
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import { API, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import getTranslatedTableHeaders from '../../utils/translationHelpers';
import {
  calculateCapacityPrice,
  getTotalPriceOfProducts,
  hoursInBooking,
  getEndDate,
  getUpdateTimestamp,
} from '../../utils/capacityHelpers';
import { formatPrice, formatDateTimeStr } from '../../utils/i18n';
import { STATUS } from '../../constants/status';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../StatusIcon';
import ModalWrapper from '../ModalWrapper';

import './index.less';
import { CAPACITY_WRITE_PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';
import CapacityReservationDeleteButton from '../CapacityReservationDeleteButton';

const logger = new Logger('components:capacity-reservation-view');
const DATA_HEADERS = [
  {
    title: 'product',
    dataIndex: 'period',
    key: 'period',
  },
  {
    title: 'timePeriod',
    dataIndex: 'timePeriod',
    key: 'timePeriod',
  },
  {
    title: 'capacity',
    dataIndex: 'capacity',
    key: 'capacity',
  },
  {
    title: 'totalCapacity',
    dataIndex: 'totalCapacity',
    key: 'totalCapacity',
  },
  {
    title: 'price',
    dataIndex: 'price',
    key: 'price',
  },
];

class CapacityReservationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reservation: null,
    };
  }

  prepareReservationData = async () => {
    const { match: { params: { id, marketPartyId } }, t } = this.props;
    const { capacityReservations } = this.context;
    let capacityReservation = null;

    this.setState({ isLoading: true });

    if (id && marketPartyId) {
      // fetch booking from server if it's empty
      if (capacityReservations === null) {
        try {
          capacityReservation = await API.get('FINTSO', `/marketparties/${marketPartyId}/capacitybookings/${id}`);
        } catch (error) {
          notification.error({
            className: 'notification-error',
            message: t('capacityReservation.message.fetchError'),
            description: createErrorMessage(error),
          });
        }
      } else {
        [capacityReservation] = capacityReservations.filter(
          reservation => (reservation.id === id && reservation.marketPartyId === marketPartyId),
        );
      }
    }

    this.setState({
      isLoading: false,
      reservation: capacityReservation,
    });
  }

  componentDidMount = async () => {
    const {
      capacityPrices,
      updateCapacityPrices,
      marketPartyIndex,
      updateMarketpartyIndex,
    } = this.context;

    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (!capacityPrices) updateCapacityPrices();
    await this.prepareReservationData();
  }

  handleConfirmationOkClick = async (status) => {
    const { handleDbChange } = this.context;
    const { reservation: { id, marketPartyId } } = this.state;
    const { t } = this.props;
    const data = { body: { status } };

    this.setState({ isLoading: true });

    try {
      const reservationUrl = `/marketparties/${marketPartyId}/capacitybookings/${id}`;
      const upgradedCapacityReservation = await API.patch('FINTSO', reservationUrl, data);
      // Upgrade CR in case websocket's message in delay
      handleDbChange('CapacityReservation', 'update', upgradedCapacityReservation);
      notification.success({
        message: `${t('capacityReservation.title.capacityReservation')} ${t('common.status.successfullyUpdated')}`,
        description: '',
      });
      this.setState({
        isLoading: false,
        reservation: upgradedCapacityReservation,
      });
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: `${t('common.notifications.errorUpdating')} ${t('capacityReservation.title.capacityReservation').toLowerCase()}`,
        description: createErrorMessage(error),
      });
      this.setState({ isLoading: false });
      logger.error(createErrorMessage(error, true));
    }
  }

  handleConfirmation = (action) => {
    const status = action === 'accept'
      ? STATUS.ACCEPTED
      : STATUS.DECLINED;
    const { t } = this.props;
    const translatedAction = t(`common.status.${action}`);

    Modal.confirm({
      key: 'modalConfirmation',
      centered: true,
      title: t('capacityReservation.dialog.actionMessage', { action: translatedAction }),
      okText: t('common.button.ok'),
      cancelText: t('common.button.cancel'),
      onOk: () => this.handleConfirmationOkClick(status),
    });
  }

  renderActionButtons = () => {
    const { reservation } = this.state;
    const { t, actionsVisible, onClose } = this.props;
    const { isAdmin, isAdminAsMarketParty } = this.context;
    const closeButton = [
      <Button
        className="capacityreservationview__close-button"
        key="close"
        onClick={onClose}
      >
        {t('common.button.close')}
      </Button>,
    ];

    const declineButton = (isAdmin(true)
      || isAdminAsMarketParty()
      || userHasPermission(this.context, CAPACITY_WRITE_PERMISSIONS)
    )
      ? [
        <Button
          className="capacityreservationview__decline-button"
          key="decline"
          type={!isAdmin() ? 'primary' : ''}
          onClick={() => this.handleConfirmation('decline')}
        >
          {t('common.button.decline')}
        </Button>,
      ]
      : [];

    if (actionsVisible
      && reservation
      && reservation.status === STATUS.PENDING) {
      return isAdmin()
        ? ([
          ...closeButton,
          ...declineButton,
          <Button
            className="capacityreservationview__accept-button"
            key="accept"
            type="primary"
            onClick={() => this.handleConfirmation('accept')}
          >
            {t('common.button.accept')}
          </Button>,
        ])
        : ([
          ...closeButton,
          ...declineButton,
        ]);
    }

    return closeButton;
  }

  getMarketPartyName = () => {
    const { reservation: { marketPartyId } } = this.state;
    const { marketPartyIndex } = this.context;
    const marketParty = marketPartyIndex && marketPartyIndex.find(({ id }) => id === marketPartyId);

    return marketParty !== undefined ? marketParty.name : '';
  }

  renderHeaderInfo = (products) => {
    const { reservation } = this.state;
    const { isAdmin } = this.context;
    const { t, onClose } = this.props;
    const range = products.reduce(({ start, end }, { startTime, endTime }) => ({
      start: moment.utc(start).isBefore(moment.utc(startTime)) ? start : startTime,
      end: moment.utc(end).isAfter(moment.utc(endTime)) ? end : endTime,
    }),
    {
      start: products[0].startTime,
      end: products[0].endTime,
    });
    const reservedFor = `${formatDateTimeStr(range.start)} - ${formatDateTimeStr(range.end)}`;
    const hasNotes = !!reservation.note;

    return (
      <div className="capacityreservationview__header-info" data-testid="capacityreservationview__header-info">
        <Row>
          <Col span={12}>
            <p className="capacityreservationview__header-info__label">
              {t('common.table.header.marketPartyName')}
            </p>
            <p className="capacityreservationview__header-info__text">
              {this.getMarketPartyName()}
            </p>
          </Col>
          <Col span={12}>
            <p className="capacityreservationview__header-info__label">
              {t('common.table.header.reservedFor')}
            </p>
            <p className="capacityreservationview__header-info__text">
              {reservedFor}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="capacityreservationview__header-info__label">
              {t('common.table.header.point')}
            </p>
            <p className="capacityreservationview__header-info__text">
              {t(`capacity.point.${products[0].type}`)}
            </p>
          </Col>
          <Col span={6}>
            <div className="capacityreservationview__header-info__status-container">
              <p className="capacityreservationview__header-info__label">
                {t('common.table.header.status')}
              </p>
              <p className="capacityreservationview__header-info__text">
                <StatusIcon status={reservation.status} />
                <span className="status">{reservation.status}</span>
              </p>
            </div>
          </Col>
          <Col span={6} className="capacityreservationview__cancel-button">
            <CapacityReservationDeleteButton
              t={t}
              isAdmin={isAdmin}
              reservation={reservation}
              onClose={onClose}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="capacityreservationview__header-info__label">
              {t('common.table.header.created')}
            </p>
            <p className="capacityreservationview__header-info__text">
              {formatDateTimeStr(reservation.createdAt)}
            </p>
          </Col>
          <Col span={12}>
            <p className="capacityreservationview__header-info__label">
              {t('common.table.header.processed')}
            </p>
            <p className="capacityreservationview__header-info__text">
              {formatDateTimeStr(getUpdateTimestamp(reservation.statusChangedAt), '-')}
            </p>
          </Col>
        </Row>
        {hasNotes && (
          <Row>
            <Col span={24} className="notes_container">
              <p className="capacityreservationview__header-info__label">
                <Icon theme="filled" type="warning" />
                &nbsp;
                {t('common.table.header.notes')}
              </p>
              <p className="capacityreservationview__header-info__text">
                {reservation.note}
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  getRemappedProducts = () => {
    const { reservation: { products } } = this.state;
    const { capacityPrices } = this.context;
    return products.map(product => ({
      ...product,
      startTime: product.start,
      endTime: getEndDate(product),
      price: calculateCapacityPrice(product, capacityPrices),
    }));
  }

  renderProducts = (products) => {
    const { t } = this.props;
    const dataSource = products.map((product, key) => ({
      key,
      period: (
        <Avatar
          className={classNames(
            'capacityproduct__header__avatar',
            'capacityproduct__header__avatar--active',
          )}
        >
          { t(`capacity.label.${product.period}`)[0]}
        </Avatar>
      ),
      timePeriod: `${formatDateTimeStr(product.startTime)} - ${formatDateTimeStr(product.endTime)}`,
      capacity: `${product.capacity} ${t('capacityProductInstance.label.unit')}`,
      totalCapacity: `${product.capacity * hoursInBooking(product)} kWh`,
      price: `${formatPrice(product.price)}`,
    }));

    return (
      <Table
        className="capacityreservationview__products__table"
        data-testid="capacityreservationview__products__table"
        columns={getTranslatedTableHeaders(DATA_HEADERS, t)}
        dataSource={dataSource}
        pagination={false}
      />
    );
  }

  renderContent = () => {
    const { t } = this.props;
    const { reservation } = this.state;
    const { capacityPrices } = this.context;

    if (reservation === null || capacityPrices === null) return null;

    const products = this.getRemappedProducts();
    const totalPrice = getTotalPriceOfProducts(products);

    return (
      <>
        {this.renderHeaderInfo(products)}
        <div className="capacityreservationview__products">
          <h3 className="capacityreservationview__products-title">
            {t('capacityReservation.title.products')}
          </h3>
          {this.renderProducts(products)}
        </div>
        <div className="capacityreservationview__total-price" data-testid="capacityreservationview__total-price">
          <h3 className="capacityreservationview__total-price__label">
            {t('capacityReservation.form.label.totalPrice')}
          </h3>
          <p className="capacityreservationview__total-price__sum">
            {`${formatPrice(totalPrice)}`}
          </p>
        </div>
      </>
    );
  }

  render = () => {
    const {
      isLoading,
      reservation,
    } = this.state;
    const { capacityPrices } = this.context;
    const {
      t,
      onClose,
    } = this.props;

    return (
      <ModalWrapper
        modalClassName="capacityreservationview"
        title={t('capacityReservation.title.capacityReservation')}
        handleClose={onClose}
        actionButtons={this.renderActionButtons()}
        isLoading={isLoading || !reservation || !capacityPrices}
        width="60%"
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

CapacityReservationView.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
      marketPartyId: PropTypes.string,
    }),
  }).isRequired,
  actionsVisible: PropTypes.bool.isRequired,
};

CapacityReservationView.displayName = 'CapacityReservationView';
CapacityReservationView.contextType = Context;

const CapacityReservationViewTranslated = withTranslation()(CapacityReservationView);

export default withRouter(CapacityReservationViewTranslated);
export {
  CapacityReservationView as PureComponent,
};
