import Decimal from 'decimal.js';
import moment from 'moment';

// TODO: Get proper locale from global state after change in locale format
const locale = 'fi-FI';

const priceFormatter = minFraction => (
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: minFraction,
  }));

const numberFormatter = (minFraction, maxFraction) => (
  Intl.NumberFormat(locale, {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  })
);

const formatPrice = (price, minFraction = 2) => (
  priceFormatter(minFraction).format(price || 0)
);

const formatNumber = (number, minFraction = 0, maxFraction = undefined) => (
  numberFormatter(minFraction, maxFraction).format(number || 0)
);

const formatPercentage = n => `${formatNumber(new Decimal(n || 0).mul(100), 2, 2)} %`;

const localeDateFormat = () => moment.localeData().longDateFormat('L');
const localeTimeFormat = () => moment.localeData().longDateFormat('LT');
const formatTime = time => moment(time).format(localeTimeFormat());
const formatDate = date => moment(date).format(localeDateFormat());
const formatDateStr = date => formatDate(`${date}T00:00:00Z`);
const formatDateTimeStr = (dateTime, placeholder = '') => {
  if (!dateTime) {
    return placeholder;
  }
  if (dateTime.includes('T')) {
    return `${formatDate(dateTime)} ${formatTime(dateTime)}`;
  }

  return formatDateStr(dateTime);
};

export {
  formatPrice,
  formatTime,
  formatDate,
  formatDateStr,
  formatDateTimeStr,
  formatNumber,
  formatPercentage,
  localeDateFormat,
};
