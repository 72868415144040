import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, DatePicker, Empty, Layout, Row,
} from 'antd';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import PageHeaderBar from '../../components/PageHeaderBar';
import Context from '../../context';
import DeclareGasTaxTable from '../../components/DeclareGasTaxTable';
import { UNITS } from '../../constants/units';
import { getRangeForResolution } from '../MeteringSites/utils';
import { RESOLUTIONS } from '../../constants/meteringSites';
import { getEndOfMonth, getStartOfMonth } from '../../utils/meteringSitesHelpers';

import './index.less';
import { getNthDay, startOfGasDay, getNthMonth } from '../../utils/gasday';

const moment = extendMoment(Moment);
const { MonthPicker } = DatePicker;

const DECLARATION_CLOSING_DAY = 15;
const DECLARATION_CLOSING_HOUR = 1;

const DeclareGasTax = () => {
  const {
    meteringSites,
    meteringSiteConsumptions: {
      data,
      requestParams: {
        start,
        end,
        resolution,
      },
    },
    taxDeclarations,
    deliveryRelationshipsLoading,
    loadingMeteringSites,
    meteringSiteConsumptionsLoading,
    meteringPointOwnershipsLoading,
    loadingTaxDeclarations,
    updateMeteringSiteConsumptions,
    selectedMarketPartyId,
    meteringPointOwnerships,
    deliveryRelationships,
    updateMeteringSites,
    updateDeliveryRelationships,
    updateMeteringPointOwnerships,
    updateTaxDeclarations,
  } = useContext(Context);

  const { t } = useTranslation();

  // Deduct which period is currently open
  const previousPeriod = getStartOfMonth(moment().subtract(1, 'months'));
  const previousPeriodEnd = startOfGasDay(
    getNthDay(getNthMonth(previousPeriod, 1), DECLARATION_CLOSING_DAY - 1),
  ).add(DECLARATION_CLOSING_HOUR, 'hours');
  const defaultPeriod = moment().isSameOrAfter(previousPeriodEnd)
    ? getStartOfMonth(moment())
    : previousPeriod;

  const [selectedStartDate, setSelectedStartDate] = useState(defaultPeriod);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultPeriod);

  const selectedUnit = UNITS.MWH;
  const selectedResolution = RESOLUTIONS.MONTH;

  const hasNoMeteringSites = () => (
    meteringSites === null || meteringSites.length === 0
  );

  // Returns period for querying tax declaration data.
  // Period is the start of the month to be declared (previous month).
  const getPeriod = () => (
    moment(selectedStartDate).format('YYYY-MM')
  );
  const period = getPeriod();

  // FIXME fetchData is called twice on page load
  const fetchData = () => {
    const { start: startDate, end: endDate } = getRangeForResolution(
      selectedStartDate,
      selectedEndDate,
      selectedResolution,
    );
    updateMeteringSiteConsumptions(startDate, endDate, selectedResolution);
    updateTaxDeclarations(period);
  };

  const siteNotBelongToMarketParty = (
    hasNoMeteringSites() || meteringSites.some((meteringSite) => {
      const relationship = deliveryRelationships.find(
        ({ meteringSiteId, marketPartyId }) => (
          meteringSiteId === meteringSite.id
          && marketPartyId === selectedMarketPartyId
        ),
      );
      const ownership = meteringPointOwnerships.find(
        ({ meteringPointId, marketPartyId }) => (
          meteringPointId === meteringSite.id
          && marketPartyId === selectedMarketPartyId
        ),
      );
      return meteringSite.marketPartyId !== selectedMarketPartyId && !relationship && !ownership;
    })
  );

  useEffect(() => {
    if (siteNotBelongToMarketParty) {
      updateMeteringSites();
      updateDeliveryRelationships();
      updateMeteringPointOwnerships();
    }
    if (!siteNotBelongToMarketParty) {
      fetchData();
    }
  }, [selectedMarketPartyId, siteNotBelongToMarketParty]);

  useEffect(() => fetchData(), [selectedStartDate, selectedEndDate]);

  const handleDateChange = (value) => {
    setSelectedStartDate(getStartOfMonth(value));
    setSelectedEndDate(getEndOfMonth(value));
  };

  const loading = deliveryRelationshipsLoading
    || loadingMeteringSites
    || meteringSiteConsumptionsLoading
    || meteringPointOwnershipsLoading
    || loadingTaxDeclarations;

  const filteredData = taxDeclarations.filter(declaration => declaration.period === period);

  const renderMonthPicker = () => (
    <MonthPicker
      defaultValue={defaultPeriod}
      allowClear={false}
      format={moment.localeData().postformat('MMMM YYYY')}
      value={selectedStartDate}
      onChange={handleDateChange}
    />
  );

  const renderHeader = () => (
    <Col className="meteringsite__date">
      <div className="meteringsite__date__title">
        Chosen month
      </div>
      <div className="meteringsite__date__selector">
        <div>
          {renderMonthPicker()}
        </div>
      </div>
    </Col>
  );

  const renderTable = () => (
    <DeclareGasTaxTable
      consumptionsDataSource={data}
      taxDeclarationsDataSource={filteredData}
      isLoading={loading}
      start={start}
      end={end}
      resolution={resolution}
      unit={selectedUnit}
    />
  );

  const renderContent = () => (
    hasNoMeteringSites()
      ? <Empty />
      : (
        <>
          <Row>
            {renderHeader()}
          </Row>
          <Row>
            {renderTable()}
          </Row>
        </>
      )
  );

  return (
    <>
      <PageHeaderBar title={t('declareGasTax.title')} />
      <Layout.Content className="layout__container__page__content declare-gastax">
        {renderContent()}
      </Layout.Content>
    </>
  );
};

export default DeclareGasTax;
