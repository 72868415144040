import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Form,
  InputNumber,
  notification,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import {
  isSetDailyCapacityOpen, isSetHourlyCapacityOpen,
} from '../../utils/capacityAndNominationsHelper';
import CardRow from '../CardRow';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';

import './index.less';

const logger = new Logger('components:capacity-availability-form');

const CAPACITY_TYPE = {
  DAILY: 'daily',
  HOURLY: 'hourly',
};

class CapacityAvailabilityForm extends Component {
  constructor(props) {
    super(props);
    const { data: { daily = 0, hourly = 0 } } = props;
    this.state = {
      daily,
      hourly,
      isLoading: false,
    };
  }

  isDirty = () => {
    const { data } = this.props;
    const { daily, hourly } = this.state;
    return daily !== data.daily || hourly !== data.hourly;
  }

  handleInputChange = (value, key) => {
    this.setState({ [key]: value });
  }

  handleCancel = (key) => {
    const { data } = this.props;
    this.setState({ [key]: data[key] });
  }

  handleSave = async (key) => {
    const { handleDbChange } = this.context;
    const { daily, hourly } = this.state;
    const { t, data: { date } } = this.props;

    this.setState({ isLoading: true });

    try {
      const queryStringParameters = key === CAPACITY_TYPE.DAILY ? { daily } : { hourly };
      const response = await API.patch('FINTSO', `/admin/capacity/imatra/${date}`, { queryStringParameters });
      handleDbChange('TotalCapacity', 'update', response);
      notification.success({
        message: t('capacityAvailabilityForm.message.updatedSuccessfully'),
        description: '',
      });
      this.setState({ isLoading: false });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('capacityAvailabilityForm.message.errorUpdating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
      this.setState({ isLoading: false });
    }
  }

  renderButtons = (key) => {
    const { t } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="button-container">
        <Button
          className="capacity-availability-form__cancel-button"
          key="cancel"
          onClick={() => this.handleCancel(key)}
          disabled={!this.isDirty()}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          className="capacity-availability-form__save-button"
          key="save"
          type="primary"
          loading={isLoading}
          onClick={() => this.handleSave(key)}
          disabled={!this.isDirty()}
        >
          {t('common.button.save')}
        </Button>
      </div>
    );
  }

  renderForm = (key) => {
    const { t, data } = this.props;
    const { [key]: inputValue } = this.state;
    const hasError = false;

    return (
      <Form
        key={`capacity-availability-form-${data.date}`}
        className="capacity-availability-form"
      >
        <Form.Item
          key={`capacity-availability-form-item${data.date}`}
          className={classNames(
            'capacity-availability-form__item',
            { 'has-error': hasError },
          )}
        >
          <InputNumber
            className="capacity-availability-form__item__input"
            name="availability"
            onChange={value => this.handleInputChange(value, key)}
            value={inputValue}
          />
          {hasError && (
            <p className="capacity-availability-form__item__error">
              {t('common.notifications.incorrectInput')}
            </p>
          )}
        </Form.Item>
        {this.renderButtons(key)}
      </Form>
    );
  }

  render = () => {
    const { t, data: { date } } = this.props;

    return (
      <>
        <h3>{t('capacityAvailabilityForm.title.setOfferedCapacity')}</h3>
        <CardRow
          label={t('capacityAvailabilityForm.label.dailyCapacity')}
          value={isSetDailyCapacityOpen(date)
            ? this.renderForm(CAPACITY_TYPE.DAILY)
            : t('capacityAvailabilityForm.label.closedForTheDay')}
        />
        <CardRow
          label={t('capacityAvailabilityForm.label.withinDayCapacity')}
          value={isSetHourlyCapacityOpen(date)
            ? this.renderForm(CAPACITY_TYPE.HOURLY)
            : t('capacityAvailabilityForm.label.notYetOpened')}
        />
      </>
    );
  }
}

CapacityAvailabilityForm.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    availableCapacity: PropTypes.number,
    bookedCapacity: PropTypes.number,
    nominations: PropTypes.number,
    date: PropTypes.string,
    daily: PropTypes.number,
    hourly: PropTypes.number,
  }),
};
CapacityAvailabilityForm.defaultProps = {
  data: {},
};

CapacityAvailabilityForm.displayName = 'CapacityAvailabilityForm';
CapacityAvailabilityForm.contextType = Context;

export default withTranslation()(CapacityAvailabilityForm);

export {
  CapacityAvailabilityForm as PureComponent,
};
