const substituteFalsy = substitutes => (values, value, index) => {
  const newValue = index < substitutes.length && !value
    ? substitutes[index]
    : value;
  return values.concat(newValue);
};

const pickUniqIf = (array, key, p = () => true) => Array.from(
  array.reduce((values, item) => (p(item) ? values.add(item[key]) : values), new Set()),
);

export {
  pickUniqIf,
  substituteFalsy,
};
