import React from 'react';
import {
  Layout,
  notification,
} from 'antd';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { createErrorMessage } from '../../context/globalStateHelper';
import PageHeaderBar from '../../components/PageHeaderBar';
import SwaggerUI from '../../components/SwaggerUI';

const { Content } = Layout;

class ApiDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      swagger: null,
    };
  }

  componentDidMount = async () => {
    const { t, swaggerUrl } = this.props;

    try {
      const response = await API.get('FINTSO', swaggerUrl || '/swagger');
      this.setState({ swagger: response });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetching'),
        description: createErrorMessage(e),
      });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading, swagger } = this.state;
    return (
      <>
        <PageHeaderBar title="API Docs" />
        <Content className="layout__container__page__content">
          {isLoading ? <div>Loading...</div> : <SwaggerUI spec={swagger} />}
        </Content>
      </>
    );
  }
}

ApiDocs.propTypes = {
  t: PropTypes.func.isRequired,
  swaggerUrl: PropTypes.string,
};

ApiDocs.defaultProps = {
  swaggerUrl: '/swagger',
};

ApiDocs.displayName = 'ApiDocs';

export default withTranslation()(ApiDocs);
