import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import moment from 'moment';

import { createErrorMessage, getDefaultFunctions } from './globalStateHelper';
import { getGasDay } from '../utils/gasday';
import {
  MAX_DAYS_IN_RANGE,
  RESOLUTIONS,
} from '../constants/deliveries';

const log = new Logger('context:deliveries');
const NOW = moment.utc();

async function updateBiogasDeliveries(
  start = getGasDay(NOW),
  end = getGasDay(NOW.clone().add(MAX_DAYS_IN_RANGE, 'days')),
  resolution = RESOLUTIONS.DAY,
) {
  try {
    this.setState({ biogasDeliveriesLoading: true });
    const { selectedMarketPartyId } = this.state;
    const url = `/marketparties/${selectedMarketPartyId}/biogasdeliveries`;
    const { items: biogasDeliveries } = await API.get('FINTSO', url, {
      queryStringParameters: {
        start,
        end,
        resolution,
      },
    });

    this.setState({ biogasDeliveries });
    log.info('biogasDeliveries updated with data:', biogasDeliveries);
  } catch (error) {
    const description = createErrorMessage(error);
    const { t } = this.props;
    this.setState({ biogasDeliveries: [] });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingDeliveries'),
      description,
    });
    log.error('error updating biogasDeliveries:', description);
  } finally {
    this.setState({ biogasDeliveriesLoading: false });
  }
}

async function updateDeliveries(
  start = getGasDay(NOW),
  end = getGasDay(NOW.clone().add(MAX_DAYS_IN_RANGE, 'days')),
  resolution = RESOLUTIONS.DAY,
) {
  try {
    this.setState({ loadingDeliveries: true });
    const { selectedMarketPartyId } = this.state;
    const deliveriesUrl = `/marketparties/${selectedMarketPartyId}/deliveries`;
    const options = {
      queryStringParameters: {
        start,
        end,
        resolution,
      },
    };
    const { items: deliveries } = await API.get('FINTSO', deliveriesUrl, options);

    this.setState({ deliveries });
    log.info('deliveries updated with data:', deliveries);
  } catch (error) {
    const description = createErrorMessage(error);
    const { t } = this.props;

    this.setState({ deliveries: [] });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingDeliveries'),
      description,
    });
    log.error('error fetching deliveries:', description);
  } finally {
    this.setState({ loadingDeliveries: false });
  }
}

async function updateDeliveryEstimates(start, end) {
  try {
    this.setState({ loadingDeliveryEstimates: true });
    const { selectedMarketPartyId } = this.state;
    const deliveryEstimatesUrl = `/marketparties/${selectedMarketPartyId}/deliveries/estimates`;
    const options = {
      queryStringParameters: {
        start,
        end,
      },
    };
    const { items: newEstimates } = await API.get('FINTSO', deliveryEstimatesUrl, options);

    const { deliveryEstimates: oldEstimates } = this.state;

    const deliveryEstimates = [
      ...oldEstimates,
      ...newEstimates,
    ];

    this.setState({ deliveryEstimates });
    log.info('delivery estimates updated with data:', deliveryEstimates);
  } catch (error) {
    const description = createErrorMessage(error);
    const { t } = this.props;

    this.setState({ deliveryEstimates: [] });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingDeliveryEstimates'),
      description,
    });
    log.error('error fetching delivery estimates:', description);
  } finally {
    this.setState({ loadingDeliveryEstimates: false });
  }
}

const functions = {
  updateBiogasDeliveries,
  updateDeliveries,
  updateDeliveryEstimates,
};

const deliveriesContext = {
  biogasDeliveries: [],
  biogasDeliveriesLoading: false,
  deliveries: [],
  loadingDeliveries: false,
  deliveryEstimates: [],
  loadingDeliveryEstimates: false,
  ...getDefaultFunctions(functions),
};

const deliveries = {
  ...deliveriesContext,
  ...functions,
};

export {
  deliveriesContext,
  deliveries,
};
