import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Empty, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Context from '../../context';

import './imbalance.less';

import {
  UNITS_EXTENDED,
} from '../../constants/units';

import UnitValue from './UnitValue';
import StatsTitle from './StatsTitle';
import { getDate } from '../../utils/dateTimeHelpers';
import { getGasDay } from '../../utils/gasday';

const renderEstimate = (estimate, unit, t) => {
  const { isAdminAsMarketParty } = useContext(Context);
  // TODO: remove check after the feature is officially published
  if (!isAdminAsMarketParty()) return null;

  return (
    <div className="stats-imbalance-estimate">
      { `(${t('balanceContainer.label.estimated')} ` }
      <UnitValue value={estimate} unit={unit} type="imbalance" />
      {')'}
    </div>
  );
};

const renderContent = (total, deliveryEstimate, unit, isLoading, t) => {
  if (isLoading) {
    return <Spin />;
  }

  if (total === undefined) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      <div className="stats-imbalance-total">
        <UnitValue value={total} type="imbalance" unit={unit} />
      </div>
      { deliveryEstimate !== undefined && renderEstimate(deliveryEstimate, unit, t) }
    </>
  );
};

const Imbalance = (props) => {
  const {
    total,
    deliveryEstimate,
    t,
    selectedDate,
    type,
    isLoading,
    unit,
  } = props;
  const gasDayNow = getGasDay(moment());
  const description = t(
    moment(gasDayNow).isSame(selectedDate)
      ? 'balanceContainer.label.forGasDaySoFar'
      : 'balanceContainer.label.forGasDay',
    { selectedDate: getDate(selectedDate) },
  );

  return (
    <>
      <StatsTitle
        title={t(`balanceContainer.title.${type}`)}
        description={description}
      />

      <div className="stats-imbalance">
        {renderContent(total, deliveryEstimate, unit, isLoading, t)}
      </div>
    </>
  );
};

Imbalance.displayName = 'Imbalance';
Imbalance.propTypes = {
  t: PropTypes.func.isRequired,
  total: PropTypes.string,
  deliveryEstimate: PropTypes.number,
  selectedDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  unit: PropTypes.oneOf(Object.values(UNITS_EXTENDED)),
};
Imbalance.defaultProps = {
  total: undefined,
  deliveryEstimate: undefined,
  isLoading: false,
  unit: UNITS_EXTENDED.MWH,
};

export default withTranslation()(Imbalance);
export {
  Imbalance as PureComponent,
};
