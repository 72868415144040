import React, { lazy, Suspense } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Context from './context';
import Loading from './components/Loading';
import {
  getFlatMenuItems,
  isMenuItemVisibleForUser,
} from './constants/menus';

const ApiDocs = lazy(() => import('./containers/ApiDocs'));
const NotFound = lazy(() => import('./containers/NotFound'));
const UserSettings = lazy(() => import('./containers/Settings'));

const renderRoutes = () => (
  <Context.Consumer>
    {context => (
      <Switch>
        <Route path="/apidocs" exact render={() => <ApiDocs />} />
        <Route path="/sysops-apidocs" exact render={() => <ApiDocs swaggerUrl="/admin/systemoperator/swagger" />} />
        {/* TODO: implement a MFA option
        <Route path="/mfa" exact render={() => <SelectMFA />} /> */}
        <Route path="/settings" exact component={UserSettings} />
        {
            getFlatMenuItems()
              // Sort items with component to the top of the list -> react-router issue!
              .sort(item => (item.component === undefined ? 1 : -1))
              .map((item) => {
                if (isMenuItemVisibleForUser(item, context)) {
                  const Component = item.component || NotFound;
                  return (
                    <Route
                      key={item.name}
                      path={item.url}
                      render={() => <Component />}
                    />
                  );
                }
                return null;
              })
          }
        <Redirect exact from="/" to="/frontpage" />
        <Redirect exact from="/shippers" to="/relationships-created" />
        <Redirect exact from="/retailers" to="/relationships-received" />
        <Route render={() => <NotFound />} />
      </Switch>
    )}
  </Context.Consumer>
);

const Routes = () => (
  <Suspense fallback={<Loading />}>
    {
      renderRoutes()
    }
  </Suspense>
);

export default Routes;
