import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { API, Logger } from 'aws-amplify';
import {
  Col,
  Divider,
  Row,
  Checkbox,
  Button,
  notification,
} from 'antd';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import StatusIcon from '../StatusIcon';
import { createErrorMessage } from '../../context/globalStateHelper';

const log = new Logger('meteringsite');

const MeteringSite = (props) => {
  const {
    id,
    status,
    marketPartyName,
    street,
    postalCode,
    city,
    emissionTrade: emissionTradeDefault,
  } = props;

  const { handleDbChange, selectedMarketPartyId } = useContext(Context);

  const [emissionTrade, setEmissionTrade] = useState(emissionTradeDefault);
  const [loading, setSaving] = useState(false);

  const { t } = useTranslation();

  const handleOnChange = ({ target: { checked } }) => {
    setEmissionTrade(checked);
  };

  const handleClick = async () => {
    setSaving(true);
    try {
      const url = `/marketparties/${selectedMarketPartyId}/meteringsites/${id}`;
      const body = { emissionTrade };
      const response = await API.patch('FINTSO', url, { body });
      handleDbChange('MeteringSite', 'update', response);
      notification.info({
        message: t('common.notifications.savedSuccessfully'),
      });
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.failedToSave'),
        description: createErrorMessage(error),
      });
      log.error(createErrorMessage(error, true));
    }
    setSaving(false);
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">Connection status:</div>
          <div className="delivery-relationship__meteringsite__text">
            <StatusIcon status={status} />
            &nbsp;
            {capitalize(status)}
          </div>
        </Col>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">Owner company:</div>
          <div className="delivery-relationship__meteringsite__text">{marketPartyName}</div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">Street address:</div>
          <div className="delivery-relationship__meteringsite__text">{street}</div>
        </Col>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">Postal code:</div>
          <div className="delivery-relationship__meteringsite__text">{postalCode}</div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">City:</div>
          <div className="delivery-relationship__meteringsite__text">{city}</div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="delivery-relationship__meteringsite__label">Emission trade:</div>
          <Checkbox checked={emissionTrade} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row className="delivery-relationship__button-container">
        <Col>
          <Button
            loading={loading}
            disabled={emissionTrade === emissionTradeDefault}
            onClick={handleClick}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

MeteringSite.displayName = 'MeteringSite';
MeteringSite.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string.isRequired,
  marketPartyName: PropTypes.string.isRequired,
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  emissionTrade: PropTypes.bool,
};
MeteringSite.defaultProps = {
  id: '',
  street: '-',
  postalCode: '-',
  city: '-',
  emissionTrade: false,
};

export default MeteringSite;
