import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon, Tooltip } from 'antd';

import './index.less';

const renderTitle = (status, title) => (
  title !== undefined && title !== ''
    ? title
    : status
);

const StatusIcon = ({ status = '', withTitle, title }) => {
  let type;
  switch (status.toLowerCase()) {
    case 'active':
    case 'accepted':
      type = 'check-circle';
      break;
    case 'inactive':
    case 'declined':
      type = 'close-circle';
      break;
    default:
      type = 'exclamation-circle';
      break;
  }

  return (
    <Tooltip
      placement="bottomRight"
      title={!withTitle && renderTitle(status, title)}
    >
      <Icon
        theme="filled"
        className={
          classnames(
            'status-icon',
            {
              'status-icon--pending': status === 'pending',
              'status-icon--accepted': ['received', 'accepted', 'active'].includes(status),
              'status-icon--declined': ['declined', 'inactive'].includes(status),
              'status-icon--has-hover-text': !withTitle,
            },
          )
        }
        type={type}
      />
      { withTitle && renderTitle(status, title) }
    </Tooltip>
  );
};

StatusIcon.displayName = 'StatusIcon';
StatusIcon.propTypes = {
  /**
   * Possible statuses, see styles -> less file.
   *
   * // Market party statuses
   * 'accepted', 'declined', 'pending'
   *
   * // Metering sites statuses
   * 'active', 'inactive'
   *
   * // Nominations statuses
   * 'received', 'declined', 'pending'
   */
  status: PropTypes.string,
  withTitle: PropTypes.bool,
  title: PropTypes.string,
};
StatusIcon.defaultProps = {
  status: 'not defined',
  withTitle: false,
  title: undefined,
};

export default StatusIcon;
