import React from 'react';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { identity } from 'lodash';
import { unparse } from 'papaparse';

import { getDate, getMonth, getTime } from '../../utils/dateTimeHelpers';
import { by } from '../../utils/sort';
import { formatNumber } from '../../utils/i18n';
import { RESOLUTIONS, TYPES } from '../../constants/meteringSites';
import { UNITS } from '../../constants/units';

const moment = extendMoment(Moment);

const FIXED_COLUMN_WIDTH = 300;
const BASE_CLASS = 'consumptions-per-metering-site';
const TABLE_CLASS = `${BASE_CLASS}__table`;

const getFirstColumn = t => ({
  title: t('common.table.header.meteringSite'),
  key: 'label',
  fixed: 'left',
  width: FIXED_COLUMN_WIDTH,
  render: (record) => {
    const className = classNames(
      {
        [`${TABLE_CLASS}__main-column`]: !!record.children,
      },
    );
    const label = record.children ? record.label : t(record.label);
    return <span className={className}>{label}</span>;
  },
});

const getRowClassName = record => classNames(
  {
    [`${TABLE_CLASS}__main-row`]: !!record.children,
    [`${TABLE_CLASS}__expanded-row`]: !record.children,
  },
);

const getTimeColumns = (start, end, resolution) => {
  if (!start || !end || !resolution) {
    return [];
  }

  const timestamps = Array
    .from(moment.range(start, end)
      .by(resolution));

  return timestamps.map((time) => {
    let title;
    const timestamp = time.toISOString();
    switch (resolution) {
      case RESOLUTIONS.HOUR:
        title = getTime(timestamp);
        break;
      case RESOLUTIONS.DAY:
        title = getDate(timestamp);
        break;
      case RESOLUTIONS.MONTH:
        title = getMonth(timestamp);
        break;
      default:
        throw new Error('Unknown resolution:', resolution);
    }
    return {
      title,
      key: timestamp,
      dataIndex: timestamp,
      align: 'right',
    };
  });
};

const getRowKey = (item, index) => {
  const {
    id,
    label,
  } = item;
  return `${id}-${label}-${index}`;
};

const flattenByTimestamp = calculate => (result, {
  timestamp,
  value,
}) => ({
  ...result,
  [timestamp]: value ? formatNumber(calculate(value), 0, 3) : null,
});

const getValue = (item, key, reduceFn, initialValue) => item.values[key].reduce(
  reduceFn,
  initialValue || {
    id: item.meteringSiteId,
    label: `meteringSites.table.information.${key}`,
  },
);

const addTotal = (data) => {
  const stringToNumber = str => (
    typeof str === 'string'
      ? Number(str.replace(/\s/g, '')
        .replace(',', '.'))
      : 0
  );
  const format = str => formatNumber(str, 0, 3);

  return {
    id: 'total',
    label: 'common.table.header.total',
    ...data
      .reduce((acc, item) => {
        const {
          id,
          label,
          children,
          ...rest
        } = item;

        Object
          .keys(rest)
          .forEach((key) => {
            acc[key] = format(
              Decimal.add(
                stringToNumber(acc[key]),
                stringToNumber(rest[key]),
              ),
            );
          });

        return acc;
      }, {}),
  };
};

/**
 * Transform data from backend response to format which the table can display.
 * Flattens time series data arrays by timestamps and divides values by 1000 if
 * MWh unit is selected. Heatvalues and volumes are contained within the children
 * property to allow their rows to be expanded or collapsed at will.
 *
 * @param {Array} dataSource
 * @param {String} unit: 'kwh' | 'mwh'
 * @param {String} resolution: 'hour' | 'day' | 'month'
 * @param {Boolean} isCSV - Is CSV flag
 */
const remap = (dataSource, unit, resolution, addTotalRow = true) => {
  const multiplier = new Decimal(unit === UNITS.MWH ? 0.001 : 1);
  const calculate = value => Number(multiplier.mul(value));
  const byType = by({
    key: 'type',
    order: [TYPES.EXITZONE, TYPES.BIOGAS],
  });
  const remappedData = dataSource
    .sort(byType)
    .map((item) => {
      const energies = getValue(item, 'energy', flattenByTimestamp(calculate), { id: item.meteringSiteId });
      const volumes = getValue(item, 'volume', flattenByTimestamp(identity));
      const children = [volumes];

      // Temporary fix: heatvalues are hidden for day and month resolutions
      // because the calculation was wrong.
      // this check should be removed when it's properly fixed
      if (resolution === RESOLUTIONS.HOUR) {
        const heatvalues = getValue(item, 'heat', flattenByTimestamp(identity));
        children.unshift(heatvalues);
      }

      return {
        id: item.id,
        label: item.name + (item.type === TYPES.BIOGAS ? ' (Biogas)' : ''),
        ...energies,
        children,
      };
    });

  return addTotalRow
    ? [
      addTotal(remappedData),
      ...remappedData,
    ]
    : remappedData;
};

/**
 * Prepare data row.
 *
 * @param {Array} headers - Header rows data
 * @param {Object} item - Data row.
 * @param {Function} - Translation function
 *
 * @returns {Array} - Data row's data.
 */
const prepareCsvItems = (headers, item, t, isChildRow = false) => {
  const title = isChildRow
    ? `\t${t(item.label)}`
    : item.label;

  return headers.reduce((data, headerItem) => {
    const dIndex = headerItem.dataIndex;
    const fieldsValue = item[dIndex];

    if (fieldsValue !== undefined) {
      data.push(fieldsValue);
    }

    return data;
  }, [title]);
};

/**
 * Prepare data for CSV file.
 *
 * @param {String} start - Date of start aggregation.
 * @param {String} end - Date of end aggregation.
 * @param {String} unit - 'kwh' | 'mwh'
 * @param {String} resolution - 'hour' | 'day' | 'month'
 * @param {Array} data
 * @param {Function} - Translation function
 *
 * @returns {String} - Converted data.
 */
const getCsvData = async (start, end, unit, resolution, data, t) => {
  // Read extra here: https://www.papaparse.com/docs#data
  const headerFields = [
    getFirstColumn(t),
    ...getTimeColumns(start, end, resolution),
  ]
    .map((field) => {
      const {
        title,
        dataIndex,
      } = field;

      return {
        title,
        dataIndex,
      };
    });
  const prepareChildrens = items => (
    items
      ? items.map(child => prepareCsvItems(headerFields, child, t, true))
      : []
  );
  const dataFields = [].concat(
    ...remap(data, unit, resolution, false)
      .map(item => [
        prepareCsvItems(headerFields, item),
        ...prepareChildrens(item.children),
      ]),
  );
  const csv = await unparse({
    fields: headerFields.map(field => field.title),
    data: dataFields,
  });

  return csv;
};

export {
  BASE_CLASS,
  TABLE_CLASS,
  getFirstColumn,
  getRowClassName,
  getTimeColumns,
  getRowKey,
  remap,
  getCsvData,
};
