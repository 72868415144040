@import 'fonts.css';
/* VARIABLES */
/* COLOR VARIABLES */
.box-frame {
  box-shadow: 5px 4px 16px 0 rgba(0, 0, 0, 0.03);
  border: 3px solid #d9d9d9;
}
.box-popup-frame {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d9d9;
}
/* grey border used by antd divider */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}
body {
  font-family: 'Ageo', sans-serif;
  font-size: 0.9375rem;
  margin: 0;
}
a,
a:active,
a:hover {
  color: #e6008c;
}
/* Front page "Loading..." spinner */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning.preloader {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-bottom: 25px;
}
.ant-spin-dot {
  position: absolute;
}
.ant-spin__text {
  padding-top: 70px;
  color: lightgrey;
}
/* <Button /> */
.ant-btn {
  height: 2.25rem;
  padding: 0.25rem 1.5rem;
  font-size: 0.9375rem;
}
.ant-btn span {
  padding-top: 2px;
}
.ant-btn-primary {
  background-color: #e6008c;
  border-color: #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #e6008c;
  border-color: #e6008c;
  box-shadow: -1px 0 0 0 #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus,
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #FFF;
  background-color: #b3006d;
  border-color: #e6008c;
}
.ant-radio-button-wrapper:hover {
  color: #e6008c;
}
.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  color: #e6008c;
  border-color: #e6008c;
}
.ant-btn-primary.active,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #FFF;
  background-color: #b3006d;
}
/* <Table /> */
.ant-table {
  font-size: 0.9375rem;
  font-weight: 400;
}
.ant-table .ant-table-tbody,
.ant-table .ant-table-body,
.ant-table .ant-table-thead,
.ant-table .ant-table-thead th {
  background-color: #FFF;
}
.ant-table .ant-table-thead th,
.ant-table .ant-table-tbody td {
  padding: 1em;
}
.ant-table .ant-table-thead div {
  font-weight: bold;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #EEEEEE;
}
.ant-table-tbody > tr.ant-table-expanded-row {
  background: #d9d9d9;
}
/* PAGINATION */
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #e6008c;
}
/* <Menu /> */
.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: #555555;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  height: auto;
  padding: 0.33333333rem;
  font-size: 0.9375rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu .ant-menu-item {
  height: auto;
  padding-top: 0.33333333rem;
  padding-bottom: 0.33333333rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title a,
.ant-menu-inline .ant-menu-submenu .ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
  /* below trick fits the anchor tag to parent
       * div to increase the clickable area
       */
  display: list-item;
  list-style-type: none;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title i.ant-menu-submenu-arrow,
.ant-menu-inline .ant-menu-submenu .ant-menu-item i.ant-menu-submenu-arrow {
  top: 1.8rem;
}
.ant-btn > i,
.ant-btn > span {
  display: inline;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-submenu-open {
  background-color: #242424;
}
/* <Empty /> */
.ant-empty {
  margin: auto;
}
#app {
  height: 100vh;
  width: 100vw;
}
.ant-modal {
  top: 4rem;
}
.ant-modal-footer {
  border-top: none;
  padding-bottom: 1rem;
  text-align: center;
}
.ant-modal-footer .ant-divider {
  margin: 1rem;
  margin-top: 0.5rem;
}
.ant-modal-body {
  font-size: 0.9375rem;
  padding: 0 1.5rem 1rem;
}
.ant-modal-header {
  border-bottom: none;
  padding: 1rem 1.5rem 1.5rem;
}
.ant-modal-title {
  font-size: 1.5rem;
  padding: 1.5rem 1.5rem 0 0;
  text-transform: uppercase;
  text-align: center;
}
/* <Notification /> */
.ant-notification .notification-error {
  border: solid 2px #ff0000;
}
/* Responsive breakpoint variables */
/* Mixins for different screen size */
.clipboard-copy {
  color: #e6008c;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.clipboard-copy::after {
  content: " \2398";
}
.anticon {
  vertical-align: -0.2em;
}
.ant-card {
  font-size: 0.9375rem;
}
.ant-card .ant-card-head-title {
  font-size: 1.1rem;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}
.ant-input {
  padding-top: 6px;
  font-size: 0.9375rem;
  line-height: 32px;
}
.ant-calendar-picker-container {
  font-family: 'Ageo', sans-serif;
}
.ant-table-thead > tr > th {
  background: #FFF;
}
.ant-layout-sider-zero-width-trigger {
  background: #333333;
  top: 15vh;
  z-index: 100;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #242424;
}
.ant-layout-sider-children {
  position: relative;
}
.ant-layout-sider-children .external-links {
  position: absolute;
  bottom: 0;
  margin: 1rem;
  color: #C2C2C2;
}
.ant-layout-sider-children .external-links a {
  color: #C2C2C2;
}
.layout__header {
  align-items: center;
  color: #FFF;
  display: flex;
  font-size: 0.9375rem;
  height: 52px;
  justify-content: space-between;
  line-height: 52px;
  padding-top: 3px;
}
.layout__header .feedback {
  /* line-height: normal; */
}
.layout__header .feedback * {
  color: #FFF;
  font-size: 0.9375rem;
  font-weight: bold;
  text-decoration: underline;
}
.layout__header.ant-layout-header {
  background: #000;
  padding: 0 0 0 1.5rem;
}
.layout__header--danger.ant-layout-header {
  background: #732597;
}
.layout__header--danger .ant-dropdown-open {
  color: #FFF;
}
.layout__header__right-wrapper {
  display: flex;
  align-items: center;
}
.layout__header__right-wrapper .anticon {
  font-size: 1.2rem;
}
.layout__header__right-wrapper .ant-divider-vertical {
  height: 1.5rem;
}
.layout__header__user__menu__divider {
  margin: 0.5rem 0;
}
.layout__header__user__menu.ant-dropdown-menu {
  border-radius: 0;
  padding: 0.33333333rem;
  margin: -0.3rem 0;
}
.layout__header__user__menu.ant-dropdown-menu a {
  color: #e6008c;
  font-weight: bold;
}
.layout__header__user__menu .ant-dropdown-menu-item:hover,
.layout__header__user__menu .ant-dropdown-menu-submenu-title:hover {
  background: none;
}
.layout__header__user__name {
  padding: 1.5rem 1rem;
}
.layout__header__user__name .code {
  padding-right: 0.5em;
  opacity: 0.5;
  font-weight: bold;
}
.layout__header__user__name.ant-dropdown-open {
  background: #242424;
}
.layout__header__user__name__icon {
  margin-right: 0.5rem;
}
.layout__header__logo {
  height: 1.7rem;
  display: flex;
  align-items: center;
}
.layout__header__logo img {
  height: 100%;
  margin: 0 1rem 0 0;
  vertical-align: initial;
  width: auto;
}
.layout__header__logo__market-party__menu.ant-menu {
  border-radius: 0;
  padding: 0.33333333rem;
  margin: -0.3rem 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d9d9;
}
.layout__header__logo__market-party__menu.ant-menu .ant-menu-item {
  color: #e6008c;
  font-weight: bold;
  margin: 0 1rem;
  padding: 0;
}
.layout__header__logo__market-party__menu.ant-menu .ant-menu-item:hover,
.layout__header__logo__market-party__menu.ant-menu .ant-menu-item.ant-menu-item-selected {
  background: none;
}
.layout__header__logo__market-party__menu.ant-menu .ant-menu-item.menu-item-select-marketparty {
  border-bottom: 1px solid #e8e8e8;
}
.layout__header__logo__market-party__menu.ant-menu .ant-menu-item.menu-item-marketparty .code {
  padding-right: 0.5em;
  opacity: 0.5;
  font-weight: bold;
}
.layout__header__company__selector {
  padding: 0 3rem;
}
.layout__header__company__selector.ant-dropdown-open {
  background: #242424;
}
.layout__container {
  height: calc(100vh - 52px);
}
.layout__container__sidebar {
  background: #333333;
  padding-top: 2rem;
  width: 246px;
}
.layout__container__sidebar__menu {
  background: #333333;
  width: 100%;
  height: calc(100% - 96px);
  overflow: auto;
}
.layout__container__sidebar__menu__item {
  margin-top: 0;
  margin-bottom: 0;
}
.layout__container__sidebar__menu__item__text {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.anticon.layout__container__sidebar__menu__item__icon {
  font-size: 1.2rem;
}
.layout__container__sidebar__menu__item .ant-menu-submenu-title {
  padding: 0;
}
.layout__container__page__content {
  background: #fff;
  padding: 1rem;
}
.layout__container__page__content__table {
  font-weight: 700;
}
.layout .ant-layout-content {
  overflow-x: scroll;
}
@media only screen and (max-width: 767px) {
  .layout__container .ant-layout-header {
    padding: 0;
  }
  .layout__container__page {
    width: 100vw;
  }
  .layout__container__sidebar {
    position: absolute;
    height: calc(100.5% - 96px);
    z-index: 100;
  }
  .layout__header__company__selector {
    padding: 0 0.5rem;
  }
  .layout__header__logo__market-party .ant-menu {
    padding: 0;
  }
  .layout__header__user__name__icon {
    margin: 0;
  }
  .layout__header__user__name__text {
    display: none;
  }
  .ant-modal {
    width: 100%!important;
  }
}
@media only screen and (max-width: 575px) {
  .layout__header__feedback-link {
    display: none;
  }
}
