import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Icon,
} from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './index.less';

import Context from '../../context';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { ROLE_FIELDS } from '../../constants/marketParties';
import { PERMISSIONS, USER_RIGHTS } from '../../constants/users';
import { mapUsersPermissionsWithMarketPartyRoles, userHasPermission } from '../../utils/userHelpers';

const UsersTable = () => {
  const context = useContext(Context);
  const {
    users,
    marketParties,
    isAdmin,
    isLoading,
  } = context;
  const { t } = useTranslation();

  const renderIcon = (permission, value = false, key) => (
    value === true
      ? (
        <Icon
          type="check"
          key={key}
          className={
            classnames(
              'check-icon',
              `check-icon-${permission}`,
            )
          }
        />
      )
      : null
  );

  /**
   * Render link for edit user popup.
   *
   * @param {string} id - User's identifier.
   * @returns {JSX} - Rendered link.
   */
  const renderEditCell = (record) => {
    const { id, marketPartyId } = record;

    // Keeping the logic check separate for edit and delete incase there is separation needed in future.
    const canEdit = isAdmin(true) || userHasPermission(context, [PERMISSIONS.ADMIN]);

    // TSO Admin User should be allowed to delete any marketParty user
    const canDelete = isAdmin(true) || userHasPermission(context, [PERMISSIONS.ADMIN]);

    return (
      <>
        {canEdit && (
        <Link to={`/users/${id}`} className="edit-user__link">
          {t('common.button.edit').toLowerCase()}
        </Link>
        )}
        {canDelete && (
        <Link to={`/users/delete/${marketPartyId}/${id}`} className="delete-user__link">
          {t('common.button.delete').toLowerCase()}
        </Link>
        )}
      </>
    );
  };

  const getTableColumnHeaders = () => (
    getTranslatedTableHeaders([
      {
        title: '',
        key: 'baseColumnHeaders',
        className: 'users__table__cell--bordered-right',
        children: [{
          title: 'user',
          dataIndex: 'name',
          className: 'users__table__cell__username',
        }, {
          title: 'email',
          dataIndex: 'email',
          className: 'users__table__cell__email',
        }, {
          title: 'phone',
          dataIndex: 'phone',
          className: 'users__table__cell--bordered-right users__table__cell__phone',
        }],
      },
      {
        title: '',
        className: 'users__table__cell--bordered-right',
        width: 30,
        children: [
          {
            title: 'admin',
            className: 'users__table__cell--bordered-right',
            children: [{
              key: 'admin',
              className: 'users__table__cell--bordered-right users__table__cell-right-admin',
              width: 30,
              render: user => renderIcon('admin', user.admin),
            }],
          },
          {
            title: 'invoice',
            className: 'users__table__cell--bordered-right',
            children: [{
              key: 'invoice',
              className: 'users__table__cell--bordered-right users__table__cell-right-invoice',
              width: 30,
              render: user => renderIcon('invoice', user.invoice),
            }],
          },
        ],
      }, ...ROLE_FIELDS.map(role => ({
        title: role.type,
        key: role.type,
        dataIndex: 'permissionsRemapped',
        width: 60,
        className: 'users__table__cell--bordered-bottom users__table__cell--bordered-right',
        children: USER_RIGHTS.map(right => ({
          title: right,
          key: right === 'write' ? role.type : `${role.type}ReadOnly`,
          width: 30,
          className: classnames(
            'users__table__cell--bordered-right',
            {
              [`users__table__cell-right-${role.type}`]: right === 'write',
              [`users__table__cell-right-${role.type}ReadOnly`]: right !== 'write',
            },
          ),
          render: user => renderIcon(
            right === 'write' ? role.type : `${role.type}ReadOnly`,
            right === 'write' ? user[role.type] : user[`${role.type}ReadOnly`],
          ),
        })),
      })), (isAdmin(true) || userHasPermission(context, [PERMISSIONS.ADMIN])) ? {
        title: '',
        key: 'editColumn',
        className: 'users__table__cell--bordered-bottom',
        children: [{
          title: 'edit',
          key: 'edit',
          width: 60,
          className: 'users__table__cell--aligned-right',
          render: record => (
            // MP parent row shoulnd't show edit link
            record.children === undefined
              ? renderEditCell(record)
              : null
          ),
        }],
      } : {},
    ], t)
  );

  const remapMarketPartiesAndUsers = () => (
    (users && marketParties) && marketParties.map(({ id, name }) => {
      const children = users
        .filter(user => Object.keys(user.permissions).includes(id))
        .map(user => (Object.assign(
          {},
          user,
          {
            marketPartyId: id,
            name: `${user.firstname} ${user.lastname}`,
            key: `${user.id}#${id}`,
          },
          mapUsersPermissionsWithMarketPartyRoles(user)[id],
        )));

      return {
        id,
        name,
        key: id,
        children: children.length > 0 ? children : null, // Hide collapse button if no children
      };
    })
  );

  return (
    <Table
      className="layout__container__page__content__table users__table"
      columns={getTableColumnHeaders()}
      dataSource={remapMarketPartiesAndUsers()}
      loading={isLoading}
      rowClassName="users__table__data-row"
      pagination={false}
    />
  );
};

UsersTable.displayName = 'UsersTable';

export default UsersTable;
