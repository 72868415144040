import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import './index.less';

const ButtonSelector = (props) => {
  const { Group, Button } = Radio;
  const {
    t, options, title, defaultValue, onChange,
  } = props;
  const renderTitle = () => (
    title
      ? (
        <div className="button-selector__title">
          {t(`common.label.${title}`)}
        </div>
      )
      : null
  );

  return (
    <div className="button-selector">
      { renderTitle() }
      <Group
        className="button-selector__options"
        defaultValue={defaultValue}
        buttonStyle="solid"
        onChange={onChange}
      >
        {options.map(option => (
          <Button key={option} value={option}>{t(`common.label.${option}`)}</Button>
        ))}
      </Group>
    </div>
  );
};

ButtonSelector.displayName = 'ButtonSelector';
ButtonSelector.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};
ButtonSelector.defaultProps = {
  title: '',
  defaultValue: '',
  onChange: () => {},
};

export default ButtonSelector;
