import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Layout,
  Row,
  Col,
  DatePicker,
  Button,
  Empty,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getRangeForResolution } from './utils';
import PageHeaderBar from '../../components/PageHeaderBar';
import ButtonSelector from '../../components/ButtonSelector';
import ConsumptionsPerMeteringSite from '../../components/ConsumptionsPerMeteringSite';
import UTF8_BOM from '../../constants/excel';

import {
  RESOLUTION_NAMES,
  RESOLUTIONS,
  DATE_TYPES,
} from '../../constants/meteringSites';
import {
  UNITS,
  UNIT_NAMES,
} from '../../constants/units';
import {
  disabledEndDate,
  getStartOfMonth,
  getEndOfMonth,
} from '../../utils/meteringSitesHelpers';
import Context from '../../context';
import { getCsvData } from '../../components/ConsumptionsPerMeteringSite/utils';

import './index.less';

const moment = extendMoment(Moment);
const {
  MonthPicker,
} = DatePicker;

const MeteringSites = () => {
  const {
    deliveryRelationships,
    meteringSites,
    selectedMarketPartyId,
    meteringSiteConsumptions: {
      data,
      requestParams: {
        start,
        end,
        resolution,
      },
    },
    meteringSiteConsumptionsLoading,
    deliveryRelationshipsLoading,
    loadingMeteringSites,
    meteringPointOwnerships,
    meteringPointOwnershipsLoading,
    updateDeliveryRelationships,
    updateMeteringSites,
    updateMeteringSiteConsumptions,
    updateMeteringPointOwnerships,
  } = useContext(Context);

  const { t } = useTranslation();
  const defaultEndDate = moment().endOf('month');
  const defaultStartDate = defaultEndDate.clone().subtract(3, 'months');
  const [selectedUnit, setSelectedUnit] = useState(UNITS.MWH);
  const [selectedResolution, setSelectedResolution] = useState(RESOLUTIONS.MONTH);
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);
  const [csvData, setCsvData] = useState();
  const [isPreparingCsvData, setIsPreparingCsvData] = useState(false);
  const hiddenAElement = useRef();

  const hasNoMeteringSites = () => (
    meteringSites === null || meteringSites.length === 0
  );

  const fetchData = () => {
    const { start: startDate, end: endDate } = getRangeForResolution(
      selectedStartDate,
      selectedEndDate,
      selectedResolution,
    );

    updateMeteringSiteConsumptions(startDate, endDate, selectedResolution);
  };

  const siteNotBelongToMarketParty = (
    hasNoMeteringSites() || meteringSites.some((meteringSite) => {
      const relationship = deliveryRelationships.find(
        ({ meteringSiteId, marketPartyId }) => (
          meteringSiteId === meteringSite.id
          && marketPartyId === selectedMarketPartyId
        ),
      );
      const ownership = meteringPointOwnerships.find(
        ({ meteringPointId, marketPartyId }) => (
          meteringPointId === meteringSite.id
          && marketPartyId === selectedMarketPartyId
        ),
      );

      return meteringSite.marketPartyId !== selectedMarketPartyId && !relationship && !ownership;
    })
  );

  useEffect(() => {
    if (siteNotBelongToMarketParty) {
      updateMeteringSites();
      updateDeliveryRelationships();
      updateMeteringPointOwnerships();
    }

    if (!siteNotBelongToMarketParty) {
      fetchData();
    }
  }, [selectedMarketPartyId, siteNotBelongToMarketParty]);

  const handleSelectedUnit = ({ target: { value } }) => setSelectedUnit(value);

  const handleSelectedStartDate = value => setSelectedStartDate(value);

  const handleSelectedEndDate = value => setSelectedEndDate(value);

  const handleSelectedResolution = ({ target: { value } }) => {
    setSelectedResolution(value);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const isButtonDisabled = () => {
    if (selectedResolution === RESOLUTIONS.HOUR) {
      return selectedStartDate === null;
    }

    return hasNoMeteringSites()
      || selectedStartDate === null
      || selectedEndDate === null;
  };

  const renderDatePicker = (type = DATE_TYPES.START) => (
    <DatePicker
      className="meteringsite__date__selector--date-picker"
      allowClear={false}
      format={moment.localeData().longDateFormat('L')}
      disabledDate={date => disabledEndDate(
        date, selectedStartDate, type, selectedResolution,
      )}
      onChange={type === DATE_TYPES.START
        ? handleSelectedStartDate
        : handleSelectedEndDate
      }
      defaultValue={type === DATE_TYPES.START ? selectedStartDate : selectedEndDate}
    />
  );

  const renderMonthPicker = (type = DATE_TYPES.START) => (
    <MonthPicker
      className="meteringsite__date__selector--month-picker"
      allowClear={false}
      format={moment.localeData().longDateFormat('L')}
      disabledDate={date => disabledEndDate(
        date, selectedStartDate, type, selectedResolution,
      )}
      value={type === DATE_TYPES.START
        ? getStartOfMonth(selectedStartDate)
        : getEndOfMonth(selectedEndDate)}
      onChange={type === DATE_TYPES.START
        ? handleSelectedStartDate
        : handleSelectedEndDate
      }
    />
  );

  const renderRangePicker = renderFunction => (
    <div className="meteringsite__date__selector__range">
      {renderFunction()}
      <span className="meteringsite__date__selector__range--separator">
        -
      </span>
      {renderFunction(DATE_TYPES.END)}
    </div>
  );

  const renderDateMonthSelector = () => {
    switch (selectedResolution) {
      case RESOLUTIONS.HOUR:
        return renderDatePicker();
      case RESOLUTIONS.DAY:
        return renderRangePicker(renderDatePicker);
      case RESOLUTIONS.MONTH:
        return renderRangePicker(renderMonthPicker);
      default:
        return null;
    }
  };

  const loading = deliveryRelationshipsLoading
    || loadingMeteringSites
    || meteringSiteConsumptionsLoading
    || meteringPointOwnershipsLoading;

  const renderTable = () => (
    <ConsumptionsPerMeteringSite
      dataSource={data}
      isLoading={loading}
      start={start}
      end={end}
      resolution={resolution}
      unit={selectedUnit}
    />
  );

  const handleExportToExcell = async () => {
    setIsPreparingCsvData(true);

    const csv = await getCsvData(
      start,
      end,
      selectedUnit,
      resolution,
      data,
      t,
    );

    setCsvData(encodeURI(UTF8_BOM + csv));
    setIsPreparingCsvData(false);
    hiddenAElement.current.click();
  };

  const renderHiddenLink = () => (
    <a
      className="meteringsite__hidden-link"
      href={`data:text/csv;charset=utf-8,${csvData}`}
      rel="noopener noreferrer"
      target="_blank"
      download={`${t('meteringSites.title.gasConsumption')}_${selectedResolution}_${selectedStartDate}-${selectedEndDate}_${selectedUnit}.csv`}
      ref={hiddenAElement}
    >
      &#8205;
    </a>
  );

  const renderHeaderWithButtons = () => (
    <>
      <Col className="meteringsite__resolution">
        <ButtonSelector
          t={t}
          options={RESOLUTION_NAMES}
          defaultValue={selectedResolution}
          title="resolution"
          onChange={handleSelectedResolution}
        />
      </Col>
      <Col className="meteringsite__date">
        <div className="meteringsite__date__title">
          {t('common.label.timePeriod')}
        </div>
        <div className="meteringsite__date__selector">
          {renderDateMonthSelector()}
        </div>
      </Col>
      <Col className="meteringsite__unit">
        <ButtonSelector
          t={t}
          options={UNIT_NAMES}
          defaultValue={selectedUnit}
          title="unit"
          onChange={handleSelectedUnit}
        />
      </Col>
      <Col>
        <Button
          className="meteringsite__search-button"
          type="primary"
          disabled={isButtonDisabled()}
          loading={loading}
          onClick={fetchData}
        >
          {t('common.button.search')}
        </Button>
      </Col>
      <Col className="meteringsite__export">
        <Button
          className="meteringsite__export-to-excel-button"
          type="primary"
          disabled={isButtonDisabled()}
          loading={isPreparingCsvData}
          onClick={handleExportToExcell}
        >
          {t('common.button.exportToExcel')}
        </Button>
        { renderHiddenLink() }
      </Col>
    </>
  );

  const renderContent = () => (
    hasNoMeteringSites()
      ? <Empty description={t('meteringSites.label.noMeteringSiteFound')} />
      : (
        <>
          <Row className="meteringsite">
            { renderHeaderWithButtons() }
          </Row>
          <Row>
            { renderTable() }
          </Row>
        </>
      )
  );

  return (
    <>
      <PageHeaderBar title={t('meteringSites.title.gasConsumption')} />
      <Layout.Content className="layout__container__page__content metering-sites">
        { renderContent() }
      </Layout.Content>
    </>
  );
};

MeteringSites.displayName = 'MeteringSites';
export default MeteringSites;
