import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import moment from 'moment-timezone';

import { calculate } from '../../utils/capacityAndNominationsHelper';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';

const MARGIN = 30;
const COLOR_STROKE = '#F5F5F5';
const COLOR_PRELIMINARY = '#AAAAAA';
const COLOR_HISTORICAL = '#3483AA';
const COLOR_MANUAL = '#C2295E';
const BAR_WIDTH = 20;
const LINE_WIDTH = 3;
const MIN_VALUE_PADDING = 0.97;
const MAX_VALUE_PADDING = 1.03;

const getMinValueFromData = data => data.reduce(
  (acc, { preliminary, historical, manual }) => {
    const values = [preliminary, historical, manual]
      .map(val => parseFloat(val))
      .filter(val => val > 0);
    const minValue = Math.min(...values);

    if (
      acc === 0
      || (minValue > 0 && minValue < acc)
    ) return minValue;

    return acc;
  }, 0,
);

const getMinValueForChart = (data, unit) => (
  calculate(getMinValueFromData(data) * MIN_VALUE_PADDING, unit)
);

const remapChartData = (data, unit) => data.map(({
  preliminary, historical, manual, timestamp,
}) => ({
  preliminary: calculate(preliminary, unit),
  historical: calculate(historical, unit),
  manual: calculate(manual, unit),
  hour: moment(timestamp).format('HH'),
}));

const HourlyImbalancesChart = ({ data, unit }) => (
  <ResponsiveContainer width="100%" height="100%">
    <ComposedChart
      data={remapChartData(data, unit)}
      margin={{
        top: MARGIN,
        right: MARGIN,
        bottom: MARGIN,
        left: MARGIN,
      }}
    >
      <CartesianGrid stroke={COLOR_STROKE} />
      <XAxis dataKey="hour" />
      <YAxis
        type="number"
        allowDataOverflow
        domain={[
          getMinValueForChart(data, unit),
          dataMax => dataMax * MAX_VALUE_PADDING,
        ]}
      />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="preliminary"
        barSize={BAR_WIDTH}
        fill={COLOR_PRELIMINARY}
      />
      <Line
        type="monotone"
        dataKey="historical"
        stroke={COLOR_HISTORICAL}
        strokeWidth={LINE_WIDTH}
      />
      <Line
        type="monotone"
        dataKey="manual"
        stroke={COLOR_MANUAL}
        strokeWidth={LINE_WIDTH}
      />
    </ComposedChart>
  </ResponsiveContainer>
);

HourlyImbalancesChart.displayName = 'HourlyImbalancesChart';
HourlyImbalancesChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    timestamp: PropTypes.string,
    historical: PropTypes.string,
    manual: PropTypes.string,
    preliminary: PropTypes.string,
    manualTimestamp: PropTypes.string,
  })).isRequired,
  unit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
};

export default HourlyImbalancesChart;

export {
  getMinValueForChart,
  getMinValueFromData,
  remapChartData,
};
