import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { generateNotificationFromCognitoError } from '../../utils/errorHelpers';
import { verificationState } from './state';

const VerificationForm = (props) => {
  const {
    form: {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      validateFields,
    },
    attribute,
    attributeText,
    setState,
    state,
  } = props;
  const { t } = useTranslation();
  const initialState = verificationState.NOT_VERIFIED;

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields(async (error, { code }) => {
      if (!error) {
        try {
          setState(verificationState.VERIFICATION_REQUESTED);
          await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
          setState(verificationState.VERIFIED);
          notification.success({
            message: t('settings.userInformation.change.info.success', { value: attributeText }),
            description: '',
          });
        } catch (err) {
          setState(initialState);
          notification.error(generateNotificationFromCognitoError(t, err));
        }
      }
    });
  };

  const codeError = isFieldTouched('code') && getFieldError('code');
  const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item
        label={t('settings.userInformation.change.verificationCode')}
        validateStatus={codeError ? 'error' : ''}
        help={codeError || ''}
      >
        {getFieldDecorator('code', {
          rules: [
            {
              required: true,
              message: t('settings.userInformation.change.error.required'),
            },
          ],
        })(
          <Input
            placeholder={t('settings.userInformation.change.verificationCode')}
            disabled={state !== initialState}
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={state === verificationState.VERIFICATION_REQUESTED}
          disabled={hasErrors(getFieldsError()) || state !== initialState}
        >
          {t('common.button.verify')}
        </Button>
      </Form.Item>
    </Form>
  );
};

VerificationForm.displayName = 'VerificationForm';
VerificationForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldError: PropTypes.func,
    isFieldTouched: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  attribute: PropTypes.oneOf(['email', 'phone_number']).isRequired,
  attributeText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.oneOf(Object.keys(verificationState)).isRequired,
};

export default Form.create({ name: 'verificationForm' })(VerificationForm);
