import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  API,
  Logger,
} from 'aws-amplify';
import {
  Modal,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import SummaryTable from '../SummaryTable';
import { STATUS } from '../../constants/status';
import CRTTableTransferItem from './CRTTableTransferItem';
import ActionButton from '../ActionButton';
import {
  formatDateStr,
  formatNumber,
} from '../../utils/i18n';
import getTableHeaders from './utils';

import { dateSort } from '../../utils/sort';

import { getTransferUpdateTimestamp } from '../../utils/capacityHelpers';

import { userHasPermission } from '../../utils/userHelpers';
import { CAPACITY_WRITE_PERMISSIONS } from '../../constants/users';

import './index.less';

const logger = new Logger('components:capacity-rights-transfers-table');

const CapacityRightsTransfersTable = ({
  actionsVisible,
  footer,
  separatePendingTransfers,
  subTitle,
  title,
  visibleElementsCount,
  includeTimestamps,
}) => {
  const {
    capacityTransfers,
    currentUser,
    handleDbChange,
    isAdmin,
    isReadOnlyAdmin,
    isReadOnlyAdminAsMarketParty,
    isLoading: contextIsLoading,
    marketPartyIndex,
    selectedMarketPartyId,
    updateCapacityTransfers,
  } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    updateCapacityTransfers();
  }, [selectedMarketPartyId]);

  const filterCapacityTransfers = data => (separatePendingTransfers && Array.isArray(data)
    ? data.reduce((output, item) => {
      if (
        [item.status, item.buyerStatus].includes(STATUS.PENDING)
          && ![item.status, item.buyerStatus].includes(STATUS.DECLINED)
      ) {
        output.pending.push(item);
      } else {
        output.rest.push(item);
      }
      return output;
    }, { pending: [], rest: [] })
    : { pending: [], rest: data }
  );

  const handleConfirmationOkClick = async (status, capacityRightTransferId, buyerId) => {
    const data = {
      body: isAdmin()
        ? { status }
        : { buyerStatus: status },
    };

    setIsLoading(true);

    try {
      const capacityRightsTransfers = await API.patch('FINTSO', `/marketparties/${buyerId}/capacitytransfers/${capacityRightTransferId}`, data);
      handleDbChange('CapacityTransfer', 'update', capacityRightsTransfers); // Upgrade CR in case websocket's message in delay
      notification.success({
        message: `${t('capacityRightsTransfer.title.capacityRightsTransfer')} ${t('common.status.successfullyUpdated')}`,
        description: '',
      });
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: `${t('common.notifications.errorUpdating')} ${t('capacityRightsTransfer.title.capacityRightsTransfer').toLowerCase()}`,
        description: createErrorMessage(error),
      });
      logger.error(createErrorMessage(error, true));
    }

    setIsLoading(false);
  };

  const handleConfirmation = (action, capacityRightTransferId, buyerId) => {
    const status = action === 'accept'
      ? STATUS.ACCEPTED
      : STATUS.DECLINED;

    Modal.confirm({
      centered: true,
      title: `Are you sure you want to ${action} this capacity right transfer?`,
      onOk: () => handleConfirmationOkClick(status, capacityRightTransferId, buyerId),
    });
  };

  const renderActionButton = (item) => {
    const {
      id,
      buyerId,
    } = item;
    const data = [];
    // ADMIN
    if (isAdmin() && item.status === STATUS.PENDING) {
      switch (item.buyerStatus) {
        case STATUS.PENDING:
          data.push({
            type: 'text',
            text: 'Waiting for buyer action',
          });
          break;
        case STATUS.ACCEPTED:
          data.push({
            type: 'button',
            text: t('common.button.accept'),
            onClick: () => handleConfirmation('accept', id, buyerId),
          }, {
            type: 'button',
            text: t('common.button.decline'),
            onClick: () => handleConfirmation('decline', id, buyerId),
          });
          break;
        default:
          break;
      }
    // USER
    } else if (item.buyerStatus === STATUS.ACCEPTED && item.status === STATUS.PENDING) {
      data.push({
        type: 'text',
        text: 'Waiting for TSO action', // TODO: "TSO" need to be changed?
      });
    } else if (
      item.buyerStatus === STATUS.PENDING
      && userHasPermission({ currentUser, selectedMarketPartyId }, CAPACITY_WRITE_PERMISSIONS)
    ) {
      data.push({
        type: 'button',
        text: 'Accept',
        onClick: () => handleConfirmation('accept', id, buyerId),
      }, {
        type: 'button',
        text: 'Decline',
        onClick: () => handleConfirmation('decline', id, buyerId),
      });
    }

    return data.length > 0 && <ActionButton data={data} />;
  };

  const remapTransfers = (transfers) => {
    if (!Array.isArray(transfers)) {
      return null;
    }

    const items = visibleElementsCount
      ? transfers.slice(0, visibleElementsCount)
      : transfers;

    return items
      .map((item) => {
        const data = item.capacities.reduce((accum, capacityItem) => ({
          capacity: accum.capacity + capacityItem.capacity,
          types: [...accum.types, capacityItem.type],
          date: [...accum.date, capacityItem.date],
          createdAt: item.createdAt,
          updatedAt: getTransferUpdateTimestamp(item.createdAt),
        }), {
          capacity: 0,
          types: [],
          date: [],
        });

        const isDeclined = [item.status, item.buyerStatus].includes(STATUS.DECLINED);
        // Render actions if user in admin role or belong to market party
        const renderAction = actionsVisible
          && (!isReadOnlyAdmin() && !isReadOnlyAdminAsMarketParty())
          && (isAdmin() || selectedMarketPartyId === item.buyerId)
          && (!isDeclined);
        const statusByUserType = (isAdmin() || isReadOnlyAdmin()) ? item.status : item.buyerStatus;
        const status = isDeclined ? STATUS.DECLINED : statusByUserType;

        return {
          id: item.id,
          type: [...new Set(data.types)].join(', '),
          date: [...new Set(data.date)].map(formatDateStr).join(', '),
          transfer: <CRTTableTransferItem
            item={item}
            marketParties={marketPartyIndex || []}
            marketPartyId={selectedMarketPartyId}
          />,
          capacity: formatNumber(data.capacity),
          status,
          action: renderAction && renderActionButton(item),
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };
      }).sort((a, b) => dateSort(a.createdAt, b.createdAt)).reverse();
  };

  const renderTable = (tableData, key, tableTitle, isPendingTable) => (
    <SummaryTable
      key={key}
      className="summarytable"
      tableHeaders={getTableHeaders(t, { includeTimestamps, isPendingTable })}
      tableData={tableData}
      title={tableTitle}
      subTitle={subTitle}
      footer={footer}
      isLoading={isLoading || contextIsLoading}
    />
  );

  const filteredTransfers = filterCapacityTransfers(capacityTransfers);

  return (
    separatePendingTransfers
      ? Object.entries(filteredTransfers).map(([index, data]) => (
        renderTable(
          remapTransfers(data),
          `table_${index}`,
          index === 'pending'
            ? t('capacityRightsTransfer.subtitle.unconfirmedCapacityRightsTransfers')
            : t('capacityRightsTransfer.subtitle.processedCapacityRightTransfers'),
          index === 'pending',
        )
      ))
      : (
        renderTable(
          remapTransfers(filteredTransfers.rest),
          'table_all',
          title,
          false,
        )
      )
  );
};

CapacityRightsTransfersTable.displayName = 'CapacityRightsTransfersTable';
CapacityRightsTransfersTable.propTypes = {
  actionsVisible: PropTypes.bool,
  footer: PropTypes.node,
  separatePendingTransfers: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  includeTimestamps: PropTypes.bool,
  visibleElementsCount: PropTypes.number,
  newCapacityTransfer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
CapacityRightsTransfersTable.defaultProps = {
  actionsVisible: false,
  footer: null,
  separatePendingTransfers: false,
  includeTimestamps: false,
  subTitle: null,
  title: null,
  visibleElementsCount: null,
  newCapacityTransfer: null,
};

export default CapacityRightsTransfersTable;
