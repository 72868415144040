import React from 'react';
import PropTypes from 'prop-types';

import './StatsTitle.less';

const StatsTitle = ({ title, description }) => (
  <div className="stats__title">
    <h3>{title}</h3>
    <span>{description}</span>
  </div>
);

StatsTitle.displayName = 'StatsTitle';
StatsTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default StatsTitle;
