import { Decimal } from 'decimal.js';
import { formatNumber } from './i18n';

import { UNITS_EXTENDED } from '../constants/units';

const getRoundedValue = (value, numDecimals = 0) => (
  value !== undefined ? new Decimal(value).toDecimalPlaces(numDecimals) : value
);

const getNumDecimalsForUnit = unit => (
  unit === UNITS_EXTENDED.KWH ? 0 : 3
);

const calculateWithUnit = (selectedUnit, value) => {
  if (value === undefined) {
    return undefined;
  }

  let multiplierValue = 1;

  if (selectedUnit === UNITS_EXTENDED.MWH) {
    multiplierValue = 0.001;
  } else if (selectedUnit === UNITS_EXTENDED.GWH) {
    multiplierValue = 0.000001;
  }

  const multiplier = new Decimal(multiplierValue);
  return getRoundedValue(Number(multiplier.mul(value)), getNumDecimalsForUnit(selectedUnit));
};

const calculateObjValues = (obj, unit) => (
  obj
    ? Object.keys(obj)
      .reduce((acc, key) => ({
        ...acc,
        [key]: calculateWithUnit(unit, obj[key]),
      }), {})
    : {}
);

const getTotalValue = (obj, unit) => (
  obj
    ? Object.values(obj)
      .reduce((total, props) => total.add(props), new Decimal(0))
      .toDecimalPlaces(getNumDecimalsForUnit(unit))
    : ''
);

const getSign = (value, type) => {
  switch (type) {
    case 'entry':
      return '+';
    case 'exit':
      return '-';
    case '#':
      if (value < 0) return '-';
      return '';
    default:
      if (value > 0) return '+';
      if (value < 0) return '-';
      return '';
  }
};

const getSignedValue = (value, type) => (
  `${getSign(value, type)} ${formatNumber(Math.abs(value))}`
);

export {
  getRoundedValue,
  calculateObjValues,
  getTotalValue,
  calculateWithUnit,
  getNumDecimalsForUnit,
  getSignedValue,
};
