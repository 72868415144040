import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider } from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

const SUPPORTED_BROWSERS = [{
  name: 'Chrome',
  link: 'https://www.google.com/chrome/',
}, {
  name: 'Firefox',
  link: 'https://www.mozilla.org/en-US/firefox/new/',
}, {
  name: 'Safari',
  link: 'https://support.apple.com/downloads/safari',
}, {
  name: 'Microsoft Edge',
  link: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
}];

const isBrowserNotSupported = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  let ieVersion = 0;

  if (msie > 0) {
    // IE 10 or older => return version number
    ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    ieVersion = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  return ieVersion !== 0 && ieVersion < 12;
};

const renderBrowserLink = () => (
  SUPPORTED_BROWSERS.map(browser => (
    <a
      key={browser.name}
      href={browser.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {browser.name}
    </a>
  ))
);

const renderContent = t => (
  <div className="unsupported-browser-notification__content">
    <p>
      {t('detectBrowser.modal.subheader')}
    </p>
    <Divider />
    <p>
      {t('detectBrowser.modal.helptext')}
    </p>
    <div>
      <p>
        <strong>{t('detectBrowser.modal.browsers')}</strong>
      </p>
      {renderBrowserLink()}
    </div>
  </div>
);


const DetectBrowser = ({ t }) => (
  <Modal
    className="unsupported-browser-notification"
    title={t('detectBrowser.modal.title')}
    visible={isBrowserNotSupported()}
    closable={false}
    footer={[]}
    width="40rem"
  >
    {renderContent(t)}
  </Modal>
);

DetectBrowser.displayName = 'DetectBrowser';
DetectBrowser.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DetectBrowser);
