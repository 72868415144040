import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { lowerCase } from 'lodash';
import {
  Button,
  DatePicker,
  Select,
  Icon,
  Row,
  Col,
  Form,
  notification,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import { localeDateFormat } from '../../utils/i18n';
import { startOfGasDay } from '../../utils/gasday';

import './RelationshipForm.less';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';
import { filterRelationshipPointAndDirection, isExternal, isShipper } from '../../utils/marketparty';
import {
  DIRECTION_NAMES,
  RELATIONSHIP_LOCATION_POINT_NAMES,
} from '../../constants/nominations';
import {
  MARKETPARTY_RELATIONSHIP_TYPES,
  MARKETPARTY_RELATIONSHIP_TYPES_NAMES,
} from '../../constants/relationships';
import { getNetworkLocationPoints } from '../../utils/networks';
import { STATUS_NAMES } from '../../constants/status';

const logger = new Logger('new-relation-ship:form');

class RelationshipForm extends Component {
  state = {
    isSaving: false,
  };

  componentDidMount = () => {
    const {
      marketPartyIndex,
      updateMarketpartyIndex,
    } = this.context;

    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
  }

  closeModal = () => {
    const { history } = this.props;

    history.push('/relationships-created');
  }

  handleCancel = () => {
    const { t } = this.props;

    return this.isButtonDisabled()
      ? this.closeModal()
      : modalConfirmClose(t, this.closeModal);
  }

  handleCreate = async (event) => {
    event.preventDefault();

    const {
      selectedMarketPartyId,
      handleDbChange,
      isAdmin,
      isReadOnlyAdmin,
    } = this.context;
    const {
      form,
      t,
    } = this.props;

    form.validateFields(async (error, body) => {
      if (!error && selectedMarketPartyId !== undefined && !isAdmin() && !isReadOnlyAdmin()) {
        const {
          start, end, marketPartyId, type,
        } = body;

        const [point, direction] = body.params ? body.params.split('#') : [];
        const params = point && direction
          ? { point, direction }
          : undefined;

        try {
          this.setState({ isSaving: true });
          const relationshipUrl = `/marketparties/${selectedMarketPartyId}/relationships`;
          const newRelationship = await API.post('FINTSO', relationshipUrl, {
            body: {
              marketPartyId,
              type,
              start: start.format('YYYY-MM-DD'),
              end: end.format('YYYY-MM-DD'),
              ...(params ? { params } : undefined),
            },
          });

          handleDbChange('Relationships', 'create', newRelationship);

          notification.success({
            message: t('relationships.message.createdSuccessfully'),
            description: '',
          });
          form.resetFields();
          this.closeModal();
        } catch (err) {
          notification.error({
            className: 'notification-error',
            message: t('relationships.message.errorCreating'),
            // TODO: translatable error message
            description: createErrorMessage(err),
          });
          logger.error(createErrorMessage(err, true));
          this.setState({ isSaving: false });
        }
      }
    });
  }

  handleUpdate = async (event) => {
    event.preventDefault();

    const {
      selectedMarketPartyId,
      handleDbChange,
      isAdmin,
    } = this.context;

    const {
      form,
      t,
      relationship,
      handleClose,
    } = this.props;

    form.validateFields(async (error, body) => {
      if (!error && selectedMarketPartyId !== undefined && isAdmin()) {
        const { start, end, status } = body;
        const { id: relationshipId, marketPartyIdA } = relationship;
        try {
          this.setState({ isSaving: true });
          const relationshipUrl = `/marketparties/${marketPartyIdA}/relationships/${relationshipId}`;
          const newRelationship = await API.patch('FINTSO', relationshipUrl, {
            body: {
              start: start.format('YYYY-MM-DD'),
              end: end.format('YYYY-MM-DD'),
              status,
            },
          });

          handleDbChange('Relationships', 'update', newRelationship);

          notification.success({
            message: t('relationships.message.updatedSuccessfully'),
            description: '',
          });
          form.resetFields();
          handleClose();
        } catch (err) {
          notification.error({
            className: 'notification-error',
            message: t('relationships.message.errorUpdating'),
            // TODO: translatable error message
            description: createErrorMessage(err),
          });
          logger.error(createErrorMessage(err, true));
          this.setState({ isSaving: false });
        }
      }
    });
  }

  handleSearch = (inputValue, option) => (option.key.includes(lowerCase(inputValue)));

  isButtonDisabled = () => {
    const {
      form: { getFieldsValue },
    } = this.props;
    const {
      start,
      end,
      marketPartyId,
      type,
      params,
    } = getFieldsValue();
    const isFieldEmpty = [start, end, marketPartyId, type].includes(undefined);

    return !this.isUpdateRelationship()
      && (isFieldEmpty || (type === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE && params === undefined));
  }

  earliestPossibleDay = (type) => {
    const now = moment();
    const { isAdmin } = this.context;
    const { form: { getFieldsValue } } = this.props;
    const {
      start,
      type: relationshipType,
    } = getFieldsValue();

    if (type === 'end' && start !== undefined && now.isBefore(startOfGasDay(start))) {
      return startOfGasDay(start);
    }

    if (!isAdmin() && relationshipType === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE) {
      const RELATIONSHIP_LEAD_TIME = 1; // number of days
      return startOfGasDay(now.add(RELATIONSHIP_LEAD_TIME, 'days'));
    }

    return startOfGasDay(now);
  }

  isDateDisabled = (timestamp, type) => (
    startOfGasDay(timestamp).isBefore(this.earliestPossibleDay(type).format('YYYY-MM-DD'))
  )

  isUpdateRelationship = () => {
    const { relationship } = this.props;
    return Boolean(relationship);
  };

  renderShipperAuthorizationSelectlist = () => {
    const { getSelectedMarketParty, marketPartyIndex } = this.context;
    const {
      form: { getFieldDecorator, getFieldValue },
      t,
      relationship,
    } = this.props;

    const isUpdate = this.isUpdateRelationship();
    if (isUpdate && !relationship.params) {
      return null;
    }

    const selectedMarketParty = isUpdate
      ? marketPartyIndex.find(({ id }) => id === relationship.marketPartyIdA)
      : getSelectedMarketParty();
    const type = getFieldValue('type');

    if (type === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE) {
      const marketPartyName = selectedMarketParty.name;

      return (
        <Form.Item
          label={<Trans i18nKey="relationships.label.authoriseShipperCheckbox" values={{ marketPartyName }} />}
          className="relationship-form__additional-permissions"
        >
          {getFieldDecorator('params', {
            initialValue: isUpdate
              ? `${relationship.params.point}#${relationship.params.direction}`
              : undefined,
            rules: [{ required: true }],
          })(
            <Select
              className="relationship-form__select-authorization"
              data-testid="relationship-form__select-authorization"
              disabled={this.isUpdateRelationship()}
              onChange={this.handleSelectChange}
            >
              {RELATIONSHIP_LOCATION_POINT_NAMES
                .flatMap(point => DIRECTION_NAMES
                  .filter(direction => filterRelationshipPointAndDirection(point, direction))
                  .map(direction => (
                    <Select.Option
                      value={`${point}#${direction}`}
                      key={`relationship-form__authorise-${point}_${direction}-select`}
                      className={`relationship-form__authorise-${point}_${direction}-select`}
                      data-testid={`relationship-form__authorise-${point}_${direction}-select`}
                    >
                      {t(`common.label.${point}`)}
                      {' '}
                      —
                      {' '}
                      {t(`common.label.${direction}`)}
                    </Select.Option>
                  )))}
            </Select>,
          )}
        </Form.Item>
      );
    }
    return null;
  }

  renderStatusField = () => {
    const { isAdmin } = this.context;
    const {
      form: { getFieldDecorator },
      t,
      relationship,
    } = this.props;

    const isUpdate = this.isUpdateRelationship();

    if (isUpdate && isAdmin()) {
      return (
        <Form.Item
          label={t('common.label.status')}
          className="relationship-form__status"
        >
          {getFieldDecorator('status', {
            initialValue: isUpdate
              ? relationship.status
              : undefined,
          })(
            <Select
              className="relationship-form__select-status"
              data-testid="relationship-form__select-status"
              onChange={this.handleSelectChange}
            >
              {STATUS_NAMES.map((status) => (
                <Select.Option
                  value={status}
                  key={`relationship-form__status-select-${status}`}
                  className={`relationship-form__status-select-${status}`}
                  data-testid={`relationship-form__status-select-${status}`}
                >
                  {t(`common.status.${status}`)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      );
    }
    return null;
  }

  renderDate = (type) => {
    const {
      form: { getFieldDecorator },
      t,
      relationship,
    } = this.props;

    return (
      <Form.Item
        label={t(`relationships.label.${type}Date`)}
        colon={false}
      >
        {getFieldDecorator(type, {
          initialValue: this.isUpdateRelationship() ? moment(relationship[type], 'YYYY-MM-DD') : undefined,
          rules: [{ required: true, message: t('common.form.dateErrorMessage') }],
        })(
          <DatePicker
            className={`relationship-form__${type}-date`}
            data-testid={`relationship-form__${type}-date`}
            format={localeDateFormat()}
            disabledDate={date => this.isDateDisabled(date, type)}
          />,
        )}
      </Form.Item>
    );
  }

  // TODO: This filtering can be removed once shipperGroup feature is made available for all users.
  filterSelectRelationship = (type) => {
    const {
      isAdmin,
    } = this.context;
    if (type === MARKETPARTY_RELATIONSHIP_TYPES.SHIPPER_GROUP) {
      return isAdmin(true);
    }
    return true;
  }

  renderTypeSelector = () => {
    const {
      t,
      form: { getFieldDecorator, setFieldsValue },
      relationship,
    } = this.props;

    return (
      <Form.Item
        label={t('relationships.label.type')}
      >
        {getFieldDecorator('type', {
          // keep 'shipper' as default selected
          initialValue: this.isUpdateRelationship() ? relationship.type : MARKETPARTY_RELATIONSHIP_TYPES.SHIPPER,
          rules: [{
            required: true,
            message: t('common.form.emptyInputFieldErrorMessage'),
          }],
        })(
          <Select
            className="relationship-form__select-type"
            data-testid="relationship-form__select-type"
            onChange={this.handleSelectChange}
            disabled={this.isUpdateRelationship()}
          >
            {MARKETPARTY_RELATIONSHIP_TYPES_NAMES.filter(this.filterSelectRelationship).map(name => (
              <Select.Option
                key={lowerCase(name)}
                value={name}
                onClick={(obj) => {
                  if (obj.key === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE) {
                    // reset Date when relationship type changes
                    setFieldsValue({
                      start: undefined,
                      end: undefined,
                    });
                  }
                }}
                data-testid={`relationship-form__type-option-${name}`}
              >
                {t(`relationships.label.types.${name}`)}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
    );
  }

  filterMarketPartyIndexes = (mpIndex) => {
    const {
      form: { getFieldsValue },
    } = this.props;
    const { type, params } = getFieldsValue();

    if (type === MARKETPARTY_RELATIONSHIP_TYPES.SHIPPER || type === MARKETPARTY_RELATIONSHIP_TYPES.SHIPPER_GROUP) {
      return isShipper(mpIndex);
    }

    // When type is authorizeOnBehalf, params should be selected first
    if (!params) {
      return false;
    }

    const [point] = params ? params.split('#') : [];

    const isInSelectedPoint = mpIndex.network.some((nw) => {
      const points = getNetworkLocationPoints(nw);
      return points.includes(point);
    });
    return (isInSelectedPoint && (isShipper(mpIndex) || isExternal(mpIndex)));
  }

  checkIsShipperSelectionDisabled = () => {
    const {
      form: { getFieldsValue },
    } = this.props;
    const { type, params } = getFieldsValue();
    return (
      type === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE
      && !params
    );
  };

  renderShipperSelector = () => {
    const { marketPartyIndex } = this.context;
    const {
      t,
      form: { getFieldDecorator, getFieldValue },
      relationship,
    } = this.props;
    const type = getFieldValue('type');

    return (
      <Form.Item
        label={type === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE
          ? t('relationships.label.marketparty')
          : t('relationships.label.shipper')
        }
        colon={false}
      >
        {getFieldDecorator('marketPartyId', {
          initialValue: this.isUpdateRelationship() ? relationship.marketPartyIdB : undefined,
          rules: [{ required: true, message: t('common.form.emptyInputFieldErrorMessage') }],
        })(
          <Select
            className="relationship-form__select-shipper"
            data-testid="relationship-form__select-shipper"
            onChange={this.handleSelectChange}
            filterOption={this.handleSearch}
            disabled={this.isUpdateRelationship() || this.checkIsShipperSelectionDisabled()}
            showSearch
            suffixIcon={<Icon type="search" />}
          >
            {marketPartyIndex.filter(this.filterMarketPartyIndexes).map(item => (
              <Select.Option
                key={lowerCase(item.name)}
                value={item.id}
                data-testid="relationship-form__options"
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
    );
  }

  renderContent = () => (
    <Form
      className="relationship-form__form"
      data-testid="relationship-form__form"
      onSubmit={this.isUpdateRelationship() ? this.handleUpdate : this.handleCreate}
    >

      <Row>
        <Col span={16}>
          {this.renderTypeSelector()}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          {this.renderShipperAuthorizationSelectlist()}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          {this.renderShipperSelector()}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          {this.renderStatusField()}
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          {this.renderDate('start')}
        </Col>
        <Col span={1}>
          <div className="relationship-form__form__input-divider">
            -
          </div>
        </Col>
        <Col span={8}>
          {this.renderDate('end')}
        </Col>
      </Row>
    </Form>
  );

  renderActionButtons = () => {
    const { t, handleClose } = this.props;
    const { isSaving } = this.state;

    return [
      <Button
        className="relationship-form__cancel-button"
        data-testid="relationship-form__cancel-button"
        key="cancel"
        onClick={handleClose || this.handleCancel}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="relationship-form__create-button"
        data-testid="relationship-form__create-button"
        key="save"
        loading={isSaving}
        type="primary"
        onClick={this.isUpdateRelationship() ? this.handleUpdate : this.handleCreate}
        disabled={this.isButtonDisabled()}
      >
        {this.isUpdateRelationship() ? t('common.button.update') : t('common.button.create')}
      </Button>,
    ];
  }

  render = () => {
    const { t, handleClose } = this.props;

    return (
      <ModalWrapper
        modalClassName="relationship-form"
        title={this.isUpdateRelationship() ? t('relationships.title.updateRelationship') : t('relationships.title.newRelationship')}
        handleClose={handleClose || this.handleCancel}
        actionButtons={this.renderActionButtons()}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

RelationshipForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  handleClose: PropTypes.func,
  relationship: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    marketPartyIdA: PropTypes.string.isRequired,
    marketPartyIdB: PropTypes.string.isRequired,
    params: PropTypes.shape({
      point: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    status: PropTypes.string,
  }),
};

RelationshipForm.defaultProps = {
  relationship: undefined,
  handleClose: undefined,
};

RelationshipForm.displayName = 'RelationshipForm';
RelationshipForm.contextType = Context;

const TRANSLATED = withTranslation()(RelationshipForm);
const FORM = Form.create({ name: 'newRelationship' })(TRANSLATED);

export default withRouter(FORM);
export {
  RelationshipForm as PureComponent,
};
