import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  Table,
  Row,
  Col,
} from 'antd';
import {
  Link,
  withRouter,
  Route,
} from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import Context from '../../context';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { formatDate } from '../../utils/i18n';
import StatusIcon from '../StatusIcon';
import NewMembership from './newMembership';
import EditMembership from './editMembership';
import { getGasDay } from '../../utils/gasday';
import { getSignedValue } from '../../utils/balanceGroupStatsHelpers';

import './index.less';
import { userHasPermission } from '../../utils/userHelpers';
import { BALANCE_WRITE_PERMISSIONS } from '../../constants/users';

const DATA_HEADERS = [
  {
    title: 'shipper',
    dataIndex: 'shipper',
    key: 'shiper',
  }, {
    title: 'memberUntil',
    dataIndex: 'end',
    key: 'end',
  }, {
    title: 'domesticEntryExit',
    dataIndex: 'entryExit',
    key: 'entryExit',
  }, {
    title: 'vtp',
    dataIndex: 'vtp',
    key: 'vtp',
  }, {
    title: 'importsExports',
    dataIndex: 'importsExports',
    key: 'importsExports',
  }, {
    title: 'imbalance',
    dataIndex: 'imbalance',
    key: 'imbalance',
  }, {
    title: 'eic',
    dataIndex: 'eic',
    key: 'eic',
  }, {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    className: 'content--centered',
  },
];

const EDIT_COLUMN = {
  title: 'edit',
  dataIndex: 'edit',
  key: 'edit',
};

class BalanceGroupMembersTable extends React.Component {
  componentDidMount = () => {
    const {
      balanceGroupMembers,
      updateBalanceGroupMembers,
      marketPartyIndex,
      updateMarketpartyIndex,
    } = this.context;

    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (!balanceGroupMembers) updateBalanceGroupMembers();
  }

  isDataLoaded = () => {
    const {
      balanceGroupMembers,
      isLoading,
      marketPartyIndex,
    } = this.context;

    return (
      balanceGroupMembers !== null
      && marketPartyIndex.length > 0
      && !isLoading
    );
  }

  getShipper = (marketParties, marketPartyId) => {
    const { currentUser: { ownMarketParties } } = this.context;
    return ownMarketParties.concat(marketParties).filter(item => item.id === marketPartyId);
  };

  renderEditLink = (membershipId) => {
    const {
      t,
      match: {
        url,
      },
    } = this.props;
    const to = `${url}/membership/${membershipId}`;

    return (
      <Link to={to}>
        { t('common.button.edit') }
      </Link>
    );
  }

  getMemberStats = (membershipId) => {
    const {
      balanceGroupStats,
    } = this.context;
    const { selectedDate } = this.props;
    const selectedGasDay = getGasDay(selectedDate);
    const statValues = {
      imbalanceTotal: undefined,
      netValues: {
        entryExit: undefined,
        vtp: undefined,
        importsExports: undefined,
      },
    };

    if (balanceGroupStats !== null && balanceGroupStats[selectedGasDay] !== undefined) {
      const { members } = balanceGroupStats[selectedGasDay];
      const membershipStats = members.find(member => (member.membershipId === membershipId));

      return { ...statValues, ...membershipStats };
    }

    return statValues;
  }

  renderStatValue = (value) => {
    const { t } = this.props;

    return (
      <span className={classNames(
        'stat-value',
        { 'not-available': value === undefined },
      )}
      >
        { value === undefined ? t('common.label.notAvailable') : getSignedValue(value)}
      </span>
    );
  };

  renderShipperName = (shipper) => {
    const { t } = this.props;
    const { selectedMarketPartyId } = this.context;
    const isSelf = selectedMarketPartyId === shipper.id;

    return (
      <span className={`balancegroupmembers__table-shipper ${isSelf ? 'self' : ''}`}>
        { isSelf
          ? (
            <Link to="/mybalance">
              {`${shipper.name} (${t('balanceContainer.label.self')})`}
            </Link>
          )
          : shipper.name
        }
      </span>
    );
  }

  remappedMembership = () => {
    if (!this.isDataLoaded()) return [];

    const {
      balanceGroupMembers,
      marketPartyIndex,
    } = this.context;
    const { balanceGroupId } = this.props;

    if (!balanceGroupId) return [];

    const dataSource = balanceGroupMembers
      .filter(membership => membership.balanceGroupId === balanceGroupId)
      .map((membership) => {
        const shipper = this.getShipper(marketPartyIndex, membership.marketPartyId)[0];

        if (shipper === undefined) return {};

        const {
          imbalanceTotal, netValues,
        } = this.getMemberStats(membership.id);

        return ({
          key: membership.id,
          shipper: this.renderShipperName(shipper),
          end: membership.end !== undefined ? formatDate(membership.end) : '',
          entryExit: this.renderStatValue(netValues.domesticEntryExit),
          vtp: this.renderStatValue(netValues.vtp),
          importsExports: this.renderStatValue(netValues.importsExports),
          imbalance: this.renderStatValue(imbalanceTotal),
          eic: shipper.eic,
          status: <StatusIcon status={membership.status} />,
          edit: this.renderEditLink(membership.id),
        });
      });

    return dataSource;
  }

  render = () => {
    const { t, isViewingPastBalanceGroup } = this.props;
    const {
      balanceGroupStatsLoading,
      isAdmin,
    } = this.context;
    const columns = DATA_HEADERS.concat((
      !isViewingPastBalanceGroup && (isAdmin(true) || userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS))
        ? EDIT_COLUMN
        : []
    ));

    return (
      <div className="balancegroupmembers">
        <Row className="balancegroupmembers__header">
          <Col span={6}>
            <h3>{t('balanceContainer.title.members')}</h3>
          </Col>
          <Col span={18}>
            {
              !isViewingPastBalanceGroup
              && (isAdmin(true)
              || userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS))
              && <NewMembership />
            }
          </Col>
        </Row>
        <Table
          className="balancegroupmembers__table"
          data-testid="balancegroupmembers__table"
          columns={getTranslatedTableHeaders(columns, t)}
          dataSource={this.remappedMembership()}
          pagination={false}
          loading={balanceGroupStatsLoading || !this.isDataLoaded()}
          rowKey={(record, index) => `${record.id}_${index}`}
        />
        {
          !isViewingPastBalanceGroup
          && (isAdmin(true)
          || userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS))
          && <Route path="/balance/membership/:id" component={EditMembership} />
        }
      </div>
    );
  }
}

BalanceGroupMembersTable.displayName = 'BalanceGroupMembersTable';
BalanceGroupMembersTable.contextType = Context;

BalanceGroupMembersTable.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  selectedDate: PropTypes.objectOf(moment).isRequired,
  balanceGroupId: PropTypes.string.isRequired,
  isViewingPastBalanceGroup: PropTypes.bool.isRequired,
};

const BalanceGroupMembersTableTranslated = withTranslation()(BalanceGroupMembersTable);
export default withRouter(BalanceGroupMembersTableTranslated);
export {
  BalanceGroupMembersTable as PureComponent,
};
