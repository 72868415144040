import joi from 'joi';

import {
  NETWORK_NAMES, ROLE_NAMES, ROLES, STATUS,
} from '../constants/marketParties';

const roleName = joi.string().valid(...ROLE_NAMES);

const networkName = joi.string().valid(...NETWORK_NAMES);

const CONTACT_PERSON_SCHEMA = {
  firstname: joi.string().allow('').optional(),
  lastname: joi.string().allow('').optional(),
  phone: joi.string().regex(/^\+[0-9]{7,15}$/),
  email: joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
};
const getMarketPartySchema = roles => ({
  id: joi.string().uuid(),
  name: joi.string(),
  vatId: joi.string(),
  /* TODO: if country is Finland, validate finnish VAT IDs:
   * .regex(/^FI[0-9]{8}$/, 'FI followed by eight digits'), */
  gs1Retailer: roles.includes(ROLES.RETAILER) ? joi.string() : joi.string().allow('', null),
  gs1Dso: roles.includes(ROLES.DSO) ? joi.string() : joi.string().allow('', null),
  eic: joi.string().regex(/^[0-9A-Z-]{16}$/, 'alphanumeric/dash'),
  street: joi.string(),
  postalCode: joi.string().regex(/^(?:[A-Z]{2}-)?[0-9]{4,5}$/, 'optional country code ("FI-", "LV-"...) followed by 4-5 digits').min(5),
  city: joi.string(),
  country: joi.string(),
  notes: joi.string(),
  status: joi.string().valid(...Object.values(STATUS)),
  roles: joi.array().min(1).items(roleName),
  network: joi.array().items(networkName),
  hidden: joi.boolean(),
  contactPerson: CONTACT_PERSON_SCHEMA,
});

export {
  // Eslint disable for now because there will be more exports in the future.
  // Can be removed after there are more than one export.
  // eslint-disable-next-line import/prefer-default-export
  getMarketPartySchema,
  CONTACT_PERSON_SCHEMA,
};
