import React from 'react';
import { Empty } from 'antd';

const NotFound = () => (
  <Empty
    className="empty"
    description="404 Not Found"
  />
);

export default NotFound;
