import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getMarketPartyFromContextBy, getMarketPartyFromContextById } from '../../utils/marketparty';
import Context from '../../context';

const MarketParty = (props) => {
  const { id, eic } = props;
  if (!id && !eic) {
    return null;
  }
  const context = useContext(Context);
  const marketParty = id
    ? getMarketPartyFromContextById(context, id)
    : getMarketPartyFromContextBy(context, 'eic')(eic);
  return marketParty
    ? (
      <>
        <div>
          {marketParty.name}
        </div>
        <div className="nominations__table__eic">
          {marketParty.eic}
        </div>
      </>
    )
    : null;
};

MarketParty.propTypes = {
  id: PropTypes.string,
  eic: PropTypes.string,
};

MarketParty.defaultProps = {
  id: undefined,
  eic: undefined,
};


export default MarketParty;
