import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import MeteringSite from './meteringSite';
import NewDeliveryRelationship from './newDeliveryRelationship';
import MeteringSiteDeliveryRelationships from './meteringSiteDeliveryRelationships';
import ModalWrapper from '../ModalWrapper';
import { getGasDay } from '../../utils/gasday';

class DeliveryRelationship extends Component {
  /**
   * Handling closing a modal.
   */
  handleClose = () => {
    const {
      history,
      match: {
        path,
      },
    } = this.props;

    history.push(path.replace('/:id', ''));
  }

  /**
   * Fetch metering site data.
   *
   * @returns {Object} - Metering site's data.
   */
  getMeteringSiteData = () => {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const {
      meteringSites,
      meteringPointOwnerships,
      marketPartyIndex,
    } = this.context;

    const meteringSite = meteringSites && meteringSites.find(site => site.id === id);

    if (!meteringSite) {
      return {
        id: '',
        name: t('common.status.notExists'),
        active: '',
        marketPartyName: '',
        street: '',
        postalCode: '',
        city: '',
        emissionTrade: false,
      };
    }

    const today = getGasDay();
    const ownership = meteringPointOwnerships.find(item => item.meteringPointId === meteringSite.id
      && item.start <= today && (!item.end || item.end >= today)) || {};
    const marketParty = marketPartyIndex.find(item => item.id === ownership.marketPartyId);

    return {
      ...meteringSite,
      marketPartyName: marketParty ? marketParty.name : t('common.status.notExists'),
    };
  }

  render = () => {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const meteringSite = this.getMeteringSiteData();

    return (
      <ModalWrapper
        modalClassName="delivery-relationship"
        title={`METERING SITE: ${meteringSite.name}`}
        width="50rem"
        handleClose={this.handleClose}
        actionButtons={[
          <Button
            className="delivery-relationship__close-button"
            key="close"
            type="primary"
            onClick={this.handleClose}
          >
            {t('common.button.close')}
          </Button>,
        ]}
      >
        <>
          <MeteringSite
            id={meteringSite.id}
            status={meteringSite.active}
            marketPartyName={meteringSite.marketPartyName}
            street={meteringSite.street}
            postalCode={meteringSite.postalCode}
            city={meteringSite.city}
            emissionTrade={meteringSite.emissionTrade}
          />
          <NewDeliveryRelationship meteringSiteId={id} />
          <MeteringSiteDeliveryRelationships meteringSiteId={id} />
        </>
      </ModalWrapper>
    );
  }
}

DeliveryRelationship.displayName = 'DeliveryRelationship';
DeliveryRelationship.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
DeliveryRelationship.contextType = Context;

export default withTranslation()(DeliveryRelationship);
