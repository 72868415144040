import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import {
  Button,
  Empty,
  Layout,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { extendMoment } from 'moment-range';
import Moment from 'moment';

import { STATUS } from '../../constants/status';
import {
  BALANCE_READ_PERMISSIONS,
  BALANCE_WRITE_PERMISSIONS,
} from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';
import { getGasDay, middleOfGasDay } from '../../utils/gasday';
import { formatDate } from '../../utils/i18n';
import { minutes } from '../../utils/interval';

import Context from '../../context';
import PageHeaderBar from '../../components/PageHeaderBar';
import Loading from '../../components/Loading';
import NewBalanceGroup from '../../components/BalanceGroup/newBalanceGroup';
import BalanceGroupMembersTable from '../../components/BalanceGroupMembersTable';
import BalanceGroupStats from '../../components/BalanceGroupStats';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';

import './index.less';

const moment = extendMoment(Moment);

const UPDATE_INTERVAL = minutes(5);

const Balance = (props) => {
  const [pastRange, setPastRange] = useState({});
  const [selectedDate, setSelectedDate] = useState(middleOfGasDay());
  const [interval, setIntervalState] = useState();
  const { t } = useTranslation();
  const {
    activeBalanceGroupId,
    balanceGroupStats,
    balanceGroupStatsLoading,
    updateBalanceGroupStats,
    balanceGroups,
    balanceGroupMembers,
    isAdmin,
    isReadOnlyAdmin,
    isReadOnlyAdminAsMarketParty,
    isLoading,
    marketPartyIndex,
    selectedMarketPartyId,
    currentUser,
  } = useContext(Context);

  const { location: { pathname } } = props;
  const pastBalanceGroupId = pathname.split('/balance/history/')[1];
  const selectedBalanceGroupId = pastBalanceGroupId || activeBalanceGroupId;

  const balanceGroupOwner = (balanceGroupMembers || []).find(
    member => member.marketPartyId === selectedMarketPartyId
      && member.balanceGroupId === selectedBalanceGroupId,
  ) || {};

  const permissionData = { selectedMarketPartyId, currentUser };
  const canWrite = userHasPermission(permissionData, BALANCE_WRITE_PERMISSIONS);
  const canRead = userHasPermission(permissionData, BALANCE_READ_PERMISSIONS);

  const fetchData = (date) => {
    updateBalanceGroupStats(getGasDay(date || selectedDate), selectedBalanceGroupId);
  };

  const handleChangeSelectedDate = (date) => {
    setSelectedDate(date);
    fetchData(date);
  };

  useEffect(() => {
    if (pastBalanceGroupId && !pastRange.start && balanceGroupMembers) {
      const ownerMembership = balanceGroupMembers.find(
        member => member.marketPartyId === selectedMarketPartyId
          && member.balanceGroupId === selectedBalanceGroupId,
      );
      const { start, end } = ownerMembership;
      setPastRange({ start, end });
      handleChangeSelectedDate(middleOfGasDay(end));
    }
  });

  useEffect(() => {
    const activeBalanceGroupSelected = selectedBalanceGroupId === activeBalanceGroupId;
    const currentDateSelected = getGasDay() === getGasDay(selectedDate);
    if (activeBalanceGroupSelected && !currentDateSelected) {
      handleChangeSelectedDate(middleOfGasDay());
    }
  }, [selectedBalanceGroupId]);

  useEffect(() => {
    if (!pastBalanceGroupId) {
      fetchData();
      const updatedInterval = setInterval(() => fetchData(), UPDATE_INTERVAL);
      setIntervalState(updatedInterval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [selectedMarketPartyId]);

  const handleNewBalanceGroupClick = () => {
    const { history } = props;
    history.push('/balance/new-balance-group');
  };

  const isDataLoaded = () => (
    activeBalanceGroupId !== null
    && balanceGroups !== null
    && balanceGroupMembers !== null
    && marketPartyIndex.length > 0
    && !isLoading
  );

  const isWithinMembership = (timestamp) => {
    if (!isDataLoaded()) {
      return false;
    }

    const { start, end } = balanceGroupOwner;
    const gasDay = moment.utc(timestamp.format('YYYY-MM-DD'));
    const isAfterStart = gasDay.isSameOrAfter(moment.utc(start));
    const isBeforeEnd = !end || gasDay.isSameOrBefore(moment.utc(end));
    return (isAfterStart && isBeforeEnd);
  };

  const canCreateBalanceGroup = () => {
    const activeBalanceGroup = (balanceGroups || [])
      .find(({ id }) => id === activeBalanceGroupId) || {};

    const hasNoActiveBalanceGroup = activeBalanceGroupId === '' && !isAdmin() && !isReadOnlyAdmin() && !isReadOnlyAdminAsMarketParty();
    const isActiveBalanceGroupDeclined = activeBalanceGroup.status === STATUS.DECLINED;

    return canWrite && (hasNoActiveBalanceGroup || isActiveBalanceGroupDeclined);
  };

  const renderHeaderAction = () => {
    if (canCreateBalanceGroup()) {
      return (
        <>
          <Button
            className="page__header__buttons__new-balancegroup"
            data-testid="page__header__buttons__new-balancegroup"
            type="primary"
            onClick={handleNewBalanceGroupClick}
          >
            {t('balanceContainer.button.newBalanceGroups')}
          </Button>
        </>
      );
    }

    if (canRead && selectedBalanceGroupId) {
      return (
        <StatsDatePicker
          selectedDate={selectedDate}
          onSelectedDateChange={handleChangeSelectedDate}
          disabledDate={timestamp => !isWithinMembership(timestamp)}
        />
      );
    }

    return null;
  };

  const renderContent = () => {
    if (!isDataLoaded()) {
      return <Loading />;
    }

    const selectedBalanceGroup = balanceGroups.find(
      balanceGroup => balanceGroup.id === selectedBalanceGroupId,
    );

    if (!selectedBalanceGroupId
      && (activeBalanceGroupId === '' || selectedBalanceGroup === undefined || selectedBalanceGroup.status === STATUS.DECLINED)) {
      return <Empty description={t('balanceContainer.emptyPage.description.noBalanceGroup')} />;
    }

    if (selectedBalanceGroup.status === STATUS.PENDING) {
      return <Empty description={t('balanceContainer.emptyPage.description.pendingBalanceGroup')} />;
    }

    const { marketPartyId } = balanceGroups.find(({ id }) => id === selectedBalanceGroupId) || {};
    if (marketPartyId !== selectedMarketPartyId) {
      return <Redirect to="/mybalance" />;
    }

    return (
      <>
        <BalanceGroupStats
          selectedDate={selectedDate}
          balanceGroupStats={balanceGroupStats}
          isLoading={balanceGroupStatsLoading}
        />
        <BalanceGroupMembersTable
          selectedDate={selectedDate}
          balanceGroupId={selectedBalanceGroupId}
          isViewingPastBalanceGroup={!!pastBalanceGroupId}
        />
      </>
    );
  };

  const { start, end } = pastRange;
  const title = pastBalanceGroupId
    ? t('balanceContainer.title.balanceHistory', { start: formatDate(start), end: formatDate(end) })
    : t('balanceContainer.title.balance');

  return (
    <>
      <PageHeaderBar
        title={title}
        actionItems={renderHeaderAction()}
        data-testid="page__header-bar"
        span={{
          title: 12,
          action: 12,
        }}
      />
      <Layout.Content className="layout__container__page__content balance">
        {renderContent()}
      </Layout.Content>
      {canCreateBalanceGroup() && <Route path="/balance/new-balance-group" component={NewBalanceGroup} />}
    </>
  );
};

Balance.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

Balance.displayName = 'Balance';

export default withRouter(Balance);
export {
  Balance as PureComponent,
};
