import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  notification,
  Table,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../StatusIcon';
import ActionButton from '../ActionButton';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { STATUS } from '../../constants/status';
import { userHasPermission } from '../../utils/userHelpers';
import { BALANCE_WRITE_PERMISSIONS } from '../../constants/users';
import { getGasDay } from '../../utils/gasday';

const logger = new Logger('components:BalanceGroupsTable');

const getDataHeaders = (activeBalanceGroupId, selectedMarketPartyId, renderActionColumn) => ([
  {
    title: 'balanceGroupOwner',
    key: 'balanceGroupOwner',
    render: (balanceGroup, { isActive }) => {
      const path = balanceGroup.id === activeBalanceGroupId
        ? '/balance'
        : `/balance/history/${balanceGroup.id}`;
      const marketPartyName = isActive
        ? <b>{balanceGroup.marketpartyName}</b>
        : balanceGroup.marketpartyName;

      return (balanceGroup.marketPartyId === selectedMarketPartyId
        ? <Link to={path}>{balanceGroup.marketpartyName}</Link>
        : marketPartyName);
    },
  },
  {
    title: 'startDate',
    dataIndex: 'start',
    key: 'start',
  },
  {
    title: 'endDate',
    dataIndex: 'end',
    key: 'end',
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
  },
  (renderActionColumn) ? {
    title: 'actions',
    dataIndex: 'actions',
    key: 'actions',
  } : {},
]);

class BalanceGroupsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      isLoading: false,
      balanceGroupsMembers: {},
    };
  }

  componentDidMount = async () => {
    const {
      activeBalanceGroupId,
      updateActiveBalanceGroupId,
      marketPartyIndex,
      updateMarketpartyIndex,
    } = this.context;

    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (activeBalanceGroupId === null) updateActiveBalanceGroupId();
  }

  componentDidUpdate = () => {
    const { activeBalanceGroupId, updateActiveBalanceGroupId } = this.context;
    const { isUpdating } = this.state;

    if (activeBalanceGroupId === null && !isUpdating) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUpdating: true }, async () => {
        await updateActiveBalanceGroupId();
        this.setState({ isUpdating: false });
      });
    }
  }

  getMarketPartyName = (id) => {
    const { marketPartyIndex } = this.context;
    const marketparty = marketPartyIndex.find(marketParty => marketParty.id === id);

    return marketparty ? marketparty.name : '';
  }

  prepareMembersForBalanceGroup = (groupId) => {
    const { balanceGroupsMembers } = this.state;
    const members = balanceGroupsMembers[groupId] || [];

    return members.map(member => ({
      ...member,
      key: member.id,
      marketpartyName: this.getMarketPartyName(member.marketPartyId),
      status: <StatusIcon status={member.status} />,
    }));
  };

  isActive = (startDate, endDate) => {
    const today = getGasDay(); // YYYY-MM-DD
    // Check if today is the same as the start date or falls between start and end dates
    if (today >= startDate && (endDate === undefined || today <= endDate)) {
      return true;
    }
    return false;
  }

  sortByLatest = (obj1, obj2) => (new Date(obj2.start) - new Date(obj1.start));

  getBalanceGroupsForTable = () => {
    if (!this.isDataLoaded()) return [];

    const {
      balanceGroups, balanceGroupMembers, isAdmin, isReadOnlyAdmin, selectedMarketPartyId,
    } = this.context;

    const rows = [];
    const bgMembersSorted = balanceGroupMembers.sort(this.sortByLatest);
    balanceGroups.forEach((balanceGroup) => {
      const memberships = bgMembersSorted.filter(item => (
        item.balanceGroupId === balanceGroup.id && (
          selectedMarketPartyId === 'admin' || item.marketPartyId === selectedMarketPartyId)
      )) || [];

      const rowObj = {
        ...balanceGroup,
        start: balanceGroup.start,
        end: balanceGroup.end,
        key: balanceGroup.id,
        marketpartyName: this.getMarketPartyName(balanceGroup.marketPartyId),
        status: this.renderStatusIcon(balanceGroup),
        actions: this.renderActionButton(balanceGroup),
        children: (isReadOnlyAdmin() || isAdmin())
          ? this.prepareMembersForBalanceGroup(balanceGroup.id)
          : undefined,
      };

      if (selectedMarketPartyId !== 'admin' && memberships.length) {
        memberships.forEach((membership) => rows.push({
          ...rowObj,
          start: membership.start,
          end: membership.end,
          isActive: this.isActive(membership.start, membership.end), // to help highlight the current active one
          children: undefined,
        }));
      } else {
        rows.push(rowObj);
      }
    });

    return rows;
  }

  handleBalanceGroupStatusChange = async (status, marketPartyId, balanceGroupId) => {
    const { handleDbChange } = this.context;
    const { t } = this.props;

    this.setState({ isLoading: true });

    try {
      const init = {
        body: { status },
      };
      const updatedBalanceGroup = await API.patch('FINTSO', `/marketparties/${marketPartyId}/balancegroups/${balanceGroupId}`, init);
      handleDbChange('BalanceGroup', 'update', updatedBalanceGroup);
      notification.success({
        message: t('balanceContainer.message.updatedSuccessfully'),
        description: '',
      });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('balanceContainer.message.errorUpdating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    }
    this.setState({ isLoading: false });
  }

  handleMembershipStatusChange = async (status, marketPartyId, balanceGroupId, membershipId) => {
    const { handleDbChange } = this.context;
    const { t } = this.props;

    this.setState({ isLoading: true });

    try {
      const init = {
        body: { status },
      };
      const updatedMembership = await API.patch(
        'FINTSO',
        `/marketparties/${marketPartyId}/balancegroups/${balanceGroupId}/members/${membershipId}`,
        init,
      );
      handleDbChange('BalanceGroupMember', 'update', updatedMembership);
      notification.success({
        message: t('balanceContainer.message.updatedSuccessfully'),
        description: '',
      });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('balanceContainer.message.errorUpdating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    }
    this.setState({ isLoading: false });
  }

  isDataLoaded = () => {
    const {
      activeBalanceGroupId,
      balanceGroups,
      balanceGroupMembers,
      isLoading,
      marketPartyIndex,
    } = this.context;

    return (
      activeBalanceGroupId !== null
      && balanceGroups !== null
      && balanceGroupMembers !== null
      && marketPartyIndex.length > 0
      && !isLoading
    );
  }

  renderActionButton = (balanceGroup) => {
    const {
      balanceGroupMembers,
      selectedMarketPartyId,
      isAdmin,
    } = this.context;
    const { t } = this.props;
    const {
      id,
      marketPartyId,
      status,
    } = balanceGroup;

    if (isAdmin(true) && status === STATUS.PENDING) {
      const data = [
        {
          type: 'button',
          text: t('common.button.accept'),
          onClick: () => this.handleBalanceGroupStatusChange(STATUS.ACCEPTED, marketPartyId, id),
        },
        {
          type: 'button',
          text: t('common.button.decline'),
          onClick: () => this.handleBalanceGroupStatusChange(STATUS.DECLINED, marketPartyId, id),
        },
      ];

      return <ActionButton data={data} />;
    }

    const membership = balanceGroupMembers.find(member => (
      member.marketPartyId === selectedMarketPartyId
      && member.balanceGroupId === id
    ));

    if (
      !['admin', marketPartyId].includes(selectedMarketPartyId)
      && membership !== undefined
      && membership.status === STATUS.PENDING
      && userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS)
    ) {
      const data = [
        {
          type: 'button',
          text: t('common.button.accept'),
          onClick: () => this.handleMembershipStatusChange(
            STATUS.ACCEPTED,
            selectedMarketPartyId,
            id,
            membership.id,
          ),
        },
        {
          type: 'button',
          text: t('common.button.decline'),
          onClick: () => this.handleMembershipStatusChange(
            STATUS.DECLINED,
            selectedMarketPartyId,
            id,
            membership.id,
          ),
        },
      ];

      return <ActionButton data={data} />;
    }

    return null;
  }

  renderStatusIcon = (balanceGroup) => {
    const {
      balanceGroupMembers,
      selectedMarketPartyId,
      isAdmin,
      isReadOnlyAdmin,
    } = this.context;

    if (balanceGroup.marketPartyId === selectedMarketPartyId || isAdmin() || isReadOnlyAdmin()) {
      return <StatusIcon status={balanceGroup.status} />;
    }

    const membership = balanceGroupMembers
      .find(member => member.balanceGroupId === balanceGroup.id);
    return membership !== undefined
      ? <StatusIcon status={membership.status} />
      : null;
  }

  fetchBalanceGroupMembers = async (marketPartyId, balanceGroupId) => {
    const { t } = this.props;
    try {
      const { items: balanceGroupMembers } = await API.get('FINTSO', `/marketparties/${marketPartyId}/balancegroups/${balanceGroupId}/members`);
      this.setState(state => ({
        ...state,
        balanceGroupsMembers: {
          ...state.balanceGroupsMembers,
          [balanceGroupId]: balanceGroupMembers,
        },
      }));
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('balanceContainer.message.errorFetchMembers'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    }
  }

  expandIconClicked = async (expanded, record) => {
    if (!expanded) return;

    const { id, marketPartyId } = record;
    const { balanceGroupsMembers } = this.state;

    if (balanceGroupsMembers[id] === undefined) {
      await this.fetchBalanceGroupMembers(marketPartyId, id);
    }
  }

  render = () => {
    const { isLoading } = this.state;
    const { t } = this.props;
    const {
      activeBalanceGroupId,
      selectedMarketPartyId,
      isAdmin,
    } = this.context;
    const renderActionColumn = isAdmin(true) || userHasPermission(this.context, BALANCE_WRITE_PERMISSIONS);
    const headers = getDataHeaders(activeBalanceGroupId, selectedMarketPartyId, renderActionColumn);

    return (
      <>
        <Table
          className="balancegroups-table"
          data-testid="balancegroups-table"
          columns={getTranslatedTableHeaders(headers, t)}
          dataSource={this.getBalanceGroupsForTable()}
          pagination={false}
          loading={isLoading || !this.isDataLoaded()}
          rowKey={(record, index) => `${record.id}_${index}`}
          onExpand={this.expandIconClicked}
        />
      </>
    );
  }
}

BalanceGroupsTable.displayName = 'BalanceGroupsTable';
BalanceGroupsTable.contextType = Context;

BalanceGroupsTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(BalanceGroupsTable);
export {
  BalanceGroupsTable as PureComponent,
};
