import { GAS_TAX_CLASS_NAMES } from './types-gastax-classes';

const TYPES = {
  IMATRA: 'imatra',
  EXITZONE: 'exitZone',
  CITYGATE: 'cityGate',
  BIOGAS: 'biogas',
};

const RESOLUTIONS = {
  MONTH: 'month',
};
const RESOLUTION_NAMES = Object.values(RESOLUTIONS);

const DATE_TYPES = {
  START: 'start',
  END: 'end',
};

const VALUE_TYPES = {
  ENERGY: 'energy',
};
const VALUE_TYPE_NAMES = Object.values(VALUE_TYPES);

const INITIAL_AMOUNTS = GAS_TAX_CLASS_NAMES.map(taxClassName => ({
  type: taxClassName,
  amount: null,
}));

export {
  RESOLUTIONS,
  RESOLUTION_NAMES,
  DATE_TYPES,
  VALUE_TYPES,
  VALUE_TYPE_NAMES,
  TYPES,
  INITIAL_AMOUNTS,
};
