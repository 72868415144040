import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';

const log = new Logger('context:deposits');

async function updateCapacitySums() {
  const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
  const url = isAdmin() || isReadOnlyAdmin()
    ? '/admin/marketparties/capacities/totals'
    : `/marketparties/${selectedMarketPartyId}/capacities/totals`;

  try {
    const { items } = await API.get('FINTSO', url);
    const capacitySums = isAdmin() || isReadOnlyAdmin()
      ? items
      : [{ marketPartyId: selectedMarketPartyId, totals: items }];
    this.setState({ capacitySums });
    log.info('capacity sums updated with data:', capacitySums);
  } catch (error) {
    this.setState({ capacitySums: [] });
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingCapacitySums'),
      description: createErrorMessage(error),
    });
    log.error('error fetching capacity sums:', createErrorMessage(error, true));
  }
}

async function updateDeposits() {
  try {
    const { items: deposits } = await API.get('FINTSO', '/admin/deposits');
    this.setState({ deposits });
    log.info('deposits updated with data:', deposits);
  } catch (error) {
    this.setState({ deposits: [] });
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingDeposits'),
      description: createErrorMessage(error),
    });
    log.error('error fetching deposits:', createErrorMessage(error, true));
  }
}

async function updateMarketPartyDepositSummary(marketPartyId) {
  this.setState({ depositSummaryLoading: true });
  const {
    selectedMarketPartyId,
    marketPartyDepositSummary: oldMarketPartyDepositSummary,
  } = this.state;
  try {
    const depositSummary = await API.get('FINTSO', `/marketparties/${marketPartyId}/deposits-v2`);
    if (selectedMarketPartyId === marketPartyId) {
      this.setState({ depositSummary });
    }
    const marketPartyDepositSummary = {
      ...oldMarketPartyDepositSummary,
      [marketPartyId]: depositSummary,
    };
    this.setState({ marketPartyDepositSummary });
  } catch (error) {
    if (selectedMarketPartyId === marketPartyId) {
      this.setState({ depositSummary: {} });
    }
    const marketPartyDepositSummary = {
      ...oldMarketPartyDepositSummary,
      [marketPartyId]: undefined,
    };
    this.setState({ marketPartyDepositSummary });

    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingDepositSummary'),
      description: createErrorMessage(error),
    });
    log.error('error fetching deposit summary:', createErrorMessage(error, true));
  }
  this.setState({ depositSummaryLoading: false });
}

async function saveDeposit(marketPartyId, amount, start, end) {
  const { deposits } = this.state;
  const existingDeposit = deposits.find(item => item.marketPartyId === marketPartyId);

  try {
    const body = {
      amount,
      marketPartyId,
      start,
      end,
    };
    const deposit = await API.post('FINTSO', '/admin/deposits', { body });

    if (!existingDeposit) {
      deposits.push(deposit);
    } else {
      Object.assign(existingDeposit, deposit);
    }

    this.setState({ deposits });
    log.info('deposit saved:', deposit);
    log.info('deposits updated with data:', deposits);
  } catch (error) {
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorSavingDeposit'),
      description: createErrorMessage(error),
    });
    log.error('error saving deposit:', createErrorMessage(error, true));
  }
}

async function updateImbalanceEstimates() {
  try {
    const { items: imbalanceEstimates } = await API.get('FINTSO', '/admin/deposits/imbalanceestimates');
    this.setState({ imbalanceEstimates });
    log.info('updated imbalance estimates with data:', imbalanceEstimates);
  } catch (error) {
    this.setState({ imbalanceEstimates: [] });
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingImbalanceEstimates'),
      description: createErrorMessage(error),
    });
    log.error('error fetching imbalance estimates:', createErrorMessage(error));
  }
}

async function updateInvoicedTaxes() {
  try {
    const { items: invoicedTaxes } = await API.get('FINTSO', '/admin/deposits/invoicedtaxes');
    this.setState({ invoicedTaxes });
    log.info('updated invoiced taxes with data:', invoicedTaxes);
  } catch (error) {
    this.setState({ invoicedTaxes: [] });
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingInvoicedTaxes'),
      description: createErrorMessage(error),
    });
    log.error('error fetching invoiced taxes:', createErrorMessage(error));
  }
}

const depositsContext = {
  capacitySums: [],
  imbalanceEstimates: [],
  invoicedTaxes: [],
  deposits: [],
  depositSummary: {},
  depositSummaryLoading: false,
  saveDeposit: () => { },
  updateCapacitySums: () => {},
  updateMarketPartyDepositSummary: () => {},
  updateDeposits: () => {},
  updateImbalanceEstimates: () => { },
  updateInvoicedTaxes: () => {},
};

const functions = {
  saveDeposit,
  updateCapacitySums,
  updateMarketPartyDepositSummary,
  updateDeposits,
  updateImbalanceEstimates,
  updateInvoicedTaxes,
};

const deposits = {
  ...depositsContext,
  ...functions,
};

export {
  depositsContext,
  deposits,
};
