import React, { useEffect, useState, useContext } from 'react';
import { Badge, Icon, Tooltip } from 'antd';
import { Link, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDateTimeStr } from '../../utils/i18n';
import { IMBALANCE_STATUS, NETWORK_STATUSES } from '../../constants/networkstatus';
import { minutes } from '../../utils/interval';
import Context from '../../context';
import SetNetworkStatus from './setNetworkStatus';

import './index.less';

const UPDATE_INTERVAL = minutes(5);

const statusMap = {
  [NETWORK_STATUSES.GREEN]: 'success',
  [NETWORK_STATUSES.YELLOW]: 'warning',
  [NETWORK_STATUSES.RED]: 'error',
};
const getStatus = ({ status }) => statusMap[status];

const imbalanceMap = {
  [IMBALANCE_STATUS.LONG]: 'plus',
  [IMBALANCE_STATUS.SHORT]: 'minus',
};
// eslint-disable-next-line react/prop-types
const getText = ({ status, imbalanceStatus }) => {
  if (status === NETWORK_STATUSES.GREEN) return null;

  const type = imbalanceMap[imbalanceStatus];
  return type ? <Icon type={type} className="icon-transmissionnetworkstatus" /> : null;
};

const TransmissionNetworkStatus = () => {
  const [updateInterval, setUpdateInterval] = useState();
  const { isAdmin, status, updateStatus } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    updateStatus();
    setUpdateInterval(setInterval(updateStatus, UPDATE_INTERVAL));
    return () => clearInterval(updateInterval);
  }, []);

  if (!status) {
    return null;
  }

  const imbalanceText = status.status === NETWORK_STATUSES.GREEN
    ? t('transmissionNetworkState.nominal')
    : t(`transmissionNetworkState.${status.imbalanceStatus}`);
  const imbalanceTime = formatDateTimeStr(status.updatedAt);
  const title = t('transmissionNetworkState.description', { imbalanceText, imbalanceTime });
  const badge = (
    <Tooltip title={title} trigger={['hover', 'focus']}>
      <Badge className={`transmissionnetworkstatus-${status.status}`} status={getStatus(status)} text={getText(status)} />
    </Tooltip>
  );

  return (
    <div className="layout__header__status">
      <span>
        <span className="layout__header__status__name">
          {t('app.menu.transmissionNetworkState')}
        </span>
        {isAdmin() ? <Link to="/set-network-status">{badge}</Link> : badge }
      </span>
      {isAdmin() && <Route path="/set-network-status" component={SetNetworkStatus} />}
    </div>
  );
};

export default TransmissionNetworkStatus;
