import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import Context from '../../context';
import SummaryTable from '../SummaryTable';
import CapacityReservationView from '../CapacityReservationView';
import { getTableHeaders, remapReservationsForTable } from './utils';
import { CAPACITY_PRODUCTS_NAMES, CAPACITY_TYPES_API_NAMES } from '../../constants/capacities';
import { STATUS_NAMES } from '../../constants/status';
import { pickUniqIf } from '../../utils/array';

const { MonthPicker } = DatePicker;
const pickIDs = array => pickUniqIf(array, 'marketPartyId', item => item.marketPartyId);

const CapacityReservationsTable = ({
  title,
  subTitle,
  footer,
  actionsVisible,
  includeTimestamps,
  visibleElementsCount,
  history,
  renderHeaderActions,
}) => {
  const {
    capacityReservations,
    capacityReservationsByMonth,
    isAdmin,
    isReadOnlyAdmin,
    isLoading,
    marketPartyIndex,
    selectedMarketPartyId,
    updateCapacityReservations,
  } = useContext(Context);

  const { t } = useTranslation();

  const currentMonth = moment().format('YYYY-MM');

  const [selectedPoints, setSelectedPoints] = useState(CAPACITY_TYPES_API_NAMES);
  const [selectedProducts, setSelectedProducts] = useState(CAPACITY_PRODUCTS_NAMES);
  const [selectedStatus, setSelectedStatus] = useState(STATUS_NAMES);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(currentMonth);

  const getCapacityReservations = () => {
    if (renderHeaderActions) {
      return capacityReservationsByMonth
        ? capacityReservationsByMonth[selectedPeriod] || []
        : [];
    }
    return capacityReservations || [];
  };

  const allMarketPartyIds = pickIDs(getCapacityReservations());
  const marketParties = marketPartyIndex
    .reduce((result, marketParty) => (allMarketPartyIds.includes(marketParty.id) ? {
      ...result,
      [marketParty.name]: marketParty.id,
    } : result), {});
  const selectedMarketPartyIds = selectedNames.map(name => marketParties[name]);

  useEffect(() => {
    updateCapacityReservations();
  }, [selectedMarketPartyId]);

  const handleRowSelected = record => ({
    onClick: () => (
      history.push(`/capacitybookings/${record.id}/${record.marketPartyId}`)
    ),
  });

  const handleCloseModal = () => history.push('/capacitybookings');

  const hasAdminStatus = () => isAdmin() || isReadOnlyAdmin();

  const tableHeaders = getTableHeaders(t, {
    includeMarketPartyNames: hasAdminStatus(),
    includeTimestamps,
  });

  const renderOptions = (options, translate = true) => (
    options.map(option => (
      <Select.Option key={option} value={option}>
        {translate ? t(`common.label.${option}`) : option}
      </Select.Option>
    ))
  );

  const adminFilter = () => (!hasAdminStatus() ? null : (
    <>
      <div className="market-party-selector">
        <div className="title">
          {t('marketParty.title.marketParties')}
        </div>
        <Select
          allowClear
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={20}
          value={selectedNames}
          onChange={setSelectedNames}
        >
          {renderOptions(Object.keys(marketParties), false)}
        </Select>
      </div>
    </>
  ));

  const headerActions = (
    <>
      {selectedMarketPartyId !== 'admin' && (
      <>
        <div className="month-selector">
          <div className="title">
            {t('common.label.monthOfCreation')}
          </div>
          <MonthPicker
            allowClear={false}
            format={moment.localeData().postformat('MMMM YYYY')}
            value={moment(selectedPeriod)}
            onChange={event => setSelectedPeriod(event.format('YYYY-MM'))}
          />
        </div>
        <div className="separator" />
      </>
      )}
      <div className="point-selector">
        <div className="title">
          {t('common.label.point')}
        </div>
        <Select
          mode="multiple"
          value={selectedPoints}
          onChange={items => setSelectedPoints(items.length ? items : selectedPoints)}
        >
          {renderOptions(CAPACITY_TYPES_API_NAMES)}
        </Select>
      </div>
      <div className="separator" />
      <div className="product-selector">
        <div className="title">
          {t('common.table.header.product')}
        </div>
        <Select
          mode="multiple"
          value={selectedProducts}
          onChange={items => setSelectedProducts(items.length ? items : selectedProducts)}
        >
          {renderOptions(CAPACITY_PRODUCTS_NAMES, false)}
        </Select>
      </div>
      <div className="separator" />
      <div className="status-selector">
        <div className="title">
          {t('common.table.header.status')}
        </div>
        <Select
          mode="multiple"
          value={selectedStatus}
          onChange={items => setSelectedStatus(items.length ? items : selectedStatus)}
        >
          {renderOptions(STATUS_NAMES, false)}
        </Select>
      </div>
      <div className="separator" />
      {adminFilter()}
    </>
  );

  const tableData = remapReservationsForTable(
    getCapacityReservations(),
    marketPartyIndex,
    visibleElementsCount,
  );
  const filteredTableData = tableData ? tableData.filter(
    reservation => selectedPoints.includes(reservation.type)
      && reservation.product.split(', ').some(product => selectedProducts.includes(product))
      && selectedStatus.includes(reservation.status)
      && (!selectedMarketPartyIds.length
        || selectedMarketPartyIds.includes(reservation.marketPartyId)),
  ) : [];

  return (
    <>
      <SummaryTable
        className="summarytable"
        tableHeaders={tableHeaders}
        tableData={filteredTableData}
        title={title}
        subTitle={subTitle}
        footer={footer}
        isLoading={isLoading}
        onSelectRow={handleRowSelected}
        headerActions={renderHeaderActions && headerActions}
        stackedHeader={renderHeaderActions}
      />
      <Route
        strict
        path="/capacitybookings/:id/:marketPartyId"
        render={() => (
          <CapacityReservationView
            onClose={handleCloseModal}
            actionsVisible={actionsVisible}
          />
        )}
      />
    </>
  );
};

CapacityReservationsTable.displayName = 'CapacityReservationsTable';
CapacityReservationsTable.propTypes = {
  actionsVisible: PropTypes.bool,
  includeTimestamps: PropTypes.bool,
  renderHeaderActions: PropTypes.bool,
  footer: PropTypes.node,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  visibleElementsCount: PropTypes.number,
  newCapacityReservation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
CapacityReservationsTable.defaultProps = {
  actionsVisible: false,
  includeTimestamps: false,
  renderHeaderActions: false,
  footer: null,
  subTitle: null,
  title: null,
  visibleElementsCount: null,
  newCapacityReservation: null,
};

export default withRouter(CapacityReservationsTable);
export {
  CapacityReservationsTable as PureComponent,
};
