import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Button,
  notification,
  Row,
  Col,
  DatePicker,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import { localeDateFormat } from '../../utils/i18n';
import { getGasDay, startOfGasDay } from '../../utils/gasday';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import './editMembership.less';

const logger = new Logger('edit-balance-group-membership:form');

const PROCESSING_DAYS = 3;

const EditMembership = (props) => {
  const {
    history,
    match: {
      params: { id },
    },
  } = props;

  const [end, setEnd] = useState('');
  const [start, setStart] = useState('');
  const [isSaving, setSaving] = useState(false);

  const { t } = useTranslation();

  const {
    activeBalanceGroupId,
    balanceGroups,
    balanceGroupMembers,
    currentUser: {
      inAdminGroup,
      ownMarketParties,
    },
    isLoading,
    marketPartyIndex,
    selectedMarketPartyId,
    handleDbChange,
    isAdmin,
    isReadOnlyAdmin,
    updateActiveBalanceGroupId,
    updateMarketpartyIndex,
  } = useContext(Context);

  useEffect(() => {
    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (activeBalanceGroupId === null) updateActiveBalanceGroupId();
  }, []);

  const isDataLoaded = (
    activeBalanceGroupId !== null
      && balanceGroups !== null
      && balanceGroupMembers !== null
      && marketPartyIndex.length > 0
      && !isLoading
  );

  const getMembershipData = () => {
    if (!isDataLoaded) return null;

    const membership = balanceGroupMembers.filter(member => (
      member.balanceGroupId === activeBalanceGroupId && member.id === id
    ));

    return membership.length > 0 ? membership[0] : null;
  };

  const getShipper = (marketPartyId) => {
    const shipper = ownMarketParties
      .concat(marketPartyIndex)
      .filter(item => item.id === marketPartyId);

    return shipper.length > 0 ? shipper[0] : null;
  };

  const membership = getMembershipData();
  const shipper = membership === null ? null : getShipper(membership.marketPartyId);

  useEffect(() => {
    if (membership) {
      setStart(membership.start);
      setEnd(membership.end);
    } else {
      setStart('');
      setEnd('');
    }
  }, [membership]);

  const earliestPossibleDay = moment.tz(getGasDay(), 'UTC')
    .add(PROCESSING_DAYS, 'days')
    .format('YYYY-MM-DD');

  const disabledDate = timestamp => (
    inAdminGroup
      ? false
      : moment.utc(startOfGasDay(getGasDay(timestamp)))
        .isBefore(startOfGasDay(earliestPossibleDay))
  );

  const isButtonDisabled = !membership
    || !(end || start)
    || (membership.end === end && membership.start === start);

  const handleDateChange = setState => (date) => {
    setState(date.format('YYYY-MM-DD'));
  };

  const closeModal = () => history.push('/balance');

  const handleCancel = () => (isButtonDisabled ? closeModal() : modalConfirmClose(t, closeModal));

  const handleSubmit = async () => {
    if (activeBalanceGroupId !== ''
      && selectedMarketPartyId !== undefined
      && !isAdmin()
      && !isReadOnlyAdmin()) {
      try {
        setSaving(true);
        const body = {
          ...start && start !== membership.start ? { start } : {},
          ...end && end !== membership.end ? { end } : {},
        };
        const updatedMembership = await API.patch(
          'FINTSO',
          `/marketparties/${selectedMarketPartyId}/balancegroups/${activeBalanceGroupId}/members/${id}`,
          { body },
        );
        handleDbChange('BalanceGroupMember', 'update', updatedMembership);
        notification.success({
          message: t('balanceContainer.membership.message.updatedSuccessfully'),
          description: '',
        });
        closeModal();
      } catch (err) {
        notification.error({
          className: 'notification-error',
          message: t('balanceContainer.membership.message.errorUpdating'),
          description: createErrorMessage(err),
        });
        logger.error(createErrorMessage(err, true));
        setSaving(false);
      }
    }
  };

  const renderDatePicker = (date, setFn, type = 'end') => (
    <DatePicker
      className={`edit-membership__${type}-date`}
      placeholder={t('balanceContainer.label.chooseDate')}
      format={localeDateFormat()}
      data-testid={`edit-membership__${type}-date`}
      disabledDate={disabledDate}
      onChange={handleDateChange(setFn)}
      defaultValue={date !== undefined ? moment(date) : undefined}
    />
  );

  const actionButtons = [
    <Button
      className="edit-membership__cancel-button"
      key="cancel"
      onClick={handleCancel}
    >
      {t('common.button.cancel')}
    </Button>,
    <Button
      className="edit-membership__save-button"
      key="save"
      loading={isSaving}
      type="primary"
      onClick={handleSubmit}
      disabled={isButtonDisabled}
    >
      {t('common.button.save')}
    </Button>,
  ];

  return (
    <ModalWrapper
      modalClassName="edit-membership"
      title={t('balanceContainer.title.editMembership', { shipperName: shipper !== null ? shipper.name : '' })}
      actionButtons={actionButtons}
      handleClose={handleCancel}
      isLoading={!isDataLoaded}
    >
      {(membership && shipper) && (
        <div className="edit-membership__content">
          <Row className="edit-membership__content__header">
            <Col span={8}>
              <span>{t('common.table.header.imbalance')}</span>
            </Col>
            <Col span={8}>
              <span>{t('common.table.header.eic')}</span>
            </Col>
          </Row>
          <Row className="edit-membership__content__data">
            <Col span={8}>
              <span className="edit-membership__content__data-imbalance">N/A</span>
            </Col>
            <Col span={8}>
              <span>{shipper.eic}</span>
            </Col>
          </Row>
          {inAdminGroup && (
          <div className="edit-membership__content__date-selector">
            <div className="edit-membership__content__date-selector__header">
              {t('balanceContainer.title.membershipStartDate')}
            </div>
            <div className="edit-membership__content__date-selector__input">
              { renderDatePicker(membership.start, setStart, 'start')}
            </div>
          </div>
          )}
          <div className="edit-membership__content__date-selector">
            <div className="edit-membership__content__date-selector__header">
              {t('balanceContainer.title.membershipEndDate')}
            </div>
            <div>
              {t('balanceContainer.membership.message.membershipEndDate')}
            </div>
            <div className="edit-membership__content__date-selector__input">
              { renderDatePicker(membership.end, setEnd)}
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

EditMembership.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

EditMembership.displayName = 'EditMembership';

export default EditMembership;
