import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Col,
  Row,
  Layout,
} from 'antd';

import './index.less';

import SetBalanceGasPrice from './SetBalanceGasPrice';
import PageHeaderBar from '../../components/PageHeaderBar';
import BalancePricesTable from '../../components/BalancePricesTable';

import Announcements from '../../components/Announcements';

import Context from '../../context';

import transmissionNetworkImage from '../../../images/transmission-network-2023.png';

/* eslint-disable max-len */
const BC_CAPACITY_ENTSOG_LINK = 'https://transparency.entsog.eu/#/points/data?indicators=Firm%20Technical%2CFirm%20Booked%2CFirm%20Available&points=fi-tso-0003itp-00550exit%2Cfi-tso-0003itp-00550entry';

const FrontPage = () => {
  const { t } = useTranslation();
  const {
    isAdmin,
  } = useContext(Context);

  const [modalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line no-underscore-dangle
  const assetPath = window.__APP_CONFIG__?.ASSET_PATH || '';

  const renderMap = () => (
    <Card title={t('frontPage.box1.title')}>
      <img className="frontpage__image" src={`${assetPath}/${transmissionNetworkImage}`} alt={t('frontPage.box1.title')} />
    </Card>
  );

  /*
  const FEEDBACK_URL = 'https://fi.surveymonkey.com/r/Gasgrid';
  const renderFeedbackBox = () => (
    <Card title={t('frontPage.feedbackbox.title')}>
      <p>
        {t('frontPage.feedbackbox.text')}
        <a
          href={FEEDBACK_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {FEEDBACK_URL}
        </a>
      </p>
    </Card>
  );
  */

  const renderBCLink = () => (
    <Card
      className="frontpage__bc-link"
      title={t('frontPage.box2.title')}
    >
      <p>
        <a
          href={BC_CAPACITY_ENTSOG_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('frontPage.box2.text')}
        </a>
      </p>
    </Card>
  );

  const setBalanceGasPriceButton = (
    <Button key="setBalanceGasPriceButton" onClick={() => setModalVisible(true)} type="primary">
      {t('balanceGasPriceDialog.buttonLabel')}
    </Button>
  );

  return (
    <>
      <PageHeaderBar
        title={t('frontPage.title')}
        actionItems={isAdmin() ? [setBalanceGasPriceButton] : []}
      />
      <Layout.Content className="layout__container__page__content frontpage">
        <Row
          gutter={{ xl: 24 }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={12}
          >
            <Row>
              <Card
                className="frontpage__gasbalance-table"
                title={t('frontPage.box4.title')}
              >
                <BalancePricesTable />
              </Card>
            </Row>
            <Row>
              { renderMap() }
              { renderBCLink() }
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={12}
          >
            <Announcements />
          </Col>
        </Row>
      </Layout.Content>
      <SetBalanceGasPrice visible={modalVisible} handleClose={() => setModalVisible(false)} />
    </>
  );
};

FrontPage.displayName = 'FrontPage';

export default FrontPage;
