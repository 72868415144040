const STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};
const STATUS_NAMES = Object.values(STATUS);


const ANNOUNCEMENT_STATUS = {
  UNPUBLISHED: STATUS.PENDING,
  PUBLISHED: STATUS.ACCEPTED,
};

const ANNOUNCEMENT_STATUS_NAMES = Object.values(ANNOUNCEMENT_STATUS);

export {
  STATUS,
  STATUS_NAMES,
  ANNOUNCEMENT_STATUS,
  ANNOUNCEMENT_STATUS_NAMES,
};
