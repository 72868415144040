import React, {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import {
  Button,
  Layout,
} from 'antd';

import PageHeaderBar from '../../components/PageHeaderBar';
import User from '../../components/User';
import DeleteUser from '../../components/DeleteUser';
import Context from '../../context';
import UsersTable from '../../components/UsersTable';
import { PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';

const Users = ({ history }) => {
  const context = useContext(Context);
  const {
    isAdminAsMarketParty,
    isReadOnlyAdminAsMarketParty,
    isAdmin,
    users,
    updateUsers,
    selectedMarketPartyId,
  } = context;
  const { t } = useTranslation();

  useEffect(() => {
    updateUsers();
  }, [selectedMarketPartyId]);

  const handleButtonClick = () => {
    history.push('/users/new-user');
  };

  const renderNewUserButton = () => (
    (selectedMarketPartyId !== 'admin' && (isAdminAsMarketParty() || !isReadOnlyAdminAsMarketParty()))
      ? (
        <Button
          className="page__header__buttons__new-user"
          type="primary"
          onClick={handleButtonClick}
        >
          {t('common.button.addNewUser')}
        </Button>
      )
      : null
  );

  const renderEditUserRoute = () => (
    (isAdmin(true) || userHasPermission(context, [PERMISSIONS.ADMIN]))
      ? (
        <Route
          exact
          path="/users/:id"
          render={() => <User users={users} />}
        />
      )
      : null
  );


  return (
    <>
      <PageHeaderBar
        title={t('app.menu.users')}
        actionItems={renderNewUserButton()}
      />
      <Layout.Content className="layout__container__page__content">
        <UsersTable />
        <Route
          path="/users/delete/:marketPartyId/:id"
          component={DeleteUser}
        />
        { renderEditUserRoute() }
      </Layout.Content>
    </>
  );
};

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
Users.displayName = 'Users';

export default withRouter(Users);
export {
  Users as PureComponent,
};
