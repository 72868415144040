import moment from 'moment-timezone';
import { getHoursInRange } from './dateTimeHelpers';

/* Definition:
 * "Gas Day is a period commencing at 5.00 UTC (daylight saving time) or 4.00
 * UTC (summer time) and ending at 5.00 UTC (daylight saving time) or 4.00 UTC
 * (summer time) on the following day."
 *
 * Instead of checking if we're in DST or summer time, just use 7am finnish
 * time, and let the timezone database decide the correct time in UTC.
 */

const startOfGasDay = date => moment.tz(date, 'Europe/Helsinki').hour(7);
const startOfGasMonth = month => startOfGasDay(`${month}-01`);
const startOfGasYear = year => startOfGasDay(`${year}-01-01`);

// End date is exclusive
const endOfGasDay = date => startOfGasDay(date).add(1, 'days');

/* get formatted date of gas day for timestamp */
const getGasDay = (timestamp) => {
  const time = moment(timestamp);
  if (time.tz('Europe/Helsinki').hour() < 7) {
    time.subtract(1, 'day');
  }
  return time.format('YYYY-MM-DD');
};

const startOfOngoingGasDay = () => startOfGasDay(getGasDay(moment.utc()));

/**
 * Ant Design date pickers use moment.js internally, and selecting the initial datetime
 * has some issues with gas days. Using this function instead of startOf(Ongoing)GasDay
 * ensures that transitions between winter and summer time work as intended and don't
 * cause an off-by-one error.
 */
const middleOfGasDay = date => startOfGasDay(date || getGasDay()).add(2, 'hours');

const getGasMonth = timestamp => getGasDay(timestamp).slice(0, 7);
const getGasYear = timestamp => getGasDay(timestamp).slice(0, 4);

const isGasDayToday = date => date === getGasDay(moment.utc());

const previousGasDay = (date, days = 1) => startOfGasDay(
  date || getGasDay(),
).subtract(days, 'days').format('YYYY-MM-DD');

const getNth = (unit) => (date, numberOfDays) => (
  moment
    .utc(date)
    .startOf(unit)
    .add(numberOfDays, unit)
    .format('YYYY-MM-DD')
);

const getNextDay = (date) => (
  getNth('days')(date, 1)
);

const getNthMonth = (date, numberOfMonths) => (
  getNth('month')(date, numberOfMonths)
);

const getNthDay = (date, numberOfDays) => (
  getNth('day')(date, numberOfDays)
);

const hoursInGasDay = (date) => {
  const start = startOfGasDay(date);
  const end = endOfGasDay(date);
  const hours = end.diff(start, 'hours');
  return hours;
};

const getHoursInGasDay = (date) => {
  const start = startOfGasDay(date);
  const end = start.clone().add(1, 'day').subtract(1, 'hour');
  return getHoursInRange(start, end);
};

/**
 * @param {*} date YYYY-MM-DD
 * @returns [momentDateWithHourObject, 'YYYY-MM-DD HH:mm', 'HH:mm'][]
 * example [moment('2022-12-13T05:00:00.000Z'), '2022-12-13T05:00:00Z', '07:00'][]
 */
const getHourlyTimesInGasDay = (date) => {
  const hours = getHoursInGasDay(date);
  return hours.map((hour, index) => {
    const time = moment.tz(`${date} ${hour}`, 'YYYY-MM-DD HH:mm', 'Europe/Helsinki').add(index >= 17 ? 1 : 0, 'days');
    const timeFormatted = moment.utc(time).format();
    return [time, timeFormatted, hour];
  });
};

const hourOfGasDay = () => moment().diff(startOfOngoingGasDay(), 'hours');

export {
  startOfGasDay,
  startOfGasMonth,
  startOfGasYear,
  startOfOngoingGasDay,
  middleOfGasDay,
  endOfGasDay,
  getGasDay,
  getGasMonth,
  getGasYear,
  previousGasDay,
  getNth,
  getNextDay,
  getNthMonth,
  getNthDay,
  getHoursInGasDay,
  hourOfGasDay,
  hoursInGasDay,
  isGasDayToday,
  getHourlyTimesInGasDay,
};
