export const generateNotificationFromCognitoError = (t, error) => {
  const errorNotification = {
    className: 'notification-error',
    message: t('common.notifications.failedToSend'),
  };

  try {
    const { code } = error;
    const description = t(`common.notifications.cognito${code}`);

    if (!description) {
      throw new Error('translation for error description not found');
    }

    return {
      ...errorNotification,
      description,
    };
  } catch (notificationError) {
    return {
      ...errorNotification,
      ...(error && { description: error.toString() }),
    };
  }
};

export default {
  generateNotificationFromCognitoError,
};
