import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import './index.less';
import { useTranslation } from 'react-i18next';

const getUserPermissions = (permissions, marketPartyId) => {
  if (permissions === undefined || permissions[marketPartyId] === undefined) {
    return '';
  }

  return permissions[marketPartyId]
    .map(permission => capitalize(permission))
    .join(', ');
};

const renderUsers = (users, marketPartyId) => users.map(user => (
  <div className="marketPartyUsers__user" key={user.email}>
    <p className="marketPartyUsers__user__info">
      <span className="marketPartyUsers__user__info__name">
        <b>{`${user.firstname} ${user.lastname}`}</b>
      </span>
      <br />
      <span className="marketPartyUsers__user__info__email">
        {user.email}
      </span>
      <br />
      <span className="marketPartyUsers__user__info__phone">
        {user.phone}
      </span>
    </p>
    <p className="marketPartyUsers__user__type">
      {getUserPermissions(user.permissions, marketPartyId)}
    </p>
  </div>
));

const MarketPartyUsers = (props) => {
  const { users, marketPartyId } = props;
  const { t } = useTranslation();

  if (users.length === 0) {
    return <></>;
  }

  return (
    <div className="marketPartyUsers">
      <h2 className="marketPartyUsers__title">{t('common.label.registeredBy')}</h2>
      {renderUsers(users, marketPartyId)}
    </div>
  );
};

MarketPartyUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  marketPartyId: PropTypes.string.isRequired,
};

MarketPartyUsers.defaultProps = {
  users: [],
};


export default MarketPartyUsers;
