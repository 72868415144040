const IMBALANCE_STATUS = {
  SHORT: 'short',
  NOMINAL: 'nominal',
  LONG: 'long',
  UNKNOWN: 'unknown',
};

const NETWORK_STATUSES = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
};
const NETWORK_STATUS_NAMES = Object.values(NETWORK_STATUSES);

export {
  IMBALANCE_STATUS,
  NETWORK_STATUSES,
  NETWORK_STATUS_NAMES,
};
