const { NETWORKS } = require('../constants/marketParties');
const { LOCATION_POINTS, CONNECTION_POINTS, METERING_POINT_TYPES } = require('../constants/nominations');


const NETWORK_TOPOLOGY = {
  [NETWORKS.GASGRID]: {
    CONNECTION_POINTS: [
      CONNECTION_POINTS.IMATRA, // entry
      CONNECTION_POINTS.BIOGAS, // entry
      CONNECTION_POINTS.EXITZONE, // exit
    ],
    LOCATION_POINTS: [
      LOCATION_POINTS.IMATRA, // entry
      LOCATION_POINTS.BALTIC_CONNECTOR, // entry & exit
      LOCATION_POINTS.VTP, // entry & exit
    ],
    METERING_POINT_TYPES: [
      METERING_POINT_TYPES.IMATRA, // entry
      METERING_POINT_TYPES.EXITZONE, // exit
      METERING_POINT_TYPES.BIOGAS, // entry
      METERING_POINT_TYPES.BIOGAS_DELIVERY_ENTRY, // entry (deprecated?)
      METERING_POINT_TYPES.CITYGATE, // exit
    ],
  },
  [NETWORKS.LNG_HAMINA]: {
    CONNECTION_POINTS: [
      CONNECTION_POINTS.LNG_HAMINA, // entry
    ],
    LOCATION_POINTS: [
      LOCATION_POINTS.LNG_HAMINA, // entry
    ],
    METERING_POINT_TYPES: [
      METERING_POINT_TYPES.LNG_HAMINA, // entry
    ],
  },
  [NETWORKS.LNG_INKOO]: {
    CONNECTION_POINTS: [
      CONNECTION_POINTS.LNG_INKOO, // entry
    ],
    LOCATION_POINTS: [
      LOCATION_POINTS.LNG_INKOO, // entry
    ],
    METERING_POINT_TYPES: [
      METERING_POINT_TYPES.LNG_INKOO, // entry
    ],
  },
  [NETWORKS.BALTIC]: {
    // no topology for baltic in Gasgrid
    CONNECTION_POINTS: [],
    LOCATION_POINTS: [],
    METERING_POINT_TYPES: [],
  },
};

const GASGRID_NETWORKS = [NETWORKS.GASGRID];
const LNG_NETWORKS = [NETWORKS.LNG_HAMINA, NETWORKS.LNG_INKOO];

const getNetworkConnectionPoints = (network) => (NETWORK_TOPOLOGY[network].CONNECTION_POINTS);
const getNetworkLocationPoints = (network) => (NETWORK_TOPOLOGY[network].LOCATION_POINTS);
const getNetworkMeteringPointTypes = (network) => (NETWORK_TOPOLOGY[network].METERING_POINT_TYPES);

// Filter helpers
const byLngNetworks = (network) => (LNG_NETWORKS.includes(network));
const byGasgridNetworks = (network) => (GASGRID_NETWORKS.includes(network));

const findNetworkBySet = (key, set) => (Object.entries(NETWORK_TOPOLOGY).find(([, topo]) => (
  topo[set].includes(key)
))[0]);

const findNetworkByLocationPoint = (point) => (findNetworkBySet(point, 'LOCATION_POINTS'));
const findNetworkByConnectionPoint = (point) => (findNetworkBySet(point, 'CONNECTION_POINTS'));
const findNetworkByMeteringPointType = (type) => (findNetworkBySet(type, 'METERING_POINT_TYPES'));

export {
  // NETWORK_TOPOLOGY,
  getNetworkConnectionPoints,
  getNetworkLocationPoints,
  getNetworkMeteringPointTypes,
  findNetworkByLocationPoint,
  findNetworkByConnectionPoint,
  findNetworkByMeteringPointType,
  findNetworkBySet,
  // Network helpers
  byLngNetworks,
  byGasgridNetworks,
  // Constants
  GASGRID_NETWORKS,
  LNG_NETWORKS,
  // NETWORK_TOPOLOGY,
};

/*
  OBA TILI:
  LOCATIONPOINT + DIRECTION

  exitzone -> exit: kiinteistöt, tankkausasemat
  biogas -> entry: biokaasun syöttö
  citygate -> exit: jakeluverkkoon
  lngHamina -> entry:
  lngInkoo -> entry:
  imatra (ei ole?)
*/
