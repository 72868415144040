import 'antd/lib/grid/style/css';
import 'antd/lib/layout/style/css';
import 'antd/lib/table/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/empty/style/css';
import 'antd/lib/checkbox/style/css';
import 'antd/lib/divider/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/spin/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/input-number/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/avatar/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/badge/style/css';
