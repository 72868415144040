import moment from 'moment';

const OPTIONS = {
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: true,
};

/**
 * Sort strings in alphabetical order
 * And multi-digit numbers are ordered as a single character
 *
 * @param {*} a - first item of the list
 * @param {*} b - second item of the list
 * @param {*} selectedLanguage - selected locale language defaults to english
 */
const naturalSort = (a, b, selectedLanguage = 'en') => {
  if (a !== undefined) {
    return b ? a.localeCompare(b, selectedLanguage, OPTIONS) : 1;
  }

  if (b !== undefined) {
    return a ? b.localeCompare(a, selectedLanguage, OPTIONS) : -1;
  }

  return 0;
};

/**
 * Sort date string using moment diff
 *
 * @param {*} a - first item of the list
 * @param {*} b - second item of the list
 */
const dateSort = (a, b) => (
  moment.utc(a).diff(moment.utc(b))
);

/**
 * Higher order comparator function for sorting objects by key using an arbitrary ordering.
 *
 * @param {String} key - the key by whose value to compare
 * @param {Array} order - an array defining the ordering of items
 * @param {Object} a - first item to compare
 * @param {Object} b - second item to compare
 *
 * @returns Comparator function to be passed into Array.prototype.sort()
 */
const by = ({ key, order }) => (a, b) => order.indexOf(a[key]) - order.indexOf(b[key]);

export {
  by,
  naturalSort,
  dateSort,
};
