import { createContext } from 'react';
import { capacityContext } from './capacity';
import { monitoringContext } from './monitoring';
import { statusContext } from './status';
import { pricesContext } from './prices';
import { balanceGroupsContext } from './balanceGroups';
import { meteringSitesContext } from './meteringSites';
import { relationshipsContext } from './relationships';
import { usersContext } from './users';
import { deliveriesContext } from './deliveries';
import { invoicesContext } from './invoices';
import { depositsContext } from './deposits';
import { taxDeclarationsContext } from './biogasTaxDeclarations';

export const initialCurrentUserState = {
  family_name: '',
  given_name: '',
  ownMarketParties: [],
  inAdminGroup: false,
  inReadOnlyAdminGroup: false,
  permissions: {},
};

/**
 * This is initial state.
*/
export default createContext({
  // Current user
  currentUser: initialCurrentUserState,
  updateCurrentUser: () => {},
  clearCurrentUser: () => {},
  updateUserOwnMarketParties: () => {},

  // Capacity reservations
  capacityReservations: null,
  capacityReservationsByMonth: {},
  updateCapacityReservations: () => {},

  // Capacity transfers
  capacityTransfers: null,
  updateCapacityTransfers: () => {},

  // Market parties
  marketParties: null,
  updateMarketParties: () => {},
  selectMarketParty: () => {},
  selectedMarketPartyId: null,
  getSelectedMarketParty: () => {},

  // Market party index
  marketPartyIndex: [],
  updateMarketpartyIndex: () => {},

  // Metering sites
  ...meteringSitesContext,

  // Delivery relationsips
  deliveryRelationships: [],
  deliveryRelationshipsLoading: false,
  updateDeliveryRelationships: () => {},

  // Market party relationsips
  ...relationshipsContext,

  // DB changes
  handleDbChange: () => {},

  // Selected site language
  selectedLanguage: 'en',
  updateSelectedLanguage: () => {},

  // Env to globals
  isDevelopment: false,
  isStaging: false,
  isProduction: false,

  // Balancegroups
  ...balanceGroupsContext,
  balanceGroupStats: null,
  balanceGroupStatsLoading: false,
  updateBalanceGroupStats: () => {},
  activeBalanceGroupId: null,
  updateActiveBalanceGroupId: () => {},

  // Balancegroup members
  balanceGroupMembers: null,
  updateBalanceGroupMembers: () => {},

  // My balance
  myBalance: null,
  myBalanceLoading: false,
  updateMyBalance: () => {},

  // Global status
  isLoading: true,

  isAdmin: () => {},
  isReadOnlyAdmin: () => {},
  isAdminAsMarketParty: () => {},
  isReadOnlyAdminAsMarketParty: () => {},

  // Initialization
  initialize: () => {},

  // Users
  ...usersContext,

  // Nominations
  nomints: [],
  isNomintsLoaded: false,
  updateNomints: () => {},
  nomreses: [],
  isNomresesLoaded: false,
  updateNomreses: () => {},

  ...capacityContext,
  ...monitoringContext,
  ...statusContext,
  ...pricesContext,
  ...deliveriesContext,
  ...invoicesContext,
  ...depositsContext,
  ...taxDeclarationsContext,
});
