// Mapping from translation keys to Cognito user attributes
const attributeMap = {
  email: 'email',
  phone: 'phone_number',
};

// Ant Design form field validation rules for different attributes
const getValidationRule = (attribute) => {
  switch (attribute) {
    case 'email':
      return {
        type: 'email',
      };
    case 'phone':
      return {
        type: 'string',
        pattern: /^\+[0-9]{7,15}$/,
      };
    default:
      return {};
  }
};

export {
  attributeMap,
  getValidationRule,
};
