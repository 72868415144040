import { RESOLUTIONS } from '../../constants/meteringSites';

import {
  startOfGasDay, endOfGasDay, startOfGasMonth,
} from '../../utils/gasday';

const getStartForResolution = (startDate, resolution) => {
  const gasDay = startDate.format('YYYY-MM-DD');
  const start = resolution === RESOLUTIONS.MONTH
    ? startOfGasMonth(gasDay.slice(0, 7))
    : startOfGasDay(gasDay);
  return start.toISOString();
};

const getEndForResolution = (endDate, resolution) => {
  const gasDay = endDate.format('YYYY-MM-DD');
  let end;
  switch (resolution) {
    case RESOLUTIONS.HOUR:
      end = endOfGasDay(gasDay).subtract(1, 'hour');
      break;
    case RESOLUTIONS.DAY:
      end = startOfGasDay(gasDay);
      break;
    case RESOLUTIONS.MONTH:
      end = startOfGasMonth(gasDay.slice(0, 7));
      break;
    default:
      end = endDate;
  }
  return end.toISOString();
};

const getRangeForResolution = (startDate, endDate, resolution) => ({
  start: getStartForResolution(startDate, resolution),
  end: getEndForResolution(endDate || startDate, resolution),
});

export {
  getStartForResolution,
  getEndForResolution,
  getRangeForResolution,
};
