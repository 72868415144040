import { isEmpty } from 'lodash';
import { DIRECTIONS, LOCATION_POINTS } from '../../constants/nominations';
import { getDatesInRange } from '../../utils/dateTimeHelpers';
import { formatNumber, formatDate } from '../../utils/i18n';
import { calculate } from '../../utils/capacityAndNominationsHelper';
import { STATUS } from '../../constants/status';

const { ENTRY, EXIT } = DIRECTIONS;
const {
  BALTIC_CONNECTOR,
  IMATRA,
  VTP,
  LNG_HAMINA,
  LNG_INKOO,
} = LOCATION_POINTS;

const getHeaders = (unit, points, directions) => {
  const formatAndConvertNumber = value => formatNumber(calculate(value, unit));
  const renderIf = ({ direction, point }) => (value) => {
    const includesDirection = direction ? directions.includes(direction) : true;
    const includesPoint = point ? points.includes(point) : true;
    return includesDirection && includesPoint ? formatAndConvertNumber(value) : '-';
  };

  return [
    {
      title: 'gasDay',
      dataIndex: 'date',
      render: formatDate,
    },
    {
      title: 'imatraEntry',
      dataIndex: 'imatraEntry',
      render: renderIf({ direction: ENTRY, point: IMATRA }),
    },
    {
      title: 'bcEntry',
      dataIndex: 'bcEntry',
      render: renderIf({ direction: ENTRY, point: BALTIC_CONNECTOR }),
    },
    {
      title: 'bcExit',
      dataIndex: 'bcExit',
      render: renderIf({ direction: EXIT, point: BALTIC_CONNECTOR }),
    },
    {
      title: 'vtpEntry',
      dataIndex: 'vtpEntry',
      render: renderIf({ direction: ENTRY, point: VTP }),
    },
    {
      title: 'vtpExit',
      dataIndex: 'vtpExit',
      render: renderIf({ direction: EXIT, point: VTP }),
    },
    {
      title: 'lngHaminaEntry',
      dataIndex: 'lngHaminaEntry',
      render: renderIf({ direction: ENTRY, point: LNG_HAMINA }),
    },
    {
      title: 'lngInkooEntry',
      dataIndex: 'lngInkooEntry',
      render: renderIf({ direction: ENTRY, point: LNG_INKOO }),
    },
    {
      title: 'entryTotal',
      dataIndex: 'entryTotal',
      render: renderIf({ direction: ENTRY }),
    },
    {
      title: 'exitTotal',
      dataIndex: 'exitTotal',
      render: renderIf({ direction: EXIT }),
    },
    {
      title: 'total',
      dataIndex: 'total',
      render: formatAndConvertNumber,
    },
  ];
};

const isImatra = ({ point }) => point === LOCATION_POINTS.IMATRA;
const isVtp = ({ point }) => point === LOCATION_POINTS.VTP;
const isBC = ({ point }) => point === LOCATION_POINTS.BALTIC_CONNECTOR;
const isLNGHamina = ({ point }) => point === LOCATION_POINTS.LNG_HAMINA;
const isLNGInkoo = ({ point }) => point === LOCATION_POINTS.LNG_INKOO;
const isEntry = ({ direction }) => direction === DIRECTIONS.ENTRY;
const isExit = ({ direction }) => direction === DIRECTIONS.EXIT;
const addIf = b => (a, predicate) => (predicate ? a + b : a);
const statusIsNotDeclined = status => status !== STATUS.DECLINED;

const getTotals = (nominationsByDate, start, end, isFiltered) => {
  if (isEmpty(nominationsByDate)) return [];
  return getDatesInRange(start, end)
    .reverse()
    .filter(date => (isFiltered ? !isEmpty(nominationsByDate[date]) : true))
    .map((date) => {
      const totals = (nominationsByDate[date] || []).reduce((a, c) => {
        const add = addIf(c.nomres !== null ? c.nomres : c.nomint || 0);
        return {
          date,
          imatraEntry: add(
            a.imatraEntry, isImatra(c) && isEntry(c) && statusIsNotDeclined(c.status),
          ),
          bcEntry: add(a.bcEntry, isBC(c) && isEntry(c) && statusIsNotDeclined(c.status)),
          bcExit: add(a.bcExit, isBC(c) && isExit(c) && statusIsNotDeclined(c.status)),
          vtpEntry: add(a.vtpEntry, isVtp(c) && isEntry(c) && statusIsNotDeclined(c.status)),
          vtpExit: add(a.vtpExit, isVtp(c) && isExit(c) && statusIsNotDeclined(c.status)),
          lngHaminaEntry: add(
            a.lngHaminaEntry, isLNGHamina(c) && isEntry(c) && statusIsNotDeclined(c.status),
          ),
          lngInkooEntry: add(
            a.lngInkooEntry, isLNGInkoo(c) && isEntry(c) && statusIsNotDeclined(c.status),
          ),
          entryTotal: add(a.entryTotal, isEntry(c) && statusIsNotDeclined(c.status)),
          exitTotal: add(a.exitTotal, isExit(c) && statusIsNotDeclined(c.status)),
        };
      }, {
        date,
        imatraEntry: 0,
        bcEntry: 0,
        bcExit: 0,
        vtpEntry: 0,
        vtpExit: 0,
        lngHaminaEntry: 0,
        lngInkooEntry: 0,
        entryTotal: 0,
        exitTotal: 0,
        total: 0,
      });
      return {
        ...totals,
        total: totals.entryTotal - totals.exitTotal,
      };
    });
};

export {
  getHeaders,
  getTotals,
};
