// TODO: category should really be coming from backend as it's already handled there
// TODO: possibly the upcoming "configuration" endpoint?

const BALANCE_RESPONSIBLE_PRODUCTS = [
  'DAILY_IMBAL_CHARGE_P',
  'DAILY_IMBAL_CHARGE_N',
];
const BALANCE_RESPONSIBLE_CORR_PRODUCTS = [
  // CORR1
  'DAILY_IMBAL_0_N_COR',
  'DAILY_IMBAL_0_P_COR',
  'DAILY_IMBAL_N_COR',
  'DAILY_IMBAL_P_COR',
  // CORR2
  'DAILY_IMBAL_NEUT_C2',
  'DAILY_IMBAL_PURC_C2',
  'DAILY_IMBAL_SELL_C2',
];

const SHIPPER_PRODUCTS = [
  'EXIT_Y',
  'EXIT_Q',
  'EXIT_M',
  'EXIT_D',
  'EXIT_WD',
  'ENTRY_IMATRA_Y',
  'ENTRY_IMATRA_Q',
  'ENTRY_IMATRA_M',
  'ENTRY_IMATRA_D',
  'ENTRY_IMATRA_WD',
  'ENTRY_BIOGAS_Y',
  'ENTRY_BIOGAS_Q',
  'ENTRY_BIOGAS_M',
  'ENTRY_BIOGAS_D',
  'ENTRY_BIOGAS_WD',
  'ENTRY_LNG_H_Y',
  'ENTRY_LNG_H_Q',
  'ENTRY_LNG_H_M',
  'ENTRY_LNG_H_D',
  'ENTRY_LNG_H_WD',
  'ENTRY_LNG_I_Y',
  'ENTRY_LNG_I_Q',
  'ENTRY_LNG_I_M',
  'ENTRY_LNG_I_D',
  'ENTRY_LNG_I_WD',
  'EXIT_CAP_OVERRUN',
  'BIOGAS_CAP_OVERRUN',
  'COMM_CHARGE_EXIT',
  'BC_RENOM_CHARGE_ENTR',
  'BC_RENOM_CHARGE_EXIT',
];
const SHIPPER_CORR_PRODUCTS = [
  // CORR1
  'COMM_CHARGE_EXIT_COR',
  'EXIT_CAP_OVERR_LESS',
  'EXIT_CAP_OVERR_MORE',
  'BIOGAS_CAP_OVER_LESS',
  'BIOGAS_CAP_OVER_MORE',
  // CORR2
  'COMM_CHARGE_EXIT_C2',
  'EXIT_CAP_OVER_MO_C2',
  'EXIT_CAP_OVER_LE_C2',
  'BIO_CAP_OVER_MORE_C2',
  'BIO_CAP_OVER_LESS_C2',
];

const TAX_PRODUCTS = [
  'CO2_TAX',
  'ENERGY_TAX',
  'STRAT_STOCK_PILE_FEE',
];

// Unfortunately not possible to distict (backend should do this)
const TAX_CORR_PRODUCTS = [
  'CO2_TAX',
  'ENERGY_TAX',
  'STRAT_STOCK_PILE_FEE',
];
// TODO: backend should return these values are part invoicerows
const INVOICE_CATEGORY = {
  IMBALANCE: 'imbalance',
  SHIPPER: 'shipper',
  TAXES: 'taxes',
  IMBALANCE_CORR: 'imbalance_corr',
  SHIPPER_CORR: 'shipper_corr',
  TAXES_CORR: 'taxes_corr',
  UNKNOWN: 'unknown',
};

export {
  BALANCE_RESPONSIBLE_PRODUCTS,
  BALANCE_RESPONSIBLE_CORR_PRODUCTS,
  SHIPPER_PRODUCTS,
  SHIPPER_CORR_PRODUCTS,
  TAX_PRODUCTS,
  TAX_CORR_PRODUCTS,
  INVOICE_CATEGORY,
};
