import React from 'react';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { Link } from 'react-router-dom';
import { by } from '../../utils/sort';
import { formatDateTimeStr, formatNumber } from '../../utils/i18n';
import { TYPES } from '../../constants/gasTaxDeclarations';
import {
  mapGasTaxClass,
  GAS_TAX_CLASSES,
  GAS_TAX_CLASS_NAMES,
  GAS_TAX_CLASSES_2022,
} from '../../constants/types-gastax-classes';
import { UNITS } from '../../constants/units';

const moment = extendMoment(Moment);

// const FIXED_COLUMN_WIDTH = 150;
const BASE_CLASS = 'tax-declarations';
const TABLE_CLASS = `${BASE_CLASS}__table`;

const getMeteringSiteIdColumn = t => ({
  title: t('common.table.header.id'),
  key: 'id',
  fixed: 'left',
  width: '12em',
  render: (record) => {
    const className = classNames(
      {
        [`${TABLE_CLASS}_main-column`]: !!record.meteringSiteId,
      },
    );
    return <span className={className}>{record.id}</span>;
  },
});

const getMeteringSiteNameColumn = t => ({
  title: t('common.table.header.meteringSite'),
  key: 'label',
  align: 'left',
  render: (record) => {
    const className = classNames(
      {
        [`${TABLE_CLASS}__main-column`]: !!record.meteringSiteId,
      },
    );
    const label = record.meteringSiteName;
    return <span className={className}>{label}</span>;
  },
});

const getMeteringSiteTypeColumn = t => ({
  title: t('common.table.header.type'),
  key: 'type',
  align: 'left',
  dataIndex: '',
  render: record => (record.meteringSiteType ? t(`common.label.${record.meteringSiteType}`) : ''),
});

const getRowClassName = record => classNames(
  {
    [`${TABLE_CLASS}__main-row`]: !!record.meteringSiteId,
    [`${TABLE_CLASS}__expanded-row`]: !record.meteringSiteId,
  },
);

const getConsumptionsColumn = (start, end, resolution, t) => {
  if (!start || !end || !resolution) {
    return [];
  }
  const timestamps = Array
    .from(moment.range(start, end)
      .by(resolution));

  return timestamps.map((time) => {
    const title = t('declareGasTax.table.information.consumption');
    const timestamp = time.toISOString();
    return {
      title,
      key: timestamp,
      dataIndex: timestamp,
      align: 'left',
    };
  });
};

// Not all classes are needed for declaration form
const getAllowedGasTaxClassNames = () => (GAS_TAX_CLASS_NAMES.filter((taxClassName) => (
  // Base Natural Gas is deducted automatically as the reminder when invoicing
  ![GAS_TAX_CLASSES_2022.PG02_NATURAL_GAS, GAS_TAX_CLASSES.PG02_NATURAL_GAS].includes(taxClassName)
)));


const getTaxClassColumns = (t) => getAllowedGasTaxClassNames().map(name => (
  {
    title: t(`declareGasTax.table.information.${name}`),
    key: name,
    dataIndex: name,
    align: 'right',
  }));

const renderDeclarationLink = (t, meteringSiteId) => (
  <Link to={`/declaregastax/${meteringSiteId}`}>
    {t('declareGasTax.table.actions.declare')}
  </Link>
);

const renderDeletionLink = (t, declarationId) => (
  <Link to={`/declaregastax/delete/${declarationId}`}>
    {t('declareGasTax.table.actions.delete')}
  </Link>
);

const getDeclarationColumn = () => ({
  title: '',
  key: 'declare',
  dataIndex: 'declare',
  align: 'right',
});

const getRowKey = (item, index) => {
  const {
    id,
    label,
  } = item;
  return `${id}-${label}-${index}`;
};

const flattenEnergyByTimestamp = calculate => (result, {
  timestamp,
  value,
}) => ({
  ...result,
  [timestamp]: value ? formatNumber(calculate(value), 0, 3) : null,
});

const getValue = (item, key, reduceFn, initialValue) => item.values[key].reduce(
  reduceFn,
  initialValue || {
    id: item.meteringSiteId,
    label: `meteringSites.table.information.${key}`,
  },
);

const formatKWhToMWh = (value) => {
  if (typeof (value) !== 'number') return value;
  const multiplier = new Decimal(0.001);
  const calculate = val => Number(multiplier.mul(val));
  return formatNumber(calculate(value), 0, 3);
};

const formatDeclarationTotals = (totals) => {
  const formattedTotals = { ...totals };
  Object.keys(formattedTotals)
    .forEach((key) => {
      formattedTotals[key] = formatKWhToMWh(formattedTotals[key]);
    });
  return formattedTotals;
};

const getDeclarationAmountTotal = (item, reduceFn, rowObject) => {
  const typeAmounts = item.declarations.reduce(
    (accumulator, declaration) => declaration.amounts.reduce(
      reduceFn, accumulator,
    ),
    rowObject,
  );
  const formattedAmounts = formatDeclarationTotals(typeAmounts);
  return formattedAmounts;
};

const flattenDeclarationsByType = () => (result, {
  type,
  amount,
}) => ({
  ...result,
  [type]: result[type] ? result[type] + amount : amount,
});

const getDeclarationRows = (t, item, canDeclare) => {
  const declarationRows = [];
  for (const declaration of item.declarations) {
    const amounts = {};
    for (const amount of declaration.amounts) {
      amounts[amount.type] = formatKWhToMWh(amount.amount);
    }
    const dateTimeStr = formatDateTimeStr(declaration.createdAt);
    const deletionLink = canDeclare ? { declare: renderDeletionLink(t, declaration.id) } : null;
    const rowData = {
      id: dateTimeStr,
      label: 'declaration',
      ...amounts,
      ...deletionLink,
    };
    declarationRows.push(rowData);
  }
  return declarationRows;
};

const addTotal = (t, data) => {
  const stringToNumber = str => (
    typeof str === 'string'
      ? Number(str.replace(/\s/g, '')
        .replace(',', '.'))
      : 0
  );
  const format = str => formatNumber(str, 0, 3);

  return {
    id: t('common.table.header.total'),
    label: 'common.table.header.total',
    ...data
      .reduce((acc, item) => {
        const {
          id,
          label,
          children,
          ...rest
        } = item;

        Object
          .keys(rest)
          .forEach((key) => {
            // Aggregate all fields except some..
            if (!['declare', 'meteringSiteName', 'meteringSiteType'].includes(key)) {
              acc[key] = format(
                Decimal.add(
                  stringToNumber(acc[key]),
                  stringToNumber(rest[key]),
                ),
              );
            }
          });
        return acc;
      }, {}),
  };
};

const remap = (t, consumptionsSource, taxDeclarationsSource, unit, canDeclare = false) => {
  const multiplier = new Decimal(unit === UNITS.MWH ? 0.001 : 1);
  const calculate = value => Number(multiplier.mul(value));
  const byType = by({
    key: 'type',
    order: [TYPES.IMATRA, TYPES.EXITZONE, TYPES.BIOGAS, TYPES.CITYGATE],
  });
  // Backwards compatibility on view and total aggregations
  taxDeclarationsSource.forEach((declaration) => {
    declaration.amounts.forEach((amount) => {
      // eslint-disable-next-line no-param-reassign
      amount.type = mapGasTaxClass(amount.type);
    });
  });
  const mergedData = consumptionsSource.map(item => (
    {
      ...item,
      declarations: taxDeclarationsSource.filter(
        declaration => declaration.meteringPointId === item.meteringSiteId,
      ),
    }
  ));
  const remappedData = mergedData
    .sort(byType)
    .map((item) => {
      const energies = getValue(item, 'energy', flattenEnergyByTimestamp(calculate), { id: item.meteringSiteId });
      const declarationAmounts = getDeclarationAmountTotal(
        item,
        flattenDeclarationsByType(),
        { id: item.meteringSiteId },
      );
      const children = getDeclarationRows(t, item, canDeclare);
      const declarationLink = canDeclare ? {
        declare: renderDeclarationLink(t, item.meteringSiteId),
      } : null;
      return {
        id: item.id,
        label: item.id,
        meteringSiteId: item.meteringSiteId,
        meteringSiteName: item.name,
        meteringSiteType: item.type,
        ...energies,
        ...declarationAmounts,
        ...declarationLink,
        children: children.length > 0 ? children : undefined,
      };
    });

  return [addTotal(t, remappedData), ...remappedData];
};

export {
  BASE_CLASS,
  TABLE_CLASS,
  getMeteringSiteIdColumn,
  getMeteringSiteNameColumn,
  getMeteringSiteTypeColumn,
  getConsumptionsColumn,
  getTaxClassColumns,
  getAllowedGasTaxClassNames,
  getDeclarationColumn,
  getRowClassName,
  getRowKey,
  remap,
};
