import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { get, groupBy } from 'lodash';

import Context from '../../context';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';
import ModalWrapper from '../../components/ModalWrapper';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { formatNumber } from '../../utils/i18n';
import { startOfGasDay, getGasDay } from '../../utils/gasday';
import { calculate, getDateString } from '../../utils/capacityAndNominationsHelper';
import { getMarketPartyFromContextById, getMarketPartyNameById } from '../../utils/marketparty';
import { naturalSort } from '../../utils/sort';
import { combine } from '../../utils/nominationHelpers';
import { LOCATION_POINTS } from '../../constants/nominations';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';

import './statsModal.less';

const getColumns = (t, unit) => [
  {
    title: 'marketParty',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => naturalSort(a.name, b.name),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
  },
  {
    title: 'nomint',
    dataIndex: 'nomint',
    key: 'nomint',
    align: 'right',
    render: nomint => `${formatNumber(calculate(nomint, unit))} ${t(`common.label.${unit}`)}/h`,
    sorter: (a, b) => a.nomint - b.nomint,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'nomres',
    dataIndex: 'nomres',
    key: 'nomres',
    align: 'right',
    render: nomres => `${formatNumber(calculate(nomres, unit))} ${t(`common.label.${unit}`)}/h`,
    sorter: (a, b) => a.nomres - b.nomres,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'bookedCapacity',
    dataIndex: 'capacity',
    key: 'capacity',
    align: 'right',
    render: capacity => `${formatNumber(calculate(capacity, unit))} ${t(`common.label.${unit}`)}`,
    sorter: (a, b) => a.capacity - b.capacity,
    sortDirections: ['ascend', 'descend'],
  },
];

const ImatraStatsModal = (props) => {
  const {
    basePath,
    history,
    unit,
    match: {
      params: {
        date,
      },
    },
  } = props;

  const context = useContext(Context);
  const {
    nomints,
    nomreses,
    marketPartyIndex,
    totalCapacities,
    updateTotalCapacities,
  } = context;

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const byDateAndPoint = (item) => {
    const gasDay = item.date || getGasDay(item.timestamp);
    return gasDay === date && item.point === LOCATION_POINTS.IMATRA;
  };

  const filteredNomints = nomints.filter(byDateAndPoint);
  const filteredNomreses = nomreses.filter(byDateAndPoint);
  // TODO: Frankly this should come from backend in the data itself
  const mappedNomreses = filteredNomreses.map((nomres) => {
    const { id } = marketPartyIndex.find(({ code }) => code === nomres.marketPartyCode1) || {};
    return {
      ...nomres,
      marketPartyId: id,
    };
  });
  const nomintsByMarketParty = groupBy(filteredNomints, 'marketPartyId');
  const nomresesByMarketParty = groupBy(mappedNomreses, 'marketPartyId');
  const getMarketPartyById = (id) => (getMarketPartyFromContextById(context, id));
  const nominations = Object.entries(nomintsByMarketParty).reduce((result, [id, nomint]) => ({
    ...result,
    [id]: combine(nomint, nomresesByMarketParty[id] || [], getMarketPartyById)[0],
  }), {});
  const capacities = totalCapacities[date] || [];

  const fetchData = async (gasDay) => {
    // console.log(gasDay);
    setLoading(true);
    if (!capacities.length) {
      await updateTotalCapacities(gasDay, gasDay);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(date);
  }, [date]);

  const handleChangeSelectedDate = (datetime) => {
    const gasDay = getGasDay(datetime);
    history.push(`${basePath}/imatra/${gasDay}`);
  };

  const dataSource = Object.entries(nominations).map(([id, { nomint, nomres }]) => ({
    id,
    capacity: get(capacities.find(item => item.marketPartyId === id), 'imatra.sum', 0),
    name: getMarketPartyNameById({ marketPartyIndex })(id),
    nomint,
    nomres,
  }));

  const handleClose = () => {
    history.push(basePath);
  };

  return (
    <ModalWrapper
      modalClassName="stats-modal"
      title={`${t('monitoring.capacityAndNominations.modal.imatraTitle')} ${getDateString(date, t)}`}
      handleClose={handleClose}
      width="60vw"
      actionButtons={[
        <Button
          className="stats-modal__close-button"
          key="close-button"
          type="primary"
          onClick={handleClose}
        >
          {t('common.button.close')}
        </Button>,
      ]}
    >
      <>
        <StatsDatePicker
          selectedDate={startOfGasDay(date)}
          onSelectedDateChange={handleChangeSelectedDate}
        />
        <Table
          className="stats-modal__table"
          data-testid="stats-modal__table"
          columns={getTranslatedTableHeaders(getColumns(t, unit), t)}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey={({ id }) => id}
        />
      </>
    </ModalWrapper>
  );
};

ImatraStatsModal.propTypes = {
  basePath: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  unit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};

ImatraStatsModal.displayName = 'ImatraStatsModal';

export default withRouter(ImatraStatsModal);
export {
  ImatraStatsModal as PureComponent,
};
