{
  "common": {
    "button": {
      "edit": "Edit",
      "accept": "Accept",
      "decline": "Decline",
      "ok": "Ok",
      "cancel": "Cancel",
      "close": "Close",
      "saveAndClose": "Save and close",
      "signIn": "Sign In",
      "submit": "Submit",
      "forgotPassword": "Forgot password",
      "backToSignIn": "Back to Sign in",
      "startRegistration": "Start registration",
      "create": "Create",
      "update": "Update",
      "save": "Save",
      "addNewUser": "Add new user",
      "search": "Search",
      "delete": "Delete",
      "deleting": "Deleting",
      "back": "Back",
      "change": "Change",
      "verify": "Verify",
      "yes": "Yes",
      "no": "No",
      "exportToExcel": "Export to Excel"
    },
    "table": {
      "header": {
        "processed": "Processed",
        "connectionPoint": "Connection point",
        "transfer": "Transfer",
        "reservedFor": "Reserved for",
        "updated": "Updated",
        "capacity": "Capacity (kWh/h)",
        "product": "Product",
        "status": "Status",
        "actions": "Actions",
        "marketPartyName": "Market party name",
        "address": "Address",
        "company": "Company",
        "marketparty": "Marketparty",
        "created": "Created",
        "createdBy": "Created by",
        "deliveryEndTime": "Delivery end time",
        "deliveryStartTime": "Delivery start time",
        "edit": "Edit",
        "eicx": "EICX",
        "endTime": "End time",
        "gs1Retailer": "Retailer GS1",
        "gs1Dso": "DSO GS1",
        "id": "ID",
        "meteringSite": "Metering site",
        "shipper": "Shipper",
        "startTime": "Start time",
        "state": "State",
        "vatId": "VAT Id",
        "timePeriod": "Time Period",
        "price": "Price",
        "type": "Type",
        "memberUntil": "Member Until",
        "balance": "Balance",
        "eic": "EIC",
        "startDate": "Start Date",
        "endDate": "End Date",
        "startGasDay": "Start Gas day",
        "endGasDay": "End Gas day",
        "balanceGroupOwner": "Balance Responsible Party",
        "imbalance": "Imbalance",
        "name": "Name",
        "street": "Street Address",
        "postalCode": "Postal Code",
        "city": "City",
        "country": "Country",
        "amount": "Amount",
        "interConnectionPoint": "Interconnection Point",
        "user": "User",
        "phone": "Phone number",
        "email": "Email",
        "trader": "Trader",
        "producer": "Producer",
        "enduser": "End user",
        "retailer": "Retailer",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO",
        "authority": "Authority",
        "external": "External",
        "read": "read",
        "write": "write",
        "admin": "admin",
        "net": "Net",
        "domesticEntryExit": "Domestic entry / exit",
        "vtp": "VTP",
        "importsExports": "Imports / exports",
        "received": "Received",
        "pointDirection": "Point — Direction",
        "point": "Point",
        "gasDay": "Gas day",
        "sent": "Sent",
        "acknowledgedByTSO": "Acknowledged by TSO",
        "gasAmount": "Gas amount ({{unit}}/d)",
        "annual": "Annual",
        "quarterly": "Quarterly",
        "monthly": "Monthly",
        "daily": "Daily",
        "hourly": "Hourly",
        "transfers": "Transfers",
        "sum": "Sum",
        "counterparty": "Counterparty",
        "owner": "Owner",
        "bookedCapacity": "Booked capacity",
        "totalCapacity": "Total capacity (kWh)",
        "balanceGasPrice": "Price (€/MWh)",
        "overrun": "Overrun",
        "imatraEntry": "Imatra entry",
        "bcEntry": "BC entry",
        "bcExit": "BC exit",
        "vtpEntry": "VTP entry",
        "vtpExit": "VTP exit",
        "lngEntry": "LNG entry",
        "lngExit": "LNG exit",
        "lngHamina": "LNG Hamina",
        "lngInkoo": "LNG Inkoo",
        "lngHaminaEntry": "LNG Hamina entry",
        "lngHaminaExit": "LNG Hamina exit",
        "lngInkooEntry": "LNG Inkoo entry",
        "lngInkooExit": "LNG Inkoo exit",
        "entryTotal": "Entry total",
        "exitTotal": "Exit total",
        "total": "Total",
        "entry": "Entry",
        "exit": "Exit",
        "imatra": "Imatra",
        "bc": "BC",
        "biogas": "Biogas",
        "consumption": "Consumption",
        "acknow": "ACKNOW",
        "counterParty": "Counter party",
        "direction": "Direction",
        "info": "Info",
        "nomint": "NOMINT",
        "counterNomint": "Counter NOMINT",
        "nomres": "NOMRES",
        "hour": "Hour",
        "preliminary": "Preliminary",
        "historical": "Historical",
        "manual": "Manual",
        "estimatedAt": "Estimated at",
        "original": "Original",
        "value": "Value",
        "monthlyCorrected": "Monthly corrected",
        "yearlyCorrected": "Yearly corrected",
        "latest": "Latest",
        "monthlyDelta": "Monthly delta",
        "yearlyDelta": "Yearly delta",
        "volume": "Volume",
        "unit": "Unit",
        "marketParty": "Market party",
        "set": "Set",
        "required": "Required",
        "used": "Used",
        "remaining": "Remaining",
        "invoice": "Invoice",
        "analyser": "Analyser",
        "taxed": "Taxed",
        "emissionTrade": "Emission trade",
        "updatedAt": "Updated at",
        "ownerCode": "Code",
        "notes": "Notes"
      }
    },
    "status": {
      "accept": "accept",
      "accepted": "Accepted",
      "pending": "Pending",
      "declined": "Declined",
      "decline": "decline",
      "wait": "Wait",
      "notExists": "Not exists",
      "successfullyCreated": "successfully created",
      "successfullyUpdated": "successfully updated",
      "successfullyDeleted": "successfully deleted",
      "sending": "Sending",
      "loading": "Loading"
    },
    "notifications": {
      "errorUpdating": "Error updating",
      "errorCreating": "Error creating",
      "errorFetching": "Error fetching",
      "errorDeleting": "Error deleting",
      "errorUpdatingTotalCapacities": "Error updating total capacities",
      "errorUpdatingPhysicalBalanceSummary": "Error updating physical balance summary",
      "errorUpdatingMeteringSiteConsumptions": "Error updating metering site consumptions",
      "errorUpdatingCapacityOverruns": "Error updating capacity overruns",
      "errorUpdatingCurrentUser": "Error updating current user",
      "errorUpdatingBookedCapacity": "Error updating booked capacity",
      "errorUpdatingAvailableCapacityImatra": "Error updating available capacity for Imatra",
      "errorUpdatingAvailableCapacityBC": "Error updating available capacity for Balticconnector",
      "errorUpdatingNominations": "Error updating nominations",
      "errorFetchingMarketPartyIndex": "Error fetching market party index",
      "errorFetchingMarketParties": "Error fetching market parties",
      "errorFetchingMeteringSites": "Error fetching metering sites",
      "errorFetchingMeteringPointAnalysers": "Error fetching metering point analysers",
      "errorFetchingDeliveryRelationships": "Error fetching delivery relationships",
      "errorFetchingCapacityReservations": "Error fetching capacity bookings",
      "errorFetchingCapacityTransfers": "Error fetching capacity transfers",
      "errorFetchingCapacities": "Error fetching capacities",
      "errorFetchingBalanceGroups": "Error fetching balance groups",
      "errorFetchingBalanceGroupStats": "Error fetching balance group stats",
      "errorFetchingBalanceGroupMembers": "Error fetching balance group members",
      "errorFetchingDeliveries": "Error fetching deliveries",
      "errorFetchingMyBalance": "Error fetching my balance stats",
      "errorFetchingUsers": "Error fetching users",
      "errorFetchingNetworkStatus": "Error fetching transmission network status",
      "errorFetchingNominations": "Error fetching nominations",
      "errorFetchingNominationsSummary": "Error fetching nominations summary",
      "errorFetchingRelationships": "Error fetching market party relationships",
      "errorFetchingImbalanceEstimates": "Error fetching imbalance estimates",
      "errorFetchingInvoicedTaxes": "Error fetching invoiced taxes",
      "errorClosingConnection": "Error closing connection",
      "errorDeletingCapacityBooking": "Error while deleting capacity booking",
      "errorUpdatingActiveBalanceGroupId": {
        "title": "Error updating active balance group",
        "description": "Currently selected market party doesn't have active balance group"
      },
      "errorFetchingBalancePrices": "Error fetching balance gas prices",
      "errorFetchingCapacityPrices": "Error fetching capacity prices",
      "errorFetchingInvoices": "Error fetching invoices",
      "errorFetchingCapacitySums": "Error fetching capacity sums",
      "errorFetchingDepositSummary": "Error fetching deposit summary",
      "errorFetchingDeposits": "Error fetching deposits",
      "errorSavingDeposit": "Error saving deposit",
      "errorFetchingOwnerships": "Error fetching metering point ownerships",
      "errorFetchingTaxDeclarations": "Error fetching tax declarations",
      "incorrectInput": "Check input data!",
      "failedToSave": "Failed to save",
      "failedToSend": "Failed to send",
      "savedSuccessfully": "Saved successfully",
      "deletedSuccessfully": "Deleted successfully",
      "cancelledSuccessfully": "Cancelled successfully",
      "errorEmailNotValid": "email must be a valid email",
      "cognitoNotAuthorizedException": "User password cannot be reset in the current state. Please contact an administrator.",
      "cognitoUserNotFoundException": "Username with the given email not found",
      "cognitoUserNotConfirmedException": "User has not been confirmed.",
      "cognitoLimitExceededException": "Attempt limit exceeded, please try after some time.",
      "cognitoCodeMismatchException": "Verification code was not correct.",
      "cognitoExpiredCodeException": "Verification code has expired.",
      "cognitoInvalidParameterException": "The given parameters were invalid.",
      "cognitoInvalidPasswordException": "The given password was invalid.",
      "cognitoPasswordTooShortException": "Password not long enough.",
      "cognitoPasswordMissingUppercaseCharsException": "The password must contain atleast one uppercase character.",
      "cognitoPasswordMissingNumericCharsException": "The password must contain atleast one numeric character.",
      "cognitoPasswordMustHaveSymbolsException": "The password must contain atleast one symbolic character."
    },
    "form": {
      "dateErrorMessage": "Date should be selected!",
      "emptyInputFieldErrorMessage": "Field can not be empty!",
      "passwordNotLongEnoughMessage": "Password needs to be atleast 8 characters long."
    },
    "label": {
      "currency": "€",
      "notAvailable": "N/A",
      "mwh": "MWh",
      "kwh": "kWh",
      "gwh": "GWh",
      "mwhPerHour": "MWh/h",
      "kwhPerHour": "kWh/h",
      "unit": "Unit",
      "resolution": "Resolution",
      "hour": "Hour",
      "day": "Day",
      "month": "Month",
      "monthOfCreation": "Month of creation",
      "timePeriod": "Time period",
      "point": "Point",
      "exitZone": "Exit zone",
      "imatra": "Imatra",
      "biogas": "Biogas",
      "cityGate": "City gate",
      "balticConnector": "BC",
      "vtp": "VTP",
      "lng": "LNG",
      "shipper": "Retailer – Shipper",
      "pointDirection": "Point — Direction",
      "authorizeToNominate": "Authorize to nominate",
      "shipperGroup": "Shipper group",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo",
      "status": "Status",
      "direction": "Direction",
      "entry": "Entry",
      "exit": "Exit",
      "counterParties": "Counter parties",
      "registeredBy": "Registered by",
      "updatedAt": "Updated at",
      "show": "Show",
      "hide": "Hide",
      "inactiveMarketParties": "Inactive market parties",
      "statusFilter": "Filter by status",
      "stateFilter": "Filter by state",
      "accepted": "Accepted",
      "pending": "Pending",
      "declined": "Declined",
      "Upcoming": "Upcoming",
      "Ongoing": "Ongoing",
      "Past": "Past",
      "all": "All"
    },
    "date": {
      "today": "Today",
      "tomorrow": "Tomorrow"
    },
    "dialog": {
      "close": "Are you sure you want to close?",
      "save": "Are you sure you want to save?"
    },
    "user": {
      "name": "Name",
      "email": "Email",
      "phone": "Phone number"
    }
  },
  "app": {
    "title": {
      "companyName": "Test Company"
    },
    "menu": {
      "admin": "Admin",
      "adminReadOnly": "Read Only Admin",
      "frontPage": "Front page",
      "capacity": "Capacity",
      "gasEnergy": "Gas energy",
      "balance": "Balance",
      "balanceGroups": "Balance groups",
      "myBalance": "My balance",
      "notifications": "Notifications",
      "deliveryRelationships": "Delivery relationships",
      "relationships": "Relationships",
      "created": "Created",
      "received": "Received",
      "marketParties": "Market parties",
      "billing": "Billing",
      "apiDocs": "API Docs",
      "logOut": "Log out",
      "capacityReservations": "Capacity bookings",
      "capacityRightsTransfers": "Capacity rights transfers",
      "meteringPoints": "Metering points",
      "biogasEntryPoints": "Biogas entry points",
      "cityGates": "City gates",
      "shippers": "Shippers",
      "users": "Users",
      "nominations": "Nominations",
      "retailers": "Retailers",
      "networkLevel": "Network Level",
      "monitoring": "Monitoring",
      "capacityAndNominations": "Capacity and nominations",
      "capacitySettings": "Capacity settings",
      "transmissionNetworkState": "Transmission network state",
      "meteringSites": "Metering sites",
      "selectMarketParty": "Select market party",
      "copyMarketPartyId": "Copy market party ID",
      "settings": "Settings",
      "deliveries": "Deliveries",
      "biogasDeliveries": "Biogas deliveries",
      "invoices": "Invoices",
      "deposits": "Deposits",
      "declareGasTax": "Declare gas tax"
    },
    "handbook": "Handbook",
    "termsOfUse": "Terms of use",
    "feedback": "Give feedback",
    "survey": "Answer the customer satisfaction survey here"
  },
  "login": {
    "description": {
      "signInToPortal": "Sign in to Finnish gas transmission system operator’s Portal",
      "registerAsMarketParty": "Would you like to register as a market party?"
    },
    "form": {
      "label": {
        "username": "Username",
        "password": "Password"
      }
    }
  },
  "forgotPassword": {
    "title": "Recover password",
    "description": {
      "sendVerificationCode": "Submit your email address and we'll send you a verification code to reset your password.",
      "sendNewPassword": "You can now define your new password with the received verification code.",
      "returnToLogin": "You can now go back to login page and sign in with your new password."
    },
    "form": {
      "label": {
        "email": "Email",
        "verificationCode": "Verification code",
        "newPassword": "New password:",
        "newPasswordAgain": "New password (again)"
      },
      "message": {
        "emailType": "Not valid e-mail!",
        "passwordsMatchRequired": "Passwords need to match!",
        "smsVerificationCode": "Verification Code sent via SMS",
        "emailVerificationCode": "Verification Code sent via email"
      }
    }
  },
  "register": {
    "title": {
      "registerForMarketParty": "REGISTER FOR A MARKET PARTY"
    },
    "form": {
      "label": {
        "typeOfMarketParty": "I want to register to the market as",
        "shipper": "Shipper",
        "trader": "Trader",
        "biogasInjector": "Biogas Injector",
        "transmissionNetworkEndUser": "Transmission Network End User",
        "retailer": "Retailer",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO",
        "authority": "Authority",
        "external": "External",
        "firstName": "First name",
        "lastName": "Last name",
        "email": "E-mail",
        "phoneNumber": "Phone number",
        "companyName": "Company name",
        "vatNumber": "VAT number",
        "streetAddress": "Street address",
        "city": "City",
        "postalCode": "Postal code",
        "country": "Country",
        "eicx": "EIC-X",
        "retailerGs1Gln": "Retailer GS1 GLN",
        "dsoGs1Gln": "DSO GS1 GLN",
        "dataConsent": "I consent to TSO checking my data given in this form"
      },
      "message": {
        "selectTypeOfMarketParty": "Select at least one checkbox!",
        "firstNameRequired": "Please input your first name!",
        "lastNameRequired": "Please input your last name!",
        "emailRequired": "Please input your e-mail!",
        "emailType": "Not valid e-mail!",
        "phoneNumberRequired": "Please input your phone number!",
        "phoneNumberType": "Not valid phone number!",
        "companyNameRequired": "Please input your company name!",
        "vatNumberRequired": "Please input your VAT number!",
        "streetAddressRequired": "Please input your street address!",
        "cityRequired": "Please input your city!",
        "postalCodeRequired": "Please input your postal code!",
        "postalCodeType": "Not valid postal code!",
        "countryRequired": "Please input your country!",
        "eicxRequired": "Please input your EIC-X!",
        "eicxType": "Not valid EIC-X!",
        "gs1GlnRequired": "Please input your GS1 GLN!"
      },
      "extraInfo": {
        "line1": {
          "part1": "If you do not have EIC code yet, see ",
          "link": "https://gasgrid.fi/en/registration-as-market-participant/",
          "linkText": "instruction",
          "part2": " how to apply it"
        },
        "line2": "Please, also send two signed written copies of the original framework agreements to the following postal address:",
        "address": {
          "company": "Gasgrid Finland Oy",
          "recipient": "Niina Heiskanen",
          "streetAddress": "Kiehuvantie 189,",
          "postalAddress": "FI-45100 Kouvola",
          "country": "Finland"
        },
        "line3": "When registering in shipper/trader role, please also provide:",
        "line4": "1) Company registry certificate (less than 3 months old) and",
        "line5": "2) Latest financial statement (if existing) via email, ",
        "line6": {
          "part1": "For more information about registration process, please see ",
          "link": "https://gasgrid.fi/en/gas-market/market-rules-and-recommendations/",
          "linkText": "Gas Market Code and Information Exchange Guidelines",
          "part2": " document"
        }
      }
    },
    "successNotification": {
      "title": "Thank you for registering",
      "bodyText": "You have successfully sent the registration form, and will soon receive an email with sign in credentials. Your application will be reviewed and you will be notified when the process is finished and you can access the portal.",
      "button": "Back to login page"
    }
  },
  "settings": {
    "title": "Settings",
    "userInformation": {
      "title": "User information",
      "change": {
        "title": "Change {{value}}",
        "label": "New {{value}}",
        "verificationCode": "Verification code",
        "info": {
          "success": "{{value}} changed succesfully!",
          "noChange": "{{value}} was unchanged"
        },
        "error": {
          "invalid": "The input value is invalid",
          "required": "Value is required"
        }
      }
    }
  },
  "capacity": {
    "title": {
      "capacityOverview": "Capacity overview",
      "capacityReservations": "capacity bookings",
      "capacityRightsTransfer": "capacity rights transfers",
      "summary": "Summary ({{point}})",
      "overruns": "Capacity overruns ({{point}})"
    },
    "subtitle": {
      "capacityReservations": "Your latest capacity bookings",
      "capacityRightsTransfer": "Your latest notifications on capacity rights transfers",
      "summary": "Summary of your capacity bookings and transfers for {{point}} (values in {{unit}})",
      "overruns": "Capacity overruns for past seven days"
    },
    "button": {
      "newCapacityReservation": "New capacity booking",
      "newCapacityRightsTransfer": "New capacity rights transfer"
    },
    "link": {
      "viewAllCapacityReservation": "View all capacity bookings",
      "viewAllCapacityRightsTransfer": "View all capacity rights transfers"
    },
    "label": {
      "annual": "Annual",
      "quarterly": "Quarterly",
      "monthly": "Monthly",
      "daily": "Daily",
      "hourly": "Hourly",
      "startDate": "Start date",
      "endDate": "End date"
    },
    "point": {
      "exitZone": "Exit Zone",
      "imatra": "Imatra",
      "biogas": "Biogas",
      "lng": "LNG",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo"
    }
  },
  "capacityReservation": {
    "title": {
      "yourCapacityReservations": "Your capacity bookings",
      "adminCapacityReservations": "System capacity bookings (last 7 days)",
      "capacityReservationsSummary": "Capacity bookings summary",
      "capacityReservation": "Capacity booking",
      "confirmDelete": "Are you sure you wish to cancel this capacity booking?",
      "products": "Products"
    },
    "form": {
      "title": {
        "newCapacityReservation": "New Capacity Booking",
        "chooseCapacityProducts": "Choose Capacity Products"
      },
      "button": {
        "cancelBooking": "Cancel booking"
      },
      "label": {
        "point": "Point",
        "from": "from",
        "totalPrice": "Total price",
        "disclaimer": "Please note",
        "remainingDeposit": "Remaining deposit",
        "reason": "Reason for cancellation (maximum 4000 characters)"
      },
      "disclaimer": "The tariff period is calendar year and therefore the duration of some capacity products may exceed past the current tariff period. In these cases the price given by the portal is only indicative.",
      "info": {
        "line1": "Erroneous capacity bookings can be cancelled within 60 minutes of the time when Gasgrid has received the capacity booking. Cancelling can be done by clicking \"Decline\" button in the Capacity Booking view for the booking in question. If capacity booking has already been accepted by Gasgrid and \"Decline\" button is no longer available, cancellation can be done within 60 minutes by sending e-mail to customerservice@gasgrid.fi. Please note that erroneous capacity bookings will be cancelled in full, and shipper is responsible of making the correct new capacity booking, if needed. Gasgrid’s customer service responds during working hours and requests that have been sent after working hours will be processed during the next working day. ",
        "line2": "For further information regarding capacity booking corrections see",
        "linkDescription": "Gas Market Code and Information Exchange Guidelines",
        "reasonHelpText": "Please provide explanation for auditing why this capacity reservation is being cancelled"
        
      }
    },
    "dialog": {
      "actionMessage": "Are you sure you want to {{action}} this capacity booking?",
      "totalPriceMessage": "Total price: {{totalPrice}}",
      "dailyAggregatesTitle": "Total daily capacities:",
      "aggregateMessage": "{{date}}: {{value}} kWh/d",
      "hourlyAggregateMessage": "Total hourly capacity: {{value}} kWh/d"
    },
    "message": {
      "fetchError": "Error fetching capacity booking"
    }
  },
  "capacityRightsTransfer": {
    "title": {
      "capacityRightsTransfer": "Capacity rights transfer",
      "yourCapacityRightsTransfer": "Your capacity rights transfers"
    },
    "subtitle": {
      "unconfirmedCapacityRightsTransfers": "Unconfirmed capacity rights transfers",
      "processedCapacityRightTransfers": "Processed capacity right transfers"
    },
    "form": {
      "title": {
        "newCapacityRightsTransfer": "New Capacity Rights Transfer"
      },
      "label": {
        "entryPoint": "Point",
        "buyer": "Buyer",
        "gasDeliveryDay": "Gas delivery day",
        "capacity": "Capacity"
      }
    }
  },
  "capacityProductInstance": {
    "label": {
      "amount": "Amount",
      "end": "Ending in",
      "price": "/kWh",
      "start": "Starting from",
      "unit": "kWh/h",
      "date": "Date",
      "startHour": "Starting",
      "endHour": "Ending"
    },
    "datePicker": {
      "day": {
        "placeholder": "Select gas day"
      },
      "month": {
        "placeholder": "Select gas month"
      }
    }
  },
  "deliveryRelationship": {
    "title": {
      "deliveryRelationships": "Delivery relationships",
      "transferNetworkMeteringSites": "Transfer network metering sites",
      "biogasEntryPoints": "Biogas entry points",
      "cityGates": "Distribution network city gates",
      "created": "Created relationships",
      "received": "Received relationships",
      "networkLevel": "Network level relationships"
    },
    "new": {
      "title": "New delivery relationship",
      "submit": {
        "action": {
          "description": "Your delivery relationship request will be sent to the chosen shipper for approval."
        },
        "button": {
          "title": "Add new relationship"
        }
      }
    },
    "modal": {
      "title": "Edit delivery relationship",
      "message": {
        "success": "Delivery relationship successfully updated",
        "error": "Error updating delivery relationship"
      }
    }
  },
  "marketParty": {
    "title": {
      "marketParty": "Market party",
      "marketParties": "Market parties"
    },
    "modal": {
      "marketPartyDeleteTitle": "Are you sure you want to delete this market party?",
      "marketPartyDeleteContent": "This will delete the market party data and remove all users from the market party. If market party has a short code, it is not released."
    },
    "dialog": {
      "acceptActionMessage": "Are you sure you want to accept this market party?"
    },
    "label": {
      "typeOfMarketParty": "Roles",
      "network": "Network",
      "notes": "Notes",
      "shipper": "Shipper",
      "trader": "Trader",
      "biogasInjector": "Biogas Injector",
      "transmissionNetworkEndUser": "Transmission Network End User",
      "retailer": "Retailer",
      "tso": "TSO",
      "dso": "DSO",
      "lto": "LTO",
      "authority": "Authority",
      "external": "External",
      "gasgrid": "Gasgrid",
      "baltic": "Baltic",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo",
      "visibility": "Market party visibility",
      "hidden": "Hidden"
    }
  },
  "balanceContainer": {
    "title": {
      "balance": "Balance",
      "balanceHistory": "Balance history ({{start}} – {{end}})",
      "newBalanceGroup": "Create a new balance group",
      "balanceGroups": "Balance groups",
      "balanceGroup": "Balance Group",
      "myBalanceInfo": "My balance information",
      "members": "Members",
      "editMembership": "Edit {{shipperName}}'s balance group membership",
      "membershipStartDate": "Membership starts at",
      "membershipEndDate": "Membership ends at",
      "balanceGroupStarts": "Balance group starts",
      "groupImbalance": "Group imbalance",
      "myImbalance": "My imbalance",
      "entry": "Entry",
      "exit": "Exit",
      "net": "Entry / Exit net"
    },
    "button": {
      "newBalanceGroups": "New balance group",
      "add": "Add"
    },
    "emptyPage": {
      "description": {
        "noBalanceGroup": "No displayable information. Create a new balance group.",
        "pendingBalanceGroup": "No displayable information. Your balance group is waiting for approval from TSO."
      }
    },
    "modal": {
      "imbalanceParty": "imbalance responsible party",
      "description_1": "By creating a new balance group you are applying to become an <0>{{party}}</0> for the group. Your credentials and additional colleteral information will be checked and you will be informed on any needed actions on your part.",
      "description_2": "You will recieve a notification when the approval process is done."
    },
    "message": {
      "createdSuccessfully": "New balance group created successfully.",
      "errorCreating": "Error creating new balance group.",
      "updatedSuccessfully": "Balance group updated successfully",
      "errorUpdating": "Error updating balance group",
      "errorFetchMembers": "Error fetching balance group's members",
      "balanceGroupStarts": "The start date can be no earlier than 3 days from now"
    },
    "label": {
      "self": "self",
      "addNewMembers": "Add new members :",
      "selectOrSearch": "Search and select shippers",
      "chooseDate": "Choose date",
      "imbalance": "Imbalance",
      "forGasDay": "for gas day {{selectedDate}}",
      "forGasDaySoFar": "for gas day {{selectedDate}} so far",
      "acquisitions": "Acquisitions",
      "biogas": "Biogas",
      "import": "Import Imatra, BC, LNG",
      "energy": "Energy",
      "surrenders": "Surrenders",
      "export": "Export",
      "totalEntry": "Total Entry",
      "totalExit": "Total Exit",
      "total": "Total",
      "domesticEntryExit": "Domestic entry / exit",
      "vtp": "Virtual Trading Point (VTP)",
      "importsExports": "Imports / exports",
      "statsChooseDate": "Choose date to be viewed",
      "previous": "<< Prev",
      "next": "Next >>",
      "estimated": "Delivery estimate"
    },
    "membership": {
      "message": {
        "addedSuccessfully": "New member added to balance group successfully.",
        "errorAdding": "Error adding new member to balance group.",
        "updatedSuccessfully": "Membership updated successfully.",
        "errorUpdating": "Error updating membership.",
        "membershipEndDate": "The end date can be no earlier than 3 gas days from now"
      }
    }
  },
  "detectBrowser": {
    "modal": {
      "title": "Please change browser",
      "subheader": "We are not supporting this browser.",
      "helptext": "Make sure you are using one of our supported browser.",
      "browsers": "Browsers"
    }
  },
  "announcements": {
    "modal": {
      "title": "New Announcement",
      "updateAnnouncementsTitle": "Update Announcement"
    },
    "label" : {
      "chooseDate": "Choose publish date",
      "updatedOn": "Updated on {{date}}",
      "headingLinkTitle": "Link titles",
      "linkTitle": "Link title",
      "headingLinkUrl": "URLs",
      "linkUrl": "URL",
      "subject": "Subject",
      "announcementText": "Announcement message",
      "status": "Status"
    },
    "status": {
      "pending": "Unpublished",
      "accepted": "Published"
    },
    "button": {
      "newAnnouncement": "New Announcement"
    },
    "notifications": {
      "errorFetching": "Error occured while fetching the list of Announcements.",
      "errorSaving": "Error occured while saving.",
      "successCreating": "Announcement created succesfully",
      "successUpdating": "Announcement updated succesfully"
    }
  },
  "users": {
    "title": {
      "newUser": "Add new user",
      "updateUser": "Update user",
      "deleteUser": "Delete user",
      "userInfo": "User Information",
      "contactPersonInfo": "Contact Person Information",
      "permissions": "Permissions",
      "read": "read",
      "write": "write",
      "confirmDelete": "Are you sure you wish to delete this user?",
      "notFound": "User not found"
    },
    "form": {
      "label": {
        "admin": "Admin",
        "invoice": "Invoice",
        "firstname": "First name",
        "lastname": "Last name",
        "phone": "Phone number",
        "email": "Email",
        "shipper": "Shipper",
        "trader": "Trader",
        "enduser": "End user",
        "producer": "Biogas injector",
        "retailer": "Retailer",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO"
      }
    },
    "notification": {
      "deleteSuccess": "User deleted succesfully",
      "deleteError": "Error deleting user"
    }
  },
  "nominations": {
    "title": {
      "yourNominations": "Your nominations",
      "networkNominations": "Network nominations",
      "newNomination": "New nomination",
      "renomination": "Renomination",
      "nomination": "Nomination",
      "nominationDetails": "Nomination details",
      "hourlyGasAmounts": "Hourly gas amount ({{unit}})",
      "hour": "Hour",
      "gasAmount": "NOMINT / NOMRES",
      "nominationMatchingGasAmount": "NOMINT / MATCHED / NEW MATCH",
      "nominationIntentions": "Nomination intentions",
      "confirmedNominations": "Confirmed nominations (for past 30 days)",
      "nominationsSummary": "Nominations summary",
      "nominationInformation": "Nomination information",
      "totalAmounts": "Total amounts",
      "nominationMatching": "Nomination Matching",
      "nomationMatchingConfirmation": "Are you sure you want to submit new match values?"
    },
    "button": {
      "newNomination": "New nomination",
      "sendNomination": "Send nomination",
      "sendRenomination": "Send renomination",
      "match": "Match"
    },
    "label": {
      "pointDirection": "Point — Direction",
      "date": "Gas delivery date",
      "shipperEIC": "Counterparty",
      "onBehalfOfMarketPartyId": "Nominate on behalf of",
      "imatra-entry": "Imatra — Entry",
      "balticConnector-entry": "Balticconnector — Entry",
      "balticConnector-exit": "Balticconnector — Exit",
      "lngHamina-entry": "LNG Hamina — Entry",
      "lngInkoo-entry": "LNG Inkoo — Entry",
      "vtp-entry": "VTP — Entry",
      "vtp-exit": "VTP — Exit",
      "entry": "Entry",
      "exit": "Exit",
      "chooseDate": "Choose date",
      "unit": "kWh",
      "totalNomint": "TOTAL NOMINT",
      "totalMatched": "TOTAL MATCHED",
      "totalNewMatch": "TOTAL NEW MATCH",
      "hoverText": "Copy for all {{hoursInGasDay}} hours",
      "renominationHoverText": "Copy for all remaining hours",
      "isExternalEic": "External counterparty",
      "selectCounterParties": "Select counter parties"
    },
    "message": {
      "createdSuccessfully": "New nomination created successfully.",
      "updatedNomresesSuccessfully": "Updated nomres amounts successfully.",
      "errorCreating": "Error creating new nomination.",
      "bcAvailableCapacity": "Available capacity D-1: {{value}} {{unit}}",
      "bcAvailableCapacityUntil": "For nominations made before {{datetime}}",
      "bcInstructions": [
        "Nominations in Balticconnector have to be equal (“flat”) for each effective hour of the gas day (effective hour = hours of the day for which a nomination can be effective excluding lead time).",
        "Note that the shipper’s aggregated nominations per direction shall not exceed the technical capacity of Balticconnector."
      ]
    },
    "nomintMissing": {
      "message": "NOMINT data missing",
      "description": "No NOMINT data was found corresponding to this nomination"
    }
  },
  "relationships": {
    "title": {
      "newRelationship": "New market party relationship",
      "updateRelationship": "Update market party relationship"
    },
    "label": {
      "types": {
        "shipper": "Retailer – Shipper",
        "shipperGroup": "Shipper group",
        "authorizeToNominate": "Authorize to nominate"
      },
      "shipper": "Shipper",
      "marketparty": "Market party",
      "startDate": "Start gas day",
      "endDate": "End gas day",
      "type": "Relationship Type",
      "authoriseShipperCheckbox":
        "May nominate on behalf of <strong>{{marketPartyName}}</strong> for"
    },
    "button": {
      "newRelationship": "New relationship"
    },
    "message": {
      "createdSuccessfully": "Relationship created successfully.",
      "errorCreating": "Error creating relationship.",
      "updatedSuccessfully": "Relationship updated successfully.",
      "errorUpdating": "Error updating relationship."
    }
  },
  "monitoring": {
    "cardLabel": {
      "availableCapacity": "Available capacity",
      "bookedCapacity": "Booked capacity",
      "nominations": "Nominations",
      "nomint": "NOMINT",
      "nomres": "NOMRES"
    },
    "button": {
      "editTechnicalCapacity": "Edit technical capacity"
    },
    "checkboxlabel": "Use full gasdays",
    "form": {
      "errorCreating": "Error updating technical capacity",
      "createdSuccessfully": "Technical capacity updated successfully",
      "capacityLabel": "Capacity (kWh / h)"
    },
    "cardInfo": {
      "noData": "No data"
    },
    "cardSubHeading": {
      "entry": "Entry",
      "exit": "Exit",
      "net": "Net"
    },
    "capacityAndNominations": {
      "title": "Capacity and nominations monitoring - {{unit}}",
      "cardContainerTitle": {
        "physicalBalanceSummary": "Physical balance summary",
        "imatra": "Imatra - entry",
        "balticConnector": "BC - entry / exit",
        "exitZone": "Exit zone - exit",
        "biogas": "Biogas - entry",
        "lng": "LNG - entry",
        "lngHamina": "LNG Hamina - entry",
        "lngInkoo": "LNG Inkoo - entry"
      },
      "cardLink": "View detailed info",
      "modal": {
        "title": "exit zone shippers",
        "imatraTitle": "Imatra shippers",
        "errorFetch": "Fetch stats error",
        "imbalance": {
          "consumption": {
            "title": "Consumption",
            "fetchError": "Error fetching consumptions",
            "updateError": "Error updating consumptions"
          },
          "changeEstimatesBy": "Change existing estimates by:"
        }
      },
      "datepicker": {
        "title": "Choose first date to be viewed",
        "valueChangedConfirmation": "Manual value is changed, do you want to save changes?"
      }
    },
    "capacitySettings": {
      "title": "Capacity settings",
      "cardContainerTitle": {
        "imatra": "Imatra"
      }
    }
  },
  "setTechnicalCapacity": {
    "modal": {
      "title": "Set technical capacity"
    }
  },
  "capacityAvailabilityForm": {
    "title": {
      "setOfferedCapacity": "Set offered capacity"
    },
    "label": {
      "dailyCapacity": "Daily capacity",
      "closedForTheDay": "closed for the day",
      "withinDayCapacity": "Within day capacity",
      "notYetOpened": "not yet opened to set"
    },
    "message": {
      "updatedSuccessfully": "Available capacity updated successfully.",
      "errorUpdating": "Error updating available capacity."
    }
  },
  "frontPage": {
    "title": "Front page",
    "box1": {
      "title": "Transmission Network"
    },
    "box2": {
      "title": "Balticconnector's capacities",
      "text": "Information regarding Balticconnector's capacities (updates hourly)"
    },
    "box3": {
      "title": "Announcements (past 3 months)"
    },
    "box4": {
      "title": "Gas balance prices"
    }
  },
  "transmissionNetworkState": {
    "description": "The transmission network is {{imbalanceText}} ({{imbalanceTime}})",
    "short": "short",
    "long": "long",
    "nominal": "nominal",
    "unknown": "unknown",
    "title": {
      "setNetworkStatus": "Set network status"
    },
    "label": {
      "networkStatus": "Network status",
      "green": "Green",
      "red": "Red",
      "yellow": "Yellow"
    },
    "message": {
      "savedSuccessfully": "Transmission network state saved successfully.",
      "errorSaving": "Error saving transmission network state."
    }
  },
  "balanceGasPricesTable": {
    "title": {
      "buy": "Buy price",
      "sell": "Sell price"
    },
    "subTitle": {
      "buy": "{{priceType}} buy price for the ongoing gas day and final buy prices",
      "sell": "{{priceType}} sell price for the ongoing gas day and final sell prices"
    },
    "overridden": "Overridden",
    "preliminary": "Preliminary",
    "overriddenAt": "Overridden at"
  },
  "balanceGasPriceDialog": {
    "buy": "Buy",
    "sell": "Sell",
    "buttonLabel": "Set balance gas price",
    "title": "Set balance gas price for {{date}}",
    "priceToUpdate": "Price to update",
    "newPrice": "New price (€/MWh)",
    "errorMessage": "Error saving balance gas price",
    "warning": {
      "statusNotYellow": "Network status should be set to yellow when setting balance gas prices.",
      "statusNominal": "Balance prices should not be set when the network balance is nominal.",
      "statusShort": "Sell price should be set when the network is short.",
      "statusLong": "Buy price should be set when the network is long."
    }
  },
  "dateRangePicker": {
    "label": {
      "startDate": "Start Date",
      "endDate": "End Date"
    }
  },
  "meteringSites": {
    "title": {
      "gasConsumption": "Gas consumption in metering sites"
    },
    "label": {
      "noMeteringSiteFound": "No metering sites found for the selected market party"
    },
    "table": {
      "title": {
        "gasConsumptionPerMeteringSite": "Gas consumption per metering site ({{unit}})"
      },
      "information": {
        "heat": "Gross calorific value (kWh/m3n)",
        "volume": "Volume (m3n)"
      }
    }
  },
  "selectMarketParty": {
    "title": "Select market party",
    "label": {
      "marketParties": "Market parties",
      "selectOrSearch": "Search and select market party"
    }
  },
  "deliveries": {
    "title": "Deliveries"
  },
  "biogasDeliveries": {
    "title": "Biogas deliveries"
  },
  "invoices": {
    "category": {
      "imbalance": "Balance responsible invoices",
      "shipper": "Shipper invoices",
      "taxes": "Tax invoices",
      "imbalance_corr": "Balance responsible correction invoices",
      "shipper_corr": "Shipper correction invoices",
      "taxes_corr": "Tax correction invoices",
      "unknown": "Uncategorized invoices"
    },
    "title": "Your invoices for {{startDate}} – {{endDate}}",
    "label": {
      "datePicker": "Choose month to be viewed:",
      "estimated": "Displayed values are current estimates and not invoiced yet"
    },
    "notification": {
      "noDailyValues": {
        "message": "No daily values",
        "description": "No daily values found for {{product}}"
      }
    },
    "product": {
      "DAILY_IMBAL_CHARGE_P": "Daily imbalance compensation",
      "DAILY_IMBAL_CHARGE_N": "Daily imbalance charge",
      "EXIT_Y": "Exit capacity - Yearly product",
      "EXIT_Q": "Exit capacity - Quarterly product",
      "EXIT_M": "Exit capacity - Monthly product",
      "EXIT_D": "Exit capacity - Daily product",
      "EXIT_WD": "Exit capacity - Within day product",
      "ENTRY_IMATRA_Y": "Entry capacity Imatra - Yearly product",
      "ENTRY_IMATRA_Q": "Entry capacity Imatra - Quarterly product",
      "ENTRY_IMATRA_M": "Entry capacity Imatra - Monthly product",
      "ENTRY_IMATRA_D": "Entry capacity Imatra - Daily product",
      "ENTRY_IMATRA_WD": "Entry capacity Imatra - Within day product",
      "ENTRY_BIOGAS_Y": "Entry capacity Biogas - Yearly product",
      "ENTRY_BIOGAS_Q": "Entry capacity Biogas - Quarterly product",
      "ENTRY_BIOGAS_M": "Entry capacity Biogas - Monthly product",
      "ENTRY_BIOGAS_D": "Entry capacity Biogas - Daily product",
      "ENTRY_BIOGAS_WD": "Entry capacity Biogas - Within day product",
      "ENTRY_LNG_H_Y": "Entry capacity Hamina LNG - Yearly product",
      "ENTRY_LNG_H_Q": "Entry capacity Hamina LNG - Quarterly product",
      "ENTRY_LNG_H_M": "Entry capacity Hamina LNG - Monthly product",
      "ENTRY_LNG_H_D": "Entry capacity Hamina LNG - Daily product",
      "ENTRY_LNG_H_WD": "Entry capacity Hamina LNG - Within day product",
      "ENTRY_LNG_I_Y": "Entry capacity Inkoo LNG - Yearly product",
      "ENTRY_LNG_I_Q": "Entry capacity Inkoo LNG - Quarterly product",
      "ENTRY_LNG_I_M": "Entry capacity Inkoo LNG - Monthly product",
      "ENTRY_LNG_I_D": "Entry capacity Inkoo LNG - Daily product",
      "ENTRY_LNG_I_WD": "Entry capacit Inkooy LNG - Within day product",
      "EXIT_CAP_OVERRUN": "Exit capacity overrun charge",
      "BIOGAS_CAP_OVERRUN": "Entry capacity Biogas overrun charge",
      "COMM_CHARGE_EXIT": "Commodity charge",
      "BC_RENOM_CHARGE_ENTR": "Balticconnector entry renomination charge",
      "BC_RENOM_CHARGE_EXIT": "Balticconnector exit renomination charge",
      "CO2_TAX": "CO2 tax",
      "ENERGY_TAX": "Energy tax",
      "STRAT_STOCK_PILE_FEE": "Strategic stockpile fee",
      "COMM_CHARGE_EXIT_COR": "Commodity charge Correction",
      "EXIT_CAP_OVERR_LESS": "Exit capacity overrun charge Correction Less",
      "EXIT_CAP_OVERR_MORE": "Exit capacity overrun charge Correction More",
      "BIOGAS_CAP_OVER_LESS": "Entry capacity overrun charge Biogas Correction Less",
      "BIOGAS_CAP_OVER_MORE": "Entry capacity overrun charge Biogas Correction More",
      "COMM_CHARGE_EXIT_C2": "Commodity charge Correction 2",
      "EXIT_CAP_OVER_MO_C2": "Exit capacity overrun charge Correction 2 More",
      "EXIT_CAP_OVER_LE_C2": "Exit capacity overrun charge Correction 2 Less",
      "BIO_CAP_OVER_MORE_C2": "Entry capacity overrun charge Biogas Correction 2 More",
      "BIO_CAP_OVER_LESS_C2": "Entry capacity overrun charge Biogas Correction 2 Less",
      "DAILY_IMBAL_0_N_COR": "Daily imbalance charge Correction Neutral price negative",
      "DAILY_IMBAL_0_P_COR": "Daily imbalance charge Correction Neutral price  positive",
      "DAILY_IMBAL_N_COR": "Daily imbalance charge Negative Correction",
      "DAILY_IMBAL_P_COR": "Daily imbalance charge Positive Correction",
      "DAILY_IMBAL_NEUT_C2": "Daily imbalance charge Correction 2 Neutral",
      "DAILY_IMBAL_PURC_C2": "Daily imbalance charge Correction 2 Positive",
      "DAILY_IMBAL_SELL_C2": "Daily imbalance charge Correction 2 Negative"
    }
  },
  "deposits": {
    "title": "Deposits",
    "error": "Set deposit must be a non-negative number",
    "modal": {
      "title": "Values for calculating the used deposit ({{marketParty}})",
      "capacityTotals": "Monthly totals from capacity invoices",
      "taxTotals": "Monthly totals from tax invoices",
      "boundCapacity": "Capacity (all products) for {{start}}-{{end}}: {{price}}",
      "boundCapacityEstimate": "Estimated capacity (all products) for {{start}}-{{end}}: {{price}}",
      "imbalance": "Imbalance charges for {{start}}-{{end}}: {{price}}",
      "imbalanceEstimate": "Estimated imbalance charges for {{start}}-{{end}}: {{price}}",
      "overrunEstimate": "Estimated overruns (all products) for {{start}}-{{end}}: {{price}}",
      "tax": "Taxes and fees for {{start}}-{{end}}: {{price}}"
    },
    "label": {
      "setAmount": "Set deposit",
      "updatedAt": " (updated at {{timestamp}})",
      "usedAmount": "Used deposit",
      "remainingAmount": "Remaining deposit",
      "startGasDay": "Start gas day",
      "endGasDay": "End gas day",
      "editDeposit": "Edit deposit for {{marketParty}}"
    }
  },
  "declareGasTax": {
    "title": "Gas tax declaration",
    "modal": {
      "pg02_natural_gas": "[2] Natural Gas",
      "pg05_biogas": "[5] Biogas (non-sustainable)",
      "pg06_biogas_r": "[6] Biogas R (RED II, renewable)",
      "pg07_biogas_t": "[7] Biogas T (sustainable)",
      "pg08_biogas_heating": "[8] Biogas Heating (non-sustainable)",
      "pg09_biogas_heating_r": "[9] Biogas Heating R (RED II, renewable)",
      "pg10_biogas_heating_t": "[10] Biogas Heating T (sustainable)",
      "pg08a_biogas_chp": "[8a] Biogas Heat & Power (non-sustainable)",
      "pg09a_biogas_chp_r": "[9a] Biogas Heat & Power R (RED II, renewable)",
      "pg10a_biogas_chp_t": "[10a] Biogas Heat & Power T (sustainable)",
      "pg05a_biogas_chp": "[5a] Biogas Heat & Power (non-sustainable)",
      "pg06a_biogas_chp_r": "[6a] Biogas Heat & Power R (RED II, renewable)",
      "pg07a_biogas_chp_t": "[7a] Biogas Heat & Power T (sustainable)",
      "pg02a_natural_gas_chp": "[2a] Natural Gas Heat & Power",
      "help": "Energy values should be entered in higher heating value (HHV)"
    },
    "delete": {
      "title": "Delete declaration",
      "confirm": "Are you sure you want to delete this declaration?",
      "error": "Error deleting declaration",
      "success": "Declaration deleted successfully",
      "period": "Period",
      "meteringSite": "Metering site"
    },
    "message": {
      "createdSuccessfully": "New tax declaration created successfully.",
      "errorCreating": "Error creating new tax declaration."
    },
    "table": {
      "title": "Higher heating value ({{unit}})",
      "actions": {
        "declare": "Declare",
        "delete": "Delete"
      },
      "information": {
        "consumption": "Consumption",
        "pg02_natural_gas": "NG",
        "pg05_biogas": "BG",
        "pg06_biogas_r": "R",
        "pg07_biogas_t": "T",
        "pg08_biogas_heating": "BG H",
        "pg09_biogas_heating_r": "R",
        "pg10_biogas_heating_t": "T",
        "pg08a_biogas_chp": "BG CHP",
        "pg09a_biogas_chp_r": "R",
        "pg10a_biogas_chp_t": "T",
        "pg05a_biogas_chp": "BG CHP",
        "pg06a_biogas_chp_r": "R",
        "pg07a_biogas_chp_t": "T",
        "pg02a_natural_gas_chp": "NG CHP"
      }
    }
  }
}
