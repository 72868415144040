import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Col, Form, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';

const AnnouncementLinkForm = (props) => {
  const { t } = useTranslation();
  const {
    link = {},
    handleEditLink,
    index,
    deleteLink,
  } = props;
  const { url = '', title = '' } = link;

  const wrapInFormItem = (i, field, label) => (
    <Form.Item label={i === 0 ? t(`announcements.label.${label}`) : undefined}>
      {field}
    </Form.Item>
  );

  return (
    <Row>
      <Col span={10}>
        {wrapInFormItem(
          index,
          <Input
            className="announcements__input-link-linkTitle"
            data-testid={`announcements__input-link-linkTitle${index}`}
            placeholder={`${t('announcements.label.linkTitle')} ${index + 1}`}
            onChange={(e) => handleEditLink({ ...link, title: e.target.value }, index)}
            value={title}
          />,
          'headingLinkTitle',
        )}
      </Col>
      <Col span={1} />
      <Col span={12}>
        {wrapInFormItem(
          index,
          (<Input
            className="announcements__input-link-linkUrl"
            data-testid={`announcements__input-link-linkUrl${index}`}
            placeholder={`${t('announcements.label.linkUrl')} ${index + 1}`}
            onChange={(e) => handleEditLink({ ...link, url: e.target.value }, index)}
            value={url}
          />),
          'headingLinkUrl',
        )}
      </Col>
      <Col span={1}>
        {index > 0
          && (
          <Button
            data-testid={`announcements__link-delete-button--${index}`}
            className="announcements__link-delete-button"
            block
            icon="delete"
            onClick={deleteLink}
          />
          )}
      </Col>
    </Row>
  );
};

AnnouncementLinkForm.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  handleEditLink: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  deleteLink: PropTypes.func.isRequired,
};

AnnouncementLinkForm.defaultProps = {
  link: undefined,
};

export default AnnouncementLinkForm;
