import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

import PageHeaderBar from '../../components/PageHeaderBar';
import MeteringSitesTable from '../../components/MeteringSitesTable';
import {
  TYPES,
  TYPES_NAMES,
} from '../../constants/meteringSites';
import Context from '../../context';
import ButtonSelector from '../../components/ButtonSelector';
import { STATES_NAMES } from '../../constants/deliveryRelationships';

const typesToShow = TYPES_NAMES.filter(type => ![TYPES.BIOGAS, TYPES.CITYGATE].includes(type));

const DeliveryRelationships = () => {
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState('all');
  const {
    selectedMarketPartyId,
    updateMeteringSites,
    updateMeteringPointAnalysers,
    updateMeteringPointOwnerships,
  } = useContext(Context);

  useEffect(() => {
    updateMeteringSites();
    updateMeteringPointAnalysers();
    updateMeteringPointOwnerships();
  }, [selectedMarketPartyId]);

  return (
    <>
      <PageHeaderBar
        title={t('deliveryRelationship.title.transferNetworkMeteringSites')}
        span={{ title: 12, action: 12 }}
        actionItems={(
          <ButtonSelector
            t={t}
            title="stateFilter"
            options={['all', ...STATES_NAMES]}
            defaultValue={selectedState}
            onChange={({ target: { value } }) => setSelectedState(value)}
          />
        )}
      />
      <Layout.Content className="layout__container__page__content">
        <MeteringSitesTable typesToShow={typesToShow} selectedState={selectedState} />
      </Layout.Content>
    </>
  );
};

DeliveryRelationships.displayName = 'DeliveryRelationships';

export default DeliveryRelationships;
