import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Table } from 'antd';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import './meteringSiteDeliveryRelationships.less';

import Context from '../../context';
import ActionButton from '../ActionButton';
import StatusIcon from '../StatusIcon';
import { STATES } from '../../constants/deliveryRelationships';
import { STATUS } from '../../constants/status';
import {
  isUserAllowedToModify,
  patchStatus,
  getState,
} from '../../utils/deliveryRelationshipHelpers';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { formatDateStr } from '../../utils/i18n';
import { dateSort } from '../../utils/sort';
import EditDeliveryRelationship from './editDeliveryRelationship';

const MeteringSiteDeliveryRelationships = (props) => {
  const { meteringSiteId } = props;
  const { t } = useTranslation();
  const {
    deliveryRelationships,
    deliveryRelationshipsLoading,
    updateDeliveryRelationships,
    marketPartyIndex,
    currentUser: {
      ownMarketParties,
    },
  } = useContext(Context);
  const [deliveryRelationship, setDeliveryRelationship] = useState(null);

  useEffect(() => {
    if (deliveryRelationships.length === 0) {
      updateDeliveryRelationships();
    }
  }, []);

  const DATA_HEADERS = [
    {
      title: 'startTime',
      dataIndex: 'start',
      key: 'start',
      render: formatDateStr,
    },
    {
      title: 'endTime',
      key: 'end',
      render: (item) => {
        const editable = [STATES.ONGOING, STATES.FUTURE].includes(item.state)
          || item.status === STATUS.PENDING;
        return (
          <div className="end-date">
            <span>{formatDateStr(item.end)}</span>
            {editable && (
              <Button className="edit-button" onClick={() => setDeliveryRelationship(item)}>
                <Icon type="edit" />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: 'shipper',
      dataIndex: 'marketPartyName',
      key: 'marketPartyName',
    },
    {
      title: 'state',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <StatusIcon
          status={status}
          withTitle
          title={capitalize(status)}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const prepareData = () => {
    if (deliveryRelationships.length === 0) {
      return [];
    }

    const siteDRSData = deliveryRelationships
      .filter(delivery => delivery.meteringSiteId === meteringSiteId)
      .map((delivery) => {
        const { status, start, end } = delivery;
        const state = getState(status, start, end);
        const data = [];
        const deliveryRelationshipMarketParty = marketPartyIndex
          .find(marketParty => marketParty.id === delivery.marketPartyId);

        if (
          isUserAllowedToModify(delivery.marketPartyId, ownMarketParties)
          && status === STATUS.PENDING
        ) {
          data.push({
            type: 'button',
            text: t('common.button.accept'),
            onClick: () => patchStatus(
              STATUS.ACCEPTED,
              delivery.id,
              delivery.marketPartyId,
              t,
            ),
          }, {
            type: 'button',
            text: t('common.button.decline'),
            onClick: () => patchStatus(
              STATUS.DECLINED,
              delivery.id,
              delivery.marketPartyId,
              t,
            ),
          });
        }

        const action = data.length > 0 && <ActionButton data={data} />;

        return {
          ...delivery,
          start,
          end,
          marketPartyName: deliveryRelationshipMarketParty
            ? deliveryRelationshipMarketParty.name
            : t('common.status.notExists'),
          state,
          status,
          action,
        };
      })
      .sort((a, b) => dateSort(a.start, b.start));

    const ongoing = siteDRSData.find(item => item.state === STATES.ONGOING);

    return ongoing !== undefined
      ? [
        ongoing,
        ...siteDRSData.filter(item => item.id !== ongoing.id),
      ]
      : siteDRSData;
  };

  return (
    <>
      <h3 className="metering-site-delivery-relationships__header-text">
        { t('deliveryRelationship.title.deliveryRelationships')}
      </h3>
      <Table
        className="metering-site-delivery-relationships__table"
        columns={getTranslatedTableHeaders(DATA_HEADERS, t)}
        rowKey={(record, index) => `${record.id}_${index}`}
        dataSource={prepareData()}
        pagination={false}
        loading={deliveryRelationshipsLoading}
      />
      <EditDeliveryRelationship
        deliveryRelationship={deliveryRelationship}
        handleClose={() => setDeliveryRelationship(null)}
      />
    </>
  );
};

MeteringSiteDeliveryRelationships.displayName = 'MeteringSiteDeliveryRelationships';
MeteringSiteDeliveryRelationships.propTypes = {
  meteringSiteId: PropTypes.string.isRequired,
};

export default MeteringSiteDeliveryRelationships;
