const MAX_DAYS_IN_RANGE = 61;
const MAX_MONTH_IN_RANGE = 12;

const DATE_TYPES = {
  START: 'start',
  END: 'end',
};

const RESOLUTIONS = {
  DAY: 'day',
  MONTH: 'month',
};

const RESOLUTION_NAMES = Object.values(RESOLUTIONS);

export {
  DATE_TYPES,
  MAX_DAYS_IN_RANGE,
  MAX_MONTH_IN_RANGE,
  RESOLUTION_NAMES,
  RESOLUTIONS,
};
