import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
  DATE_TYPES,
  RESOLUTIONS,
  MAX_DATE_RANGE,
  MAX_MONTH_RANGE,
} from '../constants/meteringSites';

const moment = extendMoment(Moment);
/**
 * Verifies if current date is disabled or not based on
 * the selected resolution
 *
 * @param {moment} currentDate - current date
 * @param {moment} start - selected start date
 * @param {String} type - date type(start or end)
 * @param {String} resolution - Selected resolution
 */
const disabledEndDate = (currentDate, start, type, resolution) => {
  if (
    start === null
    || type === DATE_TYPES.START
    || resolution === RESOLUTIONS.HOUR
  ) return false;

  const maxAllowedDate = start.clone();
  if (resolution === RESOLUTIONS.DAY) {
    maxAllowedDate.add(MAX_DATE_RANGE, 'days');
  }

  if (resolution === RESOLUTIONS.MONTH) {
    maxAllowedDate.add(MAX_MONTH_RANGE, 'months');
  }

  const range = moment().range(start, maxAllowedDate);

  return !range.contains(currentDate, { excludeEnd: true });
};

/**
 * Return start date of a month
 *
 * @param {moment} date
 */
const getStartOfMonth = date => (
  date === null ? null : date.clone().startOf('month')
);

/**
 * Return end date of a month
 *
 * @param {moment} date
 */
const getEndOfMonth = date => (
  date === null ? null : date.clone().endOf('month')
);

const getAnalyserName = ({ analyserId = '' } = {}) => analyserId.replace(/_/g, ' ');

export {
  disabledEndDate,
  getStartOfMonth,
  getEndOfMonth,
  getAnalyserName,
};
