import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import { API } from 'aws-amplify';

import ModalWrapper from '../../components/ModalWrapper';
import UserAttributeForm from './userAttributeForm';

import './index.less';

const STATE = {
  MAIN: 'MAIN',
  CHANGE_EMAIL: 'email',
  CHANGE_PHONE: 'phone',
};

const Settings = ({ history }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(STATE.MAIN);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  // Updating the email address or the phone number won't update current session,
  // so the real data needs to be fetched from the backend.
  const getUserInfo = async () => {
    setLoading(true);
    const userInfo = await API.get('FINTSO', '/users/self');
    setUser(userInfo);
    setLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const renderButton = newState => (
    <small>
      <Button
        className="link"
        type="link"
        onClick={() => setState(newState)}
      >
        {t('common.button.change')}
      </Button>
    </small>
  );

  const UserInfo = () => (
    <>
      <h2>User information</h2>
      <h3>{t('common.user.name')}</h3>
      <p>{`${user.firstname} ${user.lastname}`}</p>

      <h3>
        {t('common.user.email')}
        {renderButton(STATE.CHANGE_EMAIL)}
      </h3>
      <p>{user.email}</p>

      <h3>
        {t('common.user.phone')}
        {renderButton(STATE.CHANGE_PHONE)}
      </h3>
      <p>{user.phone}</p>
    </>
  );

  const goBack = () => setState(STATE.MAIN);

  const Content = () => {
    switch (state) {
      case STATE.CHANGE_EMAIL:
      case STATE.CHANGE_PHONE:
        return (
          <UserAttributeForm
            attributeName={state}
            goBack={goBack}
            onUpdate={getUserInfo}
            user={user}
          />
        );
      case STATE.MAIN:
      default:
        return <UserInfo />;
    }
  };

  const handleClose = () => history.goBack();
  const button = (
    <Button key="handleClose" onClick={handleClose}>
      {t('common.button.close')}
    </Button>
  );

  return (
    <ModalWrapper
      modalClassName="settings"
      title={t('settings.title')}
      actionButtons={[button]}
      handleClose={handleClose}
      isLoading={loading}
    >
      <Content />
    </ModalWrapper>
  );
};

Settings.displayName = 'Settings';
Settings.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(Settings);
