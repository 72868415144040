import React from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'antd';
import { withTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import './SummaryTable.less';

import getTranslatedTableHeaders from '../../utils/translationHelpers';
import {
  calculateObjValues,
  getTotalValue,
  getSignedValue,
} from '../../utils/balanceGroupStatsHelpers';
import StatsTitle from './StatsTitle';
import { getDate } from '../../utils/dateTimeHelpers';
import { UNITS_EXTENDED } from '../../constants/units';

const getDataHeaders = type => ([
  {
    title: type === 'net' ? 'net' : 'amount',
    dataIndex: 'amount',
    key: 'amount',
  }, {
    title: 'interConnectionPoint',
    dataIndex: 'interConnectionPoint',
    key: 'interConnectionPoint',
  },
]);

const remappedData = (data, unit, t, type) => {
  const convertedData = calculateObjValues(data, unit);
  const total = getTotalValue(convertedData, unit);
  const amount = { ...convertedData, total };

  return (
    Object.keys(amount).map(key => ({
      key,
      amount: getSignedValue(amount[key], type),
      interConnectionPoint: key === 'total' && type !== 'net'
        ? t(`balanceContainer.label.${key}${capitalize(type)}`)
        : t(`balanceContainer.label.${key}`),
    }))
  );
};

const renderContent = (type, data, unit, isLoading, t) => (
  (!isLoading && data === null)
    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    : (
      <Table
        className={`stats-summary-table-${type}`}
        data-testid={`stats-summary-table-${type}`}
        columns={getTranslatedTableHeaders(getDataHeaders(type), t)}
        dataSource={isLoading ? [] : remappedData(data, unit, t, type)}
        pagination={false}
        loading={isLoading}
        rowKey={(record, index) => `${record.id}_${index}`}
      />
    )
);

const SummaryTable = (props) => {
  const {
    data,
    isLoading,
    type,
    t,
    selectedDate,
    unit,
  } = props;

  return (
    <>
      <StatsTitle
        title={t(`balanceContainer.title.${type}`)}
        description={t('balanceContainer.label.forGasDay', { selectedDate: getDate(selectedDate) })}
      />

      <div className="stats-summary-table">
        {renderContent(type, data, unit, isLoading, t)}
      </div>
    </>
  );
};

SummaryTable.displayName = 'SummaryTable';
const entryType = PropTypes.exact({
  biogas: PropTypes.string,
  import: PropTypes.string,
  acquisitions: PropTypes.string,
});
const exitType = PropTypes.exact({
  energy: PropTypes.string,
  export: PropTypes.string,
  surrenders: PropTypes.string,
});
const netValueType = PropTypes.exact({
  domesticEntryExit: PropTypes.string,
  vtp: PropTypes.string,
  importsExports: PropTypes.string,
});
SummaryTable.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(['entry', 'exit', 'net']).isRequired,
  data: PropTypes.oneOfType([entryType, exitType, netValueType]),
  selectedDate: PropTypes.string.isRequired,
  unit: PropTypes.oneOf(Object.values(UNITS_EXTENDED)),
};
SummaryTable.defaultProps = {
  data: null,
  isLoading: false,
  unit: UNITS_EXTENDED.MWH,
};

export default withTranslation()(SummaryTable);
export {
  SummaryTable as PureComponent,
};
