import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Layout,
  Row,
} from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import Logo from '../../components/Logo';
import RegistrationForm from '../../components/RegistrationForm';
import RegistrationSuccess from '../../components/RegistrationSuccess';

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleStateUpdate = this.handleStateUpdate.bind(this);
    this.state = {
      isRegistrationSuccesful: false,
    };
  }

  /**
   * Updates `isRegistrationSuccesful` in `this.state`
   * @param { Boolean } status - Value for `this.state.isRegistrationSuccesful`
   */
  handleStateUpdate = (status) => {
    this.setState({
      isRegistrationSuccesful: status,
    });
  }

  /**
   * Render registration form
   * @returns { JSX } - Result of rendering
   */
  renderRegistreationForm = () => {
    const { t } = this.props;
    return (
      <Layout className="register__layout">
        <Layout className="register__layout__form-wrapper">
          <Logo
            className="register__layout__form-logo"
            dark
          />
          <Row className="register__layout__form-header">
            <Col span={5} />
            <Col
              span={14}
              align="center"
            >
              <h1>{t('register.title.registerForMarketParty')}</h1>
            </Col>
            <Col span={5} />
          </Row>
          <RegistrationForm isRegistrationSuccesful={this.handleStateUpdate} />
        </Layout>
      </Layout>
    );
  }

  render = () => {
    const { isRegistrationSuccesful } = this.state;
    return (
      <>
        {
          isRegistrationSuccesful
            ? (<RegistrationSuccess />)
            : this.renderRegistreationForm()
        }
      </>
    );
  }
}

Register.displayName = 'Register';
Register.propTypes = {
  t: PropTypes.func.isRequired,
};
Register.contextType = Context;

export default withTranslation()(Register);
export {
  Register as PureComponent,
};
