import React from 'react';
import { useTranslation } from 'react-i18next';

const Survey = () => {
  const { t } = useTranslation();

  const FEEDBACK_URL = 'https://fi.surveymonkey.com/r/Gasgrid';

  return (
    <div className="feedback">
      <a
        href={FEEDBACK_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('app.survey')}
      </a>
    </div>
  );
};

export default Survey;
