import React from 'react';
import {
  Col,
  Descriptions,
  Divider,
  Form,
  Icon,
  InputNumber,
  Row,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { LOCATION_POINTS } from '../../constants/nominations';
import {
  getGasDay,
  getHourlyTimesInGasDay,
  hoursInGasDay,
  isGasDayToday,
  startOfGasDay,
  startOfOngoingGasDay,
} from '../../utils/gasday';
import { getEarliestValidTime } from '../../utils/nominationHelpers';
import { formatDate, localeDateFormat } from '../../utils/i18n';
import './index.less';
import MarketParty from '../../containers/Nominations/marketParty';
import { sum } from '../../utils/math';

const MatchNominationForm = (props) => {
  const {
    t,
    nomination,
    nomination: {
      date = getGasDay(startOfOngoingGasDay()),
      shipperEIC,
    },
    nominationData,
    onChange,
  } = props;


  const getGasHours = (column) => {
    const amountTimesAndHours = getHourlyTimesInGasDay(date);

    switch (column) {
      case 'left':
        return amountTimesAndHours.slice(0, 12);
      case 'right':
        return amountTimesAndHours.slice(12);
      default:
        return [];
    }
  };

  const renderError = (hasError, field, showFieldName = true) => (
    hasError && (
      <p className="match-nomination-form__details__error">
        {`${t('common.notifications.incorrectInput')}
        ${showFieldName ? t(`nominations.label.${field}`) : ''}`}
      </p>
    ));

  const renderHourlyAmountLabel = (hour) => {
    const { date: nomDate } = nomination || { date: '' };
    let formattedDate = '';

    if (nomDate && hour === '07:00') {
      formattedDate = formatDate(nomDate);
    }
    if (nomDate && hour === '00:00') {
      formattedDate = moment(nomDate).add(1, 'days').format(localeDateFormat());
    }

    return (
      <div className="match-nomination-form__amounts__item__label">
        {hour}
        <span>
          &nbsp;
          {formattedDate}
        </span>
      </div>
    );
  };

  const copyOverAmounts = (copyAmountHourIndex) => {
    const {
      nominationData: { matchedAmounts },
    } = props;
    const hours = hoursInGasDay(date);
    const valueToCopy = matchedAmounts[copyAmountHourIndex];

    for (let hourIndex = 0; hourIndex < hours; hourIndex += 1) {
      if (copyAmountHourIndex <= hourIndex) {
        onChange(hourIndex, valueToCopy);
      } else {
        // no change
      }
    }
  };

  const renderCopyIcon = (index) => (
    (
      <Tooltip
        placement="bottomRight"
        title={t(
          'nominations.label.hoverText',
          { hoursInGasDay: hoursInGasDay(date) },
        )}
      >
        <Icon onClick={() => copyOverAmounts(index)} type="copy" />
      </Tooltip>
    )
  );

  const renderMatchedFields = (column) => {
    const {
      nomination: {
        amounts, point, matchedAmounts: currentMatchedAmounts = [],
      },
      nominationData: { matchedAmounts = [] },
      errors,
    } = props;
    const isVTP = point === LOCATION_POINTS.VTP;
    const earliestValidTime = getEarliestValidTime();
    const firstValidTimeForGasDay = isVTP || earliestValidTime.isSameOrBefore(startOfGasDay(date))
      ? startOfGasDay(date)
      : earliestValidTime;

    return getGasHours(column).map(([amountTime, amountTimeFormatted, hour], index) => {
      const amountIndex = column === 'left' ? index : (12 + index);
      const hasError = errors.includes(amountTimeFormatted);

      const updateDisabled = (isVTP && isGasDayToday(date))
        ? false
        : amountTime.isBefore(earliestValidTime);

      const isSameTime = amountTime.isSame(firstValidTimeForGasDay);
      const copyIcon = isSameTime && !updateDisabled
        ? renderCopyIcon(amountIndex)
        : '';

      const currentMatchedAmount = currentMatchedAmounts[amountIndex];
      const matchedAmount = matchedAmounts[amountIndex];
      const nomintAmount = amounts[amountIndex];

      // Use previously matched value, or if not available, default to nomination value
      const inputValueMatchedAmount = matchedAmount !== undefined
        ? matchedAmount : currentMatchedAmount || nomintAmount || 0;

      return (
        <div key={`${column}-${amountTimeFormatted}-wrapper`}>
          <Form.Item
            label={renderHourlyAmountLabel(hour)}
            key={`${column}-${amountTimeFormatted}-item`}
            className={classNames(
              'match-nomination-form__amounts__item',
              { 'has-error': hasError },
              { 'match-nomination-form__amounts__item--pasthour': updateDisabled },
            )}
            colon={false}
          >
            <span
              className={classNames(
                'ant-form-text',
                'match-nomination-form__amounts__span',
              )}
            >
              {`${nomintAmount || 0}`}
            </span>
            <span
              className={classNames(
                'ant-form-text',
                'match-nomination-form__amounts__span',
              )}
            >
              {`${currentMatchedAmount !== undefined ? currentMatchedAmount : '-'}`}
            </span>
            <InputNumber
              className={classNames(
                'match-nomination-form__amounts__input',
                `match-nomination-form-${amountTimeFormatted}__input`,
              )}
              data-testid={`match-nomination-form__${column}-${amountTimeFormatted}__select`}
              id={`${column}-${amountTimeFormatted}`}
              name={amountTimeFormatted}
              key={`${amountTimeFormatted}-input`}
              min={0}
              value={inputValueMatchedAmount}
              onChange={value => onChange(amountIndex, value)}
            />
            {copyIcon}
            {renderError(hasError, amountTimeFormatted, false)}
          </Form.Item>
          <Divider key={`nomination-amount-${hour}-divider`} />
        </div>
      );
    });
  };

  const getSum = (arrayOfNumbers) => {
    if (arrayOfNumbers && arrayOfNumbers.length) {
      return sum(...arrayOfNumbers);
    }
    return 0;
  };

  const renderTotalValues = () => {
    const { amounts, matchedAmounts: currentMatchedAmounts } = nomination;
    const { matchedAmounts = [] } = nominationData;
    const matchedAmountsWithDefaults = amounts.map((amount, index) => (
      matchedAmounts[index] || amount || 0
    ));
    const totalValues = [
      { value: getSum(amounts), title: t('nominations.label.totalNomint') },
      { value: getSum(currentMatchedAmounts), title: t('nominations.label.totalMatched') },
      { value: getSum(matchedAmountsWithDefaults), title: t('nominations.label.totalNewMatch') },
    ];

    return totalValues.map(({ title, value }) => (
      <Descriptions key={title} title={title}>
        <Descriptions.Item>
          {value}
        </Descriptions.Item>
      </Descriptions>
    ));
  };

  return (
    <Row className="match-nomination-form">
      <Row className="match-nomination-form__title-labels">
        <h3>
          <MarketParty eic={shipperEIC || ''} />
        </h3>
        {renderTotalValues()}
      </Row>
      <Row>
        <Col span={11}>
          <Row>
            <Col span={7}>
              <h4>{t('nominations.title.hour')}</h4>
            </Col>
            <Col span={17}>
              <h4>
                {`${t('nominations.title.nominationMatchingGasAmount')}
                (${t('nominations.label.unit')})`}
              </h4>
            </Col>
          </Row>
          <Divider key="nomination-amounts-left-header" />
          {renderMatchedFields('left')}
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Row>
            <Col span={7}>
              <h4>{t('nominations.title.hour')}</h4>
            </Col>
            <Col span={17}>
              <h4>
                {`${t('nominations.title.nominationMatchingGasAmount')}
                (${t('nominations.label.unit')})`}
              </h4>
            </Col>
          </Row>
          <Divider key="nomination-amounts-right-header" />
          {renderMatchedFields('right')}
        </Col>
      </Row>
    </Row>
  );
};

const nominationPropType = PropTypes.shape({
  shipperEIC: PropTypes.string,
  status: PropTypes.string,
  point: PropTypes.string,
  nomresAmounts: PropTypes.object,
  matchedAmounts: PropTypes.arrayOf(
    PropTypes.number,
  ),
  direction: PropTypes.string,
  date: PropTypes.string,
  amounts: PropTypes.arrayOf(
    PropTypes.number,
  ),
});
MatchNominationForm.propTypes = {
  t: PropTypes.func.isRequired,
  nomination: nominationPropType,
  nominationData: nominationPropType,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MatchNominationForm.defaultProps = {
  nomination: null,
  nominationData: {},
};

export default MatchNominationForm;
