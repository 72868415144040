import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';

const log = new Logger('context:invoices');

async function updateInvoices(start) {
  const {
    invoices: existingInvoices,
    isAdmin,
    isReadOnlyAdmin,
    selectedMarketPartyId,
  } = this.state;

  if (isAdmin() || isReadOnlyAdmin()) return;

  try {
    const { items: invoices } = await API.get(
      'FINTSO',
      `/marketparties/${selectedMarketPartyId}/invoices`,
      {
        queryStringParameters: {
          start,
        },
      },
    );
    this.setState({
      invoices: {
        ...existingInvoices,
        [start]: invoices,
      },
    });
    log.info(`invoices for ${start} updated with data:`, invoices);
  } catch (error) {
    const { t } = this.props;
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingInvoices'),
      description: createErrorMessage(error),
    });
    log.error(`error fetching invoices for ${start}:`, createErrorMessage(error, true));
  }
}

const invoicesContext = {
  invoices: {},
  updateInvoices: () => {},
};

const functions = {
  updateInvoices,
};

const invoices = {
  ...invoicesContext,
  ...functions,
};

export {
  invoicesContext,
  invoices,
};
