import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  DatePicker,
} from 'antd';

import './StatsDatePicker.less';

const getNewDate = (type, selectedDate, resolution = 'day') => {
  switch (type) {
    case 'previous':
      return moment.utc(selectedDate).subtract('1', resolution);
    case 'next':
      return moment.utc(selectedDate).add('1', resolution);
    default:
      return moment.utc(selectedDate);
  }
};

const renderCalendarNavigator = (type, {
  t, selectedDate, onSelectedDateChange, disabledDate, resolution,
}) => {
  const date = getNewDate(type, selectedDate, resolution);
  return (
    <button
      className={classNames(
        'stats-date-picker__navigator',
        `stats-date-picker__navigator--${type}`,
        { 'stats-date-picker__navigator--hidden': disabledDate(date) },
      )}
      type="button"
      onClick={() => onSelectedDateChange(date)}
    >
      {t(`balanceContainer.label.${type}`)}
    </button>
  );
};

const StatsDatePicker = (props) => {
  const {
    disabledDate,
    onSelectedDateChange,
    selectedDate,
    t,
    title,
    resolution,
  } = props;

  let Picker;
  switch (resolution) {
    case 'month':
      Picker = DatePicker.MonthPicker;
      break;
    case 'day':
    default:
      Picker = DatePicker;
  }

  return (
    <div className="stats-date-picker">
      <span className="stats-date-picker__text">
        {
          !title
            ? `${t('balanceContainer.label.statsChooseDate')}:`
            : title
        }
      </span>
      {renderCalendarNavigator('previous', props)}
      <Picker
        className="stats-date-picker__calendar"
        format={moment.localeData().longDateFormat('L')}
        onChange={onSelectedDateChange}
        value={selectedDate}
        disabledDate={disabledDate}
      />
      {renderCalendarNavigator('next', props)}
    </div>
  );
};

StatsDatePicker.propTypes = {
  disabledDate: PropTypes.func,
  onSelectedDateChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.objectOf(moment).isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  resolution: PropTypes.oneOf(['day', 'month']),
};
StatsDatePicker.defaultProps = {
  disabledDate: () => false,
  title: null,
  resolution: 'day',
};

export default withTranslation()(StatsDatePicker);
export {
  StatsDatePicker as PureComponent,
  getNewDate,
  renderCalendarNavigator,
};
