import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { combine } from '../../utils/nominationHelpers';
import { getGasDay } from '../../utils/gasday';
import { getTotals, getHeaders } from './util';
import { LOCATION_POINT_NAMES, DIRECTION_NAMES } from '../../constants/nominations';
import { minutes } from '../../utils/interval';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';
import Context from '../../context';
import getExpandIcon from '../ExpandIcon';
import NominationsTable from '../../containers/Nominations/nominationsTable';
import SummaryTable from '../SummaryTable';
import { pickUniqIf } from '../../utils/array';

import DateRangePicker from '../DateRangePicker';
import { getMarketPartyFromContextById } from '../../utils/marketparty';

const MAX_DAYS = 31;
const UPDATE_INTERVAL = minutes(5);

const pickEICs = array => pickUniqIf(array, 'eic2', item => item.eic2);

const NominationsSummary = (props) => {
  const middleOfCurrentGasDay = moment.utc().hour(12).startOf('hour');
  const defaultStart = middleOfCurrentGasDay.clone().subtract(1, 'day');
  const defaultEnd = middleOfCurrentGasDay.clone().add(1, 'day');

  const {
    canCreateNomination,
    canViewNomination,
    selectedUnit,
    setSelectedUnit,
    setNominationData,
    setNominationKey,
  } = props;

  const context = useContext(Context);
  const {
    marketPartyIndex,
    nomints,
    nomreses,
    isAdmin,
    isReadOnlyAdmin,
    isNomintsLoaded,
    isNomresesLoaded,
    updateActiveBalanceGroupId,
    selectedMarketPartyId,
    updateNomints,
    updateNomreses,
  } = context;
  const isLoading = !isNomintsLoaded || !isNomresesLoaded;

  const { t } = useTranslation();

  const [interval, setIntervalState] = useState();
  const [selectedPoints, setSelectedPoints] = useState(LOCATION_POINT_NAMES);
  const [selectedDirections, setSelectedDirections] = useState(DIRECTION_NAMES);
  const [selectedNames, setSelectedNames] = useState([]);
  const [range, setRange] = useState([defaultStart, defaultEnd]);
  const [start, end] = range.map(getGasDay);

  const getMarketPartyById = (id) => (getMarketPartyFromContextById(context, id));
  const nominations = useMemo(() => combine(nomints, nomreses, getMarketPartyById), [nomints, nomreses]);
  const allEICs = useMemo(() => pickEICs(nominations), [nominations]);
  const counterParties = marketPartyIndex.reduce(
    (result, marketParty) => (allEICs.includes(marketParty.eic)
      ? { ...result, [marketParty.name]: marketParty.eic }
      : result
    ),
    {},
  );
  const selectedEICs = selectedNames.map(name => counterParties[name]);

  const fetchData = () => {
    updateActiveBalanceGroupId();
    if (!(isAdmin() || isReadOnlyAdmin())) {
      updateNomints(start, end);
      updateNomreses(start, end);
    }
  };

  useEffect(() => {
    fetchData();
    clearInterval(interval);
    const newInterval = setInterval(fetchData, UPDATE_INTERVAL);
    setIntervalState(newInterval);
    return () => clearInterval(newInterval);
  }, [selectedMarketPartyId, start, end]);

  const handleDatesSelected = (startDate, endDate) => {
    setRange([startDate, endDate]);
  };

  const renderOptions = (options, translate = true) => (
    options.map(option => (
      <Select.Option key={option} value={option}>
        {translate ? t(`common.label.${option}`) : option}
      </Select.Option>
    ))
  );

  const headerActions = (
    <>
      <DateRangePicker
        maxDays={MAX_DAYS}
        onValidDateSelected={handleDatesSelected}
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
      />
      <div className="separator" />
      <div className="unit-selector">
        <div className="title">
          {t('common.label.unit')}
        </div>
        <Select
          defaultValue={selectedUnit}
          onChange={setSelectedUnit}
        >
          {renderOptions(UNIT_EXTENDED_NAMES)}
        </Select>
      </div>
      <div className="separator" />
      <div className="point-selector">
        <div className="title">
          {t('common.label.point')}
        </div>
        <Select
          mode="multiple"
          value={selectedPoints}
          onChange={items => setSelectedPoints(items.length ? items : selectedPoints)}
        >
          {renderOptions(LOCATION_POINT_NAMES)}
        </Select>
      </div>
      <div className="separator" />
      <div className="direction-selector">
        <div className="title">
          {t('common.label.direction')}
        </div>
        <Select
          mode="multiple"
          value={selectedDirections}
          onChange={items => setSelectedDirections(items.length ? items : selectedDirections)}
        >
          {renderOptions(DIRECTION_NAMES)}
        </Select>
      </div>
      <div className="separator" />
      <div className="counter-party-selector">
        <div className="title">
          {t('common.label.counterParties')}
        </div>
        <Select
          allowClear
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={20}
          placeholder={t('nominations.label.selectCounterParties')}
          value={selectedNames}
          onChange={setSelectedNames}
        >
          {renderOptions(Object.keys(counterParties), false)}
        </Select>
      </div>
    </>
  );

  const nominationHasSelectedPoint = (nomination) => selectedPoints.includes(nomination.point);
  const nominationHasSelectedDirection = (nomination) => selectedDirections.includes(nomination.direction);
  const nominationHasSelectedCounterparty = (nomination) => (
    !nomination.eic2 || (!selectedEICs.length || selectedEICs.includes(nomination.eic2))
  );

  const filteredNominations = nominations.filter(nomination => (
    nominationHasSelectedPoint(nomination)
    && nominationHasSelectedDirection(nomination)
    && nominationHasSelectedCounterparty(nomination)
  ));
  const nominationsByDate = groupBy(filteredNominations, 'date');
  const expandIcon = getExpandIcon(record => !!nominationsByDate[record.date]);

  const isFiltered = selectedNames.length > 0
  || selectedPoints.length !== LOCATION_POINT_NAMES.length
  || selectedDirections.length !== DIRECTION_NAMES.length;

  /* eslint-disable react/prop-types */
  const expandedRowRender = ({ date }) => (
    <NominationsTable
      nominations={nominationsByDate[date]}
      canCreateNomination={canCreateNomination}
      canViewNomination={canViewNomination}
      isLoading={isLoading}
      unit={selectedUnit}
      setNominationData={setNominationData}
      setNominationKey={setNominationKey}
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <div className="nominations-summary__tables">
      <SummaryTable
        title=""
        tableHeaders={getHeaders(selectedUnit, selectedPoints, selectedDirections)}
        stackedHeader
        tableData={getTotals(nominationsByDate, start, end, isFiltered)}
        headerActions={headerActions}
        rowKey={r => r.date}
        isLoading={isLoading}
        expandIcon={expandIcon}
        expandedRowRender={expandedRowRender}
      />
    </div>
  );
};

NominationsSummary.propTypes = {
  canCreateNomination: PropTypes.bool.isRequired,
  canViewNomination: PropTypes.bool.isRequired,
  selectedUnit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
  setSelectedUnit: PropTypes.func.isRequired,
  setNominationData: PropTypes.func.isRequired,
  setNominationKey: PropTypes.func.isRequired,
};

NominationsSummary.displayName = 'NominationsSummary';
export default NominationsSummary;
