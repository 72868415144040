import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';

import { createErrorMessage } from './globalStateHelper';
import { userHasPermission } from '../utils/userHelpers';
import { RELATIONSHIPS_READ_PERMISSIONS } from '../constants/users';

const log = new Logger('context:relationships');

async function updateRelationships() {
  this.setState({ loadingRelationships: true });

  try {
    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const relationshipsUrl = isAdmin() || isReadOnlyAdmin()
      ? '/admin/marketpartyrelationships'
      : `/marketparties/${selectedMarketPartyId}/relationships`;

    const { items: relationships } = (
      isAdmin()
      || isReadOnlyAdmin()
      || userHasPermission(this.state, RELATIONSHIPS_READ_PERMISSIONS)
    )
      ? await API.get('FINTSO', relationshipsUrl)
      : { items: [] };

    this.setState({
      relationships,
      loadingRelationships: false,
    });
    log.info('market party relationships updated with data:', relationships);
  } catch (error) {
    const { t } = this.props;

    this.setState({
      relationships: [],
      loadingRelationships: false,
    });

    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingRelationships'),
      description,
    });
    log.error('error fetching market party relationships:', error.message, description);
  }
}

const relationshipsContext = {
  loadingRelationships: false,
  relationships: null,
  updateRelationships: () => {},
};

const functions = {
  updateRelationships,
};

const relationships = {
  ...relationshipsContext,
  ...functions,
};

export {
  relationshipsContext,
  relationships,
};
