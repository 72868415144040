import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { add } from '../../utils/math';
import { calculate } from '../../utils/capacityAndNominationsHelper';
import { createKey } from '../../utils/nominationHelpers';
import { dateSort, naturalSort } from '../../utils/sort';
import { formatNumber } from '../../utils/i18n';
import { getDateAndTime, getDate } from '../../utils/dateTimeHelpers';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';
import Context from '../../context';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import ModalWrapper from '../../components/ModalWrapper';
import StatusIcon from '../../components/StatusIcon';
import MarketParty from './marketParty';
import { getMarketPartyEIC } from '../../utils/marketparty';


const NominationInfo = (props) => {
  const { nominationKey, unit } = props;
  const context = useContext(Context);
  const { nomints, isReadOnlyAdmin } = context;
  const history = useHistory();
  const { t } = useTranslation();

  const nomintWithEIC = (nomint) => ({
    ...nomint,
    eic1: getMarketPartyEIC(context, nomint.marketPartyId),
  });

  const relatedNomints = nomints.filter((nomint) => (createKey(nomintWithEIC(nomint)) === nominationKey));
  const relatedNomintsRemap = relatedNomints.map(n => (n.status === 'accepted' ? { ...n, status: 'received' } : n));

  const renderMeteringPointWithDirection = nomination => (
    <>
      <div className="nominations__table__metering-point">
        {
          nomination.point !== undefined
            ? t(`nominations.label.${nomination.point}-${nomination.direction}`)
            : ''
        }
      </div>
    </>
  );

  const columns = [
    {
      title: 'sent',
      dataIndex: 'createdAt',
      render: (date, nomint) => (
        (
          <>
            {getDateAndTime(date, 'nominations__table__sent')}
            {(isReadOnlyAdmin(true))
              && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                <span
                  role="link"
                  className="nominations__table__nomint-id clipboard-copy"
                  onClick={() => (navigator.clipboard.writeText(nomint.id))}
                >
                  {nomint.id.slice(-12)}
                </span>
              )
            }
          </>
        )
      ),
      sorter: (a, b) => dateSort(a.createdAt, b.createdAt),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'marketParty',
      dataIndex: 'marketPartyId',
      render: id => <MarketParty id={id || ''} />,
    },
    {
      title: 'counterParty',
      dataIndex: 'shipperEIC',
      render: eic => <MarketParty eic={eic || ''} />,
    },
    {
      title: 'acknowledgedByTSO',
      dataIndex: 'receivedAt',
      render: date => getDateAndTime(date, 'nominations__table__acknowledged'),
      sorter: (a, b) => dateSort(a.receivedAt, b.receivedAt),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'pointDirection',
      dataIndex: 'point',
      render: (text, record) => renderMeteringPointWithDirection(record),
    },
    {
      title: 'gasDay',
      dataIndex: 'date',
      render: date => getDate(date),
    },
    {
      title: {
        key: 'gasAmount',
        interpolations: {
          unit: t(`common.label.${unit}`),
        },
      },
      key: 'gasAmount',
      render: ({ amounts }) => formatNumber(calculate(amounts.reduce(add), unit)),
    },
    {
      title: 'received',
      key: 'status',
      render: stat => <StatusIcon status={stat.status} />,
      sorter: (a, b) => naturalSort(a.status, b.status),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const handleClose = () => history.goBack();

  const closeButton = (
    <Button
      key="close"
      type="primary"
      onClick={handleClose}
    >
      {t('common.button.close')}
    </Button>
  );

  return (
    <ModalWrapper
      modalClassName="nominations-information"
      title={t('nominations.title.nominationInformation')}
      actionButtons={[closeButton]}
      handleClose={handleClose}
      width="80vw"
    >
      <Row>
        <Table
          className="summarytable__nomint"
          dataSource={relatedNomintsRemap}
          columns={getTranslatedTableHeaders(columns, t)}
          pagination={false}
          rowKey={(r, i) => i}
        />
      </Row>
    </ModalWrapper>
  );
};

NominationInfo.propTypes = {
  nominationKey: PropTypes.string.isRequired,
  unit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
};

export default NominationInfo;
