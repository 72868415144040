import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import Decimal from 'decimal.js';
import { formatNumber, formatPrice } from '../../utils/i18n';
import { UNITS } from '../../constants/units';
import Loading from '../../components/Loading';
import SummaryTable from '../../components/SummaryTable';
import {
  BALANCE_RESPONSIBLE_PRODUCTS,
  BALANCE_RESPONSIBLE_CORR_PRODUCTS,
  SHIPPER_PRODUCTS,
  SHIPPER_CORR_PRODUCTS,
  TAX_PRODUCTS,
  TAX_CORR_PRODUCTS,
  INVOICE_CATEGORY,
} from './constants';
import invoiceProp from './proptypes';

const getValue = (invoiceRow, key) => {
  const { invoice, estimatedInvoice } = invoiceRow;
  if (estimatedInvoice) {
    return estimatedInvoice[key];
  }
  return invoice[key];
};

const getInvoiceHeaders = t => [
  {
    title: 'product',
    key: 'product',
    render: (item) => {
      const estimated = !!item.estimatedInvoice;
      const className = estimated ? 'invoice__estimate' : '';
      return (
        <span className={className}>
          {t(`invoices.product.${item.productCode}`)}
          {estimated && (
            <span className="invoice__estimate--information">
              {t('invoices.label.estimated')}
            </span>
          )}
        </span>
      );
    },
  },
  {
    title: 'volume',
    key: 'volume',
    align: 'right',
    render: item => formatNumber(getValue(item, 'volume'), 2, 2),
  },
  {
    title: 'unit',
    key: 'unit',
    render: () => t(`common.label.${UNITS.MWH}`),
  },
  {
    title: 'price',
    key: 'price',
    render: item => formatNumber(getValue(item, 'price'), 2, 2),
  },
  {
    title: 'total',
    key: 'total',
    render: item => formatPrice(getValue(item, 'charge')),
  },
];

const getProductCategory = (productCode) => {
  if (BALANCE_RESPONSIBLE_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.IMBALANCE;
  }
  if (SHIPPER_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.SHIPPER;
  }
  if (TAX_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.TAXES;
  }
  if (BALANCE_RESPONSIBLE_CORR_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.IMBALANCE_CORR;
  }
  if (SHIPPER_CORR_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.SHIPPER_CORR;
  }
  if (TAX_CORR_PRODUCTS.includes(productCode)) {
    return INVOICE_CATEGORY.TAXES_CORR;
  }
  return INVOICE_CATEGORY.UNKNOWN;
};

const addEstimate = (invoiceRow) => {
  const { invoice, values } = invoiceRow;
  if (!isEmpty(invoice) || !values.length) return invoiceRow;

  const estimate = values.reduce(
    (result, value) => ({
      price: Decimal.add(result.price || 0, value.price),
      volume: Decimal.add(result.volume || 0, value.volume),
      charge: Decimal.add(result.charge || 0, value.charge),
    }), {},
  );

  if (estimate.charge.isZero()) return invoiceRow;

  return {
    ...invoiceRow,
    estimatedInvoice: {
      price: estimate.price.div(values.length).toString(),
      volume: estimate.volume.toString(),
      charge: estimate.charge.toString(),
    },
  };
};

const categorize = invoices => invoices.reduce((categories, invoiceRow) => {
  const category = getProductCategory(invoiceRow.productCode);
  const invoiceWithEstimate = addEstimate(invoiceRow);
  const { invoice, estimatedInvoice } = invoiceWithEstimate;

  return !isEmpty(invoice) || !!estimatedInvoice
    ? {
      ...categories,
      [category]: categories[category].concat(invoiceWithEstimate),
    }
    : categories;
},
{
  [INVOICE_CATEGORY.IMBALANCE]: [],
  [INVOICE_CATEGORY.SHIPPER]: [],
  [INVOICE_CATEGORY.TAXES]: [],
  [INVOICE_CATEGORY.IMBALANCE_CORR]: [],
  [INVOICE_CATEGORY.SHIPPER_CORR]: [],
  [INVOICE_CATEGORY.TAXES_CORR]: [],
  [INVOICE_CATEGORY.UNKNOWN]: [],
});

const InvoiceCategories = (props) => {
  const { displayChart, invoices, isLoading } = props;
  if (isLoading) return <Loading />;

  const { t } = useTranslation();

  const invoicesByCategory = categorize(invoices || []);
  if (![].concat(...Object.values(invoicesByCategory)).length) return <Empty />;

  return Object.entries(invoicesByCategory).map(([category, invoiceRows]) => (
    !!invoiceRows.length && (
      <SummaryTable
        key={category}
        title={t(`invoices.category.${category}`)}
        tableData={invoiceRows}
        tableHeaders={getInvoiceHeaders(t)}
        onSelectRow={displayChart}
      />
    )
  ));
};

InvoiceCategories.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(invoiceProp),
};
InvoiceCategories.defaultProps = {
  invoices: [],
};
export default InvoiceCategories;
export {
  categorize,
  addEstimate,
};
