import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API, Logger } from 'aws-amplify';
import {
  Table,
  Button,
  notification,
  InputNumber,
  Row,
  Col,
} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import moment from 'moment';

import './index.less';

import { createErrorMessage } from '../../context/globalStateHelper';
import { formatDateTimeStr, formatNumber, formatTime } from '../../utils/i18n';
import { calculate } from '../../utils/capacityAndNominationsHelper';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';
import Context from '../../context';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { clamp } from '../../utils/math';
import ModalWrapper, { modalConfirmClose, modalConfirmSave } from '../ModalWrapper';
import HourlyImbalancesChart from './HourlyImbalancesChart';
import StatsDatePicker from '../Stats/StatsDatePicker';
import {
  startOfGasDay,
  startOfOngoingGasDay,
} from '../../utils/gasday';

const log = new Logger('monitoring:HourlyImbalances');

const MAX_PERCENTAGE = 100;
const MIN_PERCENTAGE = -100;

const getClassName = (key, item) => {
  const keys = ['preliminary', 'manual', 'historical'];
  const strongestKey = keys.reduce((a, c) => (Number(item[a]) ? a : c));
  return classNames({
    strongest: key === strongestKey && Number(item[key]),
  });
};

const updateManualEstimates = (summary, estimates, gasDay, imbalanceType) => {
  const dailyValues = summary[gasDay];
  if (!estimates.length) {
    return {
      [gasDay]: dailyValues,
    };
  }

  const updatedValues = dailyValues.map((value) => {
    const estimate = estimates.find(({ timestamp }) => timestamp === value.timestamp);
    return estimate
      ? {
        ...value,
        [imbalanceType]: {
          ...value[imbalanceType],
          manual: `${estimate[imbalanceType]}`,
          manualTimestamp: moment.utc().toISOString(), // TODO: use timestamp from BE response
        },
      }
      : value;
  });

  return {
    [gasDay]: updatedValues,
  };
};

const isWithinGasDay = timestamp => moment
  .utc(timestamp)
  .isSameOrAfter(startOfOngoingGasDay());

const isFutureHour = timestamp => moment.utc(timestamp).isAfter(moment.utc().startOf('hour'));

const HourlyImbalances = ({ imbalanceType, physicalBalanceSummary, unit }) => {
  const { t } = useTranslation();
  const { date } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const context = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment.utc(date));
  const [changedEstimates, setChangedEstimates] = useState({});

  const { isAdmin } = context;

  const getImbalance = item => ({ timestamp: item.timestamp, ...item[imbalanceType] });
  const defaultDataSource = (physicalBalanceSummary[selectedDate.format('YYYY-MM-DD')] || []).map(getImbalance);
  const [dataSource, setDataSource] = useState(defaultDataSource);

  const handleManualEstimateChange = ({ timestamp }) => (value) => {
    setChangedEstimates({ ...changedEstimates, [timestamp]: calculate(value, unit, true) });
  };

  const getInputValue = (item) => {
    const changedEstimate = changedEstimates[item.timestamp];
    const value = changedEstimate !== undefined ? changedEstimate : item.manual;
    return calculate(value, unit);
  };

  const renderItem = key => item => (
    isWithinGasDay(item.timestamp) && key === 'manual' && isAdmin()
      ? (
        <InputNumber
          className="estimate-input"
          min={0}
          onChange={handleManualEstimateChange(item)}
          parser={value => value.replace(/,/g, '.')}
          type="number"
          value={getInputValue(item)}
        />
      )
      : (
        <span className={getClassName(key, item)}>
          {formatNumber(calculate(item[key], unit))}
        </span>
      ));

  const columns = [
    {
      dataIndex: 'timestamp',
      width: '4rem',
      title: 'hour',
      render: formatTime,
    },
    {
      align: 'right',
      width: '10rem',
      title: 'preliminary',
      render: renderItem('preliminary', unit),
    },
    {
      align: 'right',
      width: '10rem',
      title: 'historical',
      render: renderItem('historical', unit),
    },
    {
      align: 'right',
      width: '10rem',
      title: 'manual',
      render: renderItem('manual', unit, handleManualEstimateChange),
    },
    {
      align: 'right',
      width: '10rem',
      title: 'estimatedAt',
      render: ({ manualTimestamp }) => (manualTimestamp ? formatDateTimeStr(manualTimestamp) : '-'),
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    if (!defaultDataSource.length) {
      try {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        const { items } = await API.get('FINTSO', '/admin/dashboard/summary', {
          queryStringParameters: {
            start: formattedDate,
            end: formattedDate,
          },
        });
        setDataSource(items.map(getImbalance));
      } catch (error) {
        notification.error({
          className: 'notification-error',
          message: t(`monitoring.capacityAndNominations.modal.imbalance.${imbalanceType}.fetchError`),
          description: createErrorMessage(error),
        });
        log.error(createErrorMessage(error, true));
        setDataSource([]);
      }
    } else {
      setDataSource(defaultDataSource);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  const isChanged = ([timestamp, value]) => {
    const original = dataSource.find(item => item.timestamp === timestamp);
    return original && Number(original.manual) !== value;
  };

  const estimatesChanged = Object.entries(changedEstimates).some(isChanged);

  const close = () => {
    const [, basePath] = pathname.split('/');
    history.push(`/${basePath}`);
  };

  const handleClose = () => (estimatesChanged ? modalConfirmClose(t, close) : close());

  const save = async () => {
    const { handleDbChange } = context;

    if (!isAdmin()) { return; }

    setSaving(true);

    try {
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const body = Object.entries(changedEstimates)
        .filter(([timestamp, value]) => isWithinGasDay(timestamp) && isChanged([timestamp, value]))
        .map(([timestamp, value]) => ({
          timestamp,
          [imbalanceType]: value,
        }));
      const { items } = await API.patch('FINTSO', '/admin/dashboard/estimates', { body });
      const updated = updateManualEstimates(
        physicalBalanceSummary,
        items,
        formattedDate,
        imbalanceType,
      );
      handleDbChange('Estimates', 'update', updated);
      setDataSource(updated[formattedDate].map(getImbalance));
      setChangedEstimates({});
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: t(`monitoring.capacityAndNominations.modal.imbalance.${imbalanceType}.updateError`),
        description: createErrorMessage(error),
      });
      log.error(createErrorMessage(error, true));
    }

    setSaving(false);
  };

  const actionButtons = [
    <Button
      key="buttonClose"
      onClick={handleClose}
    >
      {t('common.button.close')}
    </Button>,
    <Button
      disabled={!estimatesChanged || !isAdmin()}
      key="buttonSave"
      loading={saving}
      onClick={() => modalConfirmSave(t, save)}
      type="primary"
    >
      {t('common.button.save')}
    </Button>,
  ];

  const handlePercentageChange = (value) => {
    const percentage = new Decimal(clamp(value, MIN_PERCENTAGE, MAX_PERCENTAGE)).div(100).add(1);
    const thingsToEdit = dataSource.filter(item => isFutureHour(item.timestamp));
    const items = thingsToEdit.reduce((estimates, item) => {
      const strongestEstimate = new Decimal(
        calculate(Number(item.manual) || Number(item.historical), unit, true),
      );
      const newEstimate = strongestEstimate.mul(percentage);
      return {
        ...estimates,
        [item.timestamp]: calculate(Number(newEstimate), unit),
      };
    }, {});
    setChangedEstimates({ ...changedEstimates, ...items });
  };

  const renderPercentageChange = () => (
    <div className="change-by-percentage">
      <span>{t('monitoring.capacityAndNominations.modal.imbalance.changeEstimatesBy')}</span>
      <InputNumber
        className="percentage-input"
        defaultValue={0}
        formatter={value => `${value} %`}
        max={MAX_PERCENTAGE}
        min={MIN_PERCENTAGE}
        onChange={handlePercentageChange}
        parser={value => value.replace(/[^0-9\\-]/g, '')}
        disabled={!isAdmin()}
      />
    </div>
  );

  const mergedData = dataSource.map((item) => {
    const manual = changedEstimates[item.timestamp];
    return manual ? {
      ...item,
      manual: `${manual}`,
    } : item;
  });

  const handleSelectedDateChange = (changedDate) => {
    if (!estimatesChanged) {
      setSelectedDate(changedDate);
    } else {
      const cancelFunctionality = () => {
        setChangedEstimates({});
        setSelectedDate(changedDate);
      };
      const saveFunctionality = async () => {
        await save();
        setSelectedDate(changedDate);
      };

      modalConfirmSave(
        t,
        saveFunctionality,
        t('monitoring.capacityAndNominations.datepicker.valueChangedConfirmation'),
        cancelFunctionality,
      );
    }
  };

  const renderContent = () => (
    <Col>
      <Row
        className="hourly-imbalances__datepicker-placeholder"
        type="flex"
        justify="center"
      >
        <StatsDatePicker
          selectedDate={selectedDate}
          onSelectedDateChange={handleSelectedDateChange}
        />
      </Row>
      <Row className="hourly-imbalances__content-placeholder">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
        >
          <Table
            dataSource={dataSource}
            columns={getTranslatedTableHeaders(columns, t)}
            pagination={false}
            rowKey="timestamp"
            scroll={{ y: '55vh' }}
            loading={loading}
          />
          {
            startOfGasDay(selectedDate)
              .isSameOrAfter(startOfOngoingGasDay()) && renderPercentageChange()
          }
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
          className="hourly-imbalances__chart"
        >
          <HourlyImbalancesChart data={mergedData} unit={unit} />
        </Col>
      </Row>
    </Col>
  );

  return (
    <ModalWrapper
      actionButtons={actionButtons}
      handleClose={handleClose}
      modalClassName="hourly-imbalances"
      title={`${t(`monitoring.capacityAndNominations.modal.imbalance.${imbalanceType}.title`)} (${unit})`}
      width="90vw"
    >
      { renderContent() }
    </ModalWrapper>
  );
};

HourlyImbalances.propTypes = {
  physicalBalanceSummary: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        gasDay: PropTypes.string,
        timestamp: PropTypes.string,
        biogas: PropTypes.shape({
          historical: PropTypes.string,
          manual: PropTypes.string,
          preliminary: PropTypes.string,
          manualTimestamp: PropTypes.string,
        }),
        consumption: PropTypes.shape({
          historical: PropTypes.string,
          manual: PropTypes.string,
          preliminary: PropTypes.string,
          manualTimestamp: PropTypes.string,
        }),
      }),
    ),
  ).isRequired,
  imbalanceType: PropTypes.oneOf(['biogas', 'consumption']).isRequired,
  unit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
};

export default HourlyImbalances;
export {
  getClassName,
  updateManualEstimates,
};
