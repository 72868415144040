import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
} from 'antd';
import { API } from 'aws-amplify';
import { camelCase } from 'lodash';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import CapacityTransferForm from './CapacityTransferForm';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import {
  CAPACITY_SCHEMA,
  CAPACITYTRANSFER_SCHEMA,
} from '../../schemas/capacityTransfers';
import {
  CAPACITY_TYPES,
} from '../../constants/capacities';

class CapacityTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerId: null,
      capacity: this.getEmptyCapacity(Object.keys(CAPACITY_TYPES)[0]),
      errors: [],
      isSaving: false,
    };
  }

  getEmptyCapacity = type => ({
    type,
    date: null,
    capacity: '0',
  })

  handleChange = (field, value) => {
    const {
      errors,
      capacity,
    } = this.state;

    const isBuyerId = field === 'buyerId';

    if (isBuyerId) {
      this.setState({ buyerId: value });
    } else {
      const updatedCapacity = {
        ...capacity,
        [field]: value,
      };
      this.setState({ capacity: updatedCapacity });
    }

    if (field !== 'type') {
      const schema = isBuyerId ? CAPACITYTRANSFER_SCHEMA : CAPACITY_SCHEMA;
      const { error } = schema[field].validate(value);
      const updatedErrors = errors.filter(err => err !== field);
      if (error) {
        updatedErrors.push(field);
      }
      this.setState({ errors: updatedErrors });
    }
  }

  updateType = (type) => {
    const { capacity } = this.state;
    this.setState({
      capacity: {
        ...capacity,
        type,
      },
    });
  }

  isSaveButtonDisabled = () => {
    const {
      errors,
      isSaving,
      buyerId,
      capacity: {
        capacity,
        date,
      },
    } = this.state;

    return isSaving
      || errors.length > 0
      || buyerId === null
      || date === null
      || capacity === '0';
  }

  handleClose = () => {
    const { t, onClose } = this.props;

    return this.isSaveButtonDisabled()
      ? onClose()
      : modalConfirmClose(t, () => onClose());
  }

  remapCapacityForApi = capacity => ({
    ...capacity,
    type: camelCase(CAPACITY_TYPES[capacity.type]),
    capacity: Number.parseFloat(capacity.capacity),
  });

  handleSave = async () => {
    const { selectedMarketPartyId: marketPartyId } = this.context;
    const {
      buyerId,
      capacity,
    } = this.state;

    const capacityTransfer = {
      buyerId,
      capacities: [this.remapCapacityForApi(capacity)],
    };
    const { t, onClose } = this.props;

    const { error } = CAPACITYTRANSFER_SCHEMA.validate(capacityTransfer);
    if (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.incorrectInput'),
        description: error.toString(),
      });
      return;
    }

    try {
      this.setState({ isSaving: true });
      const init = { body: { ...capacityTransfer } };
      const newCapacityTransfer = await API.post('FINTSO', `/marketparties/${marketPartyId}/capacitytransfers`, init);

      onClose(newCapacityTransfer);
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.failedToSave'),
        description: createErrorMessage(e),
      });
      this.setState({ isSaving: false });
    }
  }

  renderContent = () => {
    const {
      buyerId,
      errors,
      capacity,
    } = this.state;
    const {
      selectedMarketPartyId: marketPartyId,
      marketPartyIndex,
    } = this.context;
    const marketParties = marketPartyId !== undefined
      ? marketPartyIndex.filter(marketParty => marketParty.id !== marketPartyId)
      : [];

    return (
      <CapacityTransferForm
        buyerId={buyerId}
        capacity={capacity}
        errors={errors}
        marketParties={marketParties}
        onChange={this.handleChange}
      />
    );
  }

  renderActionButtons = () => {
    const {
      isSaving,
    } = this.state;
    const { t } = this.props;

    return ([
      <Button
        className="capacitytransfer__modal__cancel-button"
        key="cancel"
        onClick={this.handleClose}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="capacitytransfer__modal__save-button"
        disabled={this.isSaveButtonDisabled()}
        key="save"
        loading={isSaving}
        type="primary"
        onClick={this.handleSave}
      >
        {t('common.button.saveAndClose')}
      </Button>,
    ]);
  }

  render() {
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="capacitytransfer__modal"
        title={t('capacityRightsTransfer.form.title.newCapacityRightsTransfer')}
        handleClose={this.handleClose}
        actionButtons={this.renderActionButtons()}
        width="50%"
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

CapacityTransfer.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CapacityTransfer.displayName = 'CapacityTransfer';
CapacityTransfer.contextType = Context;

export default withTranslation()(CapacityTransfer);
export {
  CapacityTransfer as PureComponent,
};
