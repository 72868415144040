import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;
    if (error) {
      // You can render any custom fallback UI
      return (
        <>
          <h1 className="error__title">Something went wrong.</h1>
          <p className="error__name"><b>{error.toString()}</b></p>
          <p className="error__info"><i>{info.componentStack}</i></p>
        </>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};


export default ErrorBoundary;
