import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Empty,
} from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import { Route, Switch } from 'react-router-dom';
import {
  RESOLUTION_NAMES,
  RESOLUTIONS,
} from '../../constants/gasTaxDeclarations';
import { UNIT_NAMES } from '../../constants/units';
import {
  BASE_CLASS,
  TABLE_CLASS,
  getMeteringSiteIdColumn,
  getMeteringSiteNameColumn,
  getMeteringSiteTypeColumn,
  getConsumptionsColumn,
  getTaxClassColumns,
  getDeclarationColumn,
  remap,
  getRowClassName,
  getRowKey,
} from './utils';
import DeclareGasTaxModal from '../DeclareGasTaxModal/DeclareGasTaxModal';
import DeleteDeclaration from '../DeleteDeclaration';
import Context from '../../context';
import { TAXDECLARATIONS_WRITE_PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';

const DeclareGasTaxTable = ({
  consumptionsDataSource,
  taxDeclarationsDataSource,
  isLoading,
  start,
  end,
  resolution,
  unit,
}) => {
  const { t } = useTranslation();
  const {
    declareStart,
    declareEnd,
    isAdmin,
    isAdminAsMarketParty,
    currentUser,
    selectedMarketPartyId,
  } = useContext(Context);

  const currentDate = Date.now();

  const hasWritePermissions = userHasPermission(
    { currentUser, selectedMarketPartyId },
    TAXDECLARATIONS_WRITE_PERMISSIONS,
  );

  const canDeclare = isAdmin() || isAdminAsMarketParty() || (hasWritePermissions
    && (currentDate > Date.parse(declareStart) && currentDate <= Date.parse(declareEnd)));

  const dataSource = remap(t, consumptionsDataSource, taxDeclarationsDataSource, unit, canDeclare);

  return (
    <div className={BASE_CLASS}>
      <h3 className={`${BASE_CLASS}__title`}>
        {t('declareGasTax.table.title', { unit })}
      </h3>
      {
        consumptionsDataSource.length
          ? (
            <Table
              className={TABLE_CLASS}
              columns={[
                getMeteringSiteIdColumn(t),
                getMeteringSiteNameColumn(t),
                getMeteringSiteTypeColumn(t),
                ...getConsumptionsColumn(start, end, resolution, t),
                ...getTaxClassColumns(t),
                getDeclarationColumn(),
              ]}
              dataSource={dataSource}
              indentSize={0}
              loading={isLoading}
              pagination={false}
              rowClassName={getRowClassName}
              rowKey={getRowKey}
              scroll={{ x: 'max-content' }}
            />
          )
          : <Empty />
      }
      <>
        <Switch>
          <Route
            path="/declaregastax/delete/:id"
            component={DeleteDeclaration}
          />
          <Route
            path="/declaregastax/:id"
            component={DeclareGasTaxModal}
          />
        </Switch>
      </>
    </div>
  );
};

const timeSeriesArray = PropTypes.arrayOf(
  PropTypes.shape({
    timestamp: PropTypes.string,
    value: PropTypes.string,
  }),
);

const declarationsArray = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.number,
  }),
);

DeclareGasTaxTable.propTypes = {
  consumptionsDataSource: PropTypes.arrayOf(
    PropTypes.shape({
      meteringSiteId: PropTypes.string,
      name: PropTypes.string,
      values: PropTypes.shape({
        energy: timeSeriesArray,
      }),
    }),
  ).isRequired,
  taxDeclarationsDataSource: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      period: PropTypes.string,
      meteringPointId: PropTypes.string,
      amounts: declarationsArray,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resolution: PropTypes.oneOf(RESOLUTION_NAMES),
  start: PropTypes.string,
  end: PropTypes.string,
  unit: PropTypes.oneOf(UNIT_NAMES).isRequired,
};
DeclareGasTaxTable.defaultProps = {
  start: '',
  end: '',
  resolution: RESOLUTIONS.MONTH,
};
DeclareGasTaxTable.displayName = 'DeclareGasTaxTable';

export default DeclareGasTaxTable;
