import joi from 'joi';
import { PERMISSION_NAMES } from '../constants/users';

const getUserSchema = () => ({
  id: joi.string().uuid(),
  firstname: joi.string(),
  lastname: joi.string(),
  phone: joi.string().regex(/^\+[0-9]{7,15}$/),
  email: joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  permissions: joi.array().items(joi.string().valid(...PERMISSION_NAMES)),
});

export default getUserSchema;
