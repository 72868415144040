import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import moment from 'moment';

import { createErrorMessage } from './globalStateHelper';
import { getGasDay } from '../utils/gasday';
import { STATUS } from '../constants/marketParties';

const log = new Logger('context:balance-groups');

async function updateBalanceGroups() {
  try {
    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const balanceGroupsUrl = isAdmin() || isReadOnlyAdmin()
      ? '/admin/balancegroups'
      : `/marketparties/${selectedMarketPartyId}/balancegroups`;
    const { items: balanceGroups } = await API.get('FINTSO', balanceGroupsUrl);

    this.setState({ balanceGroups });
    log.info('balance groups updated with data:', balanceGroups);
  } catch (error) {
    const description = createErrorMessage(error);
    const { t } = this.props;

    this.setState({ balanceGroups: [] });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingBalanceGroups'),
      description,
    });
    log.error('error fetching balance groups:', description);
  }
}

const isMarketPartyDifferentForBalanceGroup = (groups, selectedMarketPartyId) => {
  if (groups === null) return true;

  return groups.find(
    group => group.marketPartyId === selectedMarketPartyId,
  ) === undefined;
};

async function updateActiveBalanceGroupId() {
  const { t } = this.props;
  const {
    balanceGroups,
    balanceGroupMembers,
    selectedMarketPartyId,
  } = this.state;
  const marketPartyChanged = isMarketPartyDifferentForBalanceGroup(
    balanceGroups, selectedMarketPartyId,
  );

  if (marketPartyChanged || balanceGroupMembers === null) {
    await this.updateBalanceGroupMembers(marketPartyChanged);
  }

  const {
    balanceGroups: groups,
    balanceGroupMembers: members,
  } = this.state;

  const allMembers = [].concat(...Object.values(members));

  const currentGasDay = getGasDay(moment());

  const currentAndActiveMembers = allMembers
    .filter(member => (
      groups.find(group => group.id === member.balanceGroupId).status === STATUS.ACCEPTED
      && member.marketPartyId === selectedMarketPartyId
      && member.status === STATUS.ACCEPTED
      // check that membership has active date range
      && (member.end === undefined || moment(currentGasDay) <= moment(member.end))
      && (moment(currentGasDay) >= moment(member.start))
    ));

  if (currentAndActiveMembers.length === 0) {
    this.setState({ activeBalanceGroupId: '' });
    if (selectedMarketPartyId !== 'admin') {
      log.warn(
        `${t('common.notifications.errorUpdatingActiveBalanceGroupId.title')}:
      ${t('common.notifications.errorUpdatingActiveBalanceGroupId.description')}`,
      );
    }
  }

  const openEndedMember = currentAndActiveMembers.find(member => (member.end === undefined));

  if (currentAndActiveMembers.length > 0 && openEndedMember !== undefined) {
    const activeBalanceGroupId = openEndedMember.balanceGroupId;
    this.setState({ activeBalanceGroupId });
    log.info('active balance group id updated with data:', activeBalanceGroupId);
  }

  if (currentAndActiveMembers.length > 0 && openEndedMember === undefined) {
    const membersInStartOrder = currentAndActiveMembers.sort((memberA, memberB) => (
      moment(memberA.start) > (memberB.start)
    ));
    const activeBalanceGroupId = membersInStartOrder[0].balanceGroupId;
    this.setState({ activeBalanceGroupId });
    log.info('active balance group id updated with data:', activeBalanceGroupId);
  }
}
const balanceGroupsContext = {
  balanceGroups: null,
  activeBalanceGroupId: null,
  updateBalanceGroups: () => {},
  updateActiveBalanceGroupId: () => {},
};

const functions = {
  updateBalanceGroups,
  updateActiveBalanceGroupId,
};

const balanceGroups = {
  ...balanceGroupsContext,
  ...functions,
};

export {
  balanceGroupsContext,
  balanceGroups,
};
