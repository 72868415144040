import { Button } from 'antd';
import { PropTypes } from 'prop-types';
import React from 'react';
import './index.less';

const ButtonLink = ({
  onClick,
  size,
  className,
  text,
  id,
}) => (
  <Button
    className={className ? `${className} gg-button-link` : 'gg-button-link'}
    onClick={onClick}
    data-testid={id}
    size={size}
    type="link"
  >
    {text}
  </Button>
);

ButtonLink.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

ButtonLink.defaultProps = {
  onClick: () => {},
  text: undefined,
  id: undefined,
  className: undefined,
  size: 'medium',
};

export default ButtonLink;
