import React from 'react';
import classNames from 'classnames';
import './index.less';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */

/**
 * Currently Ant Design's <Table /> doesn't have native support for
 * rendering the expand/collapse icon based on the record's data.
 * This is a custom solution for cases where not all rows should be
 * expandable.
 *
 * Usage:
 *
 *   const expandIcon = getExpandIcon();
 *   return <Table expandIcon={expandIcon} ... />
 *
 * @param {Function} expandableIf - Callback to determine whether to render the icon
 */
const getExpandIcon = (expandableIf = () => true) => (props) => {
  const {
    expanded, expandable, record, onExpand,
  } = props;

  if (!(expandable && expandableIf(record))) {
    return null;
  }

  const className = classNames('expand-icon', { 'expand-icon--expanded': expanded });
  const onClick = event => onExpand(record, event);

  return <span className={className} onClick={onClick} />;
};

export default getExpandIcon;
