import PropTypes from 'prop-types';

export default PropTypes.shape({
  productCode: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  invoice: PropTypes.shape({
    productCode: PropTypes.string,
    invoiceCode: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    dueDate: PropTypes.string,
    volume: PropTypes.string,
    price: PropTypes.string,
    charge: PropTypes.string,
  }),
  values: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    volume: PropTypes.string,
    price: PropTypes.string,
    charge: PropTypes.string,
  })),
});
