import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import Context from '../../context';
import Imbalance from '../Stats/imbalance';
import SummaryTable from '../Stats/SummaryTable';
import ButtonSelector from '../ButtonSelector';
import {
  getGasDay,
  startOfGasDay,
} from '../../utils/gasday';
import { minutes } from '../../utils/interval';
import {
  UNITS_EXTENDED,
  UNIT_EXTENDED_NAMES,
} from '../../constants/units';

import './index.less';

const UPDATE_INTERVAL = minutes(5);

const MyBalanceStats = (props) => {
  const { selectedDate } = props;
  const {
    selectedMarketPartyId,
    updateMyBalance,
    myBalance,
    myBalanceLoading,
    deliveryEstimates,
    updateDeliveryEstimates,
    loadingDeliveryEstimates,
  } = useContext(Context);

  const { t } = useTranslation();

  const [selectedUnit, setSelectedUnit] = useState(UNITS_EXTENDED.MWH);

  const fetchData = () => {
    const gasDay = getGasDay(selectedDate);
    updateMyBalance(gasDay);
    updateDeliveryEstimates(gasDay, gasDay);
  };

  useEffect(() => {
    const interval = setInterval(fetchData, UPDATE_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  },
  []);

  useEffect(() => {
    fetchData();
  },
  [selectedMarketPartyId, selectedDate]);

  const selectedGasDay = getGasDay(selectedDate);
  let statValues = {
    imbalanceTotal: undefined,
    entry: null,
    exit: null,
  };

  if (myBalance !== null && myBalance[selectedGasDay] !== undefined) {
    statValues = myBalance[selectedGasDay];
  }

  const {
    imbalanceTotal,
    entry,
    exit,
  } = statValues;

  const isLoading = myBalanceLoading || loadingDeliveryEstimates;
  const startOfSelectedGasDay = startOfGasDay(selectedDate).toISOString();
  const deliveryEstimateObject = deliveryEstimates.find(({ timestamp }) => (
    timestamp === startOfSelectedGasDay
  ));

  const deliveryEstimate = deliveryEstimateObject !== undefined
    ? deliveryEstimateObject.value
    : undefined;

  return (
    <>
      <Row className="my-balance__action-header">
        <Col className="my-balance__action__unit">
          <ButtonSelector
            t={t}
            options={UNIT_EXTENDED_NAMES}
            defaultValue={selectedUnit}
            title="unit"
            onChange={({ target: { value } }) => setSelectedUnit(value)}
          />
        </Col>
      </Row>
      <Row className="my-balance-stats" data-testid="my-balance-stats">
        <Col span={6}>
          <Imbalance
            total={imbalanceTotal}
            deliveryEstimate={deliveryEstimate}
            isLoading={isLoading}
            selectedDate={selectedGasDay}
            unit={selectedUnit}
            type="myImbalance"
          />
        </Col>
        <Col span={9}>
          <SummaryTable
            data={entry}
            isLoading={isLoading}
            type="entry"
            selectedDate={selectedGasDay}
            unit={selectedUnit}
          />
        </Col>
        <Col span={9}>
          <SummaryTable
            data={exit}
            isLoading={isLoading}
            type="exit"
            selectedDate={selectedGasDay}
            unit={selectedUnit}
          />
        </Col>
      </Row>
    </>
  );
};


MyBalanceStats.displayName = 'MyBalanceStats';

MyBalanceStats.propTypes = {
  selectedDate: PropTypes.objectOf(moment).isRequired,
};

export default MyBalanceStats;
