import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Layout,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Route, withRouter } from 'react-router-dom';

import './index.less';

import { NOMINATION_WRITE_PERMISSIONS, NOMINATIONS_READ_PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';
import Context from '../../context';
import NewNomination from '../../components/Nomination/newNomination';
import NominationsSummary from '../../components/NominationsSummary';
import PageHeaderBar from '../../components/PageHeaderBar';
import NominationInfo from './nominationInfo';
import { UNITS_EXTENDED } from '../../constants/units';
import MatchNomination from './matchNomination';

const Nominations = (props) => {
  const { history } = props;
  const { t } = useTranslation();

  const context = useContext(Context);
  const {
    isReadOnlyAdmin,
    isReadOnlyAdminAsMarketParty,
    isAdmin,
    isAdminAsMarketParty,
    selectedMarketPartyId,
  //  activeBalanceGroupId,
  } = context;

  const [nominationData, setNominationData] = useState();
  const [nominationKey, setNominationKey] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(UNITS_EXTENDED.KWH);

  // TODO: This solution does not account for balance group for the future dates allowed
  // const hasBalanceGroup = activeBalanceGroupId && activeBalanceGroupId !== '';
  const hasBalanceGroup = true;
  const canCreateNomination = selectedMarketPartyId !== 'admin' && (userHasPermission(context, NOMINATION_WRITE_PERMISSIONS)
    || isAdmin(true) || isAdminAsMarketParty());
  const canViewNomination = canCreateNomination || userHasPermission(context, NOMINATIONS_READ_PERMISSIONS)
    || isReadOnlyAdmin() || isReadOnlyAdminAsMarketParty();

  const handleNewNominationClick = () => {
    history.push('/nominations/new-nomination');
  };

  const renderButton = () => (
    <Button
      className="page__header__buttons__new-nomination"
      data-testid="page__header__buttons__new-nomination"
      type="primary"
      disabled={!hasBalanceGroup}
      onClick={handleNewNominationClick}
    >
      {t('nominations.button.newNomination')}
    </Button>
  );

  return (
    <>
      <PageHeaderBar
        title={t('nominations.title.yourNominations')}
        data-testid="page__header-bar"
        actionItems={canCreateNomination ? renderButton() : null}
      />
      <Layout.Content className="layout__container__page__content nominations">
        <NominationsSummary
          canCreateNomination={canCreateNomination}
          canViewNomination={canViewNomination}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          setNominationData={setNominationData}
          setNominationKey={setNominationKey}
        />
      </Layout.Content>
      {canCreateNomination && (
        <Route path="/nominations/new-nomination">
          <NewNomination />
        </Route>
      )}
      {canViewNomination && (
        <Route path="/nominations/renomination">
          <NewNomination nominationData={nominationData} canViewOnly={!canCreateNomination} />
        </Route>
      )}
      <Route path="/nominations/info">
        <NominationInfo
          nominationKey={nominationKey}
          unit={selectedUnit}
        />
      </Route>
      {nominationData !== undefined && (
        <Route path="/nominations/match">
          <MatchNomination
            nomination={nominationData}
          />
        </Route>
      )}
    </>
  );
};

Nominations.displayName = 'Nominations';
Nominations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Nominations);
export {
  Nominations as PureComponent,
};
