import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Button,
  InputNumber,
  Modal,
  notification,
  Select,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { withTranslation } from 'react-i18next';

import {
  API,
  Logger,
} from 'aws-amplify';
import moment from 'moment-timezone';
import { createErrorMessage } from '../../context/globalStateHelper';
import {
  DIRECTIONS,
  DIRECTION_NAMES,
  LOCATION_POINTS,
  NOMINATION_POINTS_DIRECTIONS,
} from '../../constants/nominations';

import './index.less';

const logger = new Logger('new-delivery-relation-ship:form');
const { Option } = Select;

const combineLocalGasDayAndHour = (gasday, hour) => {
  const dateObj = moment.tz(gasday, 'Europe/Helsinki');
  dateObj.add(hour.split(':')[0], 'hours');
  return moment.utc(dateObj);
};

class TechnicalCapacity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      point: LOCATION_POINTS.BALTIC_CONNECTOR,
      direction: DIRECTIONS.ENTRY,
      startHour: '07:00',
      endHour: '06:00',
      isSaving: false,
      hourDisabled: true,
    };
  }

  modalConfirmSave = (t, okHandler) => {
    const {
      start: startDate,
      end: endDate,
      startHour,
      endHour,
      point,
      direction,
      amount,
      hourDisabled,
    } = this.state;

    const start = hourDisabled
      ? startDate
      : combineLocalGasDayAndHour(startDate, startHour).format();

    const end = hourDisabled
      ? endDate
      : combineLocalGasDayAndHour(endDate, endHour).format();

    const days = moment.utc(end).diff(moment.utc(start), 'days') + 1;

    if (start === undefined || end === undefined || amount === undefined || days < 0) {
      return;
    }

    const header = 'Are you sure you want to save values?';

    const startDateStr = hourDisabled ? startDate : `${startDate} ${startHour}`;
    const endDateStr = hourDisabled ? endDate : `${endDate} ${endHour}`;

    const content = (
      <>
        <div>
          From&nbsp;
          {startDateStr}
          &nbsp;to&nbsp;
          {endDateStr}
        </div>
        <div>
          {t(`nominations.label.${point}`)}
          &nbsp;
          {t(`nominations.label.${direction}`)}
        </div>
        <div>
          Amount:&nbsp;
          {amount}
          &nbsp;kWh / h
        </div>
        <div>
          Total of&nbsp;
          {days}
          &nbsp;days affected.
        </div>
      </>
    );

    Modal.confirm({
      centered: true,
      okText: t('common.button.yes'),
      cancelText: t('common.button.no'),
      title: header,
      content,
      onOk: okHandler,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({
      hourDisabled: e.target.checked,
    });
  };

  handleSave = async () => {
    const {
      start: startValue,
      end: endValue,
      startHour,
      endHour,
      point,
      direction,
      amount,
      hourDisabled,
    } = this.state;
    const {
      t,
      handleClose,
    } = this.props;

    const start = hourDisabled
      ? startValue
      : combineLocalGasDayAndHour(startValue, startHour).format();

    const end = hourDisabled
      ? endValue
      : combineLocalGasDayAndHour(endValue, endHour).format();

    this.setState({
      isSaving: true,
    });

    try {
      await API.post('FINTSO', '/admin/import/timeseries/technicalcapacity', {
        body: {
          start,
          end,
          point,
          direction,
          amount,
        },
      });

      notification.success({
        message: t('monitoring.form.createdSuccessfully'),
        description: '',
      });
      handleClose();
    } catch (err) {
      notification.error({
        className: 'notification-error',
        message: t('monitoring.form.errorCreating'),
        // TODO: translatable error message
        description: createErrorMessage(err),
      });
      logger.error(createErrorMessage(err, true));
      this.setState({ isSaving: false });
    }
  };

  handleStartChange = (date) => {
    const start = date.format('YYYY-MM-DD');
    this.setState({ start });
  };

  handleEndChange = (date) => {
    const end = date.format('YYYY-MM-DD');
    this.setState({ end });
  };

  handleAmountChange = (amount) => {
    this.setState({ amount });
  };

  handlePointDirectionChange = (pointDirection) => {
    const [point, direction] = pointDirection.split('#');
    this.setState({ point, direction });
  };

  handleEndHourChange = (endHour) => {
    this.setState({ endHour });
  };

  handleStartHourChange = (startHour) => {
    this.setState({ startHour });
  };

  initializeHours = () => {
    const hourArray = [];
    for (let i = 0; i < 24; i += 1) {
      let format = `0${i}:00`;
      if (i > 9) {
        format = `${i}:00`;
      }
      hourArray.push(format);
    }
    return hourArray;
  };

  renderHourPicker = (type, startHour, handler, hourDisabled) => {
    const numberOfHours = this.initializeHours();

    return (
      !hourDisabled
        ? (
          <Select
            key={`select-${type}-date`}
            className={`capacity-hour-picker__${type}-hour__select`}
            onChange={handler}
            value={startHour}
          >
            {
                numberOfHours.map((hourInMoment, index) => (
                  <Option
                    // eslint-disable-next-line react/no-array-index-key
                    key={`option-${type}-hour-${index}`}
                    // value={hourInMoment.clone().utc().format()}
                    value={hourInMoment}
                    // disabled={isHourDisabled(type, hourInMoment, start)}
                  >
                    {hourInMoment}
                  </Option>
                ))
              }
          </Select>
        )
        : (<></>)
    );
  };

  render = () => {
    const {
      t,
      handleClose,
      // instance,
    } = this.props;
    const {
      point,
      direction,
      startHour,
      endHour,
      isSaving,
      hourDisabled,
    } = this.state;

    // Filtering of point-direction is done based on nomination points.
    const directionOptions = DIRECTION_NAMES;
    // Technical capacity only allowed for Baltic connector and Imatra
    const pointOptions = [LOCATION_POINTS.BALTIC_CONNECTOR, LOCATION_POINTS.IMATRA];

    // TODO: should really use ModalWrapper (used strangly as content at CapacitySettings)

    return (
      <div className="set-technical-capacity">
        <Row className="set-technical-capacity__row">
          <Col span={7}>
            {t('common.label.timePeriod')}
            <br />
            <span>(given start and end time are included)</span>
          </Col>
          <Col span={17}>
            <div className="set-technical-capacity__datetimepicker">
              <DatePicker
                className="capacitydatepicker__start-date__input"
                format={moment.localeData().longDateFormat('L')}
                allowClear={false}
                onChange={this.handleStartChange}
                placeholder={
                      t('capacityProductInstance.datePicker.day.placeholder')
                  }
                showToday={false}
                defaultPickerValue={moment.utc()}
              />
              {this.renderHourPicker('start', startHour, this.handleStartHourChange, hourDisabled)}
            </div>
            &nbsp;&mdash;&nbsp;
            <div className="set-technical-capacity__datetimepicker">
              <DatePicker
                className="capacitydatepicker__start-date__input"
                format={moment.localeData().longDateFormat('L')}
                allowClear={false}
                onChange={this.handleEndChange}
                placeholder={
                      t('capacityProductInstance.datePicker.day.placeholder')
                  }
                showToday={false}
                defaultPickerValue={moment.utc()}
              />
              {this.renderHourPicker('end', endHour, this.handleEndHourChange, hourDisabled)}
            </div>
            <div>
              <Checkbox
                className="nomination-form__external-eic"
                onChange={this.onCheckboxChange}
                checked={hourDisabled}
              >
                {t('monitoring.checkboxlabel')}
              </Checkbox>
            </div>
          </Col>
        </Row>
        <Row className="set-technical-capacity__row">
          <Col span={7}>
            {t('common.label.pointDirection')}
          </Col>
          <Col span={17}>
            <Select
              className="set-technical-capacity-point-direction__select"
              data-testid="set-technical-capacity-point-direction__select"
              id="set-technical-capacity-point-direction"
              name="set-technical-capacity-point-direction"
              onChange={value => this.handlePointDirectionChange(value)}
              value={`${point}#${direction}`}
            >
              {
              Object.values(directionOptions).map(dirOpt => (
                Object.values(pointOptions)
                  // below filtering is must as to make sure correct point-direction
                  // combination is shown in the UI
                  .filter((po) => NOMINATION_POINTS_DIRECTIONS.includes(`${po}-${dirOpt}`))
                  .map(po => (
                    <Option
                      key={dirOpt}
                      value={`${po}#${dirOpt}`}
                      className="set-technical-capacity-point-direction__select__option"
                    >
                      {t(`nominations.label.${po}-${dirOpt}`)}
                    </Option>
                  ))))
            }
            </Select>
          </Col>
        </Row>
        <Row className="set-technical-capacity__row">
          <Col span={7}>
            {t('monitoring.form.capacityLabel')}
          </Col>
          <Col span={17}>
            <InputNumber
              className="set-technical-capacity-amount__input"
              data-testid="set-technical-capacity-amount__input"
              id="set-technical-capacity-amount"
              name="set-technical-capacity-amount"
              onChange={this.handleAmountChange}
              // value={0}
              type="number"
              min={0}
            />
          </Col>
        </Row>
        <Button
          className="capacityreservation__modal__cancel-button"
          key="cancel"
          onClick={handleClose}
        >
          {t('common.button.cancel')}
        </Button>
        &nbsp;
        <Button
          className="capacityreservation__modal__save-button"
        // disabled={this.isSaveButtonDisabled()}
          key="save"
          loading={isSaving}
          type="primary"
          onClick={() => this.modalConfirmSave(
            t,
            this.handleSave,
            this.handleClose,
          )}
        >
          {t('common.button.saveAndClose')}
        </Button>
      </div>
    );
  };
}

TechnicalCapacity.propTypes = {
  t: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

TechnicalCapacity.displayName = 'TechnicalCapacity';

export default withTranslation()(TechnicalCapacity);
export {
  TechnicalCapacity as PureComponent,
};
