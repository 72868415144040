
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route, withRouter } from 'react-router-dom';
import {
  Layout,
  Table,
} from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import StatusIcon from '../../components/StatusIcon';
import Context from '../../context';
import ButtonSelector from '../../components/ButtonSelector';
import PageHeaderBar from '../../components/PageHeaderBar';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { naturalSort, dateSort } from '../../utils/sort';

const MarketParty = React.lazy(() => import('../../components/MarketParty'));
const { Content } = Layout;

class MarketParties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedStatusFilter: 'all',
    };
  }

  componentDidMount = () => {
    const {
      marketParties,
      updateMarketParties,
    } = this.context;
    if (marketParties === null) updateMarketParties();
  }

  closeModal = () => {
    const { history } = this.props;
    history.push('/marketparties');
  }

  getColumnsConfig = () => [
    {
      title: 'id',
      dataIndex: 'code',
      sorter: (a, b) => naturalSort(a.code, b.code),
      sortDirections: ['ascend', 'descend'],
    }, {
      title: 'company',
      dataIndex: 'name',
      sorter: (a, b) => naturalSort(a.name, b.name),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      render: (text, record) => <Link to={`/marketparties/${record.id}`}>{text}</Link>,
    }, {
      title: 'vatId',
      dataIndex: 'vatId',
    }, {
      title: 'gs1Retailer',
      dataIndex: 'gs1Retailer',
    }, {
      title: 'gs1Dso',
      dataIndex: 'gs1Dso',
    }, {
      title: 'eicx',
      dataIndex: 'eic',
    }, {
      title: 'created',
      dataIndex: 'createdAt',
      sorter: (a, b) => dateSort(a.createdAt, b.createdAt),
      sortDirections: ['ascend', 'descend'],
    }, {
      title: 'status',
      dataIndex: 'status',
      sorter: (a, b) => naturalSort(a.status, b.status),
      render: status => <StatusIcon status={status} />,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  renderMarketParty = () => (
    <MarketParty
      onClose={this.closeModal}
      onSave={this.closeModal}
    />
  )

  renderStatusFilter = () => {
    const {
      isAdmin, isReadOnlyAdmin,
    } = this.context;
    const { t } = this.props;

    const FILTERS = {
      ALL: 'all',
      ACCEPTED: 'accepted',
      PENDING: 'pending',
      DECLINED: 'declined',
    };

    const filterNames = Object.values(FILTERS);

    const statusFilter = (isAdmin() || isReadOnlyAdmin()) ? (
      <ButtonSelector
        t={t}
        options={filterNames}
        defaultValue={FILTERS.ALL}
        title="statusFilter"
        onChange={({ target: { value } }) => {
          this.setState({ selectedStatusFilter: value });
        }}
      />
    ) : null;

    return statusFilter;
  }

  getMarketPartiesData = () => {
    const { marketParties } = this.context;
    const { selectedStatusFilter } = this.state;

    if (selectedStatusFilter === 'all') return marketParties;

    const filteredMarketParties = marketParties.filter(marketParty => marketParty.status === selectedStatusFilter);
    return filteredMarketParties;
  }

  render() {
    const { isLoading } = this.state;
    const { isLoading: contextIsLoading } = this.context;
    const { t } = this.props;

    return (
      <>
        <PageHeaderBar title={t('marketParty.title.marketParties')} actionItems={[this.renderStatusFilter()]} />
        <Content className="layout__container__page__content">
          <Table
            className="layout__container__page__content__table"
            columns={getTranslatedTableHeaders(this.getColumnsConfig(), t)}
            dataSource={this.getMarketPartiesData() || []}
            loading={isLoading || this.getMarketPartiesData() === null || contextIsLoading}
            onChange={this.onChange}
            rowKey="id"
            pagination={false}
          />
          <Route
            path="/marketparties/:id"
            render={this.renderMarketParty}
          />
        </Content>
      </>
    );
  }
}

MarketParties.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

MarketParties.displayName = 'MarketParties';
MarketParties.contextType = Context;

const MarketPartiesTranslated = withTranslation()(MarketParties);
export default withRouter(MarketPartiesTranslated);

export {
  MarketParties as PureComponent,
};
