import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  DatePicker,
  Layout,
  Row,
  Table,
} from 'antd';
import { sumBy } from 'lodash';
import Decimal from 'decimal.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import classnames from 'classnames';
import { unparse } from 'papaparse';

import './index.less';

import Context from '../../context';
import PageHeaderBar from '../../components/PageHeaderBar';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { naturalSort } from '../../utils/sort';
import UTF8_BOM from '../../constants/excel';

import {
  formatDate,
  formatNumber,
  localeDateFormat,
} from '../../utils/i18n';
import ButtonSelector from '../../components/ButtonSelector';
import {
  DATE_TYPES,
  MAX_DAYS_IN_RANGE,
  MAX_MONTH_IN_RANGE,
  RESOLUTION_NAMES,
  RESOLUTIONS,
} from '../../constants/deliveries';
import {
  UNITS,
  UNIT_NAMES,
} from '../../constants/units';
import { startOfOngoingGasDay } from '../../utils/gasday';

const moment = extendMoment(Moment);
const { MonthPicker } = DatePicker;

const Deliveries = () => {
  const {
    deliveries,
    isAdmin,
    isReadOnlyAdmin,
    isLoading,
    loadingDeliveries,
    selectedMarketPartyId,
    marketPartyIndex,
    updateDeliveries,
  } = useContext(Context);

  const { t } = useTranslation();

  const [selectedResolution, setSelectedResolution] = useState(RESOLUTIONS.MONTH);
  const [selectedUnit, setSelectedUnit] = useState(UNITS.KWH);
  const [isPreparingCsvData, setIsPreparingCsvData] = useState(false);
  const [csvData, setCsvData] = useState();

  const hiddenCSVElement = useRef();
  const deliveriesTable = useRef();

  const endDate = startOfOngoingGasDay()
    .clone()
    .endOf('month');
  const startDate = endDate
    .clone()
    .subtract(1, 'month')
    .startOf('month');
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const fetchData = async () => {
    if (isAdmin() || isReadOnlyAdmin()) return;

    await updateDeliveries(
      selectedStartDate.format('YYYY-MM-DD'),
      selectedEndDate.format('YYYY-MM-DD'),
      selectedResolution,
    );
  };

  useEffect(() => {
    fetchData();
  }, [selectedMarketPartyId, selectedResolution]);

  const calculateWithUnit = (value) => {
    const multiplier = new Decimal(selectedUnit === UNITS.MWH ? 0.001 : 1);
    return Number(multiplier.mul(value));
  };

  const TABLE_COLUMNS = [
    {
      title: 'retailer',
      dataIndex: 'counterPartyName',
    }, {
      title: '',
      dataIndex: 'timestamp',
    }, {
      title: 'original',
      dataIndex: 'originalValue',
      render: value => formatNumber(calculateWithUnit(value)),
    }, {
      title: 'monthlyDelta',
      dataIndex: 'monthlyDelta',
      render: value => value !== 0 && formatNumber(calculateWithUnit(value)),
    }, {
      title: 'yearlyDelta',
      dataIndex: 'yearlyDelta',
      render: value => value !== 0 && formatNumber(calculateWithUnit(value)),
    },
  ];

  const renderRangePicker = renderFunction => (
    <div className="deliveries__action__date__selector__range">
      {renderFunction()}
      <span className="deliveries__action__separator">
        -
      </span>
      {renderFunction(DATE_TYPES.END)}
    </div>
  );

  const handleSelection = (date, type) => {
    const isMonthResolution = selectedResolution === RESOLUTIONS.MONTH;

    if (type === DATE_TYPES.START) {
      setSelectedStartDate(
        isMonthResolution
          ? date.clone().startOf('month')
          : date,
      );
    } else {
      setSelectedEndDate(
        isMonthResolution
          ? date.clone().endOf('month')
          : date,
      );
    }
  };

  const disabledEndDate = (date, type) => {
    if (type === DATE_TYPES.START) return false;

    const isDayResolution = selectedResolution === RESOLUTIONS.DAY;
    const MAX_RANGE = isDayResolution
      ? MAX_DAYS_IN_RANGE
      : MAX_MONTH_IN_RANGE;
    const RANGE_UNIT = isDayResolution
      ? 'days'
      : 'months';
    const maxAllowedDate = selectedStartDate
      .clone()
      .add(MAX_RANGE, RANGE_UNIT);
    const range = moment().range(selectedStartDate, maxAllowedDate);

    return !range.contains(date, { excludeEnd: true });
  };

  const renderDatePicker = (type = DATE_TYPES.START) => (
    <DatePicker
      allowClear={false}
      format={localeDateFormat()}
      onChange={date => handleSelection(date, type)}
      value={type === DATE_TYPES.START
        ? selectedStartDate
        : selectedEndDate
      }
      disabledDate={date => disabledEndDate(date, type)}
      className={classnames('deliveries__action__date-picker', {
        'date-selection-error': type === DATE_TYPES.END && disabledEndDate(selectedEndDate, type),
      })}
    />
  );

  const renderMonthPicker = (type = DATE_TYPES.START) => (
    <MonthPicker
      allowClear={false}
      format="MMMM YYYY"
      onChange={date => handleSelection(date, type)}
      value={type === DATE_TYPES.START
        ? selectedStartDate
        : selectedEndDate
      }
      disabledDate={date => disabledEndDate(date, type)}
      className={classnames('deliveries__action__month-picker', {
        'date-selection-error': type === DATE_TYPES.END && disabledEndDate(selectedEndDate, type),
      })}
    />
  );

  const renderDateMonthSelector = () => {
    switch (selectedResolution) {
      case RESOLUTIONS.DAY:
        return renderRangePicker(renderDatePicker);
      case RESOLUTIONS.MONTH:
        return renderRangePicker(renderMonthPicker);
      default:
        return null;
    }
  };

  const handleSelectedResolution = ({ target: { value } }) => {
    setSelectedResolution(value);

    const start = value === RESOLUTIONS.MONTH
      ? selectedStartDate.clone().startOf('month')
      : selectedStartDate;
    const end = value === RESOLUTIONS.MONTH
      ? selectedStartDate.clone().add(1, 'months').endOf('month')
      : selectedEndDate;

    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };

  const isContentLoading = (loadingDeliveries || isLoading);

  const prepareItemForCSV = (item, isChildItem = false) => {
    const {
      counterPartyName,
      timestamp,
      originalValue,
      monthlyDelta,
      yearlyDelta,
    } = item;

    return ([
      isChildItem ? '' : counterPartyName,
      isChildItem ? timestamp : '',
      formatNumber(originalValue),
      monthlyDelta !== 0 ? formatNumber(monthlyDelta) : '',
      yearlyDelta !== 0 ? formatNumber(yearlyDelta) : '',
    ]);
  };

  const handleExportToExcel = async () => {
    setIsPreparingCsvData(true);

    const {
      columns,
      dataSource,
    } = deliveriesTable.current.props;

    const csv = await unparse({
      fields: columns.map(column => column.title),
      data: [].concat(
        ...dataSource.map(item => ([
          prepareItemForCSV(item),
          ...item.children
            ? item.children.map(childItem => prepareItemForCSV(childItem, true))
            : [],
        ])),
      ),
    });

    setCsvData(UTF8_BOM + csv);
    setIsPreparingCsvData(false);

    hiddenCSVElement.current.click();
  };

  const renderHiddenLink = () => (
    <a
      className="deliveries__hidden-link"
      href={`data:text/csv;charset=utf-8,${csvData}`}
      rel="noopener noreferrer"
      target="_blank"
      download={`${t('deliveries.title')}_${selectedResolution}_${selectedUnit}.csv`}
      ref={hiddenCSVElement}
    >
      &#8205;
    </a>
  );

  const renderActionHeader = () => (
    <Row className="deliveries__action-header">
      <Col className="deliveries__action__resolution">
        <ButtonSelector
          t={t}
          options={RESOLUTION_NAMES}
          defaultValue={selectedResolution}
          title="resolution"
          onChange={handleSelectedResolution}
        />
      </Col>
      <Col className="deliveries__action__unit">
        <ButtonSelector
          t={t}
          options={UNIT_NAMES}
          defaultValue={selectedUnit}
          title="unit"
          onChange={({ target: { value } }) => setSelectedUnit(value)}
        />
      </Col>
      <Col className="deliveries__action__date">
        <div className="deliveries__action__date__title">
          {t('common.label.timePeriod')}
        </div>
        <div className="deliveries__action__date__selector">
          {renderDateMonthSelector()}
        </div>
      </Col>
      <Col>
        <Button
          className="deliveries__action__search-button"
          type="primary"
          disabled={isContentLoading || isReadOnlyAdmin()}
          loading={isContentLoading}
          onClick={fetchData}
        >
          {t('common.button.search')}
        </Button>
      </Col>
      <Col className="deliveries__action__export">
        <Button
          className="deliveries__action__export-to-excel-button"
          type="primary"
          disabled={isContentLoading || isReadOnlyAdmin() || deliveries.length === 0}
          loading={isContentLoading || isPreparingCsvData}
          onClick={handleExportToExcel}
        >
          {t('common.button.exportToExcel')}
        </Button>
        { renderHiddenLink() }
      </Col>
    </Row>
  );

  const remapChildItems = ({ items, marketPartyId, counterPartyId }) => (
    items.map((item, childIndex) => {
      const {
        timestamp,
        originalValue,
        monthlyCorrectedValue,
        yearlyCorrectedValue,
      } = item;

      return {
        ...item,
        key: `${marketPartyId}_${counterPartyId}_${timestamp}_${childIndex}`,
        timestamp: selectedResolution === RESOLUTIONS.DAY
          ? formatDate(timestamp)
          : moment(timestamp).format('MMMM YYYY'),
        originalValue,
        monthlyDelta: monthlyCorrectedValue - originalValue,
        yearlyDelta: yearlyCorrectedValue - monthlyCorrectedValue,
      };
    })
  );

  const getTotalsByProperty = (propName, items) => sumBy(items, item => item[propName]);

  const remapDeliveries = () => (
    deliveries
      .map((delivery, deliveryIndex) => {
        const counterParty = marketPartyIndex
          .find(marketParty => marketParty.id === delivery.counterPartyId);
        const {
          marketPartyId,
          counterPartyId,
        } = delivery;
        const children = remapChildItems(delivery);
        const originalValue = getTotalsByProperty('originalValue', children);
        const monthlyCorrectedValue = getTotalsByProperty('monthlyCorrectedValue', children);
        const yearlyCorrectedValue = getTotalsByProperty('yearlyCorrectedValue', children);

        return {
          key: `${marketPartyId}_${counterPartyId}_${deliveryIndex}`,
          marketPartyId,
          counterPartyId,
          counterPartyName: counterParty
            ? counterParty.name
            : '-',
          originalValue,
          monthlyCorrectedValue,
          yearlyCorrectedValue,
          monthlyDelta: monthlyCorrectedValue - originalValue,
          yearlyDelta: yearlyCorrectedValue - monthlyCorrectedValue,
          children,
        };
      })
      .sort((a, b) => naturalSort(a.counterPartyName, b.counterPartyName))
  );

  const prepareData = () => {
    const remappedDeliveries = remapDeliveries();

    if (!remappedDeliveries.length) return [];

    const originalValue = getTotalsByProperty('originalValue', remappedDeliveries);
    const monthlyCorrectedValue = getTotalsByProperty('monthlyCorrectedValue', remappedDeliveries);
    const yearlyCorrectedValue = getTotalsByProperty('yearlyCorrectedValue', remappedDeliveries);

    return [
      {
        marketPartyId: 'total',
        counterPartyId: 'total',
        key: 'total__row',
        counterPartyName: t('common.table.header.total'),
        originalValue,
        monthlyCorrectedValue,
        yearlyCorrectedValue,
        monthlyDelta: monthlyCorrectedValue - originalValue,
        yearlyDelta: yearlyCorrectedValue - monthlyCorrectedValue,
      },
      ...remappedDeliveries,
    ];
  };

  return (
    <>
      <PageHeaderBar title={t('deliveries.title')} />
      <Layout.Content className="layout__container__page__content deliveries">
        { renderActionHeader() }
        <Row className="deliveries__table">
          <Table
            className="layout__container__page__content__table"
            columns={getTranslatedTableHeaders(TABLE_COLUMNS, t)}
            dataSource={prepareData()}
            loading={isContentLoading}
            pagination={false}
            ref={deliveriesTable}
          />
        </Row>
      </Layout.Content>
    </>
  );
};

Deliveries.displayName = 'Deliveries';

export default Deliveries;
