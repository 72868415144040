const STATES = {
  FUTURE: 'Upcoming',
  ONGOING: 'Ongoing',
  PAST: 'Past',
};

const STATES_NAMES = Object.values(STATES);

export {
  STATES,
  STATES_NAMES,
};
