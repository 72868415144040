import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import {
  Button, Form, Input,
} from 'antd';

import { CAPACITY_MONTH_PRODUCTS, CAPACITY_PRODUCTS } from '../../constants/capacities';
import { getActivePricesForCapacityType, getInstanceStart, getTimeDifferenceInQty } from '../../utils/capacityHelpers';
import { formatPrice } from '../../utils/i18n';
import CapacityDatePicker from './CapacityDatePicker';
import CapacityHourPicker from './CapacityHourPicker';

import './CapacityProductInstance.less';

const withId = (field, id) => (
  `${field}-${id}`
);

class CapacityProductInstance extends React.Component {
  handleInputChange = (event) => {
    const {
      instance: {
        id,
      },
      onChange,
    } = this.props;

    const {
      nativeEvent: {
        srcElement: {
          id: field,
        },
        target: {
          value,
        },
      },
    } = event;

    onChange(id, field.split('-')[0], value);
  }

  handleQuantityChange = (value) => {
    const {
      instance: {
        id,
      },
      onChange,
    } = this.props;

    onChange(id, 'quantity', value);
  }

  getFormattedDate = (date) => {
    const { instance: { period } } = this.props;

    return period === CAPACITY_PRODUCTS.HOURLY
      ? date
      : date.format('YYYY-MM-DD');
  }

  handleStartChange = (value) => {
    const {
      instance: {
        id,
        period,
      },
      onChange,
    } = this.props;

    if (CAPACITY_MONTH_PRODUCTS.includes(period)) {
      value.startOf('month');
    }

    onChange(id, 'start', this.getFormattedDate(value));
  }

  handleEndChange = (value) => {
    const {
      instance: {
        id,
        start,
        period,
      },
      onChange,
    } = this.props;

    onChange(id, 'quantity', getTimeDifferenceInQty(start, value, period));
  }

  render = () => {
    const {
      capacityPrices,
      errors,
      instance,
      onClose,
      t,
    } = this.props;

    const {
      period,
      start,
      type,
      id,
    } = instance;

    const capacityField = withId('capacity', id);

    const prices = getActivePricesForCapacityType(type, capacityPrices, getInstanceStart(instance));
    const instancePrice = prices[period] || 0;

    return (
      <div className="capacityproductinstance">
        {
          period === CAPACITY_PRODUCTS.HOURLY
            ? (
              <CapacityHourPicker
                errors={errors}
                start={start}
                handleStartChange={this.handleStartChange}
                handleEndChange={this.handleEndChange}
              />
            )
            : (
              <CapacityDatePicker
                errors={errors}
                instance={instance}
                handleStartChange={this.handleStartChange}
                handleEndChange={this.handleEndChange}
              />
            )
        }
        <Form.Item
          className={classNames(
            'capacityproductinstance__item',
            'capacityproductinstance__capacity',
            { 'has-error': errors.find(error => error.field === 'capacity') },
          )}
          label={`${t('capacityProductInstance.label.amount')} (${t('capacityProductInstance.label.unit')})`}
        >
          <Input
            className="capacityproductinstance__capacity__input"
            id={capacityField}
            name={capacityField}
            onChange={this.handleInputChange}
            value={instance.capacity === '0' ? '' : instance.capacity}
            type="number"
            min={0}
          />
        </Form.Item>
        <div className="capacityproductinstance__price">
          <p className="capacityproductinstance__price__sum">
            {`${formatPrice(instance.price)}`}
          </p>
          <p className="capacityproductinstance__price__rate">
            {`${formatPrice(instancePrice, 6)}${t('capacityProductInstance.label.price')}`}
          </p>
        </div>
        <div className="capacityproductinstance__close">
          <Button
            className="capacityproductinstance__close__button"
            onClick={() => onClose(instance.id)}
            type="link"
          >
            x
          </Button>
        </div>
      </div>
    );
  }
}

CapacityProductInstance.propTypes = {
  capacityPrices: PropTypes.arrayOf(PropTypes.any).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    start: PropTypes.string,
    capacity: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    price: PropTypes.number,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CapacityProductInstance.displayName = 'CapacityProductInstance';

export default withTranslation()(CapacityProductInstance);

export { CapacityProductInstance as PureComponent };
