/**
 * Based on https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
 */

const { userAgent } = window.navigator;

const BROWSER = {
  FIREFOX: 'FIREFOX',
  SAMSUNG_INTERNET: 'SAMSUNG_INTERNET',
  OPERA: 'OPERA',
  INTERNET_EXPLORER: 'INTERNET_EXPLORER',
  EDGE: 'EDGE',
  CHROME: 'CHROME',
  SAFARI: 'SAFARI',
  UNKNOWN: 'UNKNOWN',
};

let detectedBrowser;
// The order matters here, and this may report false positives for unlisted browsers.
if (userAgent.indexOf('Firefox') > -1) {
  detectedBrowser = BROWSER.FIREFOX;
} else if (userAgent.indexOf('SamsungBrowser') > -1) {
  detectedBrowser = BROWSER.SAMSUNG_INTERNET;
} else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
  detectedBrowser = BROWSER.OPERA;
} else if (userAgent.indexOf('Trident') > -1) {
  detectedBrowser = BROWSER.INTERNET_EXPLORER;
} else if (userAgent.indexOf('Edg') > -1) {
  detectedBrowser = BROWSER.EDGE;
} else if (userAgent.indexOf('Chrome') > -1) {
  detectedBrowser = BROWSER.CHROME;
} else if (userAgent.indexOf('Safari') > -1) {
  detectedBrowser = BROWSER.SAFARI;
} else {
  detectedBrowser = BROWSER.UNKNOWN;
}

const browser = detectedBrowser;
const isEdge = browser === BROWSER.EDGE;

export {
  BROWSER,
  browser,
  isEdge,
};
