import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  notification,
} from 'antd';
import { Auth, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import { generateNotificationFromCognitoError } from '../../utils/errorHelpers';

import './index.less';

const logger = new Logger('new-password:form');

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      form,
      t,
      email,
      isPasswordReset,
    } = this.props;

    this.setState({
      isLoading: true,
    });

    form.validateFields(async (err, body) => {
      if (!err) {
        try {
          const {
            newPassword,
            verificationCode,
          } = body;

          await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

          isPasswordReset(true);
        } catch (error) {
          logger.error(error);

          const {
            code,
            message,
          } = error;
          const passwordError = {};

          if (code === 'InvalidPasswordException') {
            switch (message) {
              case 'Password does not conform to policy: Password must have uppercase characters':
                passwordError.code = 'PasswordMissingUppercaseCharsException';
                break;
              case 'Password does not conform to policy: Password must have numeric characters':
                passwordError.code = 'PasswordMissingNumericCharsException';
                break;
              case 'Password does not conform to policy: Password not long enough':
                passwordError.code = 'PasswordTooShortException';
                break;
              case 'Password does not conform to policy: Password must have symbol characters':
                passwordError.code = 'PasswordMustHaveSymbolsException';
                break;
              default: // noop
            }
          }

          const notificationMessage = generateNotificationFromCognitoError(t, {
            ...error,
            ...passwordError,
          });
          notification.error(notificationMessage);
        }
      }

      this.setState({
        isLoading: false,
      });
    });
  }

  render = () => {
    const { isLoading } = this.state;
    const { t, form } = this.props;

    return (
      <>
        <Form
          className="forgot-password-form"
          onSubmit={this.handleSubmit}
        >
          <Form.Item
            label={t('forgotPassword.form.label.verificationCode')}
            colon={false}
          >
            {
              form.getFieldDecorator(
                'verificationCode',
                {
                  rules: [
                    { required: true, message: t('common.form.emptyInputFieldErrorMessage') },
                  ],
                },
              )(<Input />)
            }
          </Form.Item>
          <Form.Item
            label={t('forgotPassword.form.label.newPassword')}
            colon={false}
          >
            {
              form.getFieldDecorator(
                'newPassword',
                {
                  rules: [
                    { required: true, message: t('common.form.emptyInputFieldErrorMessage') },
                    { required: true, min: 8, message: t('common.form.passwordNotLongEnoughMessage') },
                  ],
                },
              )(<Input.Password />)
            }
          </Form.Item>
          <Form.Item
            label={t('forgotPassword.form.label.newPasswordAgain')}
            colon={false}
          >
            {
              form.getFieldDecorator(
                'newPasswordAgain',
                {
                  rules: [
                    { required: true, message: t('common.form.emptyInputFieldErrorMessage') },
                    {
                      required: true,
                      validator: (rule, value) => value === form.getFieldValue('newPassword'),
                      message: t('forgotPassword.form.message.passwordsMatchRequired'),
                    },
                  ],
                },
              )(<Input.Password />)
            }
          </Form.Item>
          <Form.Item className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {isLoading ? t('common.status.sending') : t('common.button.submit')}
            </Button>
          </Form.Item>
        </Form>
        <p className="new-password__login-container">
          <a href="/">
            {t('common.button.backToSignIn')}
          </a>
        </p>
      </>
    );
  }
}

NewPasswordForm.displayName = 'NewPasswordForm';
NewPasswordForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  isPasswordReset: PropTypes.func.isRequired,
};

const WrappedNewPasswordForm = Form.create({ name: 'new-password' })(NewPasswordForm);
export default withTranslation()(WrappedNewPasswordForm);
export {
  WrappedNewPasswordForm as PureComponent,
};
