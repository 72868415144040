import moment from 'moment';
import { add } from './math';
import {
  getGasDay,
  previousGasDay,
  startOfGasDay,
  startOfOngoingGasDay,
} from './gasday';
import { LOCATION_POINTS, NOMINATION_POINTS_DIRECTIONS } from '../constants/nominations';
import { MARKETPARTY_RELATIONSHIP_TYPES } from '../constants/relationships';
import { STATUS } from '../constants/status';
import { NETWORKS } from '../constants/marketParties';

const createKey = ({
  marketPartyId, eic1, marketPartyEic1, gasDay, date, timestamp, point, direction, eic2, shipperEIC, marketPartyEic2,
}) => [
  gasDay || date || getGasDay(timestamp),
  eic1 || marketPartyEic1 || marketPartyId,
  point,
  direction,
  eic2 || shipperEIC || marketPartyEic2,
].join('#');

/**
 * Compare two nomints/nomreses and return if they correspond to one another.
 * @param {Object} a - Nomint or Nomres
 * @param {Object} b - Nomint or Nomres
 * @returns Boolean
 */
const matches = a => b => createKey(a) === createKey(b);

const isDateAllowed = (timestamp) => {
  if (!timestamp) {
    return false;
  }

  const date = timestamp instanceof moment
    ? startOfGasDay(timestamp.format('YYYY-MM-DD'))
    : startOfGasDay(timestamp);
  const today = startOfOngoingGasDay();
  const isTooEarly = today.isAfter(date);

  const latestValidDate = today.add(400, 'days');
  const isTooLate = latestValidDate.isBefore(date);

  return !isTooEarly && !isTooLate;
};

const dayAheadClosingDatetime = date => (moment.tz(
  previousGasDay(date), 'Europe/Helsinki',
).hour(15));

const isDayAheadOpenForGasDay = (date, timestamp) => (
  moment(timestamp).utc().isBefore(dayAheadClosingDatetime(date))
);

const getTotalNomRes = (items) => {
  const aggrNomres = items.reduce((nomres, item) => {
    const key = createKey(item);
    const oldItem = nomres[key];
    if (!oldItem) {
      const {
        direction,
        point,
        timestamp,
        value,
        marketPartyEic1,
        marketPartyEic2,
        marketPartyCode1,
        marketPartyCode2,
      } = item;
      const gasDay = getGasDay(timestamp);
      // eslint-disable-next-line no-param-reassign
      nomres[key] = {
        direction,
        point,
        gasDay,
        value,
        marketPartyEic1,
        marketPartyEic2,
        marketPartyCode1,
        marketPartyCode2,
        // Nomres is always representation of "accepted" nomination values
        status: STATUS.ACCEPTED,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      nomres[key] = {
        ...oldItem,
        value: oldItem.value + item.value,
      };
    }
    return nomres;
  }, {});

  return Object.values(aggrNomres);
};

// Two full hours after current time.
// For example, if current time is 10:12, the first valid hour is 13:00
const getEarliestValidTime = (time = moment()) => time
  .add(3, 'hours')
  .set('minutes', 0)
  .set('seconds', 0)
  .set('milliseconds', 0);

// What is the purpose of this?
const aggregateNomintAmounts = (nomints) => {
  // For each nomination in the sorted nomints list, merges based on closed hours and uses only
  // parts of nomination to result as "latest nomination" amounts.
  // TODO: This may well be already handled by backend when actually creating nominations (every nomination
  // reflects all data merged from new and previous), so it's unnecessary?
  const result = nomints.reduce((combined, nomint) => {
    const { createdAt, gasDay, point } = nomint;
    const index = getEarliestValidTime(moment.utc(createdAt)).diff(startOfGasDay(gasDay), 'hours');
    const amounts = point === LOCATION_POINTS.VTP || index <= 0
      ? nomint.amounts
      : [...combined.amounts.slice(0, index), ...nomint.amounts.slice(index)];

    return {
      ...nomint,
      amounts,
    };
  });

  return {
    ...result,
    // Total sum as value
    value: result.amounts.reduce(add),
  };
};

const combine = (nomints, nomreses, getMarketPartyById) => {
  // const totalNomresOld = getTotalNomResOld(nomreses);
  const totalNomres = getTotalNomRes(nomreses);
  const groupedAndNormalized = nomints.reduce((result, nomint) => {
    const marketParty = getMarketPartyById ? getMarketPartyById(nomint.marketPartyId) : null;
    const normalizedNomint = {
      ...nomint,
      eic1: marketParty ? marketParty.eic : undefined,
      eic2: nomint.shipperEIC,
      gasDay: nomint.date,
    };
    // Groups by key into hash, inserts normalizedNomint into array to sorted index
    // Idea: Probably could call sort() on each key-value instead inplace modification
    const key = createKey(normalizedNomint);
    const sorted = result[key] || [];
    const insertIndex = sorted.findIndex(({ createdAt }) => createdAt > nomint.createdAt);
    const index = insertIndex >= 0 ? insertIndex : sorted.length;
    sorted.splice(index, 0, normalizedNomint);
    return {
      ...result,
      [key]: sorted,
    };
  }, {});

  const aggregated = Object.values(groupedAndNormalized).map(aggregateNomintAmounts);

  return aggregated.map((nomint) => {
    // Find matching nomres and combine with nomint (and remove from nomres list)
    const nomresIndex = totalNomres.findIndex(matches(nomint));
    const [nomres] = nomresIndex >= 0 ? totalNomres.splice(nomresIndex, 1) : [{ value: null }];

    return {
      ...nomint,
      nomint: nomint.value,
      nomres: nomres.value,
    };
  }).concat(totalNomres.map(nomres => ({
    ...nomres,
    eic1: nomres.marketPartyEic1,
    eic2: nomres.marketPartyEic2,
    nomres: nomres.value,
    date: nomres.gasDay,
  })));
};

const filterNominationPointAndDirection = (point, direction) => NOMINATION_POINTS_DIRECTIONS
  .includes(`${point}-${direction}`);

const filterNominationPointsForNetwork = (point, selectedMarketParty) => {
  if (point === LOCATION_POINTS.LNG_INKOO) {
    return (
      selectedMarketParty
      && selectedMarketParty.network
      && selectedMarketParty.network.includes(NETWORKS.LNG_INKOO)
    );
  }
  return true;
};

// Only checking if the the end date has not passed.
const isRelationshipValid = (relationship, point, direction) => (
  moment(getGasDay(), 'YYYY-MM-DD')
    .isSameOrBefore(
      moment(relationship.end, 'YYYY-MM-DD'),
      'day',
    )
  && point === relationship.params.point
  && direction === relationship.params.direction
);
const getMarketPartiesToActonBehalfOfMarketPartyId = (
  selectedMarketPartyId,
  relationships = [],
  marketparties = [],
  point,
  direction,
) => {
  if (relationships === null) return [];
  const authRelationshipIDs = relationships
    .filter(relationship => (
      relationship.status === STATUS.ACCEPTED
      && relationship.type === MARKETPARTY_RELATIONSHIP_TYPES.AUTHORIZE_TO_NOMINATE
      && isRelationshipValid(relationship, point, direction)
      // current (selectedMarketPartyId) is authorized
      && selectedMarketPartyId === relationship.marketPartyIdB
    ))
    .map(({ marketPartyIdA }) => marketPartyIdA);
  return marketparties.filter(mp => authRelationshipIDs.includes(mp.id));
};

export {
  aggregateNomintAmounts,
  combine,
  createKey,
  matches,
  getEarliestValidTime,
  getTotalNomRes,
  isDateAllowed,
  isDayAheadOpenForGasDay,
  dayAheadClosingDatetime,
  filterNominationPointAndDirection,
  filterNominationPointsForNetwork,
  getMarketPartiesToActonBehalfOfMarketPartyId,
};
