import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API, Logger } from 'aws-amplify';
import joi from 'joi';
import {
  Button,
  notification,
} from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalWrapper, { modalConfirmClose, modalConfirmSave } from '../../components/ModalWrapper';
import MatchNominationForm from '../../components/MatchNominationForm';
import {
  getHourlyTimesInGasDay,
} from '../../utils/gasday';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../../components/StatusIcon';

const logger = new Logger('update-nomreses:form');

const MatchNomination = (props) => {
  const {
    nomination,
    skipConfirmation,
  } = props;
  const history = useHistory();

  if (!nomination) {
    history.push('/nominations');
  }

  const { point, direction } = nomination;
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();

  const defaultNominationData = cloneDeep(nomination);
  // Use nomination amounts as the default values for the new matchedAmounts
  // Matching should always send value for each hour.
  if (!defaultNominationData.matchedAmounts || defaultNominationData.matchedAmounts.length === 0) {
    defaultNominationData.matchedAmounts = [...defaultNominationData.amounts];
  }
  const [nominationData, setNominationData] = useState(defaultNominationData);

  const handleClose = () => {
    history.goBack();
  };

  const isButtonDisabled = () => {
    const { matchedAmounts } = nominationData;
    return errors.length > 0
      || !nomination
      || (matchedAmounts && matchedAmounts.length > 0 && isEqual(nomination.matchedAmounts, matchedAmounts));
  };

  const handleCancel = () => (
    isButtonDisabled()
      ? handleClose()
      : modalConfirmClose(t, handleClose)
  );

  const closeButton = (
    <Button
      key="cancel"
      onClick={handleClose}
    >
      {t('common.button.close')}
    </Button>
  );

  const saveMatchedValues = async () => {
    const { id, matchedAmounts = [] } = nominationData;
    const { date } = nomination;
    const updatedMatchedAmounts = [];
    const amountTimesAndHours = getHourlyTimesInGasDay(date);
    amountTimesAndHours.forEach((amount, index) => {
      if (matchedAmounts[index]) {
        updatedMatchedAmounts[index] = matchedAmounts[index];
      } else {
        updatedMatchedAmounts[index] = 0;
      }
    });

    try {
      setIsSaving(true);
      await API.patch('FINTSO', `/lng/nominations/nomint/${id}`, {
        body: {
          id,
          matchedAmounts: updatedMatchedAmounts,
        },
      });
      notification.success({
        message: t('nominations.message.updatedNomresesSuccessfully'),
      });
      handleClose();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('nominations.message.errorCreating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = () => {
    modalConfirmSave(t, saveMatchedValues, t('nominations.title.nomationMatchingConfirmation'));
  };

  const saveButton = (
    <Button
      key="save"
      data-testid="match-nomination-save-button"
      type="primary"
      loading={isSaving}
      disabled={isButtonDisabled()}
      onClick={skipConfirmation ? saveMatchedValues : handleSave}
    >
      {t('common.button.save')}
    </Button>
  );


  const updateChangedFields = (field, updatedObj, error) => {
    const updatedErrors = errors.filter(err => err !== field);

    if (error) {
      updatedErrors.push(field);
    }

    setErrors(updatedErrors);
    setNominationData(updatedObj);

    return !!error;
  };

  const onChangeMatchedValues = (field, value) => {
    const { matchedAmounts = [] } = nominationData;
    const { error } = joi.number().validate(value);

    matchedAmounts[field] = value;
    const updatedObj = {
      ...nominationData,
      matchedAmounts,
    };

    updateChangedFields(field, updatedObj, error);
  };


  return (
    <ModalWrapper
      modalClassName="match"
      className="match-nominations"
      title={(
        <div className="match-nominations__title-container">
          {(nomination && nomination.status) && <StatusIcon data-testid="nomination-matched-icon" status={nomination.status} />}
          <h4 className="match-nominations__title-container--title">
            {`${t('nominations.title.nominationMatching')} (${t(`nominations.label.${point}-${direction}`)})`}
          </h4>
        </div>
        )
      }
      actionButtons={[closeButton, saveButton]}
      handleClose={handleCancel}
      width="80vw"
    >
      <MatchNominationForm
        t={t}
        errors={errors}
        onChange={onChangeMatchedValues}
        nominationData={nominationData}
        nomination={nomination}
      />
    </ModalWrapper>
  );
};

MatchNomination.propTypes = {
  nomination: PropTypes.shape({
    amounts: PropTypes.arrayOf(PropTypes.number),
    nomresAmounts: PropTypes.object,
    matchedAmounts: PropTypes.object,
    date: PropTypes.string,
    direction: PropTypes.string,
    point: PropTypes.string,
    shipperEIC: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  skipConfirmation: PropTypes.bool,
};

MatchNomination.defaultProps = {
  skipConfirmation: false,
};

export default MatchNomination;
