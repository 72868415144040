import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, Logger } from 'aws-amplify';
import { capitalize } from 'lodash';
import {
  Button,
  Col,
  DatePicker,
  Form,
  notification,
  Row,
} from 'antd';

import ModalWrapper from '../ModalWrapper';
import StatusIcon from '../StatusIcon';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import { formatDateStr, localeDateFormat } from '../../utils/i18n';
import { getGasDay, startOfGasDay, startOfOngoingGasDay } from '../../utils/gasday';

const log = new Logger('edit-delivery-relationship');

const EditDeliveryRelationship = (props) => {
  const { deliveryRelationship, handleClose } = props;
  if (!deliveryRelationship) return null;

  const { t } = useTranslation();
  const {
    selectedMarketPartyId,
    currentUser: {
      inAdminGroup,
    },
  } = useContext(Context);
  const defaultDate = deliveryRelationship.end;
  const [date, setDate] = useState(startOfGasDay(defaultDate).add(12, 'hours'));
  const [saving, setSaving] = useState(false);
  const end = getGasDay(date);

  const handleSave = async () => {
    const url = `/marketparties/${selectedMarketPartyId}/deliveryrelationships/${deliveryRelationship.id}`;
    const body = { end };
    setSaving(true);

    try {
      await API.patch('FINTSO', url, { body });
      handleClose();
    } catch (error) {
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('deliveryRelationship.modal.message.error'),
        description,
      });
      log.error('Error updating delivery relationship:', description);
    }

    setSaving(false);
  };

  const actionButtons = [
    <Button key="closeDeliveryRelationship" onClick={handleClose}>{t('common.button.close')}</Button>,
    <Button
      key="saveDeliveryRelationship"
      type="primary"
      onClick={handleSave}
      loading={saving}
      disabled={end === deliveryRelationship.end}
    >
      {t('common.button.save')}
    </Button>,
  ];

  const minimumDate = inAdminGroup
    ? startOfGasDay(deliveryRelationship.start)
    : startOfOngoingGasDay().add(3, 'days');

  const disabledDate = moment => moment.isBefore(minimumDate);

  return deliveryRelationship && (
    <ModalWrapper
      title={t('deliveryRelationship.modal.title')}
      actionButtons={actionButtons}
      visible
      handleClose={handleClose}
    >
      <Form>
        <Form.Item label={t('common.table.header.shipper')}>
          {deliveryRelationship.marketPartyName}
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={t('common.table.header.state')}>
              {deliveryRelationship.state}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('common.table.header.status')}>
              <StatusIcon
                status={deliveryRelationship.status}
                withTitle
                title={capitalize(deliveryRelationship.status)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={t('common.table.header.startTime')}>
              {formatDateStr(deliveryRelationship.start)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('common.table.header.endTime')}>
              <DatePicker
                className="delivery-relationship__end-date__input"
                format={localeDateFormat()}
                disabledDate={disabledDate}
                disabled={false}
                defaultValue={date}
                onChange={setDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalWrapper>
  );
};

export default EditDeliveryRelationship;
