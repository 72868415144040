import joi from 'joi';
import joiDateExtensions from '@joi/date';
import { STATUS_NAMES } from '../constants/status';
import {
  DIRECTION_NAMES,
  LOCATION_POINT_NAMES,
  LOCATION_POINTS,
  NOMINATION_POINTS_DIRECTIONS,
} from '../constants/nominations';

const joiDate = joi.extend(joiDateExtensions);
const timestamp = joiDate.date().utc().strict(false);
const date = timestamp.format('YYYY-MM-DD').raw().required();

const eicCode = joi.string().regex(/^[0-9A-Z-]{16}$/, 'alphanumeric/dash');

const getNominationSchema = point => ({
  id: joi.string().uuid(),
  marketPartyId: joi.string().uuid(),
  onBehalfOfMarketPartyId: joi.string(),
  // Field is optional but validate if it's specified
  // And it's required for location points other than imatra
  shipperEIC:
    point === LOCATION_POINTS.IMATRA ? eicCode.forbidden() : eicCode.required(),
  status: joi.string().valid(...STATUS_NAMES),
  pointDirection: joi // this is only for UI purpose
    .string()
    .valid(...NOMINATION_POINTS_DIRECTIONS),
  point: joi
    .string()
    .valid(...LOCATION_POINT_NAMES)
    .required(),
  direction: joi
    .string()
    .valid(...DIRECTION_NAMES)
    .required(),
  date: date.required(),
  // Allowed amounts length should be between 23 - 25
  // to accomodate transitioning to DST and back
  amounts: joi.array().min(23).max(25).items(joi.number())
    .required(),
  createdAt: timestamp,
  receivedAt: timestamp,
});

export default getNominationSchema;
