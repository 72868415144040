import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { uniq } from 'lodash';
import { createErrorMessage } from './globalStateHelper';
import { userHasPermission } from '../utils/userHelpers';
import { STATUS } from '../constants/status';
import { VALUE_TYPE_NAMES } from '../constants/meteringSites';
import { METERINGSITES_READ_PERMISSIONS } from '../constants/users';

const log = new Logger('context:metering-sites');
const moment = extendMoment(Moment);

async function updateMeteringSites() {
  this.setState({ loadingMeteringSites: true });

  try {
    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const meteringSitesUrl = isAdmin() || isReadOnlyAdmin()
      ? '/admin/meteringsites'
      : `/marketparties/${selectedMarketPartyId}/meteringsites`;

    const { items: meteringSites } = (
      isReadOnlyAdmin || userHasPermission(this.state, METERINGSITES_READ_PERMISSIONS)
    )
      ? await API.get('FINTSO', meteringSitesUrl)
      : { items: [] };

    this.setState({
      meteringSites,
      loadingMeteringSites: false,
    });
    log.info('metering sites updated with data:', meteringSites);
  } catch (error) {
    const { t } = this.props;

    this.setState({
      meteringSites: [],
      loadingMeteringSites: false,
    });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingMeteringSites'),
      description: createErrorMessage(error),
    });
    log.error('error fetching metering sites:', createErrorMessage(error, true));
  }
}

const isMeteringSiteValidForQuery = (
  start,
  end,
  selectedMarketPartyId,
  deliveryRelationships,
) => (meteringSite) => {
  if (meteringSite.marketPartyId === selectedMarketPartyId) {
    return true;
  }

  const relationships = deliveryRelationships.filter(
    ({ meteringSiteId }) => meteringSite.id === meteringSiteId,
  );

  if (relationships.length === 0) {
    return false;
  }

  const queryRange = moment.range(start, end);
  const validRelationships = relationships.filter((relationship) => {
    if (relationship.status !== STATUS.ACCEPTED) {
      return false;
    }

    const relationshipRange = moment.range(relationship.start, relationship.end);
    return queryRange.overlaps(relationshipRange, { adjacent: true });
  });

  return validRelationships.length > 0;
};

async function updateMeteringSiteConsumptions(start, end, resolution) {
  const {
    deliveryRelationships,
    meteringSites,
    selectedMarketPartyId,
    meteringPointOwnerships,
  } = this.state;

  const idsFromOwnerships = meteringPointOwnerships.map(({ meteringPointId }) => meteringPointId);
  const idsFromRelationships = deliveryRelationships.map(({ meteringSiteId }) => meteringSiteId);
  const meteringSiteIds = uniq(idsFromOwnerships.concat(idsFromRelationships));

  if (meteringSiteIds.length === 0) return;

  const url = `/marketparties/${selectedMarketPartyId}/queries/meteringsites`;
  const options = {
    queryStringParameters: {
      start,
      end,
      resolution,
      meteringSites: meteringSiteIds,
      valueTypes: VALUE_TYPE_NAMES,
    },
  };

  try {
    this.setState({ meteringSiteConsumptionsLoading: true });
    const response = await API.get('FINTSO', url, options);
    const data = response.meteringSites
      .map((item) => {
        const { name, type } = meteringSites.find(({ id }) => id === item.meteringSiteId);
        return {
          ...item,
          name,
          type,
        };
      });
    const meteringSiteConsumptions = {
      data,
      requestParams: {
        start,
        end,
        resolution,
      },
    };
    this.setState({ meteringSiteConsumptions, meteringSiteConsumptionsLoading: false });
    log.info('Metering site consumptions updated with data:', meteringSiteConsumptions);
  } catch (error) {
    const { t } = this.props;
    this.setState({
      meteringSiteConsumptions: {
        data: [],
        requestParams: {},
      },
      meteringSiteConsumptionsLoading: false,
    });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingMeteringSiteConsumptions'),
      description,
    });
    log.error('Error updating metering site consumptions:', error.message, description);
  }
}

async function updateMeteringPointAnalysers() {
  const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;

  try {
    this.setState({ meteringPointAnalysersLoading: true });
    const url = isAdmin() || isReadOnlyAdmin()
      ? '/admin/meteringsites/analysers'
      : `/marketparties/${selectedMarketPartyId}/meteringsites/analysers`;
    const { items: meteringPointAnalysers } = await API.get('FINTSO', url);
    this.setState({
      meteringPointAnalysers,
      meteringPointAnalysersLoading: false,
    });
    log.info('metering point analysers updated with data:', meteringPointAnalysers);
  } catch (error) {
    const { t } = this.props;
    this.setState({
      meteringSites: [],
      meteringPointAnalysersLoading: false,
    });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingMeteringPointAnalysers'),
      description: createErrorMessage(error),
    });
    log.error('error fetching metering point analysers:', createErrorMessage(error, true));
  }
}

async function updateMeteringPointOwnerships() {
  const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;

  try {
    this.setState({ meteringPointOwnershipsLoading: true });
    const url = isAdmin() || isReadOnlyAdmin()
      ? '/admin/meteringsites/ownerships'
      : `/marketparties/${selectedMarketPartyId}/meteringsites/ownerships`;
    const { items: meteringPointOwnerships } = await API.get('FINTSO', url);
    this.setState({
      meteringPointOwnerships,
      meteringPointOwnershipsLoading: false,
    });
    log.info('metering point ownerships updated with data:', meteringPointOwnerships);
  } catch (error) {
    const { t } = this.props;
    this.setState({
      meteringSites: [],
      meteringPointOwnershipsLoading: false,
    });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingOwnerships'),
      description: createErrorMessage(error),
    });
    log.error('error fetching metering point ownerships:', createErrorMessage(error, true));
  }
}

const meteringSitesContext = {
  meteringSites: null,
  loadingMeteringSites: false,
  meteringSiteConsumptions: {
    data: [],
    requestParams: {},
  },
  meteringSiteConsumptionsLoading: false,
  updateMeteringSites: () => {},
  updateMeteringSiteConsumptions: () => {},
  meteringPointAnalysers: [],
  meteringPointAnalysersLoading: false,
  updateMeteringPointAnalysers: () => {},
  meteringPointOwnerships: [],
  meteringPointOwnershipsLoading: false,
  updateMeteringPointOwnerships: () => {},
};

const functions = {
  updateMeteringSites,
  updateMeteringSiteConsumptions,
  updateMeteringPointAnalysers,
  updateMeteringPointOwnerships,
};

const meteringSites = {
  ...meteringSitesContext,
  ...functions,
};

export {
  meteringSitesContext,
  meteringSites,
  isMeteringSiteValidForQuery,
};
