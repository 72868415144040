import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwaggerUi from 'swagger-ui';
import { Auth } from 'aws-amplify';

import 'swagger-ui/dist/swagger-ui.css';

class SwaggerUI extends Component {
  async componentDidMount() {
    const {
      url,
      spec,
    } = this.props;

    const ui = SwaggerUi({
      dom_id: '#swaggerContainer',
      syntaxHighlight: false,
      url,
      spec,
      presets: [SwaggerUi.presets.apis],
    });

    const {
      idToken: {
        jwtToken,
      },
    } = await Auth.currentSession();

    ui.preauthorizeApiKey('ApiKeyAuth', process.env.API_KEY);
    ui.preauthorizeApiKey('JWTAuth', jwtToken);
  }

  render() {
    return (
      <div id="swaggerContainer" />
    );
  }
}

SwaggerUI.propTypes = {
  url: PropTypes.string,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  spec: PropTypes.object,
};

SwaggerUI.defaultProps = {
  url: 'v1/swagger.json',
};

export default SwaggerUI;
