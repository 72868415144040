import {
  Button, Col, notification,
  Row,
} from 'antd';
import { API } from 'aws-amplify';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'uuid/v4';
import {
  CAPACITY_PRODUCTS,
} from '../../constants/capacities';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
// import classNames from 'classnames';
import {
  CAPACITYRESERVATION_SCHEMA,
  getProductSchema, PRODUCT_DATE_SCHEMA,
} from '../../schemas/capacityReservations';
import {
  calculateCapacityPrice, getDailyAggregates,
  getEndDate, getHourlyAggregate, getNextAvailableHour, getTimeDifferenceInQty, getTotalPriceOfProducts,
} from '../../utils/capacityHelpers';
import { getGasDay } from '../../utils/gasday';
import { formatDateStr, formatNumber, formatPrice } from '../../utils/i18n';
import ModalWrapper, { modalConfirmClose, modalConfirmSave } from '../ModalWrapper';
import CapacityReservationForm from './CapacityReservationForm';
import './index.less';

class CapacityReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isLoading: false,
      isSaving: false,
      products: [],
      type: null,
    };
  }

  componentDidMount() {
    // const { updateDepositSummary } = this.context;
    // updateDepositSummary();
  }

  getEmptyProduct = (type, period) => ({
    id: uuid(),
    type,
    period: period.toLowerCase(),
    start: null,
    quantity: 1,
    capacity: '0',
    price: 0,
  })

  handleChange = (instanceId, field, value) => {
    const {
      errors,
      products,
    } = this.state;

    const { period } = products.find(prod => prod.id === instanceId);
    const schema = getProductSchema(period);
    let { error: fieldError } = schema[field].validate(value);

    if (field === 'start' && !fieldError) {
      const { error } = PRODUCT_DATE_SCHEMA.validate({
        period,
        start: value,
      });
      fieldError = error;
      if (!fieldError) {
        // Check that start hour is open for selection
        const hour = moment(value).utc();
        fieldError = hour.isBefore(getNextAvailableHour());
      }
    }
    const fieldName = field === 'quantity' ? 'end' : field;
    const updatedErrors = errors.filter(err => !(err.id === instanceId && err.field === fieldName));
    if (fieldError) {
      updatedErrors.push({
        id: instanceId,
        field: fieldName,
      });
    }

    const productInstance = products.find(product => product.id === instanceId);
    const updatedProductInstance = {
      ...productInstance,
      [field]: value,
    };

    const updatedPrice = this.getUpdatedPriceForProducInstance(updatedProductInstance);

    const updatedProductInstances = products.map(product => (
      product.id === instanceId
        ? {
          ...updatedProductInstance,
          price: updatedPrice,
        }
        : product
    ));

    this.setState({
      errors: updatedErrors,
      products: updatedProductInstances,
    }, () => {
      if (field === 'start') {
        const end = getEndDate(productInstance);
        const quantity = getTimeDifferenceInQty(value, end, period);
        switch (true) {
          // eslint-disable-next-line no-restricted-globals
          case isNaN(quantity):
            this.handleChange(instanceId, 'quantity', updatedProductInstance.quantity);
            break;
          case quantity < 0:
            this.handleChange(instanceId, 'quantity', 0);
            break;
          default:
            this.handleChange(instanceId, 'quantity', quantity);
        }
      }
    });
  }

  updateProductTypes = (type) => {
    const { products } = this.state;
    this.setState({
      products: products.map(product => ({
        ...product,
        type,
      })),
    });
  }

  updateProductPrices = () => {
    this.setState(prevState => ({
      products: prevState.products.map(product => ({
        ...product,
        price: this.getUpdatedPriceForProducInstance(product),
      })),
    }));
  }

  getUpdatedPriceForProducInstance = (instance) => {
    const { capacityPrices } = this.context;
    return calculateCapacityPrice(instance, capacityPrices);
  }

  handlePointChange = (value) => {
    this.setState({
      type: value,
    });
    this.updateProductTypes(value);
    this.updateProductPrices();
  }

  handleProductQuantityChange = (product) => {
    this.setState(prevState => ({
      products: [
        ...prevState.products,
        this.getEmptyProduct(prevState.type, CAPACITY_PRODUCTS[product]),
      ],
    }));
  }

  isSaveButtonDisabled = () => {
    const {
      errors,
      isLoading,
      products,
    } = this.state;
    const { depositSummaryLoading } = this.context;

    return depositSummaryLoading
      || isLoading
      || errors.length > 0
      || products.length < 1;
  }

  handleClose = () => {
    const { t, onClose } = this.props;

    return this.isSaveButtonDisabled()
      ? onClose()
      : modalConfirmClose(t, () => onClose());
  }

  handleCloseProductInstance = (instanceId) => {
    this.setState(prevState => ({
      products: prevState.products.filter(product => product.id !== instanceId),
    }));
  }

  remapProductsforApi = products => products.map((product) => {
    const remappedProduct = {
      ...product,
      capacity: Number.parseFloat(product.capacity),
    };
    delete remappedProduct.id;
    delete remappedProduct.price;

    return remappedProduct;
  });

  handleSave = async () => {
    const { handleDbChange, selectedMarketPartyId: marketPartyId } = this.context;
    const { products } = this.state;
    const capacityReservation = {
      products: this.remapProductsforApi(products),
    };
    const { t } = this.props;

    const { error } = CAPACITYRESERVATION_SCHEMA.validate(capacityReservation);
    if (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.incorrectInput'),
        description: error.toString(),
      });
      return;
    }

    try {
      this.setState({ isSaving: true });
      const { onClose } = this.props;
      const init = { body: { ...capacityReservation } };
      const newCapacityReservation = await API.post('FINTSO', `/marketparties/${marketPartyId}/capacitybookings`, init);
      handleDbChange('CapacityReservation', 'create', newCapacityReservation);
      onClose(newCapacityReservation);
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.failedToSave'),
        description: createErrorMessage(e),
      });
      this.setState({ isSaving: false });
    }
  }

  renderContent = () => {
    const { t } = this.props;
    const {
      errors,
      products,
      type,
    } = this.state;
    const {
      depositSummary: {
        remaining = 0,
      },
    } = this.context;
    const totalPrice = getTotalPriceOfProducts(products);

    return (
      <>
        <CapacityReservationForm
          errors={errors}
          onChange={this.handleChange}
          onClose={this.handleCloseProductInstance}
          onPointChange={this.handlePointChange}
          onProductQuantityChange={this.handleProductQuantityChange}
          type={type}
          products={products}
        >
          <div
            className="capacityreservation__modal__summary"
            // classNames('capacityreservation__modal__summary',
            // { 'capacityreservation__modal__summary__insufficient-deposit':
            //   totalPrice.greaterThan(remaining), }
            // )}
          >
            <Row className="capacityreservation__modal__summary__row">
              <Col span={12}>
                <p className="capacityreservation__modal__summary__row__label">
                  {t('capacityReservation.form.label.totalPrice')}
                </p>
              </Col>
              <Col span={12}>
                <p className="capacityreservation__modal__summary__row__sum">
                  {`${formatPrice(totalPrice)}`}
                </p>
              </Col>
            </Row>
            {false && (
            <Row className="capacityreservation__modal__summary__row">
              <Col span={12}>
                <p className="capacityreservation__modal__summary__row__label">
                  {t('capacityReservation.form.label.remainingDeposit')}
                </p>
              </Col>
              <Col span={12}>
                <p className="capacityreservation__modal__summary__row__sum">
                  {`${formatPrice(remaining)}`}
                </p>
              </Col>
            </Row>
            )}
          </div>
        </CapacityReservationForm>
        <div className="capacityreservation__modal__disclaimer">
          <p className="capacityreservation__modal__disclaimer__label">
            {`${t('capacityReservation.form.label.disclaimer')}:`}
          </p>
          <p>
            {t('capacityReservation.form.disclaimer')}
          </p>
          <p>
            {t('capacityReservation.form.info.line1')}
          </p>
          <p>
            {t('capacityReservation.form.info.line2')}
            {' '}
            <a
              href="https://gasgrid.fi/en/gas-market/market-rules-and-recommendations/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('capacityReservation.form.info.linkDescription')}
            </a>
            .
          </p>
        </div>
      </>
    );
  }

  renderActionButtons = () => {
    const {
      isSaving,
      products,
    } = this.state;
    const { t } = this.props;
    const action = t('common.button.save').toLowerCase();
    const totalPrice = formatPrice(getTotalPriceOfProducts(products));
    const hourlyAggregate = getHourlyAggregate(products);
    const dailyAggregates = getDailyAggregates(products);
    const renderSaveTitle = () => (
      <>
        <p className="capacity-reservation__dialog__message">
          {t('capacityReservation.dialog.actionMessage', { action })}
        </p>
        <p className="capacity-reservation__dialog__total-price">
          {t('capacityReservation.dialog.totalPriceMessage', { totalPrice })}
        </p>
        {!!hourlyAggregate && (
          <p className="capacity-reservation__dialog__hourly-aggregate">
            {t('capacityReservation.dialog.hourlyAggregateMessage', {
              date: formatDateStr(getGasDay()),
              value: formatNumber(hourlyAggregate, 0, 0),
            })}
          </p>
        )}
        {!!dailyAggregates.length && (
          <>
            <p className="capacity-reservation__dialog__hourly-aggregate">
              {t('capacityReservation.dialog.dailyAggregatesTitle')}
            </p>
            <ul>
              {dailyAggregates.map(({ date, value }) => (
                <li
                  className="capacity-reservation__dialog__hourly-aggregate"
                  key={`dailyAggregate-${date}`}
                >
                  {t('capacityReservation.dialog.aggregateMessage', {
                    date: formatDateStr(date),
                    value: formatNumber(value, 0, 0),
                  })}
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    );

    return ([
      <Button
        className="capacityreservation__modal__cancel-button"
        key="cancel"
        onClick={this.handleClose}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="capacityreservation__modal__save-button"
        disabled={this.isSaveButtonDisabled()}
        key="save"
        loading={isSaving}
        type="primary"
        onClick={() => modalConfirmSave(
          t,
          this.handleSave,
          renderSaveTitle(),
        )}
      >
        {t('common.button.saveAndClose')}
      </Button>,
    ]);
  }

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="capacityreservation__modal"
        title={t('capacityReservation.form.title.newCapacityReservation')}
        handleClose={this.handleClose}
        actionButtons={this.renderActionButtons()}
        isLoading={isLoading}
        width="60%"
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

CapacityReservation.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CapacityReservation.displayName = 'CapacityReservation';
CapacityReservation.contextType = Context;

export default withTranslation()(CapacityReservation);
export {
  CapacityReservation as PureComponent,
};
