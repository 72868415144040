import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';

import PageHeaderBar from '../../components/PageHeaderBar';
import RelationshipsTable from '../../components/RelationshipsTable';
import { TYPES } from '../../constants/relationships';

const NetworkLevel = ({ t }) => (
  <>
    <PageHeaderBar title={t('deliveryRelationship.title.networkLevel')} />
    <Layout.Content className="layout__container__page__content">
      <RelationshipsTable type={TYPES.OPERATORS} />
    </Layout.Content>
  </>
);

NetworkLevel.propTypes = {
  t: PropTypes.func.isRequired,
};

NetworkLevel.displayName = 'NetworkLevel';
export default withTranslation()(NetworkLevel);

export {
  NetworkLevel as PureComponent,
};
