import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import {
  Col, Form, Input, Row, Select, Checkbox, Button, Modal,
} from 'antd';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import {
  STATUS,
  ROLE_FIELDS,
  ROLES,
  NETWORK_FIELDS,
} from '../../constants/marketParties';
import UserForm from '../User/userForm';

import './index.less';

const { Option } = Select;

const FIELDS = [
  {
    label: 'Name',
    id: 'name',
    column: 'left',
  },
  {
    label: 'VAT id',
    id: 'vatId',
    column: 'left',
  },
  {
    label: 'EIC-X',
    id: 'eic',
    column: 'left',
  },
  {
    label: 'Retailer GS1',
    id: 'gs1Retailer',
    column: 'left',
  },
  {
    label: 'DSO GS1',
    id: 'gs1Dso',
    column: 'left',
  },
  {
    label: 'Street address',
    id: 'street',
    column: 'right',
  },
  {
    label: 'Postal code',
    id: 'postalCode',
    column: 'right',
  },
  {
    label: 'City',
    id: 'city',
    column: 'right',
  },
  {
    label: 'Country',
    id: 'country',
    column: 'right',
  },
];

class MarketPartyForm extends React.Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const {
      nativeEvent: {
        srcElement: {
          id: field,
        },
        target: {
          value,
        },
      },
    } = event;

    onChange(field, value);
  };

  handleCheckBoxChange = (event) => {
    const { onChange } = this.props;
    const {
      nativeEvent: {
        srcElement: { id: field },
        target: { checked },
      },
    } = event;

    onChange(field, checked);
  };

  handleCheckBoxGroupChange = (checkedValues, field) => {
    const { onChange } = this.props;

    onChange(field, checkedValues);
  };

  isFieldRequired = (fieldId) => {
    const { marketParty: { roles } } = this.props;

    switch (fieldId) {
      case 'gs1Retailer':
        return roles.includes(ROLES.RETAILER);
      case 'gs1Dso':
        return roles.includes(ROLES.DSO);
      default:
        return true;
    }
  };

  getFields = (column) => {
    if (column === 'left') return FIELDS.filter(field => field.column === 'left');
    if (column === 'right') return FIELDS.filter(field => field.column === 'right');
    return FIELDS;
  };

  renderMarketPartyRoles = () => {
    const {
      t,
      marketParty,
      errors,
      disabled,
    } = this.props;
    const field = 'roles';
    const hasError = errors.includes(field);
    const rolesAsOptions = ROLE_FIELDS.map(item => ({
      label: t(`marketParty.label.${item.title}`),
      value: item.type,
    }));

    return (
      <Form.Item
        label={t('marketParty.label.typeOfMarketParty')}
        key={field}
        className={classNames(
          'marketpartyform__roles__label',
          { 'has-error': hasError },
        )}
        required
      >
        <Checkbox.Group
          className="marketpartyform__roles__options"
          options={rolesAsOptions}
          defaultValue={marketParty.roles}
          onChange={checkedValues => this.handleCheckBoxGroupChange(checkedValues, field)}
          disabled={disabled}
        />
      </Form.Item>
    );
  };

  renderMarketPartyNetworks = () => {
    const {
      t,
      marketParty,
      errors,
      disabled,
    } = this.props;
    const field = 'network';
    const hasError = errors.includes(field);
    const networksAsOptions = NETWORK_FIELDS.map(item => ({
      label: t(`marketParty.label.${item.title}`),
      value: item.type,
    }));

    return (
      <Form.Item
        label={t('marketParty.label.network')}
        key={field}
        className={classNames(
          'marketpartyform__network__label',
          { 'has-error': hasError },
        )}
      >
        <Checkbox.Group
          className="marketpartyform__network__options"
          options={networksAsOptions}
          defaultValue={marketParty.network}
          onChange={checkedValues => this.handleCheckBoxGroupChange(checkedValues, field)}
          disabled={disabled}
        />
      </Form.Item>
    );
  };

  renderFields = column => this.getFields(column)
    .map((field) => {
      const {
        errors,
        marketParty,
        t,
        disabled,
      } = this.props;
      const hasError = errors.includes(field.id);

      return (
        <Form.Item
          label={t(`common.table.header.${field.id}`)}
          key={field.id}
          className={classNames(
            'marketpartyform__item',
            { 'has-error': hasError },
          )}
          required={this.isFieldRequired(field.id)}
        >
          <Input
            className="marketpartyform__item__input"
            id={field.id}
            name={field.id}
            onChange={this.handleChange}
            value={marketParty[field.id]}
            disabled={disabled}
          />
          {hasError && (
            <p className="marketpartyform__item__error">
              {t('common.notifications.incorrectInput')}
              {` ${field.label}`}
            </p>
          )}
        </Form.Item>
      );
    });

  renderNotesForAdmin = () => {
    const {
      t,
      marketParty,
      errors,
      disabled,
    } = this.props;
    const { isAdmin, isReadOnlyAdmin } = this.context;
    const field = 'notes';
    const hasError = errors.includes(field);

    if (isReadOnlyAdmin() || isAdmin()) {
      return (
        <Form.Item
          label={t('marketParty.label.notes')}
          key={field}
          className={classNames('marketpartyform__notes__label', {
            'has-error': hasError,
          })}
        >
          <TextArea
            id="notes"
            defaultValue={marketParty.notes}
            disabled={disabled}
            value={marketParty.notes}
            onChange={this.handleChange}
          />
        </Form.Item>
      );
    }
    return null;
  };

  handleConfirmDelete = () => {
    const { handleDelete, t } = this.props;

    Modal.confirm({
      centered: true,
      title: t('marketParty.modal.marketPartyDeleteTitle'),
      content: t('marketParty.modal.marketPartyDeleteContent'),
      onOk: () => handleDelete(),
    });
  };

  renderStatusDropdown = () => {
    const {
      onChange,
      errors,
      marketParty,
      t,
      disabled,
      marketPartyOriginal,
      isSaving,
    } = this.props;

    const { isAdmin } = this.context;

    const field = 'status';
    const hasError = errors.includes(field);

    return (
      <>
        <Form.Item
          label={t(`common.table.header.${field}`)}
          key={field}
          className={classNames('marketpartyform__status__label', {
            'has-error': hasError,
          })}
          required
        >
          <Select
            className="marketpartyform__status__select"
            value={marketParty[field]}
            id={field}
            name={field}
            onChange={(value) => onChange(field, value)}
            disabled={disabled}
          >
            {Object.values(STATUS).map((status) => (
              <Option
                key={status}
                value={status}
                className="marketpartyform__status__option"
              >
                {capitalize(status)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {marketPartyOriginal.status === 'declined' && isAdmin() && (
          <Button
            className="marketpartyform__modal__deleteButton"
            type="primary"
            onClick={this.handleConfirmDelete}
            loading={isSaving}
          >
            {t('common.button.delete')}
          </Button>
        )}
      </>
    );
  };

  renderMarketPartyHidden = () => {
    const {
      t,
      marketParty,
      errors,
      disabled,
    } = this.props;
    const field = 'hidden';
    const hasError = errors.includes(field);

    return (
      <Form.Item
        label={t('marketParty.label.visibility')}
        key={field}
        className={classNames(
          'marketpartyform__hidden__label',
          { 'has-error': hasError },
        )}
      >
        <Checkbox
          id={field}
          className="marketpartyform__hidden"
          checked={marketParty.hidden}
          onChange={this.handleCheckBoxChange}
          disabled={disabled}
        >
          {t('marketParty.label.hidden')}
        </Checkbox>
      </Form.Item>
    );
  };

  renderContactPerson = () => {
    const {
      errors,
      marketParty,
      onContactPersonChange,
    } = this.props;

    return (
      <UserForm
        errors={errors}
        user={marketParty.contactPerson || null}
        onChange={onContactPersonChange}
        userAsContactPerson
      />
    );
  };

  render() {
    return (
      <Form className="marketpartyform">
        <Row>
          {this.renderMarketPartyRoles()}
        </Row>
        <Row>
          {this.renderMarketPartyNetworks()}
        </Row>
        <Row>
          {this.renderNotesForAdmin()}
        </Row>
        <Row>
          <Col span={11}>
            {this.renderFields('left')}
          </Col>
          <Col span={2} />
          <Col span={11}>
            {this.renderFields('right')}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {this.renderStatusDropdown()}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {this.renderMarketPartyHidden()}
          </Col>
        </Row>
        {this.renderContactPerson()}
      </Form>
    );
  }
}

const marketPartyProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  vatId: PropTypes.string.isRequired,
  gs1Retailer: PropTypes.string,
  gs1Dso: PropTypes.string,
  eic: PropTypes.string,
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  roles: PropTypes.array.isRequired,
  network: PropTypes.array.isRequired,
  notes: PropTypes.string,
  hidden: PropTypes.bool,
  status: PropTypes.string,
  contactPerson: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    phonenumber: PropTypes.string,
    email: PropTypes.string,
  }),
});

MarketPartyForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  marketParty: marketPartyProps.isRequired,
  onChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  onContactPersonChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  marketPartyOriginal: marketPartyProps.isRequired,
  isSaving: PropTypes.bool.isRequired,
};
MarketPartyForm.contextType = Context;
MarketPartyForm.displayName = 'MarketPartyForm';

export default withTranslation()(MarketPartyForm);

export {
  MarketPartyForm as PureComponent,
  FIELDS,
};
