import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import { Layout, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import CapacityReservationsTable from '../../components/CapacityReservationsTable';
import CapacityReservation from '../../components/CapacityReservation';
import PageHeaderBar from '../../components/PageHeaderBar';
import { CAPACITY_WRITE_PERMISSIONS } from '../../constants/users';
import { userIsAllowedToWrite } from '../../utils/userHelpers';

class CapacityReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newCapacityReservation: null };
  }

  componentDidMount() {
    const { capacityPrices, updateCapacityPrices } = this.context;
    if (!capacityPrices) updateCapacityPrices();
  }

  handleCloseCapacityReservation = (newCapacityReservation) => {
    if (newCapacityReservation !== undefined) {
      this.setState({ newCapacityReservation });
    }
    const { history } = this.props;
    history.push('/capacitybookings');
  }

  /**
   * Reservation <Button /> click handler.
   */
  handleReservationClick = () => {
    const { history } = this.props;
    history.push('/capacitybookings/new-booking');
  };

  renderButtons = () => {
    if (!userIsAllowedToWrite(this.context, CAPACITY_WRITE_PERMISSIONS)) {
      return null;
    }

    const { t } = this.props;
    return (
      <Button
        className="page__header__buttons__reservation"
        type="primary"
        onClick={this.handleReservationClick}
      >
        {t('capacity.button.newCapacityReservation')}
      </Button>
    );
  }

  renderCapacityReservation = () => (
    userIsAllowedToWrite(this.context, CAPACITY_WRITE_PERMISSIONS)
      ? <CapacityReservation onClose={this.handleCloseCapacityReservation} />
      : null
  )

  render = () => {
    const { newCapacityReservation } = this.state;
    const { selectedMarketPartyId } = this.context;
    const { t } = this.props;

    return (
      <>
        <PageHeaderBar
          title={selectedMarketPartyId === 'admin'
            ? t('capacityReservation.title.adminCapacityReservations')
            : t('capacityReservation.title.yourCapacityReservations')
          }
          actionItems={this.renderButtons()}
        />
        <Layout.Content className="layout__container__page__content capacityreservations capacitybookings">
          <CapacityReservationsTable
            title={t('capacityReservation.title.capacityReservationsSummary')}
            actionsVisible
            newCapacityReservation={newCapacityReservation}
            includeTimestamps
            renderHeaderActions
          />
        </Layout.Content>
        <Route
          path="/capacitybookings/new-booking"
          render={this.renderCapacityReservation}
        />
      </>
    );
  }
}

CapacityReservations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

CapacityReservations.displayName = 'CapacityReservations';
CapacityReservations.contextType = Context;

const CapacityReservationsTranslated = withTranslation()(CapacityReservations);
export default withRouter(CapacityReservationsTranslated);

export {
  CapacityReservationsTranslated as PureComponent,
};
