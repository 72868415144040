import {
  ROLES,
  ROLE_NAMES,
} from './marketParties';

const PERMISSIONS = Object.assign(
  {},
  { ADMIN: 'admin' },
  { INVOICE: 'invoice' },
  ROLES,
  ...ROLE_NAMES.map(role => ({
    [`${role}_READ_ONLY`.toUpperCase()]: `${role}ReadOnly`,
  })),
);

const PERMISSION_NAMES = Object.values(PERMISSIONS);

const USER_RIGHTS = ['read', 'write'];

const BALANCE_READ_PERMISSIONS = [
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.TRADER_READ_ONLY,
];
const CAPACITY_READ_PERMISSIONS = BALANCE_READ_PERMISSIONS;
const DELIVERY_RELATIONSHIPS_READ_PERMISSIONS = [
  PERMISSIONS.ENDUSER_READ_ONLY,
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.PRODUCER_READ_ONLY,
  PERMISSIONS.DSO_READ_ONLY,
];
const METERINGSITES_READ_PERMISSIONS = [
  PERMISSIONS.ENDUSER_READ_ONLY,
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.PRODUCER_READ_ONLY,
  PERMISSIONS.DSO_READ_ONLY,
];

const NOMINATIONS_READ_PERMISSIONS = [
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.TRADER_READ_ONLY,
  PERMISSIONS.LTO_READ_ONLY,
];
const NOMINATION_WRITE_PERMISSIONS = [
  PERMISSIONS.SHIPPER,
  PERMISSIONS.TRADER,
];

const BALANCE_WRITE_PERMISSIONS = [PERMISSIONS.SHIPPER, PERMISSIONS.TRADER];
const CAPACITY_WRITE_PERMISSIONS = BALANCE_WRITE_PERMISSIONS;

const RELATIONSHIPS_READ_PERMISSIONS = [
  PERMISSIONS.DSO_READ_ONLY,
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.RETAILER_READ_ONLY,
  PERMISSIONS.LTO_READ_ONLY,
];

const RELATIONSHIPS_UPDATE_PERMISSIONS = [PERMISSIONS.SHIPPER];
const RELATIONSHIPS_CREATE_PERMISSIONS = [
  PERMISSIONS.DSO,
  PERMISSIONS.RETAILER,
  PERMISSIONS.SHIPPER,
];

const TAXDECLARATIONS_READ_PERMISSIONS = [
  PERMISSIONS.ADMIN,
  PERMISSIONS.ENDUSER_READ_ONLY,
  PERMISSIONS.SHIPPER_READ_ONLY,
  PERMISSIONS.DSO_READ_ONLY,
];

const TAXDECLARATIONS_WRITE_PERMISSIONS = [
  PERMISSIONS.ENDUSER,
  PERMISSIONS.SHIPPER,
  PERMISSIONS.DSO,
];

export {
  PERMISSIONS,
  PERMISSION_NAMES,
  USER_RIGHTS,
  BALANCE_READ_PERMISSIONS,
  CAPACITY_READ_PERMISSIONS,
  DELIVERY_RELATIONSHIPS_READ_PERMISSIONS,
  METERINGSITES_READ_PERMISSIONS,
  BALANCE_WRITE_PERMISSIONS,
  CAPACITY_WRITE_PERMISSIONS,
  NOMINATION_WRITE_PERMISSIONS,
  NOMINATIONS_READ_PERMISSIONS,
  RELATIONSHIPS_READ_PERMISSIONS,
  RELATIONSHIPS_UPDATE_PERMISSIONS,
  RELATIONSHIPS_CREATE_PERMISSIONS,
  TAXDECLARATIONS_READ_PERMISSIONS,
  TAXDECLARATIONS_WRITE_PERMISSIONS,
};
