const UNITS = {
  MWH: 'mwh',
  KWH: 'kwh',
};

const UNIT_NAMES = Object.values(UNITS);

const UNITS_EXTENDED = {
  GWH: 'gwh',
  ...UNITS,
};

const UNIT_EXTENDED_NAMES = Object.values(UNITS_EXTENDED);

const UNITS_PER_HOUR = {
  MWH_PER_HOUR: 'mwhPerHour',
  KWH_PER_HOUR: 'kwhPerHour',
};

const UNITS_PER_HOUR_NAMES = Object.values(UNITS_PER_HOUR);

export {
  UNITS,
  UNIT_NAMES,
  UNITS_EXTENDED,
  UNIT_EXTENDED_NAMES,
  UNITS_PER_HOUR,
  UNITS_PER_HOUR_NAMES,
};
