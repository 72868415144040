import React from 'react';
import { Button } from 'antd';
import { formatPercentage } from './i18n';

const NOTIFICATIONS = {
  ANNOUNCEMENT_CREATED: 'Announcement Created',
  ANNOUNCEMENT_UPDATED: 'Announcement Updated',
  MARKET_PARTY_REGISTERED: 'Market Party Registered',
  BALANCE_GROUP_REQUEST: 'Balance Group Request',
  BALANCE_GROUP_RESPONSE: 'Balance Group Response',
  BALANCE_GROUP_CHANGE: 'Balance Group Change',
  BALANCE_GROUP_MEMBER_REQUEST: 'Balance Group Member Request',
  BALANCE_GROUP_MEMBER_RESPONSE: 'Balance Group Member Response',
  BALANCE_GROUP_MEMBER_CHANGE: 'Balance Group Member Change',
  CAPACITY_RESERVATION_REQUEST: 'Capacity Reservation Request',
  CAPACITY_RESERVATION_RESPONSE: 'Capacity Reservation Response',
  CAPACITY_TRANSFER_REQUEST: 'Capacity Transfer Request',
  CAPACITY_TRANSFER_RESPONSE: 'Capacity Transfer Response',
  DELIVERY_RELATIONSHIP_REQUEST: 'Delivery Relationship Request',
  DELIVERY_RELATIONSHIP_RESPONSE: 'Delivery Relationship Response',
  MARKET_RELATIONSHIP_REQUEST: 'Market Party Relationship Request',
  MARKET_RELATIONSHIP_RESPONSE: 'Market Party Relationship Response',
  IMBALANCE: 'Imbalance',
  BALANCE_GAS_PRICE_UPDATE: 'Balance gas price update',
};

const renderMessage = (messageText, actionText, url, onActionClick) => (
  <div>
    <p>
      {messageText}
    </p>
    <Button
      onClick={() => onActionClick(url)}
      type="primary"
    >
      {actionText}
    </Button>
  </div>
);

const parseNotification = (data, onActionClick) => {
  switch (data.type) {
    case NOTIFICATIONS.MARKET_PARTY_REGISTERED: {
      const {
        marketParty,
      } = data;

      const message = renderMessage(
        'Newly registered market party needs your approval.',
        'Go to Market Party',
        `/marketparties/${marketParty.id}`,
        onActionClick,
      );

      return {
        subject: 'New Market Party registered',
        message,
      };
    }

    case NOTIFICATIONS.ANNOUNCEMENT_CREATED: {
      const { id, subject } = data;
      const message = renderMessage(
        subject,
        'Go to Announcement',
        `/frontpage#announcement-${id}`,
        onActionClick,
      );

      return {
        subject: 'New Announcement is created',
        message,
      };
    }

    case NOTIFICATIONS.ANNOUNCEMENT_UPDATED: {
      const { id, subject } = data;
      const message = renderMessage(
        subject,
        'Go to Announcement',
        `/frontpage#announcement-${id}`,
        onActionClick,
      );

      return {
        subject: 'Announcement is updated',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_REQUEST: {
      const {
        balanceGroupId,
      } = data;

      const message = renderMessage(
        'Balance Group Request needs your approval.',
        'Go to Balance Group',
        `/balancegroups/${balanceGroupId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Request',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_RESPONSE: {
      const {
        balanceGroupId,
        status,
      } = data;

      const message = renderMessage(
        `Balance Group Response: ${status}`,
        'Go to Balance Group',
        `/balancegroups/${balanceGroupId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Response',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_CHANGE: {
      const {
        balanceGroupId,
        oldEnd,
        end,
      } = data;

      const message = renderMessage(
        `Balance Group Change (end: ${oldEnd} -> ${end})`,
        'Go to Balance Group',
        `/balancegroups/${balanceGroupId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Change',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_MEMBER_REQUEST: {
      const {
        balanceGroupMembershipId,
      } = data;

      const message = renderMessage(
        'Balance Group Member Request needs your approval',
        'Go to Balance Group Member',
        `/balancegroups/membership/${balanceGroupMembershipId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Member Request',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_MEMBER_RESPONSE: {
      const {
        balanceGroupMembershipId,
        status,
      } = data;

      const message = renderMessage(
        `Balance Group Member Response: ${status}`,
        'Go to Balance Group Member',
        `/balancegroups/membership/${balanceGroupMembershipId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Member Response',
        message,
      };
    }

    case NOTIFICATIONS.BALANCE_GROUP_MEMBER_CHANGE: {
      const {
        balanceGroupMembershipId,
        oldEnd,
        end,
      } = data;

      const message = renderMessage(
        `Balance Group Member Change (end: ${oldEnd} -> ${end})`,
        'Go to Balance Group Member',
        `/balancegroups/membership/${balanceGroupMembershipId}`,
        onActionClick,
      );

      return {
        subject: 'Balance Group Member Change',
        message,
      };
    }

    // TODO: Use correct url for specific capacity reservation
    case NOTIFICATIONS.CAPACITY_RESERVATION_REQUEST: {
      const message = renderMessage(
        'Capacity Reservation Request needs your approval.',
        'Go to Capacity Reservation',
        '/capacitybookings',
        onActionClick,
      );

      return {
        subject: 'Capacity Reservation Request',
        message,
      };
    }

    // TODO: Use correct url for specific capacity reservation
    case NOTIFICATIONS.CAPACITY_RESERVATION_RESPONSE: {
      const {
        status,
      } = data;

      const message = renderMessage(
        // TODO: More descriptive message?
        `Capacity Reservation Response: ${status}`,
        'Go to Capacity Reservation',
        '/capacitybookings',
        onActionClick,
      );

      return {
        subject: 'Capacity Reservation Response',
        message,
      };
    }

    // TODO: Use correct url for specific capacity transfer
    case NOTIFICATIONS.CAPACITY_TRANSFER_REQUEST: {
      const message = renderMessage(
        'Capacity Transfer Request needs your approval.',
        'Go to Capacity Transfer',
        '/capacityrightstransfers',
        onActionClick,
      );

      return {
        subject: 'Capacity Transfer Request',
        message,
      };
    }

    // TODO: Use correct url for specific capacity transfer
    case NOTIFICATIONS.CAPACITY_TRANSFER_RESPONSE: {
      const {
        buyerStatus,
        status,
      } = data;

      const message = renderMessage(
        // TODO: More descriptive message?
        `Capacity Transfer Response: ${(status !== undefined) ? status : `${buyerStatus} by buyer`}`,
        'Go to Capacity Transfer',
        '/capacityrightstransfers',
        onActionClick,
      );

      return {
        subject: 'Capacity Transfer Response',
        message,
      };
    }

    case NOTIFICATIONS.DELIVERY_RELATIONSHIP_REQUEST: {
      const message = renderMessage(
        'Delivery Relationship Request needs your approval.',
        'Go to Delivery Relationship',
        '/deliveryrelationships',
        onActionClick,
      );

      return {
        subject: 'Delivery Relationship Request',
        message,
      };
    }

    case NOTIFICATIONS.DELIVERY_RELATIONSHIP_RESPONSE: {
      const {
        relationship: {
          status,
        },
      } = data;

      const message = renderMessage(
        `Delivery Relationship Response: ${status}`,
        'Go to Delivery Relationship',
        '/deliveryrelationships',
        onActionClick,
      );

      return {
        subject: 'Delivery Relationship Response',
        message,
      };
    }

    case NOTIFICATIONS.MARKET_RELATIONSHIP_REQUEST: {
      const message = renderMessage(
        'Market Party Relationship Request needs your approval.',
        'Go to Market Party Relationship',
        '/relationships-received',
        onActionClick,
      );

      return {
        subject: 'Market Party Relationship Request',
        message,
      };
    }

    case NOTIFICATIONS.MARKET_RELATIONSHIP_RESPONSE: {
      const {
        relationship: {
          status,
        },
      } = data;

      const message = renderMessage(
        `Market Party Relationship Response: ${status}`,
        'Go to Market Party Relationship',
        '/relationships-created',
        onActionClick,
      );

      return {
        subject: 'Market Party Relationship Response',
        message,
      };
    }

    case NOTIFICATIONS.IMBALANCE: {
      const {
        percentage,
        previousPercentage,
        updatedStatus,
        previousStatus,
      } = data;

      const message = renderMessage(
        <>
          {`Current: ${formatPercentage(percentage)} (${updatedStatus})`}
          <br />
          {`Previous: ${formatPercentage(previousPercentage)} (${previousStatus})`}
        </>,
        'Go to Monitoring',
        '/capacityandnominations',
        onActionClick,
      );

      return {
        subject: 'Imbalance percentage change',
        message,
        options: {
          duration: 0,
        },
      };
    }

    case NOTIFICATIONS.BALANCE_GAS_PRICE_UPDATE: {
      const { buy, sell } = data;
      const buyPrice = buy ? `Buy price: ${buy} €/MWh` : '';
      const sellPrice = sell ? `Sell price: ${sell} €/MWh` : '';

      const message = renderMessage(
        <>
          {buyPrice}
          <br />
          {sellPrice}
        </>,
        'Go to Front Page',
        '/frontpage',
        onActionClick,
      );

      return {
        subject: 'New balance gas price',
        message,
      };
    }

    default:
      throw new Error(`Unknown notification type: ${data.type}`);
  }
};

export {
  NOTIFICATIONS,
  parseNotification,
};
