import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import {
  Button,
  Input,
  DatePicker,
  Form,
  Row,
  Col,
} from 'antd';

import ModalWrapper from '../../components/ModalWrapper';
import { formatPrice, formatDateTimeStr, localeDateFormat } from '../../utils/i18n';

import './editDeposit.less';

const EditDeposit = (props) => {
  const {
    dataSource,
    close,
    save,
    visible,
  } = props;

  if (!dataSource) return null;

  const {
    marketParty,
    marketPartyId,
    remaining,
    set,
    timestamp,
    used,
    start,
    end,
  } = dataSource;

  const [saving, setSaving] = useState(false);
  const [deposit, setDeposit] = useState(set);
  const [dateRange, setDateRange] = useState({ start, end });
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const hasRequired = (deposit !== '') && dateRange.start && dateRange.end;

  const hasChanged = (Number(deposit) !== Number(set))
    || (dateRange.end !== end)
    || (dateRange.start !== start);

  const canSave = hasRequired && hasChanged;

  const clearState = () => {
    setDeposit(set);
    setDateRange({ start, end });
    setError('');
  };

  useEffect(() => {
    clearState();
  }, [set]);

  const handleClose = () => {
    clearState();
    close();
  };

  const handleSave = async () => {
    const value = Number(deposit);
    if (Number.isNaN(value) || value < 0) {
      setError(t('deposits.error'));
      return;
    }

    setSaving(true);
    await save(marketPartyId, value, dateRange.start, dateRange.end);
    setError('');
    close();
    setSaving(false);
  };

  // TODO: move to own component
  const renderDate = (dateRangeValue, type, setFn, name, className) => (
    <Form.Item
      label={t(`${name}.label.${type}GasDay`)}
      colon={false}
      required={false}
    >
      <DatePicker
        className={`${className}__${type}-date`}
        data-testid={`${className}__${type}-date`}
        format={localeDateFormat()}
        value={dateRangeValue[type] ? moment(dateRangeValue[type]) : undefined}
        onChange={date => setFn(
          { ...dateRangeValue, [type]: date ? date.format('YYYY-MM-DD') : undefined },
        )}
      />
    </Form.Item>
  );

  const renderDateRangeItem = (dateRangeValue, setFn, name, className) => (
    <Row>
      <Col span={8}>
        {renderDate(dateRangeValue, 'start', setFn, name, className)}
      </Col>
      <Col span={1}>
        <div className={`${className}__form__input-divider`}>
          -
        </div>
      </Col>
      <Col span={8}>
        {renderDate(dateRangeValue, 'end', setFn, name, className)}
      </Col>
    </Row>
  );

  const actionButtons = [
    <Button
      disabled={!canSave}
      key="saveButton"
      loading={saving}
      onClick={handleSave}
      type="primary"
    >
      {t('common.button.save')}
    </Button>,
    <Button
      disabled={saving}
      key="closeButton"
      onClick={handleClose}
    >
      {t('common.button.close')}
    </Button>,
  ];

  const updatedAt = timestamp
    ? t('deposits.label.updatedAt', { timestamp: formatDateTimeStr(timestamp) })
    : '';

  const label = t('deposits.label.setAmount') + updatedAt;

  return (
    <ModalWrapper
      modalClassName="edit-deposit"
      title={t('deposits.label.editDeposit', { marketParty })}
      handleClose={handleClose}
      actionButtons={actionButtons}
      visible={visible}
    >
      <Form>
        <Row>
          <Col span={8}>
            <Form.Item label={label}>
              <Row>
                <Col span={20}>
                  <Input
                    type="number"
                    className={error ? 'has-error' : ''}
                    onChange={({ currentTarget: { value } }) => setDeposit(value)}
                    value={deposit}
                  />
                  <span className="error">{error}</span>
                </Col>
                <Col span={4}>&nbsp;&euro;</Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        {renderDateRangeItem(dateRange, setDateRange, 'deposits', 'edit-deposit')}

        <Form.Item label={t('deposits.label.usedAmount')}>
          {formatPrice(used)}
        </Form.Item>
        <Form.Item label={t('deposits.label.remainingAmount')}>
          {formatPrice(remaining)}
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};

EditDeposit.propTypes = {
  dataSource: PropTypes.shape({
    marketParty: PropTypes.string,
    marketPartyId: PropTypes.string,
    remaining: PropTypes.number,
    set: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    timestamp: PropTypes.string,
    used: PropTypes.number,
  }).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const FORM = Form.create({ name: 'editDeposit' })(EditDeposit);
export default FORM;
