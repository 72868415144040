import React, { useContext, useEffect, useState } from 'react';
import { Layout, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { formatDate } from '../../utils/i18n';
import {
  getGasDay, getGasMonth, startOfGasDay, startOfGasMonth, startOfOngoingGasDay,
} from '../../utils/gasday';
import PageHeaderBar from '../../components/PageHeaderBar';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';
import ModalWrapper from '../../components/ModalWrapper';
import InvoiceCategories from './invoiceCategories';
import InvoiceChart from './invoiceChart';

import './index.less';

const Invoices = () => {
  const { t } = useTranslation();
  const { invoices, selectedMarketPartyId, updateInvoices } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [chartData, setChartData] = useState({});

  const displayChart = invoiceRow => ({
    onClick: () => {
      const { productCode, values } = invoiceRow;
      if (values.length) {
        setChartData(invoiceRow);
        setModalVisible(true);
      } else {
        notification.info({
          message: t('invoices.notification.noDailyValues.message'),
          description: t('invoices.notification.noDailyValues.description', {
            product: t(`invoices.product.${productCode}`),
          }),
        });
      }
    },
  });

  const now = startOfOngoingGasDay();
  const [selectedDate, setSelectedDate] = useState(
    startOfGasMonth(getGasMonth(now.subtract(1, 'months'))),
  );

  const gasDay = getGasDay(selectedDate);
  const invoicesByMonth = invoices[gasDay];

  const update = async () => {
    setLoading(true);
    await updateInvoices(gasDay);
    setLoading(false);
  };

  useEffect(() => {
    if (!invoicesByMonth) {
      update();
    }
  }, [selectedMarketPartyId, selectedDate]);

  const title = t('invoices.title', {
    startDate: formatDate(selectedDate),
    endDate: formatDate(selectedDate.clone().add(1, 'month')),
  });

  const datePicker = (
    <StatsDatePicker
      title={t('invoices.label.datePicker')}
      selectedDate={selectedDate}
      onSelectedDateChange={date => setSelectedDate(startOfGasDay(date.format('YYYY-MM-DD')))}
      resolution="month"
      t={t}
    />
  );

  const closeModal = () => setModalVisible(false);
  const closeButton = (
    <Button key="close" onClick={closeModal}>
      {t('common.button.close')}
    </Button>
  );

  return (
    <>
      <PageHeaderBar
        title={title}
        actionItems={datePicker}
        span={{
          title: 12,
          action: 12,
        }}
      />
      <Layout.Content className="layout__container__page__content invoices">
        <InvoiceCategories
          invoices={invoicesByMonth}
          isLoading={loading}
          displayChart={displayChart}
        />
      </Layout.Content>
      <ModalWrapper
        modalClassName="invoice__chart"
        title={t(`invoices.product.${chartData.productCode}`)}
        actionButtons={[closeButton]}
        visible={modalVisible}
        handleClose={closeModal}
        width="99vw"
      >
        <InvoiceChart chartData={chartData} />
      </ModalWrapper>
    </>
  );
};

Invoices.displayName = 'Invoices';

export default Invoices;
