import { get } from 'lodash';
import Decimal from 'decimal.js';
import moment from 'moment-timezone';

import {
  formatDate,
  formatPercentage,
} from './i18n';
import {
  getGasDay,
  isGasDayToday,
} from './gasday';
import { getDatesInRange } from './dateTimeHelpers';
import { UNITS_EXTENDED } from '../constants/units';
import { sum } from './math';

const getEmptyData = (start, end) => getDatesInRange(start, end)
  .reduce((result, date) => ({
    ...result,
    [date]: null,
  }),
  {});

const getPercentage = (a, b) => (b ? new Decimal(a).div(b) : new Decimal(0));

const getStrongestValue = (valueObject = {}) => {
  const preliminary = Number(valueObject.preliminary);
  const historical = Number(valueObject.historical);
  const manual = Number(valueObject.manual);
  return preliminary || manual || historical;
};

/**
 * Calculate value according to unit.
 *
 * @param {Number} value - Value to be calculated.
 * @param {String} unit - Unit used for calculation.
 * @param {Boolean} toKWh - Should the value be converted to kWh
 *
 * @returns {Number} - Result of calculation.
 */
const calculate = (value, unit, toKWh = false) => {
  if (!value) {
    return value;
  }

  let multiplier;

  switch (unit) {
    case UNITS_EXTENDED.MWH:
      multiplier = new Decimal(0.001);
      break;
    case UNITS_EXTENDED.GWH:
      multiplier = new Decimal(0.000001);
      break;
    default:
      multiplier = new Decimal(1);
      break;
  }
  multiplier = toKWh ? new Decimal(1).div(multiplier) : multiplier;

  return Number(
    unit === UNITS_EXTENDED.KWH
      ? multiplier.mul(value)
      : multiplier
        .mul(value)
        .toFixed(2),
  );
};

const getPhysicalBalanceSummary = (physicalBalanceSummary, unit) => (
  Object
    .entries(physicalBalanceSummary)
    .reduce((acc, [date, data]) => {
      const originalSums = data.reduce((total, item) => ({
        bcEntry: sum(total.bcEntry, item.bcEntryNomres),
        bcExit: sum(total.bcExit, item.bcExitNomres),
        biogas: sum(total.biogas, getStrongestValue(item.biogas)),
        consumption: sum(total.consumption, getStrongestValue(item.consumption)),
        imatra: sum(total.imatra, item.imatraNomres),
        lngHamina: sum(total.lngHamina, item.lngHaminaNomres),
        lngInkoo: sum(total.lngInkoo, item.lngInkooNomres),
      }),
      {
        bcEntry: 0,
        bcExit: 0,
        lngHamina: 0,
        lngInkoo: 0,
        consumption: 0,
        imatra: 0,
        biogas: 0,
      });
      const total = Object.entries(originalSums).reduce((result, [key, value]) => ({
        ...result,
        [key]: calculate(value, unit),
      }), {});

      const totalEntry = sum(
        total.imatra, total.bcEntry, total.biogas, total.lngHamina, total.lngInkoo,
      );
      const totalExit = sum(total.bcExit, total.consumption);
      const imbalance = sum(totalEntry, -totalExit);
      const imbalancePercentage = formatPercentage(getPercentage(imbalance, totalEntry));

      return acc.concat({
        date,
        ...total,
        nomresEntry: sum(total.imatra, total.bcEntry, total.lngHamina, total.lngInkoo),
        totalEntry,
        totalExit,
        imbalance,
        imbalancePercentage,
      });
    }, [])
);

const getDataForCapacityAndNominations = (context, unit = UNITS_EXTENDED.KWH) => {
  const {
    availableCapacityBC,
    availableCapacityImatra,
    bookedCapacity,
    nominations,
    physicalBalanceSummary,
  } = context;

  const summary = getPhysicalBalanceSummary(physicalBalanceSummary, unit);

  const imatra = Object.entries(availableCapacityImatra).reduce((acc, [date, data]) => {
    const item = {
      date,
      availableCapacity: calculate(data, unit),
      bookedCapacity: calculate(get(bookedCapacity, `${date}.imatra`, null), unit),
      nomint: calculate(get(nominations, `nomint.${date}.imatra.entry`, null), unit),
      nomres: calculate(get(nominations, `nomres.${date}.imatra.entry`, null), unit),
    };

    return acc.concat(item);
  }, []);

  const balticConnector = Object.entries(availableCapacityBC).reduce((acc, [date, data]) => {
    const nomintEntry = calculate(get(nominations, `nomint.${date}.balticConnector.entry`, null), unit);
    const nomintExit = calculate(get(nominations, `nomint.${date}.balticConnector.exit`, null), unit);
    const nomintNet = (nomintEntry !== null && nomintExit !== null)
      ? (nomintEntry - nomintExit)
      : null;
    const nomresEntry = calculate(get(nominations, `nomres.${date}.balticConnector.entry`, null), unit);
    const nomresExit = calculate(get(nominations, `nomres.${date}.balticConnector.exit`, null), unit);
    const nomresNet = (nomresEntry !== null && nomresExit !== null)
      ? (nomresEntry - nomresExit)
      : null;

    const item = {
      date,
      availableCapacityEntry: calculate(get(data, 'entry', null), unit),
      availableCapacityExit: calculate(get(data, 'exit', null), unit),
      nomintEntry,
      nomintExit,
      nomintNet,
      nomresEntry,
      nomresExit,
      nomresNet,
    };

    return acc.concat(item);
  }, []);

  const bookedEntries = Object.entries(bookedCapacity);

  const exitZone = bookedEntries.map(([date, data]) => ({
    date,
    bookedCapacity: calculate(get(data, 'exitZone', null), unit),
  }));

  const biogas = bookedEntries.map(([date, data]) => ({
    date,
    bookedCapacity: calculate(get(data, 'biogas', null), unit),
  }));

  return {
    summary,
    imatra,
    balticConnector,
    exitZone,
    biogas,
  };
};

const getDataForCapacitySettings = (context) => {
  const { availableCapacityImatra, bookedCapacity, dailyAndHourlyCapacity } = context;

  return Object.entries(availableCapacityImatra).reduce((acc, [date, data]) => {
    const item = {
      date,
      availableCapacity: data,
      bookedCapacity: get(bookedCapacity, `${date}.imatra`, null),
      daily: get(dailyAndHourlyCapacity, `${date}.daily`, null),
      hourly: get(dailyAndHourlyCapacity, `${date}.hourly`, null),
    };

    return acc.concat(item);
  }, []);
};

const getDateString = (date, t) => {
  const suffix = isGasDayToday(date)
    ? ` (${t('common.date.today')})`
    : '';

  return formatDate(date) + suffix;
};

const getStartAndEndDate = (amountOfDays = 1, startDate = moment.utc()) => {
  const start = getGasDay(startDate);
  const end = getGasDay(startDate.add(amountOfDays - 1, 'days'));

  return { start, end };
};

const getCardClass = data => ({ 'monitoring-card__grey': isGasDayToday(data.date) });

const updateDataForCapacityAndNominations = (context, cardAmount, startDate) => {
  const {
    updateAvailableCapacityBC,
    updateAvailableCapacityImatra,
    updateBookedCapacity,
    updateTotalCapacities,
    updateNominations,
    updateNomints,
    updateNomreses,
    updatePhysicalBalanceSummary,
  } = context;
  const { start, end } = getStartAndEndDate(cardAmount, startDate.clone());
  const { start: summaryStart, end: summaryEnd } = getStartAndEndDate(3, startDate.clone());

  updateAvailableCapacityImatra(start, end);
  updateAvailableCapacityBC(start, end);
  updateBookedCapacity(start, end);
  updateTotalCapacities(start, end);
  updateNominations(start, end);
  updateNomints(start, end);
  updateNomreses(start, end);
  updatePhysicalBalanceSummary(summaryStart, summaryEnd);
};

const updateDataForCapacitySettings = (context, cardAmount) => {
  const {
    updateAvailableCapacityImatra,
    updateBookedCapacity,
    updateDailyAndHourlyCapacity,
  } = context;
  const { start, end } = getStartAndEndDate(cardAmount);

  updateAvailableCapacityImatra(start, end);
  updateBookedCapacity(start, end);
  updateDailyAndHourlyCapacity(start, end);
};

/**
 * Return end time to set daily available capacity
 * i.e - it will be closed after D-1 18:00 PM
 *
 * @param {String} date - formatted date string
 */
const endOfSetDailyCapacity = date => moment.tz(date, 'Europe/Helsinki').subtract(1, 'day').hour(18);

/**
 * Return true if it's possible to set daily available capacity
 * i.e - if it is before D-1 18:00 PM
 *
 * @param {String} date - formatted date string
 */
const isSetDailyCapacityOpen = date => moment().isBefore(endOfSetDailyCapacity(date));

/**
 * Return true if it's possible to set hourly available capacity
 * i.e - it will be closed when gas day changes
 *
 * @param {String} date - formatted date string
 */
const isSetHourlyCapacityOpen = date => moment(date).isSame(getGasDay(moment()), 'date');


export {
  calculate,
  getEmptyData,
  getDataForCapacityAndNominations,
  getDataForCapacitySettings,
  getDateString,
  getPhysicalBalanceSummary,
  getStartAndEndDate,
  getCardClass,
  updateDataForCapacityAndNominations,
  updateDataForCapacitySettings,
  endOfSetDailyCapacity,
  isSetDailyCapacityOpen,
  isSetHourlyCapacityOpen,
};
