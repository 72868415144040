const verificationState = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFICATION_REQUESTED: 'VERIFICATION_REQUESTED',
  VERIFIED: 'VERIFIED',
};

const updateState = {
  NOT_UPDATED: 'NOT_UPDATED',
  UPDATE_REQUESTED: 'UPDATE_REQUESTED',
  ...verificationState,
};

export {
  updateState,
  verificationState,
};
