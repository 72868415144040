import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import './index.less';

const Loading = (props) => {
  const { delay } = props;
  return (
    <div className="loading">
      <Spin delay={delay} />
    </div>
  );
};

Loading.propTypes = {
  delay: PropTypes.number,
};
Loading.defaultProps = {
  delay: undefined,
};


export default Loading;
