import { lazy } from 'react';
import { PERMISSIONS } from './users';
import { userHasPermission } from '../utils/userHelpers';

const MarketParties = lazy(() => import('../containers/MarketParties'));
const Capacity = lazy(() => import('../containers/Capacity'));
const DeliveryRelationships = lazy(() => import('../containers/DeliveryRelationships'));
const BiogasEntryPoints = lazy(() => import('../containers/BiogasEntryPoints'));
const CityGates = lazy(() => import('../containers/CityGates'));
const Balance = lazy(() => import('../containers/Balance'));
const BalanceGroups = lazy(() => import('../containers/BalanceGroups'));
const MyBalance = lazy(() => import('../containers/MyBalance'));
const CapacityReservations = lazy(() => import('../containers/CapacityReservations'));
const CapacityRightsTransfers = lazy(() => import('../containers/CapacityRightsTransfers'));
const Users = lazy(() => import('../containers/Users'));
const Nominations = lazy(() => import('../containers/Nominations'));
const Shippers = lazy(() => import('../containers/Shippers'));
const Retailers = lazy(() => import('../containers/Retailers'));
const NetworkLevel = lazy(() => import('../containers/NetworkLevel'));
const CapacityAndNominations = lazy(() => import('../containers/CapacityAndNominations'));
const CapacitySettings = lazy(() => import('../containers/CapacitySettings'));
const FrontPage = lazy(() => import('../containers/FrontPage'));
const MeteringSites = lazy(() => import('../containers/MeteringSites'));
const BiogasDeliveries = lazy(() => import('../containers/BiogasDeliveries'));
const Deliveries = lazy(() => import('../containers/Deliveries'));
const Invoices = lazy(() => import('../containers/Invoices'));
const Deposits = lazy(() => import('../containers/Deposits'));
const DeclareGasTax = lazy(() => import('../containers/DeclareGasTax'));

const MENUITEMS = [
  {
    name: 'frontPage',
    icon: 'home',
    url: '/frontpage',
    component: FrontPage,
    // With this you can handle visibility MP user and TSO admin on all kind of environments.
    visible: {
      user: { // MP user
        // List of environments
        production: true,
        staging: true,
        development: true,
      },
      admin: { // TSO admin
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
    },
  }, {
    name: 'capacity',
    icon: 'area-chart',
    url: '/capacity',
    component: Capacity,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      // User is required to have at least one of the following permissions to see this menu item.
      permissions: [
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.TRADER,
        PERMISSIONS.TRADER_READ_ONLY,
      ],
    },
    submenus: [
      {
        name: 'capacityReservations',
        icon: '',
        url: '/capacitybookings',
        component: CapacityReservations,
        parentUrl: '/capacity',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.TRADER,
            PERMISSIONS.TRADER_READ_ONLY,
          ],
        },
      }, {
        name: 'capacityRightsTransfers',
        icon: '',
        url: '/capacityrightstransfers',
        component: CapacityRightsTransfers,
        parentUrl: '/capacity',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.TRADER,
            PERMISSIONS.TRADER_READ_ONLY,
          ],
        },
      },
    ],
  }, {
    name: 'gasEnergy',
    icon: 'fire',
    url: '/nominations',
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      permissions: [
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.PRODUCER,
        PERMISSIONS.PRODUCER_READ_ONLY,
        PERMISSIONS.ENDUSER,
        PERMISSIONS.ENDUSER_READ_ONLY,
        PERMISSIONS.DSO,
        PERMISSIONS.DSO_READ_ONLY,
        PERMISSIONS.LTO,
        PERMISSIONS.LTO_READ_ONLY,
      ],
    },
    submenus: [
      {
        name: 'nominations',
        icon: '',
        url: '/nominations',
        component: Nominations,
        parentUrl: '/gasenergy',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.TRADER,
            PERMISSIONS.TRADER_READ_ONLY,
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      }, {
        name: 'meteringSites',
        icon: '',
        url: '/meteringsites',
        component: MeteringSites,
        parentUrl: '/nominations',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.PRODUCER,
            PERMISSIONS.PRODUCER_READ_ONLY,
            PERMISSIONS.ENDUSER,
            PERMISSIONS.ENDUSER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      },
      {
        name: 'deliveries',
        icon: '',
        url: '/deliveries',
        component: Deliveries,
        parentUrl: '/nominations',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.RETAILER,
            PERMISSIONS.RETAILER_READ_ONLY,
          ],
        },
      },
      {
        name: 'biogasDeliveries',
        icon: '',
        url: '/biogasdeliveries',
        component: BiogasDeliveries,
        parentUrl: '/nominations',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.RETAILER,
            PERMISSIONS.RETAILER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
          ],
        },
      },
      {
        name: 'declareGasTax',
        icon: '',
        url: '/declaregastax',
        component: DeclareGasTax,
        parentUrl: '/nominations',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.ENDUSER,
            PERMISSIONS.ENDUSER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
          ],
        },
      },
    ],
  }, {
    name: 'balance',
    icon: 'sliders',
    url: '/balance',
    component: Balance,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      permissions: [
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.TRADER,
        PERMISSIONS.TRADER_READ_ONLY,
      ],
    },
    submenus: [
      {
        name: 'balanceGroups',
        icon: '',
        url: '/balancegroups',
        component: BalanceGroups,
        parentUrl: '/balance',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.TRADER,
            PERMISSIONS.TRADER_READ_ONLY,
          ],
        },
      },
      {
        name: 'myBalance',
        icon: '',
        url: '/mybalance',
        component: MyBalance,
        parentUrl: '/balance',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.TRADER,
            PERMISSIONS.TRADER_READ_ONLY,
          ],
        },
      },
    ],
  },
  {
    name: 'invoices',
    icon: 'euro',
    url: '/invoices',
    component: Invoices,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [ // restricted permissions has precedence over permissions
        PERMISSIONS.LTO,
        PERMISSIONS.LTO_READ_ONLY,
      ],
      permissions: [
        PERMISSIONS.INVOICE,
      ],
    },
  },
  {
    name: 'deposits',
    icon: 'bank',
    url: '/deposits',
    component: Deposits,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [ // restricted permissions has precedence over permissions
        PERMISSIONS.LTO,
        PERMISSIONS.LTO_READ_ONLY,
      ],
      permissions: [
        PERMISSIONS.INVOICE,
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.TRADER,
        PERMISSIONS.TRADER_READ_ONLY,
      ],
    },
  },
  {
    name: 'relationships',
    icon: 'share-alt',
    url: '/relationships',
    component: Shippers,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      permissions: [
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.RETAILER,
        PERMISSIONS.RETAILER_READ_ONLY,
        PERMISSIONS.PRODUCER,
        PERMISSIONS.PRODUCER_READ_ONLY,
        PERMISSIONS.ENDUSER,
        PERMISSIONS.ENDUSER_READ_ONLY,
        PERMISSIONS.DSO,
        PERMISSIONS.DSO_READ_ONLY,
      ],
    },
    submenus: [
      {
        name: 'created',
        icon: '',
        url: '/relationships-created',
        component: Shippers,
        parentUrl: '/relationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.RETAILER,
            PERMISSIONS.RETAILER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      },
      {
        name: 'received',
        icon: '',
        url: '/relationships-received',
        nest: true,
        component: Retailers,
        parentUrl: '/relationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.SHIPPER,
            PERMISSIONS.SHIPPER_READ_ONLY,
            PERMISSIONS.RETAILER,
            PERMISSIONS.RETAILER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      },
      {
        name: 'networkLevel',
        icon: '',
        url: '/networkLevel',
        component: NetworkLevel,
        parentUrl: '/relationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      },
    ],
  },
  {
    name: 'deliveryRelationships',
    icon: 'share-alt',
    url: '/deliveryrelationships',
    component: DeliveryRelationships,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      permissions: [
        PERMISSIONS.SHIPPER,
        PERMISSIONS.SHIPPER_READ_ONLY,
        PERMISSIONS.PRODUCER,
        PERMISSIONS.PRODUCER_READ_ONLY,
        PERMISSIONS.ENDUSER,
        PERMISSIONS.ENDUSER_READ_ONLY,
        PERMISSIONS.DSO,
        PERMISSIONS.DSO_READ_ONLY,
        PERMISSIONS.LTO,
        PERMISSIONS.LTO_READ_ONLY,
      ],
    },
    submenus: [
      {
        name: 'meteringPoints',
        icon: '',
        url: '/deliveryrelationships',
        parentUrl: '/deliveryrelationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.ENDUSER,
            PERMISSIONS.ENDUSER_READ_ONLY,
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
            PERMISSIONS.LTO,
            PERMISSIONS.LTO_READ_ONLY,
          ],
        },
      }, {
        name: 'cityGates',
        icon: '',
        url: '/citygates',
        component: CityGates,
        parentUrl: '/deliveryrelationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          permissions: [
            PERMISSIONS.DSO,
            PERMISSIONS.DSO_READ_ONLY,
          ],
        },
      }, {
        name: 'biogasEntryPoints',
        icon: '',
        url: '/biogasentrypoints',
        component: BiogasEntryPoints,
        parentUrl: '/deliveryrelationships',
        visible: {
          user: {
            production: true,
            staging: true,
            development: true,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
          restricted: [], // restricted permissions has precedence over permissions
          permissions: [
            PERMISSIONS.PRODUCER,
            PERMISSIONS.PRODUCER_READ_ONLY,
          ],
        },
      },
    ],
  }, {
    name: 'marketParties',
    icon: 'shopping',
    url: '/marketparties',
    component: MarketParties,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
    },
  },
  {
    name: 'monitoring',
    icon: 'appstore',
    url: '/capacityandnominations',
    visible: {
      user: {
        production: false,
        staging: false,
        development: false,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
    },
    submenus: [
      {
        name: 'capacityAndNominations',
        component: CapacityAndNominations,
        url: '/capacityandnominations',
        parentUrl: '/monitoring',
        visible: {
          user: {
            production: false,
            staging: false,
            development: false,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
        },
      },
      {
        name: 'capacitySettings',
        component: CapacitySettings,
        url: '/capacitysettings',
        parentUrl: '/capacityandnominations',
        visible: {
          user: {
            production: false,
            staging: false,
            development: false,
          },
          admin: {
            production: true,
            staging: true,
            development: true,
          },
        },
      },
    ],
  },
  {
    name: 'users',
    icon: 'team',
    url: '/users',
    component: Users,
    visible: {
      user: {
        production: true,
        staging: true,
        development: true,
      },
      admin: {
        production: true,
        staging: true,
        development: true,
      },
      restricted: [], // restricted permissions has precedence over permissions
      permissions: [
        PERMISSIONS.ADMIN,
      ],
    },
  },
];

/**
 * Function to flat MENUITEMS array.
 *
 * @returns {Array<Object>} - Flatten array.
 */
const getFlatMenuItems = () => (
  []
    .concat(
      ...MENUITEMS.map(menu => (
        menu.submenus && menu.submenus.length > 0
          ? [
            menu,
            ...menu.submenus,
          ]
          : menu
      )),
    )
);

/**
 * Function is checks if item will be visible for user.
 *
 * @param {Object} item - Menu item.
 * @param {Object} context - Context API.
 *
 * @returns {Boolean} - Result of checking.
 */
const isMenuItemVisibleForUser = (item, context) => {
  const {
    isAdmin,
    isReadOnlyAdmin,
    isAdminAsMarketParty,
    isReadOnlyAdminAsMarketParty,
    isProduction,
    isStaging,
    isDevelopment,
  } = context;

  const checkAdmin = isAdmin()
    || isReadOnlyAdmin()
    || isAdminAsMarketParty()
    || isReadOnlyAdminAsMarketParty();

  const hasPermission = checkAdmin
    || !item.visible.permissions
    || userHasPermission(context, item.visible.permissions);

  const user = checkAdmin ? 'admin' : 'user';
  const {
    production,
    staging,
    development,
  } = item.visible[user];
  const hasRestrictions = item.visible.restricted && item.visible.restricted.length > 0;
  const isRestricted = userHasPermission(context, item.visible.restricted || []);

  if (!checkAdmin && hasRestrictions && isRestricted) {
    return false;
  }

  if (isProduction) {
    return hasPermission && production;
  }
  if (isStaging) {
    return hasPermission && staging;
  }
  if (isDevelopment || process.env.NODE_ENV === 'test') {
    return hasPermission && development;
  }

  return false;
};

/**
 * Checks if one of the submenu items are visible for user
 *
 * This function will help in a situation where top level menu item is not permitted
 * for current user while one of the submenus is allowed. So it will be used to render
 * main menu selector and submenu item/s(depending on the user's permissions).
 *
 * @param {Object} item - Menu item.
 * @param {Object} context - Context API.
 *
 * @returns {Boolean} - Result of checking.
 */
const hasVisibleSubMenuItemForUser = (item, context) => {
  if (!item.submenus && !item.submenus.length) {
    return false;
  }

  const visibleSubMenu = item.submenus.find(
    submenu => isMenuItemVisibleForUser(submenu, context) === true,
  );

  return !!visibleSubMenu;
};

export default MENUITEMS;
export {
  getFlatMenuItems,
  isMenuItemVisibleForUser,
  hasVisibleSubMenuItemForUser,
};
