import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Route, withRouter } from 'react-router-dom';

import Context from '../../context';
import PageHeaderBar from '../../components/PageHeaderBar';
import MyBalanceStats from '../../components/MyBalanceStats';
import NewBalanceGroup from '../../components/BalanceGroup/newBalanceGroup';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';
import { getGasDay, middleOfGasDay } from '../../utils/gasday';
import { isDateInRange } from '../../utils/dateTimeHelpers';

import './myBalance.less';

const newBalanceGroupUrl = '/mybalance/new-balance-group';

const MyBalance = (props) => {
  const [selectedDate, setSelectedDate] = useState(middleOfGasDay());
  const { t } = useTranslation();
  const { balanceGroupMembers, selectedMarketPartyId } = useContext(Context);
  const { history } = props;

  const handleNewBalanceGroupClick = () => {
    history.push(newBalanceGroupUrl);
  };

  const canCreateBalanceGroup = (() => {
    const now = getGasDay();
    const membership = (balanceGroupMembers || []).find(({ marketPartyId, start, end }) => (
      marketPartyId === selectedMarketPartyId && isDateInRange(now, start, end)
    ));
    const isCurrentMember = !!membership;
    const isMembershipEnding = membership && membership.end;
    return !isCurrentMember || isMembershipEnding;
  })();

  const actionItems = (
    <div className="my-balance__page-header-bar__action-items">
      <StatsDatePicker
        selectedDate={selectedDate}
        onSelectedDateChange={setSelectedDate}
      />
      {canCreateBalanceGroup && (
        <Button
          className="page__header__buttons__new-balancegroup"
          type="primary"
          onClick={handleNewBalanceGroupClick}
        >
          {t('balanceContainer.button.newBalanceGroups')}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <PageHeaderBar
        title={t('balanceContainer.title.myBalanceInfo')}
        data-testid="page__header-bar"
        actionItems={actionItems}
        span={{
          title: 12,
          action: 12,
        }}
      />
      <Layout.Content className="layout__container__page__content balance">
        <MyBalanceStats selectedDate={selectedDate} />
      </Layout.Content>
      {canCreateBalanceGroup && (
        <Route path={newBalanceGroupUrl}>
          <NewBalanceGroup />
        </Route>
      )}
    </>
  );
};

MyBalance.displayName = 'MyBalance';
MyBalance.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(MyBalance);
export { MyBalance as PureComponent };
