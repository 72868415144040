import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import {
  Table,
  notification,
} from 'antd';
import {
  withRouter, Route,
} from 'react-router-dom';
import {
  API,
  Logger,
} from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../StatusIcon';
import ActionButton from '../ActionButton';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { getState } from '../../utils/deliveryRelationshipHelpers';
import { userHasPermission } from '../../utils/userHelpers';
import { formatDateStr } from '../../utils/i18n';
import { dateSort } from '../../utils/sort';

import { RELATIONSHIPS_UPDATE_PERMISSIONS } from '../../constants/users';
import { TYPES, MARKETPARTY_RELATIONSHIP_TYPES } from '../../constants/relationships';
import { STATUS } from '../../constants/status';
import { getMarketPartyFromContextById } from '../../utils/marketparty';
import RelationshipForm from '../Relationship/RelationshipForm';

const logger = new Logger('components:RelationshipsTable');
const FIXED_HEADERS = [
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
  },
];
const DATA_HEADERS = [
  {
    title: 'deliveryStartTime',
    dataIndex: 'start',
    key: 'start',
    sorter: () => undefined, // manually handle sorting at getTableData()
  },
  {
    title: 'deliveryEndTime',
    dataIndex: 'end',
    key: 'end',
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'state',
    dataIndex: 'state',
    key: 'state',
  },
];
const SHIPPER_A_DATA_HEADERS = [
  {
    title: 'marketparty',
    dataIndex: 'shipperA',
    key: 'shipperA',
  },
];
const SHIPPER_B_DATA_HEADERS = [
  {
    title: 'marketparty',
    dataIndex: 'shipperB',
    key: 'shipperB',
  },
];
const EDIT_DATA_HEADERS = [
  {
    title: 'actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const RelationshipsTable = ({ type, statusFilter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editRelationship, setEditRelationship] = useState();
  const [sortOrder, setSortOrder] = useState('descend');
  const { t } = useTranslation();
  const context = useContext(Context);
  const {
    marketPartyIndex,
    marketParties,
    relationships,
    loadingRelationships,
    updateRelationships,
    updateMarketpartyIndex,
    updateMarketParties,
    selectedMarketPartyId,
    handleDbChange,
    isAdmin,
    isReadOnlyAdmin,
    isReadOnlyAdminAsMarketParty,
    currentUser,
  } = context;

  useEffect(() => {
    updateRelationships();
    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (marketParties === null) updateMarketParties();
  }, [selectedMarketPartyId]);

  const getMarketParty = (marketPartyId) => (
    getMarketPartyFromContextById(context, marketPartyId)
  );

  const getMarketPartyName = (marketPartyId) => {
    const marketParty = getMarketParty(marketPartyId);

    return marketParty ? marketParty.name : '';
  };

  const isRelationshipAllowed = (relationship) => {
    const { marketPartyIdA, marketPartyIdB } = relationship;

    const viewAsAdmin = type === TYPES.OPERATORS || isAdmin() || isReadOnlyAdmin();

    const marketPartyA = getMarketParty(marketPartyIdA);
    const marketPartyB = getMarketParty(marketPartyIdB);

    if (!viewAsAdmin && (!marketPartyA || !marketPartyB)) {
      return false;
    }

    // special case: shipperGroup relationship should only be visible to admin users
    if (!(isAdmin(true) || isReadOnlyAdmin(true)) && relationship.type === MARKETPARTY_RELATIONSHIP_TYPES.SHIPPER_GROUP) {
      return false;
    }

    switch (type) {
      case TYPES.RETAILERS: // This is for the Received view
        return (isAdmin() || isReadOnlyAdmin() || selectedMarketPartyId === marketPartyIdB);
      case TYPES.SHIPPERS: // This is for the Created view
        return (isAdmin() || isReadOnlyAdmin() || selectedMarketPartyId === marketPartyIdA);
      case TYPES.OPERATORS:
        return true;
      default:
        return false;
    }
  };

  const hasWritePermission = () => (
    userHasPermission(
      {
        selectedMarketPartyId,
        currentUser,
      },
      RELATIONSHIPS_UPDATE_PERMISSIONS,
    )
    && !isReadOnlyAdmin()
    && !isReadOnlyAdminAsMarketParty()
  );

  const handleRelationshipStatusChange = async (status, marketPartyId, relationshipId) => {
    try {
      setIsLoading(true);
      const relationshipUrl = `/marketparties/${marketPartyId}/relationships/${relationshipId}`;
      const updatedRelationship = await API.patch('FINTSO', relationshipUrl, {
        body: { status },
      });

      handleDbChange('Relationships', 'update', updatedRelationship);

      notification.success({
        message: t('relationships.message.updatedSuccessfully'),
        description: '',
      });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('relationships.message.errorUpdating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    } finally {
      setIsLoading(false);
    }
  };

  const renderActionButton = (relationship) => {
    const { id, marketPartyIdB, status } = relationship;
    const data = [];
    if (
      status === STATUS.PENDING
      && type === TYPES.RETAILERS
      && hasWritePermission()
      && !isReadOnlyAdmin()
      && !isReadOnlyAdminAsMarketParty()
    ) {
      data.push(...[
        {
          type: 'button',
          id: `button-accept-${relationship.id}`,
          text: t('common.button.accept'),
          onClick: () => handleRelationshipStatusChange(STATUS.ACCEPTED, marketPartyIdB, id),
        },
        {
          type: 'button',
          id: `button-decline-${relationship.id}`,
          text: t('common.button.decline'),
          onClick: () => handleRelationshipStatusChange(STATUS.DECLINED, marketPartyIdB, id),
        },
      ]);
    }
    if (isAdmin()) {
      data.push({
        type: 'button',
        text: t('common.button.edit'),
        id: `button-edit-${relationship.id}`,
        onClick: () => setEditRelationship(relationship),
      });
    }

    if (data.length > 0) {
      return <ActionButton data={data} />;
    }
    return null;
  };

  const getTableHeaders = () => {
    let headers = FIXED_HEADERS;

    if (isAdmin() || isReadOnlyAdmin() || type === TYPES.OPERATORS) {
      // below title change is done only for admin users to differntiate between shipper and retailer
      headers = headers
        .concat(SHIPPER_A_DATA_HEADERS.map(h => ({ ...h, title: 'createdBy' })))
        .concat(SHIPPER_B_DATA_HEADERS.map(h => ({ ...h, title: 'marketparty' })));
    } else {
      if (type === TYPES.RETAILERS) {
        headers = headers.concat(SHIPPER_A_DATA_HEADERS);
      }
      if (type === TYPES.SHIPPERS) {
        headers = headers.concat(SHIPPER_B_DATA_HEADERS);
      }
    }

    headers = headers.concat(DATA_HEADERS);

    // Don not show action columns for the OPERATORS / LTO users
    if ((hasWritePermission() && type === TYPES.RETAILERS) || isAdmin()) {
      headers = headers.concat(EDIT_DATA_HEADERS);
    }

    return headers;
  };

  const byStatus = ({ status }) => (
    statusFilter === 'all' ? true : status === statusFilter
  );

  const byStart = (a, b) => (
    sortOrder === 'descend' ? dateSort(b.start, a.start) : dateSort(a.start, b.start)
  );

  const getTableData = () => (
    relationships
      ? relationships
        .sort(byStart)
        .filter(relationship => isRelationshipAllowed(relationship) && byStatus(relationship))
        .map(relationship => {
          const {
            id,
            status,
            start,
            end,
            type: relationshipType,
            marketPartyIdA,
            marketPartyIdB,
          } = relationship;
          return ({
            key: id,
            shipperA: getMarketPartyName(marketPartyIdA),
            shipperB: getMarketPartyName(marketPartyIdB),
            address: '',
            type: t(`common.label.${relationshipType}`),
            start: formatDateStr(start),
            end: formatDateStr(end),
            status: <StatusIcon
              status={status}
              withTitle
              title={capitalize(status)}
            />,
            state: getState(status, start, end),
            actions: renderActionButton(relationship),
          });
        })
      : []
  );

  const handleModalClose = () => {
    setEditRelationship();
  };

  const onChangeTable = (_, __, sorting) => {
    setSortOrder(sorting.order);
  };


  return (
    <>
      {editRelationship ? (
        <RelationshipForm
          handleClose={handleModalClose}
          relationship={editRelationship}
        />
      ) : null }
      <Table
        className="relationships-table"
        data-testid="relationships-table"
        columns={getTranslatedTableHeaders(getTableHeaders(), t)}
        dataSource={getTableData()}
        onChange={onChangeTable}
        pagination={false}
        loading={isLoading || loadingRelationships}
        rowKey={(record, index) => `${record.key}_${index}`}
        rowClassName={() => (isAdmin() ? 'relationships-table--row-interactive' : '')}
      />
      {
        hasWritePermission() && type === TYPES.RETAILERS
          ? <Route path="/retailers/:id" />
          : ''
      }
    </>
  );
};

RelationshipsTable.displayName = 'RelationshipsTable';
RelationshipsTable.propTypes = {
  type: PropTypes.string.isRequired,
  statusFilter: PropTypes.string,
};
RelationshipsTable.defaultProps = {
  statusFilter: 'all',
};

export default withRouter(RelationshipsTable);
export {
  RelationshipsTable as PureComponent,
};
