// TODO: move capacity related context update functions here

import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';
import { CAPACITY_TYPES } from '../constants/capacities';

const log = new Logger('context:capacity');

const emptyCapacityOverruns = {
  [CAPACITY_TYPES.BIOGAS]: [],
  [CAPACITY_TYPES.EXITZONE]: [],
};

// TODO: test
async function updateCapacityOverruns(point, start, end) {
  const {
    isAdmin,
    selectedMarketPartyId,
    capacityOverruns: oldCapacityOverruns,
  } = this.state;

  // TODO/TBD: endpoint for admins?
  if (isAdmin()) {
    this.setState({ capacityOverruns: emptyCapacityOverruns });
    return;
  }

  // Only these points are available to be fetched
  const points = [CAPACITY_TYPES.BIOGAS, CAPACITY_TYPES.EXITZONE];
  if (!points.includes(point)) {
    return;
  }

  try {
    this.setState({ capacityOverrunsLoading: true });
    const url = `/marketparties/${selectedMarketPartyId}/capacities/overruns`;
    const request = API.get(
      'FINTSO',
      url,
      {
        queryStringParameters:
        {
          start,
          end,
          point,
        },
      },
    );
    const { items } = await request;
    const capacityOverruns = {
      ...oldCapacityOverruns,
      [point]: items,
    };
    this.setState({
      capacityOverruns,
      capacityOverrunsLoading: false,
    });
    log.info('Capacity overruns updated with data:', capacityOverruns);
  } catch (error) {
    const { t } = this.props;
    this.setState({
      capacityOverruns: emptyCapacityOverruns,
      capacityOverrunsLoading: false,
    });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingCapacityOverruns'),
      description,
    });
    log.error('Error updating capacity overruns:', error.message, description);
  }
}

const REFRESH_INTERVAL = 1 * 60 * 60 * 1000;

async function updateCapacityBCSummary(start, end) {
  try {
    const { capacitySummaryUpdated } = this.state;
    const ts = Date.now();
    const age = (ts - capacitySummaryUpdated);
    if (capacitySummaryUpdated && age < REFRESH_INTERVAL) {
      log.info('Capacity bc summary updated skipped, data age:', age / 1000);
      return;
    }
    this.setState({ capacityBCSummaryLoading: true });
    const request = API.get('FINTSO', '/capacities/bc/summary',
      {
        queryStringParameters:
        {
          start,
          end,
          resolution: 'day',
        },
      });
    const { items: capacitySummary } = await request;
    this.setState({
      capacitySummary,
      capacitySummaryUpdated: Date.now(),
      capacityBCSummaryLoading: false,
    });
    log.info('Capacity bc summary updated with data:', capacitySummary);
  } catch (error) {
    // const { t } = this.props;
    this.setState({
      capacitySummary: [],
      capacitySummaryUpdated: null,
      capacityBCSummaryLoading: false,
    });
    const description = createErrorMessage(error);
    /*
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingCapacityOverruns'),
      description,
    });
    */
    log.error('Error updating capacity bc summary:', error.message, description);
  }
}

const capacityContext = {
  capacityOverruns: null,
  capacityOverrunsLoading: false,
  updateCapacityOverruns: () => { },

  capacityBCSummary: null,
  capacityBCSummaryLoading: false,
  updateCapacityBCSummary: () => { },
};

const functions = {
  updateCapacityOverruns,
  updateCapacityBCSummary,
};

const capacity = {
  ...capacityContext,
  ...functions,
};

export {
  capacityContext,
  capacity,
};
