
import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';
import { userHasPermission } from '../utils/userHelpers';
import { TAXDECLARATIONS_READ_PERMISSIONS } from '../constants/users';
// import { biogasTaxDeclarations as mockData } from './__mock__/responses'; // TODO delete mock

const log = new Logger('context-taxdeclarations');

async function updateTaxDeclarations(
  period, // TODO verify backend query parameters
) {
  try {
    this.setState({ loadingTaxDeclarations: true });

    const {
      isAdmin,
      isReadOnlyAdmin,
      selectedMarketPartyId,
      isAdminAsMarketParty,
      isReadOnlyAdminAsMarketParty,
    } = this.state;

    const taxDeclarationsUrl = isAdmin() || isReadOnlyAdmin()
      ? '/admin/meteringsites/taxdeclarations'
      : `/marketparties/${selectedMarketPartyId}/meteringsites/taxdeclarations`;

    const parameters = {
      queryStringParameters: {
        period,
      },
    };

    const canViewDeclarations = isAdmin() || isReadOnlyAdmin()
      || isAdminAsMarketParty() || isReadOnlyAdminAsMarketParty()
      || userHasPermission(this.state, TAXDECLARATIONS_READ_PERMISSIONS);

    const taxDeclarations = canViewDeclarations
      ? await API.get('FINTSO', taxDeclarationsUrl, parameters)
      : { items: [] };

    if (!taxDeclarations || !taxDeclarations.items) {
      throw new Error('Tax declarations could not be loaded');
    }

    this.setState({
      declareStart: taxDeclarations.declareStart,
      declareEnd: taxDeclarations.declareEnd,
      taxDeclarations: taxDeclarations.items,
      loadingTaxDeclarations: false,
    });
    log.info('tax declarations updated with data:', taxDeclarations);
  } catch (error) {
    const { t } = this.props;
    this.setState({
      declareStart: '',
      declareEnd: '',
      taxDeclarations: [],
      loadingTaxDeclarations: false,
    });
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingTaxDeclarations'),
      description: createErrorMessage(error),
    });
    log.error('error fetching tax declarations:', createErrorMessage(error, true));
  }
}

const taxDeclarationsContext = {
  declareStart: '',
  declareEnd: '',
  taxDeclarations: [],
  loadingTaxDeclarations: false,
  updateTaxDeclarations: () => { },
};

const functions = {
  updateTaxDeclarations,
};

const biogasTaxDeclarations = {
  ...taxDeclarationsContext,
  ...functions,
};

export {
  taxDeclarationsContext,
  biogasTaxDeclarations,
};
