import {
  Auth,
  Logger,
} from 'aws-amplify';

const websocketUrl = process.env.WEBSOCKET_URL;
let logger;

class Websocket {
  constructor(onMessage) {
    this.onMessage = onMessage;
    logger = new Logger('websocket');
  }

  static onOpen() {
    logger.info('socket connected');
  }

  async onClose(event) {
    logger.info('socket closed:', event);
    try {
      await Auth.currentSession();
    } catch (e) { // User not signed in, no reconnect.
      return;
    }

    setTimeout(() => { this.connect(); }, 1000);
  }

  onError(event) {
    logger.error('socket error:', event);
    this.socket.close();
  }

  async connect() {
    logger.info(`connecting to websocket: ${websocketUrl}`);

    try {
      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;
      const url = `${websocketUrl}?token=${token}`;
      this.socket = new WebSocket(url);
    } catch (error) {
      logger.error(`error connecting websocket: ${error.message}`);
      return;
    }

    this.socket.onopen = Websocket.onOpen;
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onmessage = this.onMessage;
  }

  close() {
    if (this.socket === undefined) return;

    logger.info('closing websocket');
    this.socket.close();
  }
}

export default Websocket;
