import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';

const log = new Logger('context:status');

const NETWORK_STATUS_URL = '/status';

async function updateStatus() {
  try {
    const status = await API.get('FINTSO', NETWORK_STATUS_URL);
    this.setState({ status });
    log.info('transmission network status updated with data:', status);
  } catch (error) {
    const { t } = this.props;
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingNetworkStatus'),
      description,
    });
    log.error('error fetching transmission network status:', error.message, description);
  }
}

function setStatus(status) {
  this.setState({ status });
}

const statusContext = {
  status: null,
  updateStatus: () => {},
  setStatus: () => {},
};

const functions = {
  updateStatus,
  setStatus,
};

const status = {
  ...statusContext,
  ...functions,
};

export {
  statusContext,
  status,
};
