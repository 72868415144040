import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  notification,
  Row,
  Col,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import joi from 'joi';
import { withTranslation } from 'react-i18next';
import { isEmpty, pick } from 'lodash';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import MarketPartyForm from '../MarketPartyForm';
import MarketPartyUsers from '../MarketPartyUsers';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';
import { getMarketPartySchema, CONTACT_PERSON_SCHEMA } from '../../schemas/marketParties';
import { PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';

import './index.less';

const logger = new Logger('components:market-party');

class MarketParty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isLoading: true,
      isSaving: false,
      marketParty: null,
      marketPartyOriginal: null,
    };
  }

  componentDidMount = async () => {
    const {
      match,
      t,
      onClose,
    } = this.props;

    try {
      const marketPartyId = match.params.id;

      const marketParty = await API.get('FINTSO', `/marketparties/${marketPartyId}`);
      this.setState({
        isLoading: false,
        marketParty,
        marketPartyOriginal: marketParty,
      });
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetching'),
        description: createErrorMessage(e),
      });
      onClose();
    }
  }

  getChangedData = (original, edited) => Object.keys(edited).reduce((data, key) => {
    const isValueDifferent = JSON.stringify(edited[key]) !== JSON.stringify(original[key]);
    const newPropertyIsEmptyString = edited[key] === '' && original[key] === undefined;
    if (isValueDifferent && !newPropertyIsEmptyString) {
      return {
        ...data,
        [key]: edited[key],
      };
    }
    return data;
  }, {})

  handleChange = (field, value) => {
    const { errors, marketParty } = this.state;
    const marketPartySchema = getMarketPartySchema(marketParty.roles);
    const { error } = marketPartySchema[field].validate(value);

    const updatedErrors = errors.filter(err => err !== field);
    if (error) {
      updatedErrors.push(field);
    }

    this.setState({
      errors: updatedErrors,
      marketParty: {
        ...marketParty,
        [field]: value,
      },
    });

    return !!error;
  }

  handleContactPersonChange = (field, value) => {
    const { errors, marketParty } = this.state;
    const { error } = CONTACT_PERSON_SCHEMA[field].validate(value);
    const updatedErrors = errors.filter(err => err !== field);
    if (error) {
      updatedErrors.push(field);
    }

    this.setState({
      errors: updatedErrors,
      marketParty: {
        ...marketParty,
        contactPerson: {
          ...marketParty.contactPerson,
          [field]: value,
        },
      },
    });

    return !!error;
  }

  isSaveButtonDisabled = () => {
    const { isAdmin } = this.context;
    const {
      errors,
      isLoading,
    } = this.state;
    const canEditMarketParty = isAdmin() || userHasPermission(this.context, [PERMISSIONS.ADMIN]);

    return !canEditMarketParty
      || isLoading
      || errors.length > 0
      || !this.hasChanges();
  }

  handleClose = () => {
    const { t, onClose } = this.props;

    return !this.hasChanges()
      ? onClose()
      : modalConfirmClose(t, () => onClose());
  }

  handleSave = async () => {
    const {
      marketParty,
      marketPartyOriginal,
    } = this.state;
    const { handleDbChange, isAdmin } = this.context;
    const { t, onClose } = this.props;
    const data = this.getChangedData(marketPartyOriginal, marketParty);
    const marketPartySchema = getMarketPartySchema(marketParty.roles);
    const { error } = joi.object(marketPartySchema).validate(data);

    if (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.incorrectInput'),
        description: error.toString(),
      });
      return;
    }

    const editableByMarketParty = ['contactPerson'];
    const body = isAdmin() ? data : pick(data, editableByMarketParty);
    const url = isAdmin()
      ? `/admin/marketparties/${marketParty.id}`
      : `/marketparties/${marketParty.id}`;

    try {
      this.setState({ isSaving: true });
      const response = await API.patch('FINTSO', url, { body });
      handleDbChange('MarketParty', 'update', response); // Upgrade CR in case websocket's message in delay
      notification.success({
        message: `${t('marketParty.title.marketParty')} ${t('common.status.successfullyUpdated')}`,
        description: '',
      });

      const { onSave } = this.props;
      onSave(response);

      onClose();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: `${t('common.notifications.errorUpdating')} ${t('marketParty.title.marketParty').toLowerCase()}`,
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
      this.setState({ isSaving: false });
    }
  }

  handleDelete = async () => {
    const { marketParty } = this.state;
    const { isAdmin } = this.context;
    const { t, onClose } = this.props;

    try {
      if (!isAdmin()) {
        throw new Error('Only Admins Can Delete MarketParties');
      }

      const url = `/admin/marketparties/${marketParty.id}`;
      this.setState({ isSaving: true });
      await API.del('FINTSO', url);
      notification.success({
        message: `${t('marketParty.title.marketParty')} ${t('common.status.successfullyDeleted')}`,
        description: '',
      });
      onClose();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: `${t('common.notifications.errorDeleting')} ${t('marketParty.title.marketParty').toLowerCase()}`,
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
      this.setState({ isSaving: false });
    }
  }

  hasChanges = () => {
    const { marketParty, marketPartyOriginal } = this.state;
    const changes = this.getChangedData(marketPartyOriginal, marketParty);
    return !isEmpty(changes);
  }

  renderContent = () => {
    const { isAdmin } = this.context;
    const {
      errors,
      marketParty,
      marketPartyOriginal,
      isSaving,
    } = this.state;

    const marketpartyUsers = marketParty ? [marketParty.registeredBy] : [];

    if (marketParty === null) return null;

    return (
      <>
        <MarketPartyForm
          errors={errors}
          marketParty={marketParty}
          onChange={this.handleChange}
          onContactPersonChange={this.handleContactPersonChange}
          disabled={!isAdmin()}
          marketPartyOriginal={marketPartyOriginal}
          handleDelete={this.handleDelete}
          isSaving={isSaving}
        />
        <MarketPartyUsers
          marketPartyId={marketParty && marketParty.id}
          users={marketpartyUsers}
        />
      </>
    );
  }

  renderTitle = () => {
    const { marketParty } = this.state;
    const { isAdmin } = this.context;

    return marketParty
      ? (
        <Col>
          <Row className="marketparty__modal__title">{ marketParty.name }</Row>
          <Row className="marketparty__modal__id">
            <div>{ `id: ${marketParty.code}` }</div>
            { isAdmin() && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className="clipboard-copy"
              onClick={() => (navigator.clipboard.writeText(marketParty.id))}
            >
              {`${marketParty.id.slice(-12)}`}
            </div>
            ) }
          </Row>
        </Col>
      )
      : '';
  }

  renderActionButtons = () => {
    const {
      isSaving,
    } = this.state;
    const { t } = this.props;

    return ([
      <Button
        className="marketparty__modal__cancelButton"
        key="cancel"
        onClick={this.handleClose}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="marketparty__modal__saveButton"
        disabled={this.isSaveButtonDisabled()}
        key="save"
        loading={isSaving}
        type="primary"
        onClick={this.handleSave}
      >
        {t('common.button.saveAndClose')}
      </Button>,
    ]);
  }

  render() {
    const {
      isLoading,
    } = this.state;

    return (
      <ModalWrapper
        modalClassName="marketparty__modal"
        title={this.renderTitle()}
        handleClose={this.handleClose}
        actionButtons={this.renderActionButtons()}
        width="40rem"
        isLoading={isLoading}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

MarketParty.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
MarketParty.contextType = Context;

const MarketPartyTranslated = withTranslation()(MarketParty);
export default withRouter(MarketPartyTranslated);

export {
  MarketParty as PureComponent,
};
