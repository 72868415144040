import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, notification } from 'antd';
import { API } from 'aws-amplify';
import { createErrorMessage } from '../../context/globalStateHelper';
import Context from '../../context';

const DeleteUser = (props) => {
  const {
    history,
    match: {
      params: {
        id,
        marketPartyId,
      },
    },
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { handleDbChange, users } = useContext(Context);
  const { t } = useTranslation();

  const handleClose = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const { userId } = await API.del('FINTSO', `/marketparties/${marketPartyId}/users/${id}`);
      handleDbChange('MarketPartyUser', 'delete', {
        userId,
        marketPartyId,
      });
      notification.success({
        message: t('users.notification.deleteSuccess'),
        description: '',
      });
      handleClose();
    } catch (error) {
      setIsLoading(false);
      notification.error({
        className: 'notification-error',
        message: t('users.notification.deleteError'),
        description: createErrorMessage(error),
      });
    }
  };

  const selectedUser = (users || []).find(user => user.id === id);

  return (
    <Modal
      title={t('users.title.deleteUser')}
      visible
      closable={false}
      onCancel={handleClose}
      footer={(
        <>
          <Button onClick={handleClose} disabled={isLoading}>
            {t('common.button.cancel')}
          </Button>
          <Button type="primary" onClick={handleDelete} loading={isLoading} disabled={!selectedUser}>
            {t(isLoading ? 'common.button.deleting' : 'common.button.delete')}
          </Button>
        </>
      )}
    >
      {selectedUser ? (
        <>
          <h2>{t('users.title.confirmDelete')}</h2>
          <p>{`${t('common.table.header.name')}: ${selectedUser.firstname} ${selectedUser.lastname}`}</p>
          <p>{`${t('common.table.header.email')}: ${selectedUser.email}`}</p>
          <p>{`${t('common.table.header.phone')}: ${selectedUser.phone}`}</p>
        </>
      )
        : <h2>{t('users.title.notFound')}</h2>
      }
    </Modal>
  );
};

DeleteUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      marketPartyId: PropTypes.string,
    }),
  }).isRequired,
};

DeleteUser.displayName = 'DeleteUser';

export default withRouter(DeleteUser);

export { DeleteUser as PureComponent };
