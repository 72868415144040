import React from 'react';
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Col, Row } from 'antd';
import Decimal from 'decimal.js';
import invoiceProp from './proptypes';
import { formatDate } from '../../utils/i18n';

const COLOR_STROKE = '#F5F5F5';
const COLOR_PRELIMINARY = '#53C41A';
const COLOR_CHARGE = '#3483AA';
const COLOR_PRICE = '#C2295E';
const MARGIN = 30;
const BAR_WIDTH = 20;
const LINE_WIDTH = 3;

const getUpperDomainLimit = (dataMax) => {
  if (dataMax <= 0) return 0;
  const d = new Decimal(dataMax).toDecimalPlaces(0, Decimal.ROUND_UP);
  const magnitude = new Decimal(10 ** (d.toString().length - 1));
  const x = d.add(0.1).toNearest(magnitude.div(2), Decimal.ROUND_UP);
  return Number(x);
};

const getLowerDomainLimit = (dataMin) => {
  if (dataMin >= 0) return 0;
  return -(getUpperDomainLimit(-dataMin));
};

const InvoiceChart = ({ chartData }) => {
  const { values: original } = chartData;
  const values = original.map(({
    date, price, volume, charge,
  }) => ({
    date: formatDate(date),
    price: Number(price),
    volume: Number(volume),
    charge: Number(charge),
  }));

  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        className="invoice__chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={values}
            margin={{
              top: MARGIN,
              right: MARGIN,
              bottom: MARGIN,
              left: MARGIN,
            }}
          >
            <CartesianGrid stroke={COLOR_STROKE} />
            <XAxis dataKey="date" />
            <YAxis
              allowDecimals={false}
              type="number"
              domain={[0, getUpperDomainLimit]}
            />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="price"
              barSize={BAR_WIDTH}
              fill={COLOR_PRELIMINARY}
            />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        className="invoice__chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={values}
            margin={{
              top: MARGIN,
              right: MARGIN,
              bottom: MARGIN,
              left: MARGIN,
            }}
          >
            <CartesianGrid stroke={COLOR_STROKE} />
            <XAxis dataKey="date" />
            <YAxis
              yAxisId="charge"
              allowDecimals={false}
              type="number"
              domain={[getLowerDomainLimit, getUpperDomainLimit]}
            />
            <YAxis
              yAxisId="volume"
              orientation="right"
              allowDecimals={false}
              type="number"
              domain={[getLowerDomainLimit, getUpperDomainLimit]}
            />
            <Tooltip />
            <Legend />
            <Area
              yAxisId="charge"
              type="monotone"
              dataKey="charge"
              stroke={COLOR_CHARGE}
              strokeWidth={LINE_WIDTH}
              fill={COLOR_CHARGE}
              fillOpacity={0.25}
            />
            <Line
              dot={false}
              yAxisId="volume"
              type="monotone"
              dataKey="volume"
              stroke={COLOR_PRICE}
              strokeWidth={LINE_WIDTH}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
};

InvoiceChart.propTypes = {
  chartData: invoiceProp.isRequired,
};

export default InvoiceChart;
export {
  getUpperDomainLimit,
};
