const LOADING_STATE = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

/* eslint-disable import/prefer-default-export */
export {
  LOADING_STATE,
};
