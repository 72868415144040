const BALANCE_PRICE_TYPES = {
  SELL: 'sell',
  BUY: 'buy',
};
const BALANCE_PRICE_TYPE_NAMES = Object.values(BALANCE_PRICE_TYPES);

export {
  BALANCE_PRICE_TYPES,
  BALANCE_PRICE_TYPE_NAMES,
};
