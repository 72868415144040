import { isEmpty } from 'lodash';
import { PERMISSION_NAMES } from '../constants/users';

/**
 * Merged all possible market party and user permissions.
 *
 * @param {Object} user - User object. See Global state -> users.
 * @returns {Object} - Permissions object.
 */
const mapUsersPermissionsWithMarketPartyRoles = user => (
  Object.assign(
    {},
    ...Object.entries(user.permissions)
      .map(([id, marketpartyPermissions]) => ({
        [id]: {
          ...Object.assign(
            {},
            ...PERMISSION_NAMES.map(name => ({
              [name]: marketpartyPermissions.some(permission => name.startsWith(permission)),
            })),
          ),
        },
      })),
  )
);

const userHasMarketParties = (context) => {
  const { currentUser } = context;
  return currentUser && currentUser.ownMarketParties && currentUser.ownMarketParties.length;
};

const userHasPermission = (context, requiredPermissions) => {
  const {
    currentUser: {
      inAdminGroup,
      permissions: userPermissions,
    },
    selectedMarketPartyId,
  } = context;

  const isAdminAsMarketParty = inAdminGroup && selectedMarketPartyId && selectedMarketPartyId !== 'admin';
  const permissions = userPermissions[selectedMarketPartyId] || [];

  return isAdminAsMarketParty || permissions.some(
    permission => requiredPermissions.find(
      requiredPermission => requiredPermission.startsWith(permission),
    ),
  );
};

const userIsAllowedToWrite = (context, permissions) => (
  !isEmpty(context)
  && userHasMarketParties(context)
  && userHasPermission(context, permissions)
);

export {
  mapUsersPermissionsWithMarketPartyRoles,
  userHasMarketParties,
  userHasPermission,
  userIsAllowedToWrite,
};
