import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { uniqueId } from 'lodash';
import {
  Card, Icon, Tag, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Context from '../../context';
import { ANNOUNCEMENT_STATUS } from '../../constants/status';
import { getDateAndTimeStr } from '../../utils/dateTimeHelpers';
import { COLOR_YELLOW, COLOR_GREEN } from '../../constants/colors';
import ButtonLink from '../ButtonLink';

const Announcement = (props) => {
  const { t } = useTranslation();
  const { announcement, handleEditAnnouncement, isLoading } = props;
  const {
    publishedAt, message, subject, id, links = [],
    status,
  } = announcement;
  const context = useContext(Context);
  const { isAdmin } = context;


  const renderTag = (announcementStatus) => {
    if (!isAdmin) return null;

    let color = null;
    switch (announcementStatus) {
      case ANNOUNCEMENT_STATUS.UNPUBLISHED:
        color = COLOR_YELLOW;
        break;
      case ANNOUNCEMENT_STATUS.PUBLISHED:
        color = COLOR_GREEN;
        break;
      default:
        color = 'default';
        break;
    }

    if (color) {
      return (
        <Tag color={color}>{t(`announcements.status.${status}`)}</Tag>
      );
    }
    return null;
  };

  return (
    <Card
      id={`announcement-${id}`}
      loading={isLoading}
      bordered={false}
      className="announcements__announcement"
      title={(
        <>
          <Typography level={4}>
            {subject}
          </Typography>
          <Typography.Title disabled level={1} type="secondary">
            {getDateAndTimeStr(publishedAt)}
          </Typography.Title>
        </>
      )}
      extra={isAdmin() && (
        <div className="announcements__section-buttons">
          {renderTag(status)}
          <ButtonLink
            onClick={() => handleEditAnnouncement(announcement)}
            type="link"
            size="small"
            id={`announcements-edit-button__${id}`}
            className="announcements__section-buttons--button-edit"
            text={t('common.button.edit')}
          />
        </div>
      )}
    >
      {message.split('\n').map(msg => (
        <p key={uniqueId('announcement-message')}>{msg}</p>
      ))}
      {links.map(({ url, title }) => (
        <div key={`announcement-link-${title || url}`}>
          <a
            className="announcements--button-link"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon type="link" />
            {title || url}
          </a>
        </div>
      ))}
    </Card>
  );
};

Announcement.propTypes = {
  announcement: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    publishedAt: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    })),
    status: PropTypes.string,
    subject: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleEditAnnouncement: PropTypes.func.isRequired,
};

export default Announcement;
