import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { API } from 'aws-amplify';
import {
  Button, Form, Input, notification,
} from 'antd';
import ModalWrapper from '../ModalWrapper';
import './index.less';
import StatusIcon from '../StatusIcon';
import { createErrorMessage } from '../../context/globalStateHelper';
import { STATUS } from '../../constants/status';

const { TextArea } = Input;
const CapacityReservationDeleteButton = (props) => {
  const {
    t,
    isAdmin,
    reservation,
    onClose,
  } = props;

  const [state, setState] = useState({
    reason: '',
    showDeleteModal: false,
    deleteNoteError: false,
  });
  const { reason, deleteNoteError, showDeleteModal } = state;

  /**
   * TSO Admin users can 'Cancel' the capacitybooking (status PENDING/ACCEPTED)
   * at any time, though non admin users have 1 hour window after creation, during
   * which they are allowed to 'Cancel' the capacitybooking (status PENDING).
   */
  const isAllowedForNonAdmin = () => {
    const { status, createdAt } = reservation;

    const isCancelTimeWindowClosed = () => {
      const oneHourAfterCreation = moment(createdAt).add(1, 'hour');
      return moment().isAfter(oneHourAfterCreation);
    };

    const isCancellable = status === STATUS.PENDING;

    return (
      isCancellable && !isCancelTimeWindowClosed()
    );
  };

  const isAllowedForTSOAdmin = () => {
    const { status } = reservation;
    const isCancellable = status !== STATUS.DECLINED;
    return (
      isCancellable && isAdmin(true)
    );
  };

  const isCancellationAllowed = () => (
    isAllowedForTSOAdmin() || isAllowedForNonAdmin()
  );

  const onClickYes = async () => {
    if (!reason || reason.length < 5) {
      setState({ ...state, deleteNoteError: true });
    } else if (
      isAllowedForTSOAdmin() || isAllowedForNonAdmin()
    ) {
      try {
        setState({ ...state, deleteNoteError: false });
        const { id: reservationId, marketPartyId } = reservation;
        await API.del('FINTSO', `/marketparties/${marketPartyId}/capacitybookings/${reservationId}`, {
          body: { note: reason },
        });
        notification.success({
          className: 'notification-error',
          message: t('common.notifications.cancelledSuccessfully'),
        });

        onClose();
      } catch (e) {
        notification.error({
          className: 'notification-error',
          message: t('common.notifications.errorDeletingCapacityBooking'),
          description: createErrorMessage(e),
        });
      }
    }
  };

  const renderDeleteActionButtons = () => [
    <Button
      className="capacityreservation-delete-button__button-cancel"
      key="capacityreservation-delete-button__button-cancel"
      data-testid="capacityreservation-delete-button__button-cancel"
      onClick={() => setState({ ...state, showDeleteModal: false })}
      type="secondary"
    >
      {t('common.button.no')}
    </Button>,
    <Button
      className="capacityreservation-delete-button__button-confirm"
      key="capacityreservation-delete-button__button-confirm"
      data-testid="capacityreservation-delete-button__button-confirm"
      type="primary"
      disabled={!isCancellationAllowed()}
      onClick={onClickYes}
    >
      {t('common.button.yes')}
    </Button>,
  ];

  if (!isCancellationAllowed()) {
    return null;
  }

  return (
    <>
      <ModalWrapper
        modalClassName="capacityreservation-delete-button"
        key="capacityreservation-delete-button-modal"
        visible={showDeleteModal}
        title={(
          <div className="capacityreservation-delete-button__title-container">
            <StatusIcon status="pending" />
            <h6 className="capacityreservation-delete-button__title-container--title">
              {t('capacityReservation.title.confirmDelete')}
            </h6>
          </div>
          )
        }
        handleClose={() => setState({ ...state, showDeleteModal: false })}
        actionButtons={renderDeleteActionButtons()}
        width="40%"
      >
        <Form.Item
          required
          label={t('capacityReservation.form.label.reason')}
          validateStatus={deleteNoteError ? 'error' : ''}
          help={deleteNoteError ? t('capacityReservation.form.info.reasonHelpText') : undefined}
        >
          <TextArea
            value={reason}
            disabled={!isCancellationAllowed()}
            required
            maxLength={4000}
            data-testid="capacityreservation-delete-button__reason-input"
            onChange={(e) => {
              setState({ state, reason: e.target.value });
            }}
            rows={6}
          />
        </Form.Item>
      </ModalWrapper>
      <Button
        className="capacityreservation-delete-button__button-delete"
        data-testid="capacityreservation-delete-button__button-delete"
        onClick={() => setState({ ...state, showDeleteModal: true })}
        type="primary"
        disabled={!isCancellationAllowed()}
      >
        {t('capacityReservation.form.button.cancelBooking')}
      </Button>
    </>
  );
};

CapacityReservationDeleteButton.propTypes = {
  t: PropTypes.func.isRequired,
  reservation: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    marketPartyId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CapacityReservationDeleteButton.displayName = 'CapacityReservationDeleteButton';

const CapacityReservationDeleteButtonTranslated = withTranslation()(CapacityReservationDeleteButton);

export default CapacityReservationDeleteButtonTranslated;
export {
  CapacityReservationDeleteButton as PureComponent,
};
