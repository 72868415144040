import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';

import {
  formatDate,
  localeDateFormat,
  formatNumber,
} from '../../utils/i18n';
import {
  getGasDay,
  getHourlyTimesInGasDay,
  hoursInGasDay,
  isGasDayToday,
  startOfGasDay,
  startOfOngoingGasDay,
} from '../../utils/gasday';
import {
  getEarliestValidTime,
  dayAheadClosingDatetime,
  isDayAheadOpenForGasDay,
  filterNominationPointAndDirection,
  getMarketPartiesToActonBehalfOfMarketPartyId,
  filterNominationPointsForNetwork,
} from '../../utils/nominationHelpers';
import {
  DIRECTIONS,
  DIRECTION_NAMES,
  LOCATION_POINTS,
  LOCATION_POINT_NAMES,
} from '../../constants/nominations';
import { getNumDecimalsForUnit } from '../../utils/balanceGroupStatsHelpers';
import { UNITS_EXTENDED } from '../../constants/units';
import { getMarketPartyEIC } from '../../utils/marketparty';

const { Option } = Select;

class NominationForm extends Component {
  constructor(props) {
    super(props);
    this.eicFieldRef = React.createRef();
    this.state = {
      isExternalEic: false,
    };
  }

  renderError = (hasError, field, showFieldName = true) => {
    const { t } = this.props;

    return hasError && (
      <p className="nomination-form__details__error">
        {`${t('common.notifications.incorrectInput')}
        ${showFieldName ? t(`nominations.label.${field}`) : ''}`}
      </p>
    );
  }

  handleSelectChange = (field, value) => {
    const { onChange } = this.props;
    const { isExternalEic } = this.state;

    // clear `Counterparty` field when imatra selected
    if (field === 'pointDirection' && value.startsWith(LOCATION_POINTS.IMATRA)) {
      if (isExternalEic) {
        this.eicFieldRef.current.setState({ value: [] });
      } else {
        const { rcSelect } = this.eicFieldRef.current;
        rcSelect.setState({ value: [] });
      }
    }

    onChange(field, value);
  }

  handleDateChange = (date) => {
    const { onChange } = this.props;
    const deliveryDate = date.format('YYYY-MM-DD');

    onChange('date', deliveryDate);
  }

  handleSearch = (inputValue, option) => (
    option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  )

  onCheckboxChange = (e) => {
    this.setState({
      isExternalEic: e.target.checked,
    });
  }

  handleInputChange = (event) => {
    const { onChange } = this.props;
    const {
      nativeEvent: {
        srcElement: {
          id: field,
        },
        target: {
          value,
        },
      },
    } = event;

    onChange(field, value);
  }

  getOptionValue = (field, option) => {
    switch (field) {
      case 'onBehalfOfMarketPartyId':
        return option.id;
      case 'pointDirection':
        return option;
      default:
        return '';
    }
  };

  getOptionLabel = (field, option) => {
    const { t } = this.props;
    switch (field) {
      case 'onBehalfOfMarketPartyId':
        return option.name;
      case 'pointDirection':
        return t(`nominations.label.${option}`);
      default:
        return '';
    }
  };

  getPointDirection = () => {
    const { nomination } = this.props;
    const { point, direction } = nomination;
    if (point && direction) {
      return `${point}-${direction}`; // make sure - (hyphen) is used as separator.
    }
    return '';
  }

  renderSelectField = (field, options) => {
    const {
      errors,
      isRenomination,
      viewOnly,
      nomination,
      t,
    } = this.props;
    const isPointDirectionField = field === 'pointDirection';
    const hasError = errors.includes(field);

    return (
      <Form.Item
        label={t(`nominations.label.${field}`)}
        key={field}
        className={classNames(
          'nomination-form__details__label',
          `nomination-form__${field}__label`,
          { 'has-error': hasError },
        )}
        colon={false}
      >
        <Select
          className={`nomination-form__${field}__select`}
          data-testid={`nomination-form__${field}__select`}
          id={field}
          name={field}
          disabled={isRenomination || viewOnly}
          allowClear={field === 'onBehalfOfMarketPartyId'}
          onChange={value => this.handleSelectChange(field, value)}
          value={
            isPointDirectionField
              ? this.getPointDirection()
              : nomination[field]
          }
        >
          {
            Object.values(options).map(option => (
              <Option
                key={option}
                value={this.getOptionValue(field, option)}
                data-testid={`nomination-form__${field}__option_${option}`}
                className={`nomination-form__${field}__option`}
              >
                {this.getOptionLabel(field, option)}
              </Option>
            ))
          }
        </Select>
        {this.renderError(hasError, field)}
      </Form.Item>
    );
  }

  renderDeliveryDateField = () => {
    const {
      errors,
      t,
      isDateAllowed,
      isRenomination,
      viewOnly,
      nomination,
    } = this.props;
    const field = 'date';
    const value = nomination[field] ? moment(nomination[field], 'YYYY-MM-DD') : null;
    const hasError = errors.includes(field);

    return (
      <Form.Item
        label={t(`nominations.label.${field}`)}
        key={field}
        className={classNames(
          'nomination-form__details__label',
          `nomination-form__${field}__label`,
          { 'has-error': hasError },
        )}
        colon={false}
      >
        <DatePicker
          className={`nomination-form__${field}__select`}
          id={field}
          format={localeDateFormat()}
          data-testid={`nomination-form__${field}__select`}
          disabledDate={ts => !isDateAllowed(ts)}
          onChange={this.handleDateChange}
          placeholder={t('nominations.label.chooseDate')}
          value={value}
          disabled={isRenomination || viewOnly}
        />
        {this.renderError(hasError, field)}
      </Form.Item>
    );
  }

  getMarketPartyByEic = (eic) => {
    const { marketPartyIndex } = this.props;
    return marketPartyIndex.find(marketParty => marketParty.eic === eic);
  }

  renderEICInputField = (field, disabled, value) => (
    <Input
      ref={this.eicFieldRef}
      className={`nomination-form__${field}__input`}
      data-testid={`nomination-form__${field}__input`}
      id={field}
      name={field}
      disabled={disabled}
      onChange={this.handleInputChange}
      value={value}
    />
  );

  renderEICSearchField = (field, disabled, value) => {
    const {
      marketPartyIndex,
      nomination,
    } = this.props;
    const { selectedMarketPartyId } = this.context;
    const { onBehalfOfMarketPartyId } = nomination;
    const showSelectedMarketPartyEic = (marketParty) => (
      !onBehalfOfMarketPartyId || marketParty.id === selectedMarketPartyId
    );
    const eicMarketParties = marketPartyIndex.filter(showSelectedMarketPartyEic);

    return (
      <Select
        ref={this.eicFieldRef}
        className={`nomination-form__${field}__select`}
        data-testid={`nomination-form__${field}__select`}
        id={field}
        name={field}
        onChange={id => this.handleSelectChange(
          field,
          getMarketPartyEIC({ marketPartyIndex }, id),
        )}
        filterOption={this.handleSearch}
        disabled={disabled}
        showSearch
        suffixIcon={<Icon type="search" />}
        value={value}
      >
        {
          eicMarketParties.map(marketParty => (
            <Option
              key={`${marketParty.id}_${marketParty.name}-option`}
              value={marketParty.id} // If `value` have duplicates -> console will scream!
              className={`nomination-form__${field}__option`}
            >
              {`${marketParty.name} (${marketParty.eic})`}
            </Option>
          ))
        }
      </Select>
    );
  }

  renderEICField = () => {
    const {
      errors,
      t,
      nomination,
      isRenomination,
      viewOnly,
    } = this.props;
    const { isExternalEic } = this.state;

    const field = 'shipperEIC';
    const hasError = errors.includes(field);
    const { shipperEIC, onBehalfOfMarketPartyId } = nomination;
    const disabled = Boolean(isRenomination
      || (nomination && nomination.point === LOCATION_POINTS.IMATRA)
      || onBehalfOfMarketPartyId
      || viewOnly);

    const marketParty = shipperEIC !== undefined
      ? this.getMarketPartyByEic(shipperEIC)
      : {};
    const { id: marketPartyId } = marketParty || {};
    const externalEic = isExternalEic || (isRenomination && marketPartyId === undefined);

    return (
      <>
        <Form.Item
          label={t(`nominations.label.${field}`)}
          key={field}
          className={classNames(
            'nomination-form__details__label',
            `nomination-form__${field}__label`,
            { 'has-error': hasError },
          )}
          colon={false}
          filterOption={this.handleSearch}
          disabled={disabled}
          showSearch
          suffixIcon={<Icon type="search" />}
          value={nomination[field]}
        >
          {externalEic
            ? this.renderEICInputField(field, disabled, shipperEIC)
            : this.renderEICSearchField(field, disabled, marketPartyId)
          }
          {this.renderError(hasError, field)}
        </Form.Item>
        <Checkbox
          className="nomination-form__external-eic"
          disabled={disabled}
          onChange={this.onCheckboxChange}
          checked={externalEic}
        >
          {t('nominations.label.isExternalEic')}
        </Checkbox>
      </>
    );
  }

  renderBCCapacityText = () => {
    const {
      nomination: {
        point,
        direction,
        date: selectedGasDay,
      },
      t,
    } = this.props;

    if (!selectedGasDay || point !== LOCATION_POINTS.BALTIC_CONNECTOR) {
      return null;
    }

    const { capacitySummary, capacityBCSummaryLoading } = this.context;

    if (capacityBCSummaryLoading) {
      return null;
    }

    if (!isDayAheadOpenForGasDay(selectedGasDay)) {
      return null;
    }

    const capacity = capacitySummary
      ? capacitySummary.find(value => value.gasDay === selectedGasDay) : undefined;

    let capacityValue;
    if (capacity && direction === DIRECTIONS.ENTRY) {
      capacityValue = capacity.entryBCOTC;
    }
    if (capacity && direction === DIRECTIONS.EXIT) {
      capacityValue = capacity.exitBCOTC;
    }

    // No value available
    if (capacityValue === undefined) {
      return null;
    }

    const dayAheadClosingTime = dayAheadClosingDatetime(selectedGasDay);

    return (
      <>
        <div>
          <span>
            {t('nominations.message.bcAvailableCapacity', {
              value: formatNumber(capacityValue, getNumDecimalsForUnit(UNITS_EXTENDED.KWH)),
              unit: 'kWh/day',
            })}
            <br />
            {t('nominations.message.bcAvailableCapacityUntil', {
              datetime: `${dayAheadClosingTime.format('YYYY-DD-MM HH:mm')}`,
            })}
          </span>
        </div>
      </>
    );
  }

  renderInstructionText = () => {
    const {
      nomination: {
        point,
      },
      t,
    } = this.props;

    if (point !== LOCATION_POINTS.BALTIC_CONNECTOR) {
      return null;
    }
    let instructions = t('nominations.message.bcInstructions', { returnObjects: true });
    instructions = Array.isArray(instructions) ? instructions : [instructions];

    return (
      <>
        {instructions.map((s, index) => (
          /* eslint-disable react/no-array-index-key */
          <Row className="nomination-form__instruction-text" key={`instruction-row-${index}`}>
            {s}
          </Row>
          /* eslint-enable react/no-array-index-key */
        ))}
      </>
    );
  }

  renderNominationDetails = () => {
    const {
      t,
      isShipper,
      viewOnly,
      marketPartyIndex,
      nomination,
      relationships,
      selectedMarketPartyId,
      getSelectedMarketParty,
    } = this.props;

    const selectedMarketParty = getSelectedMarketParty();
    // TODO: This logic is rather hard to follow what is really going on
    const shipperPoints = LOCATION_POINT_NAMES.flatMap(
      point => DIRECTION_NAMES
        .filter((direction) => filterNominationPointAndDirection(point, direction))
        .filter(() => filterNominationPointsForNetwork(point, selectedMarketParty))
        .map((direction) => `${point}-${direction}`), // make sure - (hyphen) is used as separator.
    );
    const points = isShipper || viewOnly
      ? shipperPoints
      : DIRECTION_NAMES.map(direction => `${LOCATION_POINTS.VTP}-${direction}`);

    const onBehalfOfMarketPartyIdMarketparties = getMarketPartiesToActonBehalfOfMarketPartyId(
      selectedMarketPartyId,
      relationships,
      marketPartyIndex,
      nomination.point,
      nomination.direction,
    );

    return (
      <>
        <Row>
          <h3>{t('nominations.title.nominationDetails')}</h3>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderSelectField('pointDirection', points)}
          </Col>
          <Col span={12}>
            {nomination.point && onBehalfOfMarketPartyIdMarketparties.length > 0
              && this.renderSelectField('onBehalfOfMarketPartyId', onBehalfOfMarketPartyIdMarketparties)
            }
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {this.renderDeliveryDateField()}
            {this.renderBCCapacityText()}
          </Col>
          <Col span={12}>
            {this.renderEICField()}
          </Col>
        </Row>
        { this.renderInstructionText() }
      </>
    );
  }

  getGasHours = (column) => {
    const {
      nomination: {
        date = getGasDay(startOfOngoingGasDay()),
      },
    } = this.props;

    const amountTimesAndHours = getHourlyTimesInGasDay(date);

    switch (column) {
      case 'left':
        return amountTimesAndHours.slice(0, 12);
      case 'right':
        return amountTimesAndHours.slice(12);
      default:
        return [];
    }
  };

  renderHourlyAmountLabel = (hour) => {
    const { nomination } = this.props;

    const { date } = nomination || { date: '' };
    let formattedDate = '';

    if (date && hour === '07:00') {
      formattedDate = formatDate(date);
    }
    if (date && hour === '00:00') {
      formattedDate = moment(date).add(1, 'days').format(localeDateFormat());
    }

    return (
      <div className="nomination-form__amounts__item__label">
        {hour}
        <span>{formattedDate}</span>
      </div>
    );
  };

  copyOverAmounts = (index) => {
    const {
      nomination: {
        amounts,
        date,
      },
      onChange,
    } = this.props;
    const valueToCopy = amounts[index];
    const length = hoursInGasDay(date);
    const amountIndexes = Array.from({ length }, (unused, i) => i);
    amountIndexes.forEach((amountIndex) => {
      const field = `amounts_${amountIndex}`;
      if (amountIndex > index) {
        onChange(field, valueToCopy);
      }
    });
  }

  renderCopyIcon = (index) => {
    const { t, nomination: { date } } = this.props;
    const translationKey = index === 0 ? 'hoverText' : 'renominationHoverText';

    return (
      <Tooltip
        placement="bottomRight"
        title={t(
          `nominations.label.${translationKey}`,
          { hoursInGasDay: hoursInGasDay(date) },
        )}
      >
        <Icon data-testid="nomint-copy-button" onClick={() => this.copyOverAmounts(index)} type="copy" />
      </Tooltip>
    );
  }

  renderAmountFields = (column) => {
    const {
      nomination: { amounts = [], date = getGasDay(), point },
      nomresAmounts,
      isRenomination,
      onChange,
      errors,
      viewOnly,
    } = this.props;

    const isVTP = point === LOCATION_POINTS.VTP;
    const earliestValidTime = getEarliestValidTime();
    const firstValidTimeForGasDay = isVTP || earliestValidTime.isSameOrBefore(startOfGasDay(date))
      ? startOfGasDay(date)
      : earliestValidTime;

    return this.getGasHours(column).map(([amountTime, amountTimeFormatted, hour], index) => {
      const amountIndex = column === 'left' ? index : (12 + index);
      const field = `amounts_${amountIndex}`;
      const hasError = errors.includes(field);

      const disabled = viewOnly || ((isVTP && isGasDayToday(date))
        ? false
        : amountTime.isBefore(earliestValidTime));
      if (disabled) {
        amounts[amountIndex] = isRenomination || viewOnly ? amounts[amountIndex] : 0;
      }

      const copyIcon = amountTime.isSame(firstValidTimeForGasDay) && !disabled
        ? this.renderCopyIcon(amountIndex)
        : '';

      return (
        <div key={`${field}-wrapper`}>
          <Form.Item
            label={this.renderHourlyAmountLabel(hour)}
            key={`${field}-item`}
            className={classNames(
              'nomination-form__amounts__item',
              `nomination-form__${field}__item`,
              { 'has-error': hasError },
            )}
            colon={false}
          >
            { disabled
              ? (
                <span
                  className={classNames(
                    'ant-form-text',
                    'nomination-form__amounts__span',
                  )}
                >
                  {amounts[amountIndex]}
                </span>
              )
              : (
                <InputNumber
                  className={classNames(
                    'nomination-form__amounts__input',
                    `nomination-form__${field}__input`,
                  )}
                  data-testid={`nomination-form__${field}__select`}
                  id={field}
                  name={field}
                  key={`${field}-input`}
                  min={0}
                  value={amounts[amountIndex]}
                  onChange={value => onChange(field, value)}
                />
              )
            }
            <span
              className={classNames(
                'ant-form-text',
                'nomination-form__amounts__span',
              )}
            >
              {`/ ${nomresAmounts[amountTimeFormatted] ?? '-'}`}
            </span>
            {copyIcon}
            {this.renderError(hasError, field, false)}
          </Form.Item>
          <Divider key={`nomination-amount-${hour}-divider`} />
        </div>
      );
    });
  }

  renderAmounts = () => {
    const { t } = this.props;

    return (
      <Row className="nomination-form__amounts">
        <Row>
          <h3>{t('nominations.title.hourlyGasAmounts', { unit: t('nominations.label.unit') })}</h3>
        </Row>
        <Row>
          <Col span={11}>
            <Row>
              <Col span={8}>
                <h4>{t('nominations.title.hour')}</h4>
              </Col>
              <Col span={16}>
                <h4>{t('nominations.title.gasAmount')}</h4>
              </Col>
            </Row>
            <Divider key="nomination-amounts-left-header" />
            {this.renderAmountFields('left')}
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Row>
              <Col span={8}>
                <h4>{t('nominations.title.hour')}</h4>
              </Col>
              <Col span={16}>
                <h4>{t('nominations.title.gasAmount')}</h4>
              </Col>
            </Row>
            <Divider key="nomination-amounts-right-header" />
            {this.renderAmountFields('right')}
          </Col>
        </Row>
      </Row>
    );
  }

  renderTotals = () => {
    const {
      nomination: {
        amounts = [],
      },
      nomresAmounts,
      t,
    } = this.props;

    const totalNomint = amounts.reduce((a, c) => a + c, 0);
    const nomresValues = Object.values(nomresAmounts).filter(amount => amount !== null);
    const totalNomres = nomresValues.length
      ? nomresValues.reduce((a, c) => a + c, 0)
      : null;

    return (
      <div className="nomination-form__totals">
        <h3>{t('nominations.title.totalAmounts')}</h3>
        <Row>
          <Col span={6}>
            <h4>
              {t('common.table.header.nomint')}
            </h4>
          </Col>
          <Col span={6}>
            {`${formatNumber(totalNomint, 0, 0)} kWh/d`}
          </Col>
          <Col span={6}>
            <h4>
              {t('common.table.header.nomres')}
            </h4>
          </Col>
          <Col span={6}>
            { totalNomres !== null ? `${formatNumber(totalNomres, 0, 0)} kWh/d` : 'N/A' }
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <Form className="nomination-form">
        <Row className="nomination-form__details">
          {this.renderNominationDetails()}
        </Row>
        <Divider key="nomination-details-divider" />
        {this.renderAmounts()}
        <Divider key="nomination-totals-divider" />
        {this.renderTotals()}
      </Form>
    );
  }
}

NominationForm.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  isDateAllowed: PropTypes.func.isRequired,
  selectedMarketPartyId: PropTypes.string.isRequired,
  nomination: PropTypes.shape({
    shipperEIC: PropTypes.string,
    status: PropTypes.string,
    point: PropTypes.string,
    direction: PropTypes.string,
    onBehalfOfMarketPartyId: PropTypes.string,
    date: PropTypes.string,
    amounts: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }),
  relationships: PropTypes.arrayOf(PropTypes.shape({})),
  nomresAmounts: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getSelectedMarketParty: PropTypes.func.isRequired,
  marketPartyIndex: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isShipper: PropTypes.bool.isRequired,
  isRenomination: PropTypes.bool,
  viewOnly: PropTypes.bool,
};
NominationForm.defaultProps = {
  nomination: null,
  nomresAmounts: {},
  isRenomination: false,
  relationships: [],
  viewOnly: false,
};
NominationForm.displayName = 'NominationForm';
NominationForm.contextType = Context;


export default withTranslation()(NominationForm);

export {
  NominationForm as PureComponent,
};
