import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Empty } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';
import Decimal from 'decimal.js';

import { formatDate, formatPrice } from '../../utils/i18n';
import ModalWrapper from '../../components/ModalWrapper';

import { DEFAULT_RANGE_TOTAL } from './constants';

import './capacitySumsChart.less';

const COLOR_STROKE = '#F5F5F5';
const COLOR_FILL = '#53C41A';
const MARGIN = 30;
const BAR_WIDTH = 20;

const getUpperDomainLimit = (dataMax) => {
  const maxRoundedUp = new Decimal(dataMax).toDecimalPlaces(0, Decimal.ROUND_UP);
  const magnitude = new Decimal(10 ** (maxRoundedUp.toString().length - 1));
  const maxPadded = maxRoundedUp.add(0.1).toNearest(magnitude.div(2), Decimal.ROUND_UP);
  return Number(maxPadded);
};

const CapacitySumsChart = (props) => {
  const {
    close,
    dataSource: {
      marketParty,
      capacityTotals = { totals: [] },
      taxTotals = { totals: [] },
      boundCapacity = DEFAULT_RANGE_TOTAL,
      boundCapacityEstimate = DEFAULT_RANGE_TOTAL,
      imbalance = DEFAULT_RANGE_TOTAL,
      imbalanceEstimate = DEFAULT_RANGE_TOTAL,
      overrunEstimate = DEFAULT_RANGE_TOTAL,
      tax = DEFAULT_RANGE_TOTAL,
    },
    visible,
  } = props;

  if (!marketParty) return null;

  const { t } = useTranslation();

  const actionButtons = [
    <Button key="closeButton" onClick={close}>
      {t('common.button.close')}
    </Button>,
  ];

  const renderChart = (data) => {
    if (!data.totals.length) return <Empty />;

    const values = data.totals.map(item => ({
      date: formatDate(item.timestamp),
      total: Number(new Decimal(item.total).toDecimalPlaces(2)),
    }));

    const maxItem = values.reduce(
      (max, current) => (current.total > max.total ? current : max), { total: 0 },
    );

    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        className="totals-chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={values}
            margin={{
              top: MARGIN,
              right: MARGIN,
              bottom: MARGIN,
              left: MARGIN,
            }}
          >
            <CartesianGrid stroke={COLOR_STROKE} />
            <XAxis dataKey="date" />
            <YAxis
              allowDecimals={false}
              type="number"
              domain={[0, getUpperDomainLimit]}
            />
            <Tooltip formatter={value => formatPrice(value)} />
            <Legend />
            <Bar
              dataKey="total"
              barSize={BAR_WIDTH}
              fill={COLOR_FILL}
            />
            <ReferenceLine
              y={maxItem.total}
              stroke="red"
              strokeDasharray="3 3"
            />
            <ReferenceLine
              x={maxItem.date}
              label={{
                position: 'top',
                value: formatPrice(maxItem.total),
              }}
              opacity={0}
            />
          </BarChart>
        </ResponsiveContainer>
      </Col>
    );
  };

  return (
    <ModalWrapper
      title={t('deposits.modal.title', { marketParty })}
      modalClassName="deposits-modal"
      handleClose={close}
      actionButtons={actionButtons}
      visible={visible}
      width="99vw"
    >
      <>
        <h2>{t('deposits.modal.capacityTotals')}</h2>
        {renderChart(capacityTotals)}
        <h2>{t('deposits.modal.taxTotals')}</h2>
        {renderChart(taxTotals)}
        <h2>
          {t('deposits.modal.boundCapacity', {
            start: formatDate(boundCapacity.start),
            end: formatDate(boundCapacity.end),
            price: formatPrice(boundCapacity.total),
          })}
        </h2>
        <h2>
          {t('deposits.modal.boundCapacityEstimate', {
            start: formatDate(boundCapacityEstimate.start),
            end: formatDate(boundCapacityEstimate.end),
            price: formatPrice(boundCapacityEstimate.total),
          })}
        </h2>
        <h2>
          {t('deposits.modal.imbalance', {
            start: formatDate(imbalance.start),
            end: formatDate(imbalance.end),
            price: formatPrice(imbalance.total),
          })}
        </h2>
        <h2>
          {t('deposits.modal.imbalanceEstimate', {
            start: formatDate(imbalanceEstimate.start),
            end: formatDate(imbalanceEstimate.end),
            price: formatPrice(imbalanceEstimate.total),
          })}
        </h2>
        <h2>
          {t('deposits.modal.overrunEstimate', {
            start: formatDate(overrunEstimate.start),
            end: formatDate(overrunEstimate.end),
            price: formatPrice(overrunEstimate.total),
          })}
        </h2>
        <h2>
          {t('deposits.modal.tax', {
            start: formatDate(tax.start),
            end: formatDate(tax.end),
            price: formatPrice(tax.total),
          })}
        </h2>
      </>
    </ModalWrapper>
  );
};

const totalsType = PropTypes.shape({
  totals: PropTypes.arrayOf(PropTypes.shape({
    total: PropTypes.number,
    timestamp: PropTypes.string,
  })),
});

CapacitySumsChart.propTypes = {
  dataSource: PropTypes.shape({
    marketParty: PropTypes.string,
    capacityTotals: totalsType,
    taxTotals: totalsType,
    boundCapacity: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
    boundCapacityEstimate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
    imbalance: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
    imbalanceEstimate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
    overrunEstimate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
    tax: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      total: PropTypes.number,
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default CapacitySumsChart;
