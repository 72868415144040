import joi from 'joi';
import joiDateExtensions from '@joi/date';

import {
  CAPACITY_TYPES_API_NAMES,
} from '../constants/capacities';
import {
  STATUS_NAMES,
} from '../constants/status';

const joiDate = joi.extend(joiDateExtensions);
const date = joiDate.date().utc().format('YYYY-MM-DD').raw();

const CAPACITY_SCHEMA = {
  type: joi.string().valid(...CAPACITY_TYPES_API_NAMES),
  date: date.greater('now'),
  capacity: joi.number(),
};

const capacity = joi.object().keys(Object.assign(
  ...Object.entries(CAPACITY_SCHEMA).map(([key, value]) => ({
    [key]: value.required(),
  })),
));

const CAPACITYTRANSFER_SCHEMA = joi.object({
  id: joi.string().uuid(),
  sellerId: joi.string().uuid(),
  buyerId: joi.string().uuid(),
  buyerStatus: joi.string().valid(...STATUS_NAMES),
  status: joi.string().valid(...STATUS_NAMES),
  capacities: joi.array().min(1).items(capacity),
});

export {
  CAPACITY_SCHEMA,
  CAPACITYTRANSFER_SCHEMA,
};
