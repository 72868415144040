import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Icon,
  DatePicker,
  Button,
  notification,
  Row,
  Col,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { lowerCase } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import Loading from '../Loading';
import { localeDateFormat } from '../../utils/i18n';
import { getGasDay, startOfGasDay } from '../../utils/gasday';

import './newMembership.less';

const logger = new Logger('add-new-balancegroup-membership:form');

const PROCESSING_DAYS = 3;

class NewMembership extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      memberMarketPartyId: '',
      start: '',
    };
  }

  componentDidMount = async () => {
    const {
      activeBalanceGroupId,
      updateActiveBalanceGroupId,
      marketPartyIndex,
      updateMarketpartyIndex,
    } = this.context;

    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (activeBalanceGroupId === null) updateActiveBalanceGroupId();
  }

  earliestPossibleDay = () => (
    moment.tz(getGasDay(), 'UTC').add(PROCESSING_DAYS, 'days').format('YYYY-MM-DD')
  );

  isButtonDisabled = () => {
    const {
      memberMarketPartyId,
      start,
    } = this.state;

    return memberMarketPartyId === '' || start === '';
  }

  isDataLoaded = () => {
    const {
      activeBalanceGroupId,
      balanceGroups,
      isLoading,
      marketPartyIndex,
    } = this.context;

    return (
      activeBalanceGroupId !== null
      && balanceGroups !== null
      && marketPartyIndex.length > 0
      && !isLoading
    );
  }

  isDateAllowed = timestamp => (
    moment().isSameOrAfter(startOfGasDay(this.earliestPossibleDay(timestamp)))
  );

  handleSearch = (inputValue, option) => (option.key.includes(lowerCase(inputValue)));

  handleSelectChange = (memberMarketPartyId) => {
    this.setState({ memberMarketPartyId });
  };

  handleDateChange = (date) => {
    const start = date.format('YYYY-MM-DD');
    this.setState({ start });
  }

  handleSubmit = async () => {
    const { t } = this.props;
    const {
      memberMarketPartyId,
      start,
    } = this.state;
    const {
      selectedMarketPartyId,
      activeBalanceGroupId,
      handleDbChange,
      isAdmin,
      isReadOnlyAdmin,
    } = this.context;

    if (activeBalanceGroupId !== ''
      && selectedMarketPartyId !== undefined
      && !isAdmin()
      && !isReadOnlyAdmin()) {
      this.setState({ isLoading: true });
      try {
        const createdMembership = await API.post(
          'FINTSO',
          `/marketparties/${selectedMarketPartyId}/balancegroups/${activeBalanceGroupId}/members`,
          {
            body: {
              marketPartyId: memberMarketPartyId,
              start,
            },
          },
        );

        handleDbChange('BalanceGroupMember', 'create', createdMembership);

        notification.success({
          message: t('balanceContainer.membership.message.addedSuccessfully'),
          description: '',
        });

        this.setState({ isLoading: false });
      } catch (err) {
        notification.error({
          className: 'notification-error',
          message: t('balanceContainer.membership.message.errorAdding'),
          // TODO: translatable error message
          description: createErrorMessage(err),
        });
        logger.error(createErrorMessage(err, true));
        this.setState({ isLoading: false });
      }
    }
  }

  renderStartDate = () => {
    const { t } = this.props;
    const { activeBalanceGroupId, balanceGroups } = this.context;
    const balanceGroup = (balanceGroups || []).find(({ id }) => id === activeBalanceGroupId);
    const disabledDate = (timestamp) => {
      const gasDay = getGasDay(timestamp);
      return gasDay <= this.earliestPossibleDay() || (balanceGroup && balanceGroup.start > gasDay);
    };

    return (
      <DatePicker
        className="new-membership__start-date"
        placeholder={t('balanceContainer.label.chooseDate')}
        format={localeDateFormat()}
        data-testid="new-membership__start-date"
        disabledDate={disabledDate}
        onChange={this.handleDateChange}
      />
    );
  };

  renderSubmitButton = () => {
    const { t } = this.props;
    const { isLoading } = this.state;

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={this.isButtonDisabled()}
        loading={isLoading}
        onClick={this.handleSubmit}
      >
        { isLoading ? `${t('common.status.loading')}...` : t('balanceContainer.button.add') }
      </Button>
    );
  }

  renderShippersSelector = () => {
    const { marketPartyIndex } = this.context;
    const { t } = this.props;

    return (
      <Select
        className="new-membership__selectOrSearch"
        data-testid="new-membership__selectOrSearch"
        placeholder={t('balanceContainer.label.selectOrSearch')}
        onChange={this.handleSelectChange}
        filterOption={this.handleSearch}
        showSearch
        suffixIcon={<Icon type="search" />}
      >
        {marketPartyIndex.map(item => (
          <Select.Option
            key={lowerCase(item.name)}
            value={item.id}
            data-testid="new-membership__options"
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  render = () => {
    const { t } = this.props;

    return this.isDataLoaded()
      ? (
        <Row className="new-membership">
          <Col span={5}>
            <span className="new-membership-label">
              {t('balanceContainer.label.addNewMembers')}
            </span>
          </Col>
          <Col span={10}>{this.renderShippersSelector()}</Col>
          <Col span={6}>{this.renderStartDate()}</Col>
          <Col span={3}>{this.renderSubmitButton()}</Col>
        </Row>
      )
      : <Loading />;
  }
}

NewMembership.displayName = 'NewMembership';
NewMembership.contextType = Context;

NewMembership.propTypes = {
  t: PropTypes.func.isRequired,
};

const NewMembershipTranslated = withTranslation()(NewMembership);

export default NewMembershipTranslated;
export {
  NewMembership as PureComponent,
};
