import React, { useContext, useEffect, useState } from 'react';
import { Layout, Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Decimal from 'decimal.js';

import Context from '../../context';
import PageHeaderBar from '../../components/PageHeaderBar';
import ButtonSelector from '../../components/ButtonSelector';
import { naturalSort } from '../../utils/sort';
import { formatPrice, formatDateTimeStr } from '../../utils/i18n';
import { hasRole } from '../../utils/marketparty';
import { ROLES } from '../../constants/marketParties';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import EditDeposit from './editDeposit';
import CapacitySumsChart from './capacitySumsChart';

import './index.less';

const VISIBILITY = {
  SHOW: 'show',
  HIDDEN: 'hide',
};
const VISIBILITY_NAMES = Object.values(VISIBILITY);

const Deposits = () => {
  const { t } = useTranslation();
  const {
    deposits,
    marketPartyDepositSummary = {},
    isAdmin,
    isReadOnlyAdmin,
    marketPartyIndex,
    saveDeposit,
    selectedMarketPartyId,
    updateDeposits,
    updateMarketPartyDepositSummary,
    updateMarketpartyIndex,
  } = useContext(Context);

  const [chartVisible, setChartVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [hideInactive, setHideInactive] = useState(true);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);

  // TODO: optimize for non admins, no need for this list
  const marketParties = marketPartyIndex.filter(item => (
    hasRole(item, [ROLES.SHIPPER, ROLES.DSO, /* ROLES.TRADER, */ ROLES.ENDUSER])
  ));
  const marketPartyIds = marketParties.map(item => item.id);

  const fetchDepositSummaries = async () => {
    for await (const mid of marketPartyIds) {
      // This loop becomes background process, so to detect "unmount" for functional component
      // different route needs to be checked with window.location object which is mutable
      if (window.location.pathname === '/deposits') {
        await updateMarketPartyDepositSummary(mid);
      } else {
        // stop loading, restarted when browsed back
        break;
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    if (marketPartyIndex.length === 0) updateMarketpartyIndex();
    if (isAdmin() || isReadOnlyAdmin()) {
      await updateDeposits();
      fetchDepositSummaries();
    } else {
      await updateMarketPartyDepositSummary(selectedMarketPartyId);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedMarketPartyId]);

  const columns = [
    {
      title: 'marketParty',
      dataIndex: 'marketParty',
      key: 'marketParty',
      sorter: (a, b) => naturalSort(a.marketParty, b.marketParty),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'set',
      dataIndex: 'set',
      key: 'set',
      render: value => formatPrice(value),
      sorter: (a, b) => a.set - b.set,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'required',
      dataIndex: 'required',
      key: 'required',
      render: value => (value !== undefined ? formatPrice(value) : ''),
      sorter: (a, b) => a.required - b.required,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'used',
      dataIndex: 'used',
      key: 'used',
      render: value => (value !== undefined ? formatPrice(value) : ''),
      sorter: (a, b) => a.used - b.used,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: value => (value !== undefined ? formatPrice(value) : ''),
      sorter: (a, b) => a.remaining - b.remaining,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'startGasDay',
      dataIndex: 'start',
      key: 'start',
      render: formatDateTimeStr,
      sorter: (a, b) => a.start - b.end,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'endGasDay',
      dataIndex: 'end',
      key: 'end',
      render: formatDateTimeStr,
      sorter: (a, b) => a.start - b.end,
      sortDirections: ['ascend', 'descend'],
    }].concat(isAdmin()
    ? {
      title: 'edit',
      render: row => (
        <Button
          className="link"
          onClick={(event) => {
            // Intercept row click event
            event.stopPropagation();
            setId(row.marketPartyId);
            setEditVisible(true);
          }}
        >
          {t('common.table.header.edit')}
        </Button>
      ),
    }
    : []);

  const by = marketPartyId => item => item.marketPartyId === marketPartyId;

  const getDataSource = () => {
    const getDepositSummary = (marketPartyId) => {
      // Summary may be missing, if it has not yet loaded
      const summary = marketPartyDepositSummary[marketPartyId] || { deposit: {} };
      const { details = {} } = summary.deposit;
      // eslint-disable-next-line object-curly-newline
      let { set = '0', requisite: required, used, remaining } = summary.deposit;
      // Convert to number
      set = set !== undefined ? Number(set) : undefined;
      required = required !== undefined ? Number(required) : undefined;
      used = used !== undefined ? Number(used) : undefined;
      remaining = remaining !== undefined ? Number(remaining) : undefined;
      return {
        set,
        required,
        used,
        remaining,
        start: details.start,
        end: details.end,
      };
    };

    if (isAdmin() || isReadOnlyAdmin()) {
      return marketPartyIds.reduce((acc, marketPartyId) => {
        const marketParty = marketPartyIndex.find(item => item.id === marketPartyId);

        if (!marketParty) return acc;

        const deposit = deposits.find(by(marketPartyId)) || {};
        const timestamp = deposit.timestamp || '';

        const set = Number(new Decimal(deposit.amount || 0));

        const { required, used, remaining } = getDepositSummary(marketPartyId);

        // used value comes later, so rows may appear if no "set", but there is "used"
        const hasBoughtCapacity = set !== 0 || (used !== undefined && used !== 0);
        if (!hasBoughtCapacity && hideInactive) {
          return acc;
        }

        acc.push({
          marketParty: marketParty.name,
          marketPartyId,
          set,
          required,
          used,
          remaining,
          start: deposit.start,
          end: deposit.end,
          timestamp,
        });
        return acc;
      }, []);
    }

    const marketParty = marketPartyIndex.find(item => item.id === selectedMarketPartyId) || {};

    const depositSummary = getDepositSummary(selectedMarketPartyId);
    return [{
      marketParty: marketParty.name,
      marketPartyId: selectedMarketPartyId,
      ...depositSummary,
    }];
  };

  const selectedId = (isAdmin() || isReadOnlyAdmin()) ? id : selectedMarketPartyId;
  const dataSource = loading ? [] : getDataSource();
  const marketPartyData = dataSource.find(item => item.marketPartyId === selectedId) || {};

  // Chart data for selected market party (renders chart if visible)
  let capacityTotals;
  let taxTotals;
  let imbalanceEstimate;
  let imbalance;
  let boundCapacity;
  let boundCapacityEstimate;
  let overrunEstimate;
  let tax;
  if (chartVisible) {
    const summary = marketPartyDepositSummary[selectedId] || {};
    if (summary.requisiteDetails) {
      const { capacitySums = {}, taxSums = {} } = summary.requisiteDetails;

      const mapToChartData = sums => (Object.entries(sums).map(
        ([timestamp, total]) => ({ timestamp, total: Number(total) }),
      ));

      capacityTotals = mapToChartData(capacitySums);
      taxTotals = mapToChartData(taxSums);
    }

    if (summary.boundDetails && summary.boundDetails.boundImbalance) {
      imbalance = {
        total: Number(summary.bound.boundImbalance || 0),
        start: summary.boundDetails.boundImbalance.start,
        end: summary.boundDetails.boundImbalance.end,
      };
    }

    if (summary.boundDetails && summary.boundDetails.boundImbalanceEstimate) {
      imbalanceEstimate = {
        total: Number(summary.bound.boundImbalanceEstimate || 0),
        start: summary.boundDetails.boundImbalanceEstimate.start,
        end: summary.boundDetails.boundImbalanceEstimate.end,
      };
    }

    if (summary.boundDetails && summary.boundDetails.boundTax) {
      tax = {
        total: Number(summary.bound.boundTax || 0),
        start: summary.boundDetails.boundTax.start,
        end: summary.boundDetails.boundTax.end,
      };
    }

    if (summary.bound) {
      boundCapacity = {
        total: Number(summary.bound.boundCapacityLong || 0)
          + Number(summary.bound.boundCapacityShort || 0),
        start: summary.bound.range.start,
        end: summary.bound.range.end,
      };
      boundCapacityEstimate = {
        total: Number(summary.bound.boundCapacityLongEstimate || 0)
          + Number(summary.bound.boundCapacityShortEstimate || 0),
        start: summary.boundDetails.boundCapacityShortEstimate.start || summary.bound.range.start,
        end: summary.boundDetails.boundCapacityShortEstimate.end || summary.bound.range.end,
      };
      overrunEstimate = {
        total: Number(summary.bound.boundOverrunEstimate || 0),
        start: summary.boundDetails.boundOverrunEstimate.start,
        end: summary.boundDetails.boundOverrunEstimate.end,
      };
    }
  }

  const chartData = {
    capacityTotals: capacityTotals ? { totals: capacityTotals } : undefined,
    taxTotals: taxTotals ? { totals: taxTotals } : undefined,
    boundCapacity,
    boundCapacityEstimate,
    overrunEstimate,
    imbalanceEstimate,
    imbalance,
    tax,
    marketParty: marketPartyData.marketParty,
  };

  const displayChart = row => ({
    onClick: () => {
      const { marketPartyId } = row;
      setId(marketPartyId);
      setChartVisible(true);
    },
  });

  const actionItem = (isAdmin() || isReadOnlyAdmin()) ? (
    <ButtonSelector
      t={t}
      options={VISIBILITY_NAMES}
      defaultValue={VISIBILITY.HIDDEN}
      title="inactiveMarketParties"
      onChange={({ target: { value } }) => {
        setHideInactive(value === VISIBILITY.HIDDEN);
      }}
    />
  ) : null;

  return (
    <>
      <PageHeaderBar
        title={t('deposits.title')}
        span={{
          title: 12,
          action: 12,
        }}
        actionItems={[actionItem]}
      />
      <Layout.Content className="layout__container__page__content deposits">
        <Table
          columns={getTranslatedTableHeaders(columns, t)}
          dataSource={dataSource}
          loading={loading}
          pagination={false}
          rowKey={item => item.marketPartyId}
          onRow={displayChart}
        />
      </Layout.Content>
      {!!marketPartyData && (
      <EditDeposit
        close={() => setEditVisible(false)}
        dataSource={marketPartyData}
        save={saveDeposit}
        visible={editVisible}
      />
      )}
      {!!chartData && (
      <CapacitySumsChart
        close={() => setChartVisible(false)}
        dataSource={chartData}
        visible={chartVisible}
      />
      )}
    </>
  );
};

export default Deposits;
