/* eslint-disable no-bitwise */

/**
 * A simple hash function. Not safe for crypto!
 * https://stackoverflow.com/a/7616484/4796241
 *
 * @param {String} str
 * @returns {Number} hash
 */
const getHash = (str = '') => {
  let hash = 0;
  let chr = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
};

export default getHash;
