import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Button,
  DatePicker,
  notification,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { withTranslation, Trans } from 'react-i18next';
import {
  withRouter,
} from 'react-router-dom';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import { localeDateFormat } from '../../utils/i18n';
import { getGasDay } from '../../utils/gasday';
import { getLatestDate, isDateInRange } from '../../utils/dateTimeHelpers';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import './newBalanceGroup.less';

const PROCESSING_DAYS = 3;

const logger = new Logger('new-delivery-relation-ship:form');

class NewBalanceGroup extends Component {
  state = {
    isSaving: false,
    start: '',
  };

  componentDidMount() {
    const { balanceGroupMembers, updateBalanceGroupMembers } = this.context;
    if (!balanceGroupMembers) updateBalanceGroupMembers();
  }

  isButtonDisabled = () => {
    const { start } = this.state;

    return start === '';
  }

  closeModal = () => {
    const {
      history,
      match: {
        url,
      },
    } = this.props;
    const basePath = url.replace('/new-balance-group', '');
    return history.push(basePath);
  };

  handleCancel = () => {
    const { t } = this.props;

    return this.isButtonDisabled()
      ? this.closeModal()
      : modalConfirmClose(t, this.closeModal);
  }

  handleCreate = async (event) => {
    event.preventDefault();

    this.setState({
      isSaving: true,
    });

    const {
      isAdmin,
      isReadOnlyAdmin,
      selectedMarketPartyId,
    } = this.context;
    const { t } = this.props;
    const { start } = this.state;

    if (selectedMarketPartyId !== undefined && !isAdmin() && !isReadOnlyAdmin()) {
      try {
        await API.post('FINTSO', `/marketparties/${selectedMarketPartyId}/balancegroups`, {
          body: {
            start,
          },
        });

        notification.success({
          message: t('balanceContainer.message.createdSuccessfully'),
          description: '',
        });

        this.closeModal();
      } catch (err) {
        notification.error({
          className: 'notification-error',
          message: t('balanceContainer.message.errorCreating'),
          // TODO: translatable error message
          description: createErrorMessage(err),
        });
        logger.error(createErrorMessage(err, true));
        this.setState({ isSaving: false });
      }
    }
  };

  earliestPossibleDay = () => (
    moment.tz(getGasDay(), 'UTC').add(PROCESSING_DAYS, 'days').format('YYYY-MM-DD')
  );

  handleDateChange = (date) => {
    const start = date.format('YYYY-MM-DD');
    this.setState({ start });
  }

  getCurrentMembership = () => {
    const { balanceGroupMembers, selectedMarketPartyId } = this.context;
    return (balanceGroupMembers || []).find(({ marketPartyId, start, end }) => (
      marketPartyId === selectedMarketPartyId && isDateInRange(getGasDay(), start, end)
    )) || {};
  }

  renderEndDate = () => {
    const { t } = this.props;
    const minDate = getLatestDate([this.earliestPossibleDay(), this.getCurrentMembership().end]);

    return (
      <DatePicker
        className="new-balance-group__start-date"
        placeholder={t('balanceContainer.label.chooseDate')}
        format={localeDateFormat()}
        data-testid="new-balance-group__start-date"
        disabledDate={timestamp => getGasDay(timestamp) <= minDate}
        onChange={this.handleDateChange}
      />
    );
  };

  renderContent = () => {
    const { t } = this.props;
    const party = t('balanceContainer.modal.imbalanceParty');

    return (
      <div className="new-balance-group__content">
        <p>
          <Trans i18nKey="balanceContainer.modal.description_1">
            <strong>{{ party }}</strong>
          </Trans>
        </p>
        <p>
          {t('balanceContainer.modal.description_2')}
        </p>
        <div className="new-balance-group__content__date-selector">
          <div className="new-balance-group__content__date-selector__header">
            {t('balanceContainer.title.balanceGroupStarts')}
          </div>
          <div>
            {t('balanceContainer.message.balanceGroupStarts')}
          </div>
          <div className="new-balance-group__content__date-selector__input">
            { this.renderEndDate()}
          </div>
        </div>
      </div>
    );
  }

  renderActionButtons = () => {
    const { t } = this.props;
    const { isSaving } = this.state;

    return ([
      <Button
        className="new-balance-group__cancel-button"
        key="cancel"
        onClick={this.handleCancel}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="new-balance-group__create-button"
        key="save"
        loading={isSaving}
        type="primary"
        onClick={this.handleCreate}
        disabled={this.isButtonDisabled()}
      >
        {t('common.button.create')}
      </Button>,
    ]);
  };

  render = () => {
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="new-balance-group"
        title={t('balanceContainer.title.newBalanceGroup')}
        actionButtons={this.renderActionButtons()}
        handleClose={this.handleCancel}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

NewBalanceGroup.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

NewBalanceGroup.displayName = 'NewBalanceGroup';
NewBalanceGroup.contextType = Context;

const TRANSLATED = withTranslation()(NewBalanceGroup);

export default withRouter(TRANSLATED);
export {
  NewBalanceGroup as PureComponent,
};
