import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  DatePicker,
} from 'antd';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import {
  localeDateFormat,
} from '../../utils/i18n';

import './index.less';

const DateRangePicker = ({
  defaultStart, defaultEnd, onValidDateSelected, maxDays,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStart);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEnd);
  const { t } = useTranslation();

  const invalidDate = date => date && (date.isBefore(selectedStartDate)
    || ((maxDays && maxDays > 0) && date.diff(selectedStartDate, 'days') > maxDays));

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      if (onValidDateSelected && !invalidDate(selectedEndDate)) {
        onValidDateSelected(selectedStartDate, selectedEndDate);
      }
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className="date-picker">
      <div className="date-picker-start">
        <div className="title">
          {t('dateRangePicker.label.startDate')}
        </div>
        <DatePicker
          className="date-range-picker__date-picker-start"
          allowClear={false}
          format={localeDateFormat()}
          value={selectedStartDate}
          onChange={setSelectedStartDate}
        />
      </div>
      <span className="date-picker__separator">–</span>
      <div className="date-picker-end">
        <div className="title">
          {t('dateRangePicker.label.endDate')}
        </div>
        <DatePicker
          className={classNames(
            'date-range-picker__date-picker-end',
            { 'ant-calendar-picker--error': invalidDate(selectedEndDate) },
          )}
          allowClear={false}
          format={localeDateFormat()}
          value={selectedEndDate}
          disabledDate={invalidDate}
          onChange={setSelectedEndDate}
        />
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  defaultStart: PropTypes.instanceOf(moment),
  defaultEnd: PropTypes.instanceOf(moment),
  onValidDateSelected: PropTypes.func,
  maxDays: PropTypes.number,
};

DateRangePicker.defaultProps = {
  defaultStart: null,
  defaultEnd: null,
  onValidDateSelected: null,
  maxDays: 31,
};

export default DateRangePicker;
