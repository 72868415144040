import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
} from 'antd';
import {
  API,
  Logger,
} from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import {
  withRouter,
} from 'react-router-dom';
import joi from 'joi';
import { isEqual } from 'lodash';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import UserForm from './userForm';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';
import getUserSchema from '../../schemas/users';
import { mapUsersPermissionsWithMarketPartyRoles } from '../../utils/userHelpers';


const logger = new Logger('new-users:form');

const User = ({
  match,
  history,
  users,
}) => {
  const { t } = useTranslation();

  const {
    selectedMarketPartyId,
    handleDbChange,
    isAdmin,
    isReadOnlyAdmin,
  } = useContext(Context);

  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState(null);
  const [userOriginal, setUserOriginal] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [permissionsOriginal, setPermissionsOriginal] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { params: { id: userId } } = match;
  const isUpdateUser = () => (userId !== 'new-user');

  useEffect(() => {
    if (isUpdateUser()) {
      const selectedUser = users && users.find(item => (item.id === userId));

      if (selectedUser) {
        const permissionsForUser = mapUsersPermissionsWithMarketPartyRoles(
          selectedUser,
        )[selectedMarketPartyId];

        setUser(selectedUser);
        setUserOriginal(selectedUser);
        setPermissions(permissionsForUser);
        setPermissionsOriginal(permissionsForUser);
      }
    }
  }, [match.params]);

  const handleChange = (field, value) => {
    const userSchema = getUserSchema();
    const { error } = userSchema[field].validate(value);
    const updatedErrors = errors && errors.filter(err => err !== field);

    if (error) {
      updatedErrors.push(field);
    }

    setErrors(updatedErrors);
    setUser({
      ...user,
      [field]: value,
    });

    return !!error;
  };

  const getAggregateUserData = () => {
    const userPermissions = permissions && Object.keys(permissions)
      .filter(key => (permissions[key] === true))
      .map(permission => (permission));

    return {
      ...user,
      permissions: userPermissions,
    };
  };

  const hasPermissionChanges = () => {
    const userChanges = !isEqual(userOriginal, user);
    const permissionsChanges = !isEqual(permissionsOriginal, permissions);

    return userChanges || permissionsChanges;
  };

  const hasUserInfoChanged = () => {
    const updated = getAggregateUserData();
    const fields = ['firstname', 'lastname', 'phone', 'email'];
    const hasChanges = fields.some((key) => updated[key] !== userOriginal[key]);
    return hasChanges;
  };

  const incompleteUserRequiredInfo = () => {
    if (user === null) return true;
    const {
      firstname,
      lastname,
      phone,
      email,
    } = user;

    return !firstname || !lastname || !phone || !email;
  };

  const isSaveButtonDisabled = () => (
    errors.length > 0
    || permissions === null
    || incompleteUserRequiredInfo()
    || (isUpdateUser() && !hasPermissionChanges())
  );

  const closeModal = () => history.push('/users');

  const handleCancel = () => (
    isSaveButtonDisabled()
      ? closeModal()
      : modalConfirmClose(t, closeModal)
  );

  const validateData = (payload) => {
    const userSchema = getUserSchema();
    const { error } = joi.object(userSchema).validate(payload);

    if (error) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.incorrectInput'),
        description: error.toString(),
      });
    }

    return !error;
  };

  const throwError = (error, errorMessage) => {
    notification.error({
      className: 'notification-error',
      message: errorMessage,
      description: createErrorMessage(error),
    });
    logger.error(createErrorMessage(error, true));
  };

  const handleSubmit = async () => {
    const aggregatedUserData = getAggregateUserData();
    const {
      permissions: aggregatedUserPermissions,
      firstname,
      lastname,
      phone,
      email,
    } = aggregatedUserData;

    if (!validateData(aggregatedUserData)) return;

    let successMessage = `${t('common.status.successfullyCreated')}`;
    let errorMessage = `${t('common.notifications.errorCreating')}`;
    if (isUpdateUser()) {
      successMessage = `${t('common.status.successfullyUpdated')}`;
      errorMessage = `${t('common.notifications.errorUpdating')}`;
    }

    // user info update allowed to only for TSO admin users
    if (isAdmin(true) && isUpdateUser() && hasUserInfoChanged()) {
      try {
        setIsSaving(true);
        const response = await API.patch('FINTSO', `/admin/users/${userId}`, {
          body: {
            firstname, lastname, email, phone,
          },
        });

        handleDbChange('MarketPartyUser', 'update', {
          ...response,
          permissions: aggregatedUserPermissions,
        });
        notification.success({
          message: `User's info ${successMessage}`,
          description: '',
        });
      } catch (e) {
        throwError(e, errorMessage);
      } finally {
        setIsSaving(false);
      }
    }

    if (selectedMarketPartyId !== undefined && !isAdmin() && !isReadOnlyAdmin()) {
      try {
        setIsSaving(true);

        if (isUpdateUser()) {
          const response = await API.patch('FINTSO', `/marketparties/${selectedMarketPartyId}/users/${aggregatedUserData.id}`, {
            body: { permissions: aggregatedUserPermissions },
          });

          handleDbChange('MarketPartyUser', 'update', response);
          notification.success({
            message: `Permissions ${successMessage}`,
            description: '',
          });
        } else {
          const response = await API.post('FINTSO', `/marketparties/${selectedMarketPartyId}/users`, {
            body: aggregatedUserData,
          });

          handleDbChange('MarketPartyUser', 'create', response);
          notification.success({
            message: successMessage,
            description: '',
          });
        }
      } catch (e) {
        throwError(e, errorMessage);
      } finally {
        setIsSaving(false);
      }
    }
    closeModal();
  };

  const renderActionButtons = () => [
    <Button
      className="new-user__cancel-button"
      data-testid="users-dialog__cancel-button"
      key="cancel"
      onClick={handleCancel}
    >
      {t('common.button.cancel')}
    </Button>,
    <Button
      className="new-users__create-button"
      key="save"
      data-testid="users-dialog__submit-button"
      loading={isSaving}
      type="primary"
      onClick={handleSubmit}
      disabled={isSaveButtonDisabled()}
    >
      {t('common.button.saveAndClose')}
    </Button>,
  ];

  return (
    <ModalWrapper
      modalClassName="new-users"
      title={isUpdateUser() ? t('users.title.updateUser') : t('users.title.newUser')}
      handleClose={handleCancel}
      actionButtons={renderActionButtons()}
    >
      <UserForm
        errors={errors}
        user={user}
        permissions={permissions}
        onPermissionsChange={setPermissions}
        onChange={handleChange}
        isUpdateUser={isUpdateUser()}
      />
    </ModalWrapper>
  );
};

User.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
};
User.defaultProps = {
  users: [],
};
User.displayName = 'User';

export default withRouter(User);
export {
  User as PureComponent,
};
