/**
 * Helper functions for generating millisecond intervals with
 * human friendly abstractions.
 */

const seconds = amount => amount * 1000;
const minutes = amount => 60 * seconds(amount);

export {
  seconds,
  minutes,
};
