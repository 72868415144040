import React from 'react';
import PropTypes from 'prop-types';
import { Card, Empty } from 'antd';
import classNames from 'classnames';
import getHash from '../../utils/hash';

import './index.less';

/**
 * A container for displaying an array of similar data objects as cards.
 * A renderCard function is passed to determine how to show the data. An
 * optional cardClass can be provided to give rendered cards a particular
 * CSS class.
 *
 * The cardClassFn callback can be provided to add additional classes based
 * on the data within the card. The function should return either a string
 * or an object { className1: boolean, className2: boolean, ...}
 */
const CardContainer = (props) => {
  const {
    cardClass, cardClassFn, dataSource, renderCard, title, wide,
  } = props;

  const cards = dataSource.map((data) => {
    const className = classNames(cardClass, cardClassFn(data));
    return (
      <Card key={`${title}#${getHash(JSON.stringify(data))}`} className={className}>
        {renderCard(data)}
      </Card>
    );
  });

  const containerClass = classNames(
    'card-container--content',
    { 'card-container--content-wide': wide },
  );

  return (
    <div className="card-container">
      {title && <h2 className="card-container--title">{title}</h2>}
      <div className={containerClass}>
        {cards.length ? cards : <Empty />}
      </div>
    </div>
  );
};

CardContainer.displayName = 'CardContainer';

CardContainer.propTypes = {
  cardClass: PropTypes.string,
  cardClassFn: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  renderCard: PropTypes.func.isRequired,
  title: PropTypes.string,
  wide: PropTypes.bool,
};

CardContainer.defaultProps = {
  cardClass: '',
  cardClassFn: () => '',
  dataSource: [],
  title: '',
  wide: false,
};

export default CardContainer;
