import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';

import { createErrorMessage } from './globalStateHelper';
import { userHasPermission } from '../utils/userHelpers';
import { PERMISSIONS } from '../constants/users';

const log = new Logger('context:marketparty:users');

async function updateUsers() {
  this.setState({ loadingUsers: true });

  try {
    const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
    const usersUrl = isAdmin() || isReadOnlyAdmin()
      ? '/admin/users'
      : `/marketparties/${selectedMarketPartyId}/users`;

    const { items: users } = (
      isAdmin() || isReadOnlyAdmin() || userHasPermission(this.state, [PERMISSIONS.ADMIN])
    )
      ? await API.get('FINTSO', usersUrl)
      : { items: [] };


    log.info('users updated with data:', users);
    this.setState({
      users,
      loadingUsers: false,
    });
  } catch (error) {
    const { t } = this.props;
    const description = createErrorMessage(error);

    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingUsers'),
      description,
    });
    log.error('error fetching users:', error.message, description);

    this.setState({
      users: [],
      loadingUsers: false,
    });
  }
}

const usersContext = {
  users: null,
  loadingUsers: false,
  updateUsers: () => { },
};

const functions = {
  updateUsers,
};

const users = {
  ...usersContext,
  ...functions,
};

export {
  usersContext,
  users,
};
