const getTranslatedTableHeaders = (tableHeaders, t) => (
  tableHeaders.map((header) => {
    const { children, title } = header;
    const translationKey = typeof title === 'object'
      ? t(`common.table.header.${title.key}`, title.interpolations)
      : t(`common.table.header.${title}`);

    return {
      ...header,
      title: title ? translationKey : title,
      children: children ? getTranslatedTableHeaders(children, t) : undefined,
    };
  })
);

export default getTranslatedTableHeaders;
