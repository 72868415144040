import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, DatePicker, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../ModalWrapper';
import Context from '../../context';
import { ANNOUNCEMENT_STATUS_NAMES } from '../../constants/status';
import AnnouncementLinkForm from './AnnouncementLinkForm';

/**
 * TODO: This should be handled in a better way in future,
 * at the moment only 2 links per announcement is supported
 */
const getRenderableLinksObject = (links) => {
  if (links.length === 0) {
    return [{}];
  }
  return links;
};
const TIME_FORMAT = 'HH:mm';
const DATETIME_READ_FORMAT = `DD-MM-YYYY, ${TIME_FORMAT}`;
const { TextArea } = Input;

const AnnouncementModal = (props) => {
  const {
    isEdit, handleModalClose, onChangeAnnouncement,
    announcement, handleSave, handleEditAnnouncement,
  } = props;
  const {
    id,
    message,
    status,
    subject,
    publishedAt,
    links = [{}], // by default one link field should be there
  } = announcement;
  const { t } = useTranslation();
  const { isAdmin, announcementsLoading } = useContext(Context);


  const renderActionButtons = () => (isAdmin() ? [
    <Button
      className="new-nomination__cancel-button"
      key="cancel"
      onClick={handleModalClose}
    >
      {t('common.button.cancel')}
    </Button>,
    <Button
      className="new-announcement_create-button"
      key="save"
      loading={announcementsLoading}
      type="primary"
      onClick={handleSave}
    >
      {id === 'new' ? t('common.button.create') : t('common.button.save')}
    </Button>,
  ] : []);

  const renderableLinks = getRenderableLinksObject(links);

  return (
    <ModalWrapper
      modalClassName="announcements"
      visible={isEdit}
      title={id === 'new'
        ? t('announcements.modal.title')
        : t('announcements.modal.updateAnnouncementsTitle')
      }
      handleClose={handleModalClose}
      actionButtons={renderActionButtons()}
      width="60rem"
    >
      <>
        <Row gutter={32} type="row-flex" justify="space-between" className="announcements__input-fields">
          <Col xs={24} sm={24} md={8} lg={10} xl={10}>
            <Form.Item
              disabled={!isAdmin()}
              required
              label={t('announcements.label.subject')}
            >
              <Input
                className="new-announcements__input-subject"
                data-testid="new-announcements__input-subject"
                placeholder={t('announcements.label.subject')}
                onChange={(e) => onChangeAnnouncement({
                  ...announcement,
                  subject: e.target.value,
                })}
                value={subject}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item
              className="announcements__publish-date"
              disabled={!isAdmin()}
              required
              label={t('announcements.label.chooseDate')}
              extra={DATETIME_READ_FORMAT}
            >
              <DatePicker
                format={DATETIME_READ_FORMAT}
                value={
                  publishedAt || null
                }
                allowClear={false}
                data-testid="announcements__publish-date"
                onChange={(datetime) => onChangeAnnouncement({ ...announcement, publishedAt: datetime })}
                showTime={{
                  format: TIME_FORMAT,
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item
              disabled={!isAdmin()}
              label={t('announcements.label.status')}
            >
              <Select
                size="default"
                data-testid="new-announcements__select-status"
                value={status}
                onChange={(newStatus) => onChangeAnnouncement({ ...announcement, status: newStatus })}
              >
                {ANNOUNCEMENT_STATUS_NAMES.map(s => (
                  <Select.Option
                    data-testid={`new-announcements__select-status-${s}`}
                    key={`status-select-${s}`}
                    value={s}
                  >
                    {t(`announcements.status.${s}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <TextArea
          value={message}
          required
          disabled={!isAdmin()}
          data-testid="new-announcements__input-message"
          placeholder={t('announcements.label.announcementText')}
          onChange={(e) => onChangeAnnouncement({
            ...announcement,
            message: e.target.value,
          })}
          rows={15}
        />
        {renderableLinks.map((link, index) => (
          <AnnouncementLinkForm
            // eslint-disable-next-line react/no-array-index-key
            key={`announcement-link-${index}`}
            link={link}
            index={index}
            deleteLink={() => onChangeAnnouncement({
              ...announcement,
              links: announcement.links.filter((_, i) => i !== index),
            })}
            handleEditLink={(updatedLink, key) => {
              const newLink = [...links]; // clone original links
              newLink[key] = updatedLink;
              // remove the title property if the user has removed it
              if (newLink[key].title === '') {
                delete newLink[key].title;
              }
              handleEditAnnouncement({
                ...announcement,
                links: newLink,
              });
            }}
          />
        ))}
        <Button
          data-testid="announcements__link-add-button"
          type="primary"
          icon="plus"
          size="large"
          onClick={() => onChangeAnnouncement({
            ...announcement,
            links: [...announcement.links, {}],
          })}
        />
      </>
    </ModalWrapper>
  );
};

const AnnouncementType = {
  id: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  publishedAt: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({})),
};

AnnouncementModal.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onChangeAnnouncement: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleEditAnnouncement: PropTypes.func.isRequired,
  announcement: PropTypes.shape(AnnouncementType).isRequired,
};

export default AnnouncementModal;
