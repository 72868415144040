import { STATUS } from './status';

const MARKETPARTY_STATUS = { ...STATUS };

const ROLES = {
  SHIPPER: 'shipper',
  TRADER: 'trader',
  PRODUCER: 'producer', // biogas
  ENDUSER: 'enduser', // at transmission network
  RETAILER: 'retailer',
  TSO: 'tso',
  DSO: 'dso',
  LTO: 'lto', // LNG Terminal Operator
  AUTHORITY: 'authority',
  EXTERNAL: 'external',
};
const ROLE_NAMES = Object.values(ROLES);
const ROLE_FIELDS = [
  {
    type: ROLES.SHIPPER,
    title: 'shipper',
  },
  {
    type: ROLES.TRADER,
    title: 'trader',
  },
  {
    type: ROLES.PRODUCER, // biogas
    title: 'biogasInjector',
  },
  {
    type: ROLES.ENDUSER, // at transmission network
    title: 'transmissionNetworkEndUser',
  },
  {
    type: ROLES.RETAILER,
    title: 'retailer',
  },
  {
    type: ROLES.TSO,
    title: 'tso',
  },
  {
    type: ROLES.DSO,
    title: 'dso',
  },
  {
    type: ROLES.LTO,
    title: 'lto',
  },
  {
    type: ROLES.AUTHORITY,
    title: 'authority',
  },
  {
    type: ROLES.EXTERNAL,
    title: 'external',
  },
];

const NETWORKS = {
  GASGRID: 'gasgrid',
  BALTIC: 'baltic',
  LNG_HAMINA: 'lngHamina',
  LNG_INKOO: 'lngInkoo',
};
const NETWORK_NAMES = Object.values(NETWORKS);
const NETWORK_FIELDS = [
  {
    type: NETWORKS.GASGRID,
    title: 'gasgrid',
  },
  {
    type: NETWORKS.BALTIC,
    title: 'baltic',
  },
  {
    type: NETWORKS.LNG_HAMINA,
    title: 'lngHamina',
  },
  {
    type: NETWORKS.LNG_INKOO,
    title: 'lngInkoo',
  },
];

export {
  MARKETPARTY_STATUS as STATUS,
  ROLES,
  ROLE_NAMES,
  ROLE_FIELDS,
  NETWORKS,
  NETWORK_NAMES,
  NETWORK_FIELDS,
};
