import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import './index.less';

import BalanceGroupsTable from '../../components/BalanceGroupsTable';
import BalanceGroupView from '../../components/BalanceGroupView';
import PageHeaderBar from '../../components/PageHeaderBar';
import BalanceGroupMemberView from '../../components/BalanceGroupMemberView';


const BalanceGroups = ({ t }) => (
  <>
    <PageHeaderBar
      title={t('balanceContainer.title.balanceGroups')}
      data-testid="page__header-bar"
    />
    <Layout.Content className="layout__container__page__content balancegroups-table__placeholder">
      <BalanceGroupsTable />
    </Layout.Content>
    <Switch>
      <Route
        path="/balancegroups/membership/:id"
        component={BalanceGroupMemberView}
      />
      <Route
        path="/balancegroups/:id"
        component={BalanceGroupView}
        exact
      />
    </Switch>
  </>
);

BalanceGroups.displayName = 'BalanceGroups';
BalanceGroups.propTypes = {
  t: PropTypes.func.isRequired,
};

const BalanceGroupsTranslated = withTranslation()(BalanceGroups);

export default withRouter(BalanceGroupsTranslated);
export {
  BalanceGroups as PureComponent,
};
