import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import {
  Form,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

import Loading from '../Loading';
import CapacityProduct from './CapacityProduct';

import Context from '../../context';
import {
  CAPACITY_PRODUCTS,
  CAPACITY_TYPES,
} from '../../constants/capacities';
import { LOADING_STATE } from '../../utils/state';

import './CapacityReservationForm.less';
import { filterCapacityTypesForNetwork } from '../../utils/capacityHelpers';

const { Option } = Select;

const CapacityReservationForm = (props) => {
  const {
    errors,
    onChange,
    onClose,
    onPointChange,
    onProductQuantityChange,
    products,
    type,
    children,
  } = props;
  const {
    capacityPrices,
    updateCapacityPrices,
    getSelectedMarketParty,
  } = useContext(Context);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(capacityPrices
    ? LOADING_STATE.LOADED
    : LOADING_STATE.NOT_LOADED);

  const selectedMarketParty = getSelectedMarketParty();

  const fetchPrices = async () => {
    setLoading(LOADING_STATE.LOADING);
    await updateCapacityPrices();
    setLoading(LOADING_STATE.LOADED);
  };

  useEffect(() => {
    if (!capacityPrices) fetchPrices();
  }, []);

  const renderPointSelect = () => (
    <Form.Item
      label={t('capacityReservation.form.label.point')}
      key="type"
      className="capacityreservationform__type__label"
    >
      <Select
        className="capacityreservationform__type__select"
        id="type"
        name="type"
        onChange={onPointChange}
      >
        {
          Object.values(CAPACITY_TYPES)
            .filter((capacityType) => filterCapacityTypesForNetwork(capacityType, selectedMarketParty))
            .map(value => (
              <Option key={value} value={value}>{t(`capacity.point.${value}`)}</Option>
            ))
        }
      </Select>
    </Form.Item>
  );

  const renderProducts = () => Object.keys(CAPACITY_PRODUCTS).reverse().map((product) => {
    // TODO: refactor the camelCase out
    const instances = products
      .filter(instance => instance.period === camelCase(CAPACITY_PRODUCTS[product]));
    const prices = capacityPrices || [];

    return (
      <CapacityProduct
        errors={errors}
        key={product}
        name={product}
        instances={instances}
        onChange={onChange}
        onClose={onClose}
        onProductQuantityChange={onProductQuantityChange}
        type={type}
        capacityPrices={prices}
      />
    );
  });

  const renderContent = () => {
    switch (loading) {
      case LOADING_STATE.LOADING:
        // TODO: spinner styles are broken
        return <Loading delay={0} />;
      case LOADING_STATE.LOADED:
        return renderProducts();
      case LOADING_STATE.NOT_LOADED:
      default:
        return null;
    }
  };

  return (
    <Form className="capacityreservationform">
      {renderPointSelect()}
      {children}
      {renderContent()}
    </Form>
  );
};

CapacityReservationForm.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onPointChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProductQuantityChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    start: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    capacity: PropTypes.string.isRequired,
  })).isRequired,
};

CapacityReservationForm.defaultProps = {
  type: null,
  children: undefined,
};

CapacityReservationForm.displayName = 'CapacityReservationForm';

export default CapacityReservationForm;
