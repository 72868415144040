import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Divider, Button,
} from 'antd';

import { withTranslation } from 'react-i18next';
import {
  withRouter,
} from 'react-router-dom';
import {
  getDataForCapacitySettings, getDateString, getCardClass, updateDataForCapacitySettings,
} from '../../utils/capacityAndNominationsHelper';
import { formatNumber } from '../../utils/i18n';
import { minutes } from '../../utils/interval';
import PageHeaderBar from '../../components/PageHeaderBar';
import CardContainer from '../../components/CardContainer';
import CardRow from '../../components/CardRow';
import CapacityAvailabilityForm from '../../components/CapacityAvailabilityForm';
import Context from '../../context';
import ModalWrapper, { modalConfirmClose } from '../../components/ModalWrapper';
import TechnicalCapacity from '../../components/TechnicalCapacityModal';

import './index.less';

const CARD_AMOUNT = 12;
const UPDATE_INTERVAL = minutes(5);

class CapacitySettings extends Component {
  componentWillMount = () => {
    updateDataForCapacitySettings(this.context, CARD_AMOUNT);
    const interval = setInterval(
      () => updateDataForCapacitySettings(this.context, CARD_AMOUNT),
      UPDATE_INTERVAL,
    );
    this.setState({ interval, isModalOpen: false });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  formatValue = (value) => {
    const { t } = this.props;
    return value === null
      ? t('monitoring.cardInfo.noData')
      : formatNumber(value);
  }

  cardContent = (data) => {
    const { t } = this.props;
    const { isAdmin } = this.context;

    return (
      <>
        <h3>{getDateString(data.date, t)}</h3>
        <CardRow
          label={t('monitoring.cardLabel.bookedCapacity')}
          value={this.formatValue(data.bookedCapacity)}
        />
        <CardRow
          label={t('monitoring.cardLabel.availableCapacity')}
          value={this.formatValue(data.availableCapacity)}
        />
        {isAdmin() && (
          <>
            <Divider />
            <CapacityAvailabilityForm data={data} />
          </>
        )}
      </>
    );
  }

  render = () => {
    const {
      t,
      // instance,
    } = this.props;
    const {
      currentUser,
    } = this.context;

    const dataSource = getDataForCapacitySettings(this.context);

    const handleTechnicalCapacityClick = () => {
      this.setState({ isModalOpen: true });
    };

    const renderActionButton = () => (
      (currentUser.inAdminGroup || currentUser.inReadOnlyAdminGroup)
        ? (
          <Button
            className="page__header__buttons__new-relati/onship"
            type="primary"
            onClick={handleTechnicalCapacityClick}
          >
            {t('monitoring.button.editTechnicalCapacity')}
          </Button>
        )
        : null
    );
    const IsModalOpen = () => {
      const { isModalOpen } = this.state;

      if (isModalOpen) {
        return true;
      }
      return false;
    };

    const closeModal = () => {
      this.setState({ isModalOpen: false });
      const { history } = this.props;
      history.push('/capacitysettings');
    };

    const handleCancel = () => modalConfirmClose(t, closeModal);

    return (
      <>
        <PageHeaderBar
          title={t('monitoring.capacitySettings.title')}
          data-testid="page__header-bar"
          actionItems={renderActionButton()}
        />
        {(IsModalOpen())
          ? (
            <ModalWrapper
              modalClassName="capacityreservation__modal"
              title={t('setTechnicalCapacity.modal.title')}
              width="60%"
              closable="true"
              handleClose={handleCancel}
              actionButtons={[]}
            >
              <TechnicalCapacity
                handleClose={closeModal}
              />
            </ModalWrapper>
          ) : null
        }


        <Layout.Content className="layout__container__page__content capacity-settings">
          <CardContainer
            renderCard={this.cardContent}
            dataSource={dataSource}
            cardClass="monitoring-card"
            cardClassFn={getCardClass}
            title={t('monitoring.capacitySettings.cardContainerTitle.imatra')}
          />
        </Layout.Content>
      </>
    );
  }
}

CapacitySettings.contextType = Context;
CapacitySettings.displayName = 'CapacitySettings';
CapacitySettings.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const CapacitySettingsTranslated = withTranslation()(CapacitySettings);

export default withRouter(CapacitySettingsTranslated);
export {
  CapacitySettings as PureComponent,
};
