
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import ButtonSelector from '../ButtonSelector';
import Imbalance from '../Stats/imbalance';
import SummaryTable from '../Stats/SummaryTable';
import { getGasDay } from '../../utils/gasday';
import {
  UNITS_EXTENDED,
  UNIT_EXTENDED_NAMES,
} from '../../constants/units';

import './index.less';

const BalanceGroupStats = (props) => {
  const { balanceGroupStats, isLoading, selectedDate } = props;
  const { t } = useTranslation();

  const [selectedUnit, setSelectedUnit] = useState(UNITS_EXTENDED.MWH);

  const selectedGasDay = getGasDay(selectedDate);
  let statValues = { imbalanceTotal: undefined, netValues: null };

  if (balanceGroupStats !== null && balanceGroupStats[selectedGasDay] !== undefined) {
    statValues = balanceGroupStats[selectedGasDay];
  }

  const { imbalanceTotal, netValues } = statValues;

  return (
    <>
      <Row className="balance-group__action-header">
        <Col className="balance-group__action__unit">
          <ButtonSelector
            t={t}
            options={UNIT_EXTENDED_NAMES}
            defaultValue={selectedUnit}
            title="unit"
            onChange={({ target: { value } }) => setSelectedUnit(value)}
          />
        </Col>
      </Row>
      <Row className="balance-group-stats" data-testid="balance-group-stats">
        <Col span={6}>
          <Imbalance
            total={imbalanceTotal}
            selectedDate={selectedGasDay}
            type="groupImbalance"
            unit={selectedUnit}
            isLoading={isLoading}
          />
        </Col>
        <Col span={18}>
          <SummaryTable
            data={netValues}
            isLoading={isLoading}
            unit={selectedUnit}
            type="net"
            selectedDate={selectedGasDay}
          />
        </Col>
      </Row>
    </>
  );
};


BalanceGroupStats.displayName = 'BalanceGroupStats';

const netValues = PropTypes.shape({
  domesticEntryExit: PropTypes.string,
  vtp: PropTypes.string,
  importsExports: PropTypes.string,
});

BalanceGroupStats.propTypes = {
  balanceGroupStats: PropTypes.objectOf(PropTypes.shape({
    imbalanceTotal: PropTypes.string,
    netValues,
    members: PropTypes.arrayOf(PropTypes.shape({
      imbalanceTotal: PropTypes.string,
      netValues,
      marketPartyId: PropTypes.string,
    })),
  })),
  isLoading: PropTypes.bool.isRequired,
  selectedDate: PropTypes.objectOf(moment).isRequired,
};
BalanceGroupStats.defaultProps = {
  balanceGroupStats: null,
};

export default BalanceGroupStats;
