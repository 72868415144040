import { API, Logger } from 'aws-amplify';
import { groupBy, merge } from 'lodash';
import { notification } from 'antd';
import { createErrorMessage } from './globalStateHelper';
import { getEmptyData } from '../utils/capacityAndNominationsHelper';
import { getDatesInRange } from '../utils/dateTimeHelpers';

const log = new Logger('context:global:monitoring');

async function updateTechnicalCapacity(start, end, resolution) {
  try {
    const technicalCapacity = API.get('FINTSO', '/statistics/capacities/technical/bc', {
      queryStringParameters: {
        start,
        end,
        resolution,
      },
    });
    this.setState({ technicalCapacity });
    log.info('Technical capacity updated with data:', technicalCapacity);
  } catch (error) {
    const { t } = this.props;
    this.setState({ technicalCapacity: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingAvailableCapacityImatra'),
      description,
    });
    log.error('Error updating available capacity for Imatra:', error.message, description);
  }
}

async function updateAvailableCapacityImatra(start, end) {
  try {
    const availableCapacityImatra = await API.get('FINTSO', '/admin/dashboard/capacity/available', {
      queryStringParameters: {
        start,
        end,
      },
    });
    this.setState({ availableCapacityImatra });
    log.info('Available capacity for Imatra updated with data:', availableCapacityImatra);
  } catch (error) {
    const { t } = this.props;
    this.setState({ availableCapacityImatra: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingAvailableCapacityImatra'),
      description,
    });
    log.error('Error updating available capacity for Imatra:', error.message, description);
  }
}

async function updateAvailableCapacityBC(start, end) {
  const dates = getDatesInRange(start, end);
  try {
    const response = await Promise.all(
      dates.map(date => API.get('FINTSO', `/admin/capacity/bc/${date}`)),
    );
    const availableCapacityBC = response.reduce((result, { date, entry, exit }) => ({
      ...result,
      [date]: {
        entry,
        exit,
      },
    }),
    {});
    this.setState({ availableCapacityBC });
    log.info('Available capacity for BalticConnector updated with data:', availableCapacityBC);
  } catch (error) {
    const { t } = this.props;
    this.setState({ availableCapacityBC: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingAvailableCapacityBC'),
      description,
    });
    log.error('Error updating available capacity for Balticconnector:', error.message, description);
  }
}

async function updateBookedCapacity(start, end) {
  try {
    const bookedCapacity = await API.get('FINTSO', '/admin/dashboard/capacity/bookings', {
      queryStringParameters: {
        start,
        end,
      },
    });
    this.setState({ bookedCapacity });
    log.info('Booked capacity updated with data:', bookedCapacity);
  } catch (error) {
    const { t } = this.props;
    this.setState({ bookedCapacity: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingBookedCapacity'),
      description,
    });
    log.error('Error updating booked capacity:', error.message, description);
  }
}

async function updateDailyAndHourlyCapacity(start, end) {
  try {
    const options = {
      queryStringParameters: { start, end },
    };
    const { items } = await API.get('FINTSO', '/admin/dashboard/capacity/imatra', options);
    const dailyAndHourlyCapacity = items.reduce((result, { date, hourly, daily }) => ({
      ...result,
      [date]: {
        hourly,
        daily,
      },
    }),
    {});
    this.setState({ dailyAndHourlyCapacity });
    log.info('Daily and hourly capacity updated with data:', dailyAndHourlyCapacity);
  } catch (error) {
    const { t } = this.props;
    this.setState({ dailyAndHourlyCapacity: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingDailyAndHourlyCapacity'),
      description,
    });
    log.error('Error updating daily and hourly capacity:', error.message, description);
  }
}

async function updateNominations(start, end) {
  const queryStringParameters = {
    start,
    end,
  };

  try {
    const nomres = await API.get('FINTSO', '/admin/dashboard/nominations/nomres', { queryStringParameters });
    const nomint = await API.get('FINTSO', '/admin/dashboard/nominations/nomint', { queryStringParameters });
    const nominations = {
      nomres,
      nomint,
    };
    this.setState({ nominations });
    log.info('Nominations updated with data:', nominations);
  } catch (error) {
    this.setState({
      nominations: {
        nomres: getEmptyData(start, end),
        nomint: getEmptyData(start, end),
      },
    });
    const { t } = this.props;
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingNominations'),
      description,
    });
    log.error('Error updating nominations:', error.message, description);
  }
}

async function updatePhysicalBalanceSummary(start, end) {
  try {
    const { items } = await API.get('FINTSO', '/admin/dashboard/summary', {
      queryStringParameters: {
        start,
        end,
      },
    });
    const physicalBalanceSummary = groupBy(items, ({ gasDay }) => gasDay);
    this.setState({ physicalBalanceSummary });
    log.info('Physical balance summary updated with data:', physicalBalanceSummary);
  } catch (error) {
    const { t } = this.props;
    this.setState({ physicalBalanceSummary: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingPhysicalBalanceSummary'),
      description,
    });
    log.error('Error updating physical balance summary:', error.message, description);
  }
}

async function updateTotalCapacities(start, end, refresh = false) {
  const { totalCapacities } = this.state;
  const existing = Object.keys(totalCapacities);
  const dates = getDatesInRange(start, end).filter(date => refresh || !existing.includes(date));
  const url = '/admin/dashboard/capacity/bookings/by/marketparty';

  try {
    const responses = await Promise.all(dates.map(async (date) => {
      const response = await API.get('FINTSO', url, { queryStringParameters: { date } });
      return { [date]: response };
    }));
    const combined = { ...totalCapacities, ...responses.reduce(merge, {}) };
    this.setState({ totalCapacities: combined });
    log.info('Total capacities updated with data:', combined);
  } catch (error) {
    const { t } = this.props;
    this.setState({ totalCapacities: getEmptyData(start, end) });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorUpdatingTotalCapacities'),
      description,
    });
    log.error('Error updating total capacities:', error.message, description);
  }
}

const monitoringContext = {
  technicalCapacity: {},
  availableCapacityImatra: {},
  availableCapacityBC: {},
  bookedCapacity: {},
  dailyAndHourlyCapacity: {},
  totalCapacities: {},
  nominations: {},
  physicalBalanceSummary: {},
  updateTechnicalCapacity: () => { },
  updateAvailableCapacityImatra: () => { },
  updateAvailableCapacityBC: () => { },
  updateBookedCapacity: () => { },
  updateDailyAndHourlyCapacity: () => { },
  updateTotalCapacities: () => { },
  updateNominations: () => { },
  updatePhysicalBalanceSummary: () => { },
};

const functions = {
  updateTechnicalCapacity,
  updateAvailableCapacityImatra,
  updateAvailableCapacityBC,
  updateBookedCapacity,
  updateDailyAndHourlyCapacity,
  updateTotalCapacities,
  updateNominations,
  updatePhysicalBalanceSummary,
};

const monitoring = {
  ...monitoringContext,
  ...functions,
};

export {
  monitoringContext,
  monitoring,
};
