import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import { withTranslation } from 'react-i18next';
import Context from '../../context';
import {
  CAPACITY_TYPES,
  CAPACITY_TYPES_ENABLED,
} from '../../constants/capacities';

import './CapacityTransferForm.less';

const { Option } = Select;

class CapacityTransferForm extends React.Component {
  handleInputChange = (event) => {
    const { onChange } = this.props;

    const {
      currentTarget: {
        name: field,
        value,
      },
    } = event;

    onChange(field, value);
  }

  handleDateChange = (field, value) => {
    const { onChange } = this.props;

    onChange(field, value.format('YYYY-MM-DD'));
  }

  renderBuyerSelect = () => {
    const {
      buyerId,
      errors,
      marketParties,
      onChange,
      t,
    } = this.props;

    const field = 'buyerId';

    return (
      <Form.Item
        label={t('capacityRightsTransfer.form.label.buyer')}
        key={field}
        className={classNames(
          'capacitytransferform__buyerid__label',
          { 'has-error': errors.find(error => error === field) },
        )}
      >
        <Select
          className="capacitytransferform__buyerid__select"
          value={buyerId}
          id={field}
          name={field}
          onChange={value => onChange(field, value)}
        >
          {
            marketParties.map(({ id, name, eic }) => <Option key={id} value={id}>{`${name} (${eic})`}</Option>)
          }
        </Select>
      </Form.Item>
    );
  }

  renderCapacityInput = () => {
    const {
      capacity: {
        capacity,
      },
      errors,
      t,
    } = this.props;

    const field = 'capacity';

    return (
      <Form.Item
        label={`${t('capacityRightsTransfer.form.label.capacity')} kWh/h`}
        key={field}
        className={classNames(
          'capacitytransferform__capacity__label',
          { 'has-error': errors.find(error => error === field) },
        )}
      >
        <Input
          className="capacitytransferform__capacity__input"
          id={field}
          name={field}
          onChange={this.handleInputChange}
          value={capacity}
          type="number"
          min={0}
        />
      </Form.Item>
    );
  }

  renderDatePicker = () => {
    const {
      capacity: {
        date,
      },
      errors,
      t,
    } = this.props;

    const field = 'date';

    return (
      <Form.Item
        label={t('capacityRightsTransfer.form.label.gasDeliveryDay')}
        key={field}
        className={classNames(
          'capacitytransferform__date__label',
          { 'has-error': errors.find(error => error === field) },
        )}
      >
        <DatePicker
          className="capacitytransferform__date__datepicker"
          id={field}
          name={field}
          format="DD.MM.YYYY"
          onChange={value => this.handleDateChange(field, value)}
          value={date !== null ? moment.utc(date) : null}
          allowClear={false}
        />
      </Form.Item>
    );
  }

  renderTypeSelect = () => {
    const {
      onChange,
      capacity: {
        type,
      },
      t,
    } = this.props;
    const {
      isAdminAsMarketParty,
    } = this.context;

    const field = 'type';

    return (
      <Form.Item
        label={t('capacityRightsTransfer.form.label.entryPoint')}
        key={field}
        className="capacitytransferform__type__label"
      >
        <Select
          className="capacitytransferform__type__select"
          defaultValue={type}
          id={field}
          name={field}
          onChange={value => onChange(field, value)}
        >
          {
            Object.keys(CAPACITY_TYPES)
              .filter(key => isAdminAsMarketParty()
                || !!CAPACITY_TYPES_ENABLED[CAPACITY_TYPES[key]])
              .map(key => <Option key={key} value={key}>{t(`capacity.point.${CAPACITY_TYPES[key]}`)}</Option>)
          }
        </Select>
      </Form.Item>
    );
  }

  render() {
    return (
      <Form className="capacitytransferform">
        {this.renderTypeSelect()}
        {this.renderBuyerSelect()}
        <Row>
          <Col span={11}>
            {this.renderDatePicker()}
          </Col>
          <Col span={2} />
          <Col span={11}>
            {this.renderCapacityInput()}
          </Col>
        </Row>
      </Form>
    );
  }
}

CapacityTransferForm.propTypes = {
  buyerId: PropTypes.string,
  capacity: PropTypes.shape({
    type: PropTypes.string.isRequired,
    date: PropTypes.string,
    capacity: PropTypes.string,
  }),
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  marketParties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      eic: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CapacityTransferForm.defaultProps = {
  buyerId: '',
  capacity: {
    type: '',
    date: '',
    capacity: '',
  },
  marketParties: [],
};

CapacityTransferForm.displayName = 'CapacityTransferForm';
CapacityTransferForm.contextType = Context;

export default withTranslation()(CapacityTransferForm);
