import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Form,
  Input,
  Checkbox,
  Divider,
  Row,
  Col,
} from 'antd';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { PERMISSIONS } from '../../constants/users';

import './userForm.less';
import { userHasPermission } from '../../utils/userHelpers';
import { ROLES } from '../../constants/marketParties';

const INFO_FIELDS = [
  {
    id: 'firstname',
    column: 'left',
    type: 'text',
    required: true,
    index: 1,
  },
  {
    id: 'lastname',
    column: 'right',
    type: 'text',
    required: true,
    index: 2,
  },
  {
    id: 'phone',
    column: 'left',
    type: 'text',
    required: true,
    index: 3,
  },
  {
    id: 'email',
    column: 'right',
    type: 'email',
    required: true,
    index: 4,
  },
];

class UserForm extends React.Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const {
      nativeEvent: {
        target: {
          value,
          id: field,
        },
      },
    } = event;

    onChange(field, value);
  }

  handleCheckBoxChange = (event) => {
    const { onPermissionsChange, permissions } = this.props;
    const {
      nativeEvent: {
        srcElement: {
          id: field,
        },
        target: {
          checked,
        },
      },
    } = event;

    const newPermissions = {
      [field]: checked,
    };
    if (
      ![PERMISSIONS.ADMIN, PERMISSIONS.INVOICE].includes(field)
      && !field.endsWith('ReadOnly')
      && checked === true
    ) {
      const respectiveReadOnlyPermission = `${field}ReadOnly`;
      newPermissions[respectiveReadOnlyPermission] = true;
    }

    const updatedPermissions = {
      ...permissions,
      ...newPermissions,
    };

    onPermissionsChange(updatedPermissions);
  }

  isTextFieldDisabled = () => {
    const { isAdmin } = this.context;
    const { isUpdateUser, userAsContactPerson } = this.props;
    const canEditContactPerson = isAdmin() || userHasPermission(this.context, [PERMISSIONS.ADMIN]);
    const isTSOAdmin = isAdmin(true);
    return !isTSOAdmin && (isUpdateUser || (userAsContactPerson && !canEditContactPerson));
  }

  getRoles = () => {
    // Some roles are not allowed for user permissions for now
    const DISALLOWED_ROLES = [ROLES.EXTERNAL];

    const {
      isAdmin,
      isReadOnlyAdmin,
      marketParties,
      selectedMarketPartyId,
    } = this.context;

    if (marketParties !== null && !isAdmin() && !isReadOnlyAdmin()) {
      return marketParties
        .filter(marketParty => (marketParty.id === selectedMarketPartyId))
        .map(party => (party.roles.filter(role => !DISALLOWED_ROLES.includes(role))));
    }

    return [];
  }

  getFields = (column) => {
    if (column === 'left') return INFO_FIELDS.filter(field => field.column === 'left');
    if (column === 'right') return INFO_FIELDS.filter(field => field.column === 'right');
    return INFO_FIELDS;
  }

  isReadOnlyFieldDisabled = (field) => {
    const { permissions } = this.props;
    const fragment = field.split('ReadOnly');

    return permissions && fragment.length === 2 && permissions[fragment[0]];
  }

  isNotValidSelectedMarketPartyId = () => {
    const { selectedMarketPartyId } = this.context;
    return !selectedMarketPartyId || selectedMarketPartyId === 'admin';
  }

  renderInfoFields = column => this.getFields(column).map((field) => {
    const {
      errors,
      user,
      t,
      userAsContactPerson,
    } = this.props;
    const hasError = errors.includes(field.id);

    return (
      <Form.Item
        label={t(`users.form.label.${field.id}`)}
        key={field.id}
        className={classNames(
          'new-user__form__info__item',
          { 'has-error': hasError },
        )}
        required={!userAsContactPerson && field.required}
        colon={false}
      >
        <Input
          className="new-user__form__info__input"
          id={field.id}
          name={field.id}
          type={field.type}
          onChange={this.handleChange}
          value={user !== null ? user[field.id] : ''}
          tabIndex={field.index}
          disabled={this.isTextFieldDisabled()}
        />
        {hasError && (
          <p className="new-user__form__info__item__error">
            {t('common.notifications.incorrectInput')}
          </p>
        )}
      </Form.Item>
    );
  });

  renderPermissionField = (field) => {
    const { t, permissions } = this.props;

    return (
      <Form.Item
        label={t(`users.form.label.${field}`)}
        key={field}
        className="new-user__form__permissions__label"
        colon={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 4 }}
      >
        <Checkbox
          className="new-user__form__permissions__single"
          id={field}
          data-testid={field}
          checked={permissions !== null ? permissions[field] : false}
          onChange={this.handleCheckBoxChange}
          // can be changed only when a marketparty is selected
          disabled={this.isNotValidSelectedMarketPartyId()}
        />
      </Form.Item>
    );
  }

  renderPermissionFields = () => {
    const roles = this.getRoles();
    if (roles.length === 0) return '';

    return roles[0].map((role) => {
      const {
        t,
        permissions,
      } = this.props;
      const permissionsOptions = [`${role}ReadOnly`, role];

      return (
        <Form.Item
          label={t(`users.form.label.${role}`)}
          key={`permissions-${role}`}
          className="new-user__form__permissions__role"
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 4 }}
        >
          {
          permissionsOptions.map(field => (
            <Checkbox
              className="new-user__form__permissions__role__options"
              id={field}
              data-testid={field}
              key={field}
              checked={permissions !== null ? permissions[field] : false}
              onChange={this.handleCheckBoxChange}
              disabled={this.isReadOnlyFieldDisabled(field) || this.isNotValidSelectedMarketPartyId()}
            />
          ))
        }
        </Form.Item>
      );
    });
  }

  render = () => {
    const { t, userAsContactPerson } = this.props;

    return (
      <div className="new-user__form" data-testid="new-user__form">
        <Row className="new-user__form__info">
          <h3>{ userAsContactPerson ? t('users.title.contactPersonInfo') : t('users.title.userInfo')}</h3>
          <Col span={11}>
            {this.renderInfoFields('left')}
          </Col>
          <Col span={2} />
          <Col span={11}>
            {this.renderInfoFields('right')}
          </Col>
        </Row>
        { !userAsContactPerson && (
          <>
            <Divider />
            <div className="new-user__form__permissions">
              <h3>{t('users.title.permissions')}</h3>
              <Row>
                {this.renderPermissionField(PERMISSIONS.ADMIN)}
                {this.renderPermissionField(PERMISSIONS.INVOICE)}
              </Row>
              <Row className="new-user__form__permissions__header">
                <Col span={8} />
                <Col span={2}>
                  {t('users.title.read')}
                </Col>
                <Col span={2}>
                  {t('users.title.write')}
                </Col>
              </Row>
              {this.renderPermissionFields()}
            </div>
          </>
        )}
      </div>
    );
  }
}

UserForm.displayName = 'UserForm';
UserForm.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    phonenumber: PropTypes.string,
    email: PropTypes.string,
  }),
  permissions: PropTypes.shape(),
  isUpdateUser: PropTypes.bool,
  userAsContactPerson: PropTypes.bool,
  onPermissionsChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
UserForm.defaultProps = {
  user: null,
  permissions: null,
  onPermissionsChange: () => {},
  userAsContactPerson: false,
  isUpdateUser: false,
};
UserForm.contextType = Context;

const TRANSLATED = withTranslation()(UserForm);
const FORM = Form.create({ name: 'userForm' })(TRANSLATED);

export default FORM;
export {
  UserForm as PureComponent,
};
