import React, { useContext, useState } from 'react';
import {
  Button, Form, Icon, InputNumber, notification,
} from 'antd';
import { API, Logger } from 'aws-amplify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Decimal from 'decimal.js';
import { createErrorMessage } from '../../context/globalStateHelper';
import ModalWrapper, { modalConfirmClose, modalConfirmSave } from '../ModalWrapper';
import Context from '../../context';
import { INITIAL_AMOUNTS } from '../../constants/gasTaxDeclarations';
import { UNITS } from '../../constants/units';
import { getAllowedGasTaxClassNames } from '../DeclareGasTaxTable/utils';

import './DeclareGasTaxModal.less';

const logger = new Logger('new-biogas-tax-declaration'); // TODO logger name ok?

const DeclareGasTaxModal = () => {
  const {
    isAdmin,
    isAdminAsMarketParty,
    isReadOnlyAdmin,
    selectedMarketPartyId,
    meteringSites,
    meteringSiteConsumptions: {
      requestParams: {
        start,
      },
    },
    declareStart,
    declareEnd,
  } = useContext(Context);

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const [amounts, setAmounts] = useState(INITIAL_AMOUNTS);
  const [saving, setSaving] = useState(false);

  const period = moment(start)
    .format('YYYY-MM');
  const selectedMeteringSite = (meteringSites || []).find(site => site.id === id);
  const meteringSiteName = selectedMeteringSite ? selectedMeteringSite.name : id;

  const currentDate = Date.now();
  const canDeclare = isAdmin() || isAdminAsMarketParty() || (currentDate > Date.parse(declareStart)
    && currentDate <= Date.parse(declareEnd));

  const filterEmptyAmounts = () => amounts.filter(item => item.amount);
  const isEmpty = () => !filterEmptyAmounts().length;

  const closeModal = () => history.push(location.pathname.replace(`/${id}`, ''));
  const handleClose = () => (isEmpty() ? closeModal() : modalConfirmClose(t, closeModal));

  const handleSave = async () => {
    setSaving(true);

    // Don't save if user isn't allowed to make declarations
    // TODO are the conditions ok?
    if (selectedMarketPartyId === undefined || isAdmin() || isReadOnlyAdmin()) {
      setSaving(false);
      return;
    }
    try {
      const payload = {
        period,
        meteringPointId: id,
        amounts: filterEmptyAmounts(),
      };
      await API.post(
        'FINTSO',
        `/marketparties/${selectedMarketPartyId}/meteringsites/taxdeclarations`,
        { body: payload },
      );
      notification.success({
        message: t('declareGasTax.message.createdSuccessfully'),
        description: '',
      });
      closeModal();
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: t('declareGasTax.message.errorCreating'),
        description: createErrorMessage(error),
      });
      logger.error(createErrorMessage(error, true));
    }
    setSaving(false);
  };

  const multiplier = new Decimal(0.001);
  const convertValue = (value, outputUnit = UNITS.KWH) => Number(
    outputUnit === UNITS.MWH
      ? multiplier.mul(value)
      : new Decimal(1).div(multiplier)
        .mul(value),
  );

  const handleChange = (value, taxClassName) => {
    const amount = typeof value === 'number'
      ? convertValue(value, UNITS.KWH)
      : null;
    setAmounts(
      prevState => prevState.map(
        taxClass => (
          taxClass.type === taxClassName
            ? {
              type: taxClassName,
              amount,
            }
            : taxClass
        ),
      ),
    );
  };

  const calculateValue = (taxClassName) => {
    const value = amounts.find(taxClass => taxClass.type === taxClassName).amount;
    return typeof value === 'number' ? convertValue(value, UNITS.MWH) : null;
  };

  const renderTaxClassFields = () => getAllowedGasTaxClassNames().map(
    taxClassName => (
      <>
        <Form.Item
          className={`tax-declarations__${taxClassName}-item`}
          key={taxClassName}
          label={`${t(`declareGasTax.modal.${taxClassName}`)}`}
        >
          <InputNumber
            key={taxClassName}
            className={`tax-declarations__${taxClassName}-input`}
            min={0}
            precision={3}
            step={1}
            decimalSeparator=","
            value={calculateValue(taxClassName)}
            onChange={value => handleChange(value, taxClassName)}
          />
          &nbsp;MWh
        </Form.Item>
      </>
    ),
  );

  const actionButtons = [
    <Button
      className="tax-declarations__cancel-button"
      key="closeButton"
      onClick={() => handleClose()}
    >
      {t('common.button.close')}
    </Button>,
    <Button
      className="tax-declarations__save-button"
      key="saveButton"
      loading={saving}
      type="primary"
      onClick={() => modalConfirmSave(t, handleSave)}
    >
      {t('common.button.save')}
    </Button>,
  ];

  return canDeclare && (
    <ModalWrapper
      modalClassName="declaregastax-modal"
      title={t('declareGasTax.title')}
      actionButtons={actionButtons}
      handleClose={handleClose}
    >
      <>
        <h2>{`${meteringSiteName} (${id})`}</h2>
        <h3>{moment(period).format('MMMM YYYY')}</h3>
        <div>
          <Form
            layout="horizontal"
            labelAlign="left"
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 10 }}
          >
            {renderTaxClassFields()}
          </Form>
        </div>
        <div className="declaregastax-modal__help">
          <Icon type="info-circle" />
          <span>{t('declareGasTax.modal.help')}</span>
        </div>
      </>
    </ModalWrapper>
  );
};

export default DeclareGasTaxModal;
