import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Select,
  DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Decimal } from 'decimal.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Context from '../../context';
import SummaryTable from '../SummaryTable';
import {
  formatDateTimeStr,
  formatNumber,
  localeDateFormat,
} from '../../utils/i18n';
import {
  UNITS,
  UNIT_NAMES,
  UNITS_PER_HOUR_NAMES,
} from '../../constants/units';
import {
  CAPACITY_TYPES_API_NAMES,
  CAPACITY_TYPES,
} from '../../constants/capacities';
import { convertNumber } from '../../utils/capacityHelpers';
import { hoursInGasDay } from '../../utils/gasday';

import { PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';

import './index.less';

const moment = extendMoment(Moment);

/**
 * Convert number according to selected unit.
 *
 * @param {Number} number - Number value for convertion.
 * @param {String} unit - Unit of capacity booking.
 * @param {String} date - Date of capacity booking.
 *
 * @returns {String} - Result of convertion.
 */
// eslint-disable-next-line camelcase
const convertValue_kWh_h = (number, unit, date) => {
  const convertedValue = convertNumber(number, unit);

  return formatNumber(
    UNIT_NAMES.includes(unit)
      ? new Decimal(convertedValue).mul(hoursInGasDay(date))
      : convertedValue,
  );
};

// eslint-disable-next-line camelcase
const convertValue_kWh = (number, unit, date) => {
  const convertedValue = convertNumber(number, unit);

  return formatNumber(
    !UNIT_NAMES.includes(unit)
      ? new Decimal(convertedValue).div(hoursInGasDay(date) || 24)
      : convertedValue,
  );
};

const getDataHeaders = selectedUnit => ([{
  title: 'gasDay',
  dataIndex: 'date',
  key: 'date',
  render: date => formatDateTimeStr(date),
}, {
  title: 'annual',
  dataIndex: 'value.annual',
  key: 'value.annual',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'quarterly',
  dataIndex: 'value.quarterly',
  key: 'value.quarterly',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'monthly',
  dataIndex: 'value.monthly',
  key: 'value.monthly',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'daily',
  dataIndex: 'value.daily',
  key: 'value.daily',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'hourly',
  dataIndex: 'value.hourly',
  key: 'value.hourly',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'transfers',
  dataIndex: 'value.transfers',
  key: 'value.transfers',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'sum',
  dataIndex: 'value.sum',
  key: 'value.sum',
  render: (number, { date: recordDate }) => convertValue_kWh_h(number, selectedUnit, recordDate),
}, {
  title: 'overrun',
  dataIndex: 'overrun',
  key: 'overrun',
  render: (number, { date: recordDate }) => convertValue_kWh(number, selectedUnit, recordDate),
}]);

const MAX_DATE_RANGE = '31';
const DATE_TYPES = {
  START: 'start',
  END: 'end',
};
const isDateDisabled = (currentDate, start, type) => {
  if (type === DATE_TYPES.START) return false;

  const maxAllowedDate = start.clone().add(MAX_DATE_RANGE, 'days');
  const range = moment().range(start, maxAllowedDate);

  return !range.contains(currentDate, { excludeEnd: true });
};
const { Option } = Select;

const combineOverrunsToCapacitiesArray = (_capacities, capacityOverruns, point) => {
  const capacities = _capacities;

  if (capacities !== null && capacities !== undefined) {
    for (let i = 0; i < capacities.length; i += 1) {
      capacities[i].overrun = 0;
      if (capacityOverruns[point]
        && (point === CAPACITY_TYPES.EXITZONE || point === CAPACITY_TYPES.BIOGAS)) {
        for (let j = 0; j < capacityOverruns[point].length; j += 1) {
          if (capacities[i].date === capacityOverruns[point][j].date) {
            capacities[i].overrun = capacityOverruns[point][j].value;
            break;
          }
        }
      }
    }
  }
  return capacities;
};

const CapacityBookingSummaryTable = ({ capacityOverruns }) => {
  const {
    currentUser,
    isLoading,
    capacities,
    updateCapacities,
    updateCapacityOverruns,
    selectedMarketPartyId,
  } = useContext(Context);

  const hasShipperReadOnlyPermission = () => (
    userHasPermission(
      { currentUser, selectedMarketPartyId },
      [PERMISSIONS.SHIPPER_READ_ONLY],
    ) || currentUser.inAdminGroup || currentUser.inReadOnlyAdminGroup
  );

  const { t } = useTranslation();
  const now = moment();
  const defaultStartDate = now.clone().subtract(3, 'days');
  const defaultEndDate = now.clone().add(3, 'day');
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);
  const [selectedUnit, setSelectedUnit] = useState(UNITS.MWH);
  const [selectedPoint, setSelectedPoint] = useState(CAPACITY_TYPES.EXITZONE);
  const title = t('capacity.title.summary', {
    point: t(`common.label.${selectedPoint}`),
  });
  const subTitle = t('capacity.subtitle.summary', {
    point: t(`common.label.${selectedPoint}`).toLowerCase(),
    unit: t(`common.label.${selectedUnit}`),
  });

  const combined = combineOverrunsToCapacitiesArray(capacities, capacityOverruns, selectedPoint);

  useEffect(() => {
    const start = selectedStartDate.format('YYYY-MM-DD');
    const end = selectedEndDate.format('YYYY-MM-DD');

    if (hasShipperReadOnlyPermission()) {
      updateCapacityOverruns(selectedPoint, start, end);
    }

    updateCapacities(
      selectedPoint,
      start,
      end,
    );
  }, [selectedMarketPartyId, selectedPoint, selectedStartDate, selectedEndDate]);

  const renderDatePicker = type => (
    <DatePicker
      className="date__selector--date-picker"
      allowClear={false}
      format={localeDateFormat()}
      disabledDate={date => isDateDisabled(
        date, selectedStartDate, type,
      )}
      onChange={type === DATE_TYPES.START
        ? setSelectedStartDate
        : setSelectedEndDate
      }
      defaultValue={type === DATE_TYPES.START ? selectedStartDate : selectedEndDate}
    />
  );

  const renderOptions = options => (
    options.map(option => (
      <Option key={option} value={option}>
        {t(`common.label.${option}`)}
      </Option>
    ))
  );

  const renderHeaderActions = () => (
    <>
      <div className="date-selector">
        <div className="date-selector--start-date">
          <div className="date-selector--title">
            {t('capacity.label.startDate')}
          </div>
          {renderDatePicker(DATE_TYPES.START)}
        </div>
        <span className="date-selector--separator">
          -
        </span>
        <div className="date-selector--end-date">
          <div className="date-selector--title">
            {t('capacity.label.endDate')}
          </div>
          {renderDatePicker(DATE_TYPES.END)}
        </div>
      </div>
      <div className="unit-selector">
        <div className="unit-selector--title">
          {t('common.label.unit')}
        </div>
        <Select
          defaultValue={selectedUnit}
          onChange={setSelectedUnit}
        >
          {renderOptions([...UNIT_NAMES, ...UNITS_PER_HOUR_NAMES])}
        </Select>
      </div>
      <div className="point-selector">
        <div className="point-selector--title">
          {t('common.label.point')}
        </div>
        <Select
          defaultValue={selectedPoint}
          onChange={setSelectedPoint}
        >
          {renderOptions(CAPACITY_TYPES_API_NAMES)}
        </Select>
      </div>
    </>
  );

  return (
    <>
      <SummaryTable
        className="capacity-booking__summary-table"
        tableHeaders={getDataHeaders(selectedUnit)}
        tableData={combined}
        title={title}
        subTitle={subTitle}
        isLoading={isLoading}
        headerActions={renderHeaderActions()}
      />
    </>
  );
};

CapacityBookingSummaryTable.displayName = 'CapacityBookingSummaryTable';
CapacityBookingSummaryTable.propTypes = {
  capacityOverruns: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    value: PropTypes.number,
  }))).isRequired,
};

export default CapacityBookingSummaryTable;
export {
  isDateDisabled,
  DATE_TYPES,
};
