import React from 'react';
import PropTypes from 'prop-types';

const CardRow = ({ label, value }) => (
  <div className="monitoring-card--row">
    <span className="monitoring-card--row--label">{label}</span>
    <span className="monitoring-card--row--value">{value}</span>
  </div>
);

CardRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default CardRow;
