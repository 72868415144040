import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Table, notification } from 'antd';

import moment from 'moment-timezone';

import { add } from '../../utils/math';
import { createKey, getEarliestValidTime, matches } from '../../utils/nominationHelpers';
import { dateSort, naturalSort } from '../../utils/sort';
import { formatNumber } from '../../utils/i18n';
import { getDateAndTime } from '../../utils/dateTimeHelpers';
import { getMarketPartyPropertyBy } from '../../utils/marketparty';
import Context from '../../context';
import MarketParty from './marketParty';
import getTranslatedTableHeaders from '../../utils/translationHelpers';
import { LNG_LOCATION_POINT_NAMES, LOCATION_POINTS } from '../../constants/nominations';
import { calculate } from '../../utils/capacityAndNominationsHelper';
import { UNIT_EXTENDED_NAMES } from '../../constants/units';
import StatusIcon from '../../components/StatusIcon';
import { userHasPermission } from '../../utils/userHelpers';
import { PERMISSIONS } from '../../constants/users';
import { ROLES } from '../../constants/marketParties';

const getCounterNomintAmount = nomint => (nomint.counterPartyAmounts || [0]).reduce(add);
const isValidPoint = nomint => [
  LOCATION_POINTS.VTP,
  LOCATION_POINTS.BALTIC_CONNECTOR,
].includes(nomint.point);

const isValidCounterNomint = (nomint) => (
  nomint && nomint.counterPartyAmounts && nomint.counterPartyAmounts.every((n) => typeof n === 'number')
);

// Collects nomres timeseries values that match given row and converts these into
// timestamp key-value pair. The reduce() is used to combine filter().map() into a single pass
const convertNomresTimeseriesToRowNomintNomresAmounts = (row, nomreses) => (
  nomreses.reduce(
    // eslint-disable-next-line no-return-assign
    (amounts, nomres) => (matches(nomres)(row)
    // note: nomres amounts may not contain value for each hour, so we need to index by timestamps
    // eslint-disable-next-line no-param-reassign
      ? (amounts[moment.utc(nomres.timestamp).format()] = nomres.value, amounts)
      : amounts),
    {},
  )
);

const NominationsTable = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const {
    // eslint-disable-next-line no-unused-vars
    canCreateNomination,
    canViewNomination,
    isLoading,
    nominations,
    unit,
    setNominationData,
    setNominationKey,
  } = props;

  const context = useContext(Context);
  const { marketPartyIndex, nomreses, getSelectedMarketParty } = context;
  const selectedMarketParty = getSelectedMarketParty();

  const getMarketPartyName = getMarketPartyPropertyBy(marketPartyIndex, 'name', 'eic') || '';
  const formatAndConvertNumber = value => formatNumber(calculate(value, unit));
  const renderMissingNomintNotification = () => {
    notification.info({
      message: t('nominations.nomintMissing.message'),
      description: t('nominations.nomintMissing.description'),
    });
  };

  const getActionsColumn = () => {
    const isLTOUser = userHasPermission(context, [PERMISSIONS.LTO]);
    const isLTO = selectedMarketParty && selectedMarketParty.roles.includes(ROLES.LTO);

    if (isLTOUser && isLTO) {
      return [
        {
          title: 'actions',
          // below onCell onClick function is required to avoid any accidental clicks on Actions column cell
          onCell: () => ({
            onClick: (ev) => {
              // Intercept row click event
              ev.stopPropagation();
            },
          }),
          render: row => {
            const updateDisabled = moment(row.gasDay, 'YYYY-MM-DD').isBefore(getEarliestValidTime());
            const isLNGPoint = LNG_LOCATION_POINT_NAMES.includes(row.point);
            if (isLNGPoint) {
              return (
                <Button
                  type={updateDisabled ? 'default' : 'primary'}
                  onClick={(event) => {
                    // Intercept row click event
                    event.stopPropagation();
                    if (row.id === undefined) {
                      renderMissingNomintNotification();
                      return;
                    }
                    setNominationKey(createKey(row));
                    const fields = [
                      'date', 'status', 'id', 'shipperEIC',
                      'amounts',
                      'matchedAmounts',
                      'point', 'direction',
                      'version', 'matchedHistory',
                    ];
                    const nomresAmounts = convertNomresTimeseriesToRowNomintNomresAmounts(row, nomreses);
                    const nominationData = {
                      ...pick(cloneDeep(row), fields),
                      shipperEIC: row.eic2,
                      date: row.gasDay,
                      nomresAmounts,
                    };
                    setNominationData(nominationData);
                    history.push('/nominations/match');
                  }}
                >
                  {t('nominations.button.match')}
                </Button>
              );
            }
            return null;
          },
        }];
    }
    return [];
  };

  const columns = [
    {
      title: 'status',
      key: 'status',
      render: stat => <StatusIcon status={stat.status} />,
      sorter: (a, b) => naturalSort(a.status, b.status),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'acknow',
      dataIndex: 'receivedAt',
      render: receivedAt => getDateAndTime(receivedAt, 'nominations__table__acknowledged'),
      sorter: (a, b) => dateSort(a.receivedAt, b.receivedAt),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'marketParty',
      dataIndex: 'marketPartyId',
      // TODO: figure out correct way
      // sorter: (a, b) => naturalSort(getMarketPartyName(a.eic), getMarketPartyName(b.eic)),
      sortDirections: ['ascend', 'descend'],
      render: id => <MarketParty id={id || ''} />,
    },
    {
      title: 'counterParty',
      dataIndex: 'eic2',
      sorter: (a, b) => naturalSort(getMarketPartyName(a.eic2), getMarketPartyName(b.eic2)),
      sortDirections: ['ascend', 'descend'],
      render: eic2 => <MarketParty eic={eic2 || ''} />,
    },
    {
      title: 'pointDirection',
      dataIndex: 'point',
      sorter: (a, b) => naturalSort(a.point, b.point),
      render: (point, row) => t(`nominations.label.${point}-${row.direction}`),
    },
    {
      title: 'nomint',
      dataIndex: 'nomint',
      sorter: (a, b) => a.nomint - b.nomint,
      render: nomint => (nomint === undefined ? 'N/A' : formatAndConvertNumber(nomint)),
    },
    {
      title: 'counterNomint',
      sorter: (a, b) => getCounterNomintAmount(a) - getCounterNomintAmount(b),
      render: nomint => (isValidPoint(nomint) && isValidCounterNomint(nomint)
        ? formatAndConvertNumber(getCounterNomintAmount(nomint))
        : '-'),
    },
    {
      title: 'nomres',
      dataIndex: 'nomres',
      sorter: (a, b) => a.nomres - b.nomres,
      render: nomres => (nomres !== null ? formatAndConvertNumber(nomres) : '-'),
    },
    {
      title: 'info',
      render: row => (
        <Button
          className="link"
          onClick={(event) => {
            // Intercept row click event
            event.stopPropagation();
            if (row.id === undefined) {
              renderMissingNomintNotification();
              return;
            }
            setNominationKey(createKey(row));
            history.push('/nominations/info');
          }}
        >
          {t('common.table.header.info')}
        </Button>
      ),
    },
    ...getActionsColumn(),
  ];

  const handleSelectRow = row => ({
    onClick: () => {
      if (!canViewNomination || location.pathname.startsWith('/nominations/info')) {
        return;
      }
      // "id" is nomination's id
      if (row.id === undefined) {
        renderMissingNomintNotification();
        return;
      }
      const fields = ['date', 'shipperEIC', 'amounts', 'point', 'direction'];
      const nomresAmounts = convertNomresTimeseriesToRowNomintNomresAmounts(row, nomreses);
      const nominationData = {
        ...pick(cloneDeep(row), fields),
        shipperEIC: row.eic2,
        date: row.gasDay,
        nomresAmounts,
      };
      setNominationData(nominationData);
      history.push('/nominations/renomination');
    },
  });

  return (
    <Table
      className="nominations__table"
      columns={getTranslatedTableHeaders(columns, t)}
      dataSource={nominations}
      isLoading={isLoading}
      onRow={handleSelectRow}
      pagination={false}
      rowKey={(record, index) => createKey(record) + index}
    />
  );
};

NominationsTable.propTypes = {
  canCreateNomination: PropTypes.bool.isRequired,
  canViewNomination: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setNominationData: PropTypes.func.isRequired,
  setNominationKey: PropTypes.func.isRequired,
  nominations: PropTypes.arrayOf(PropTypes.any).isRequired,
  unit: PropTypes.oneOf(UNIT_EXTENDED_NAMES).isRequired,
};

export default NominationsTable;
