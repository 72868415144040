const TYPES = {
  RETAILERS: 'retailers',
  SHIPPERS: 'shippers',
  OPERATORS: 'operators',
};

const TYPES_NAMES = Object.values(TYPES);

const MARKETPARTY_RELATIONSHIP_TYPES = {
  SHIPPER: 'shipper',
  SHIPPER_GROUP: 'shipperGroup',
  AUTHORIZE_TO_NOMINATE: 'authorizeToNominate',
};

const MARKETPARTY_RELATIONSHIP_TYPES_NAMES = Object.values(MARKETPARTY_RELATIONSHIP_TYPES);

// This will enable only allowed relationships
const ALLOWED_MARKET_PARTY_RELATIONSHIPS = ['lngInkoo-entry'];

export {
  TYPES,
  TYPES_NAMES,
  ALLOWED_MARKET_PARTY_RELATIONSHIPS,
  MARKETPARTY_RELATIONSHIP_TYPES,
  MARKETPARTY_RELATIONSHIP_TYPES_NAMES,
};
