import React from 'react';
import PropTypes from 'prop-types';

import { calculateWithUnit, getSignedValue } from '../../utils/balanceGroupStatsHelpers';
import {
  UNITS_EXTENDED,
} from '../../constants/units';

const UnitValue = (props) => {
  const {
    value,
    type,
    unit,
  } = props;

  const convertedValue = getSignedValue(calculateWithUnit(unit, value), type);

  return (
    <>
      {convertedValue}
    </>
  );
};

UnitValue.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['entry', 'exit', 'imbalance']).isRequired,
  unit: PropTypes.oneOf(Object.values(UNITS_EXTENDED)).isRequired,
};

export default UnitValue;
