import { API, Logger } from 'aws-amplify';
import { notification } from 'antd';
import { getDefaultFunctions, createErrorMessage } from './globalStateHelper';

const log = new Logger('context:prices');

async function updateBalancePrices(start, end) {
  try {
    const { items: balancePrices } = await API.get(
      'FINTSO',
      '/prices/balance',
      {
        queryStringParameters:
        {
          ...(start ? { start } : undefined),
          ...(end ? { end } : undefined),
        },
      },
    );

    this.setState({ balancePrices });
    log.info('balance prices updated with data:', balancePrices);
  } catch (error) {
    const { t } = this.props;
    this.setState({ balancePrices: [] });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingBalancePrices'),
      description,
    });
    log.error('error fetching balance prices:', createErrorMessage(error, true));
  }
}

async function updateCapacityPrices() {
  try {
    const { items: capacityPrices } = await API.get('FINTSO', '/prices/capacity');
    this.setState({ capacityPrices });
    log.info('capacity prices updated with data:', capacityPrices);
  } catch (error) {
    const { t } = this.props;
    this.setState({ capacityPrices: [] });
    const description = createErrorMessage(error);
    notification.error({
      className: 'notification-error',
      message: t('common.notifications.errorFetchingCapacityPrices'),
      description,
    });
    log.error('error fetching capacity prices:', createErrorMessage(error, true));
  }
}

const functions = {
  updateBalancePrices,
  updateCapacityPrices,
};

const pricesContext = {
  balancePrices: null,
  capacityPrices: null,
  ...getDefaultFunctions(functions),
};

const prices = {
  ...pricesContext,
  ...functions,
};

export {
  pricesContext,
  prices,
};
