import i18n from 'i18next';
import moment from 'moment-timezone';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation';
import translationFI from './locales/fi/translation';

moment.locale('fi', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH.mm',
    LLLL: 'dddd D MMMM YYYY HH.mm',
  },
});

const resources = {
  en: {
    translation: translationEN,
  },
  fi: {
    translation: translationFI,
  },
};
const FALLBACK_LANGUAGE = 'en';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    debug: process.env.NODE_ENV === 'development',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

export {
  FALLBACK_LANGUAGE,
};
