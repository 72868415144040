import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
  Row,
  Col,
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import { API, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import StatusIcon from '../StatusIcon';
import { STATUS } from '../../constants/status';
import ModalWrapper from '../ModalWrapper';

import './index.less';

const logger = new Logger('components:BalanceGroupView');

class BalanceGroupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingStatus: '',
    };
  }

  componentDidMount = () => {
    const {
      balanceGroups,
      updateBalanceGroups,
    } = this.context;

    if (balanceGroups === null) updateBalanceGroups();
  }

  handleClose = () => {
    const { history } = this.props;

    return history.push('/balancegroups');
  }

  getBalanceGroup = () => {
    const { match: { params: { id } } } = this.props;
    const { balanceGroups } = this.context;

    if (id !== undefined && balanceGroups !== null) {
      return balanceGroups.find(item => item.id === id);
    }

    return null;
  }

  isActionAllowed = () => {
    const { isAdmin } = this.context;
    const balanceGroup = this.getBalanceGroup();

    if (!balanceGroup) return false;
    const { status } = balanceGroup;

    return isAdmin() && status === STATUS.PENDING;
  }

  handleBalanceGroupStatusChange = async (status) => {
    const { handleDbChange } = this.context;
    const { t } = this.props;
    const balanceGroup = this.getBalanceGroup();

    if (balanceGroup === null) return;
    const {
      id: balanceGroupId,
      marketPartyId,
    } = balanceGroup;

    try {
      this.setState({ sendingStatus: status });
      const updateBalanceGroupUrl = `/marketparties/${marketPartyId}/balancegroups/${balanceGroupId}`;
      const updatedBalanceGroup = await API.patch('FINTSO', updateBalanceGroupUrl, {
        body: { status },
      });

      handleDbChange('BalanceGroup', 'update', updatedBalanceGroup);
      notification.success({
        message: t('balanceContainer.message.updatedSuccessfully'),
        description: '',
      });

      this.handleClose();
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('balanceContainer.message.errorUpdating'),
        description: createErrorMessage(e),
      });
      logger.error(createErrorMessage(e, true));
    } finally {
      this.setState({ sendingStatus: '' });
    }
  }

  renderContent = () => {
    const { t } = this.props;
    const { marketPartyIndex } = this.context;
    const balanceGroup = this.getBalanceGroup();

    if (!balanceGroup) return null;

    const marketparty = marketPartyIndex
      .find(marketParty => marketParty.id === balanceGroup.marketPartyId);

    return (
      <div data-testid="balance-group-view__content" className="balance-group-view__content">
        <Row>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.balanceGroupOwner')}
            </p>
            <p className="balance-group-view__content__text">
              {marketparty !== undefined ? marketparty.name : ''}
            </p>
          </Col>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.status')}
            </p>
            <p className="balance-group-view__content__text">
              <StatusIcon status={balanceGroup.status} />
              {balanceGroup.status}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.startDate')}
            </p>
            <p className="balance-group-view__content__text">
              {balanceGroup.start}
            </p>
          </Col>
          <Col span={12}>
            <p className="balance-group-view__content__label">
              {t('common.table.header.endDate')}
            </p>
            <p className="balance-group-view__content__text">
              {balanceGroup.end !== undefined ? balanceGroup.end : '-'}
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  renderActionButtons = () => {
    const { t } = this.props;
    const { sendingStatus } = this.state;
    const actionButtonDisabled = !this.isActionAllowed();

    return ([
      <Button
        className="balance-group-view__close-button"
        key="close"
        onClick={this.handleClose}
      >
        {t('common.button.close')}
      </Button>,
      <Button
        className="balance-group-view__decline-button"
        key="decline"
        disabled={actionButtonDisabled}
        onClick={() => this.handleBalanceGroupStatusChange(STATUS.DECLINED)}
        loading={sendingStatus === STATUS.DECLINED}
      >
        {t('common.button.decline')}
      </Button>,
      <Button
        className="balance-group-view__accept-button"
        key="accept"
        type="primary"
        disabled={actionButtonDisabled}
        onClick={() => this.handleBalanceGroupStatusChange(STATUS.ACCEPTED)}
        loading={sendingStatus === STATUS.ACCEPTED}
      >
        {t('common.button.accept')}
      </Button>,
    ]

    );
  }

  render = () => {
    const { t } = this.props;
    const { balanceGroups } = this.context;

    return (
      <ModalWrapper
        modalClassName="balance-group-view"
        title={t('balanceContainer.title.balanceGroup')}
        actionButtons={this.renderActionButtons()}
        handleClose={this.handleClose}
        isLoading={balanceGroups === null}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

BalanceGroupView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
      marketPartyId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

BalanceGroupView.displayName = 'BalanceGroupView';
BalanceGroupView.contextType = Context;

const BalanceGroupViewTranslated = withTranslation()(BalanceGroupView);

export default withRouter(BalanceGroupViewTranslated);
export {
  BalanceGroupView as PureComponent,
};
