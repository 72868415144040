import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Route,
  withRouter,
} from 'react-router-dom';

import './index.less';

import PageHeaderBar from '../../components/PageHeaderBar';
import RelationshipsTable from '../../components/RelationshipsTable';
import { TYPES } from '../../constants/relationships';
import { RELATIONSHIPS_CREATE_PERMISSIONS } from '../../constants/users';
import { userHasPermission } from '../../utils/userHelpers';
import Context from '../../context';
import RelationshipForm from '../../components/Relationship/RelationshipForm';
import ButtonSelector from '../../components/ButtonSelector';
import { STATUS_NAMES } from '../../constants/status';

const Shippers = ({ history }) => {
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState('all');
  const {
    currentUser,
    selectedMarketPartyId,
    isAdminAsMarketParty,
    isAdmin,
  } = useContext(Context);
  const canCreateRelationship = () => (
    userHasPermission(
      {
        currentUser,
        selectedMarketPartyId,
      },
      RELATIONSHIPS_CREATE_PERMISSIONS,
    )
  );

  const handleNewRelationshipClick = () => {
    history.push('/relationships/relationship-form');
  };

  const renderActionButton = () => (
    (canCreateRelationship() || (isAdmin(true) || isAdminAsMarketParty()))
      ? (
        <Button
          className="page__header__buttons__relationship-form"
          type="primary"
          onClick={handleNewRelationshipClick}
        >
          {t('relationships.button.newRelationship')}
        </Button>
      )
      : null
  );

  return (
    <>
      <PageHeaderBar
        title={t('deliveryRelationship.title.created')}
        actionItems={(
          <div className="relationship-shipper--action-items">
            <ButtonSelector
              t={t}
              options={['all'].concat(STATUS_NAMES)}
              defaultValue={statusFilter}
              title="status"
              onChange={({ target: { value } }) => setStatusFilter(value)}
            />
            {renderActionButton()}
          </div>
        )}
      />
      <Layout.Content className="layout__container__page__content">
        <RelationshipsTable type={TYPES.SHIPPERS} statusFilter={statusFilter} />
      </Layout.Content>
      <Route
        path="/relationships/relationship-form"
        component={
          (canCreateRelationship() || (isAdmin(true) || isAdminAsMarketParty()))
            ? RelationshipForm
            : null
        }
      />
    </>
  );
};

Shippers.displayName = 'Shippers';
Shippers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Shippers);
export {
  Shippers as PureComponent,
};
