import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DatePicker,
  Form,
} from 'antd';

import {
  getEndDateForProduct,
  productInMomentTerm,
} from '../../utils/capacityHelpers';
import { startOfGasDay, hourOfGasDay } from '../../utils/gasday';
import {
  CAPACITY_PRODUCTS,
  CAPACITY_MONTH_PRODUCTS,
  QUARTAL_START_MONTHS,
  CAPACITY_TYPES,
} from '../../constants/capacities';

import './CapacityDatePicker.less';

const {
  MonthPicker,
} = DatePicker;


const disabledStartDate = (currentDate, period, type) => {
  const terms = {
    ...productInMomentTerm,
    annual: 'months',
  };
  const term = terms[period];
  const now = moment.utc().startOf(term);
  const current = currentDate.clone().endOf(term);
  const difference = now.diff(current, term);

  if (difference >= 0) {
    return true;
  }

  const currentMonth = currentDate.format('MM');
  switch (period) {
    case CAPACITY_PRODUCTS.ANNUAL:
      return type === CAPACITY_TYPES.IMATRA ? currentMonth !== '10' : false;
    case CAPACITY_PRODUCTS.QUARTERLY:
      return !QUARTAL_START_MONTHS.includes(currentMonth) || difference !== -1;
    case CAPACITY_PRODUCTS.MONTHLY:
      return difference < -3;
    case CAPACITY_PRODUCTS.DAILY: {
      const isNextDay = difference === -1;
      const isPastDeadline = hourOfGasDay() >= 11; // 6:00 PM Finnish time
      return difference < -5 || (isNextDay && isPastDeadline);
    }
    default:
      return false;
  }
};

const disabledEndDate = (currentDate, period, startDate) => {
  const term = productInMomentTerm[period];
  const start = moment.utc(startDate).startOf(term);
  const current = currentDate.clone().startOf(term);
  const difference = start.diff(current, term);

  switch (period) {
    case CAPACITY_PRODUCTS.DAILY:
      return difference >= 1 || difference < -4;
    case CAPACITY_PRODUCTS.MONTHLY:
      return difference >= 1 || difference < 0;
    default:
      return false;
  }
};

const getStartDate = start => (
  start !== null ? moment.utc(start) : null
);

const getEndDate = (start, quantity, period) => {
  if (quantity <= 0 || start === null) {
    return null;
  }

  return startOfGasDay(getEndDateForProduct(start, quantity, period));
};

const renderQuartal = date => (
  `Q${date.quarter()}`
);

const renderPicker = ({
  // eslint-disable-next-line react/prop-types
  value, className, onChange, disabledDate, period, disabled, t,
}) => {
  if (CAPACITY_MONTH_PRODUCTS.includes(period)) {
    return (
      <MonthPicker
        className={className}
        format={moment.localeData().longDateFormat('L')}
        onChange={onChange}
        value={value}
        allowClear={false}
        placeholder={
          disabled && period === CAPACITY_PRODUCTS.ANNUAL
            ? '-'
            : t('capacityProductInstance.datePicker.month.placeholder')
        }
        disabledDate={disabledDate}
        monthCellContentRender={
          period === CAPACITY_PRODUCTS.QUARTERLY
            ? renderQuartal
            : null
        }
        dropdownClassName={classNames(
          `capacitydatepicker__datepicker__${period}`,
          `${className}__datepicker__${period}`,
        )}
        disabled={disabled}
        suffixIcon={disabled ? ' ' : null}
        defaultPickerValue={moment.utc()}
      />
    );
  }

  return (
    <DatePicker
      className={className}
      format={moment.localeData().longDateFormat('L')}
      onChange={onChange}
      value={value}
      allowClear={false}
      placeholder={
        disabled && period === CAPACITY_PRODUCTS.ANNUAL
          ? '-'
          : t('capacityProductInstance.datePicker.day.placeholder')
      }
      disabledDate={disabledDate}
      showToday={false}
      disabled={disabled}
      suffixIcon={disabled ? ' ' : null}
      defaultPickerValue={moment.utc()}
    />
  );
};

const CapacityDatePicker = (props) => {
  const {
    errors,
    handleStartChange,
    handleEndChange,
    instance: {
      start,
      quantity,
      period,
      type,
    },
  } = props;
  const { t } = useTranslation();
  const startDate = getStartDate(start);
  const endDate = getEndDate(start, quantity, period, type);
  const singlePeriods = [CAPACITY_PRODUCTS.ANNUAL, CAPACITY_PRODUCTS.QUARTERLY];
  const isEndDateDisabled = startDate === null || singlePeriods.includes(period);

  return (
    <>
      <Form.Item
        label={t('capacityProductInstance.label.start')}
        className={classNames(
          'capacitydatepicker__item',
          'capacitydatepicker__start-date',
          { 'has-error': errors.find(error => error.field === 'start') },
        )}
      >
        {
          renderPicker({
            className: 'capacitydatepicker__start-date__input',
            disabledDate: (currentDate => disabledStartDate(currentDate, period, type)),
            onChange: handleStartChange,
            period,
            value: startDate,
            t,
          })
        }
      </Form.Item>
      <Form.Item
        label={t('capacityProductInstance.label.end')}
        className={classNames(
          'capacitydatepicker__item',
          'capacitydatepicker__end-date',
          { 'capacitydatepicker__end-date--no-input': singlePeriods.includes(period) },
        )}
      >
        {
          renderPicker({
            className: 'capacitydatepicker__end-date__input',
            disabled: isEndDateDisabled,
            disabledDate: (current => disabledEndDate(current, period, startDate)),
            period,
            onChange: handleEndChange,
            value: endDate,
            t,
          })
        }
      </Form.Item>
    </>
  );
};

CapacityDatePicker.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  instance: PropTypes.shape({
    quantity: PropTypes.number,
    start: PropTypes.string,
    period: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleStartChange: PropTypes.func.isRequired,
  handleEndChange: PropTypes.func.isRequired,
};

export default CapacityDatePicker;

export {
  disabledStartDate,
  disabledEndDate,
  renderQuartal,
  getEndDate,
};
