import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Dropdown,
  Menu,
} from 'antd';

import './index.less';

const ActionButton = (props) => {
  const { data } = props;

  /**
   * Render <Button />
   *
   * @param {Object} itemData - Data for render a <Button />, see `ActionButton.propTypes`.
   * @returns {JSX} - Result of rendering.
   */
  const renderActionButton = itemData => (
    <Button
      className={classnames(
        'action-button',
        `action-button--${itemData.text.toLowerCase().replace(' ', '_')}`,
        itemData.className,
      )}
      data-testid={itemData.id}
      id={itemData.id}
      onClick={itemData.onClick}
    >
      {itemData.text}
    </Button>
  );

  /**
   * Render single action item based on item type (`button` or `text`).
   *
   * @param {Object} itemData - Data for render item, see `ActionButton.propTypes`.
   * @returns {JSX} - Result of rendering.
   */
  const renderSingleAction = itemData => (
    itemData.type === 'text'
      ? (
        <div
          className={classnames(
            'action-text-label',
            `action-text-label--${itemData.text.toLowerCase().replace(' ', '_')}`,
            itemData.className,
          )}
        >
          {itemData.text}
        </div>
      )
      : renderActionButton(itemData)
  );

  /**
   * Render stack of action items.
   * @returns {JSX} - Result of rendering.
   */
  const renderStackActions = () => {
    const [first, ...rest] = data;
    const overlay = (
      <Menu>
        {
          rest.map(item => (
            <Menu.Item key={`menu-item-${item.text}`}>
              { renderSingleAction(item) }
            </Menu.Item>
          ))
        }
      </Menu>
    );

    return (
      <Dropdown.Button
        className={classnames(
          'action-button',
          'action-button--primary',
          `action-button--${first.text.toLowerCase().replace(' ', '_')}`,
          first.className,
        )}
        id={first.id}
        data-testid={first.id}
        onClick={first.onClick}
        overlay={overlay}
      >
        {first.text}
      </Dropdown.Button>
    );
  };

  return data && data.length > 1
    ? renderStackActions(data)
    : renderSingleAction(data[0]);
};

ActionButton.displayName = 'ActionButton';
ActionButton.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'text']).isRequired,
      className: PropTypes.string,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      id: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default ActionButton;
