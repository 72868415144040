import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, Logger } from 'aws-amplify';
import { Button, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Decimal from 'decimal.js';
import ModalWrapper from '../ModalWrapper';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';

const logger = new Logger('delete-biogas-tax-declaration');

const DeleteDeclaration = () => {
  const {
    selectedMarketPartyId,
    taxDeclarations,
    meteringSites,
  } = useContext(Context);

  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [isDeleting, setIsDeleting] = useState(false);

  const declarationToDelete = (taxDeclarations || []).find(declaration => declaration.id === id);
  const meteringPointId = declarationToDelete ? declarationToDelete.meteringPointId : '';

  const handleCLose = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await API.del(
        'FINTSO',
        `/marketparties/${selectedMarketPartyId}/meteringsites/taxdeclarations/${id}`,
      );
      notification.success({
        message: t('declareGasTax.delete.success'),
        description: '',
      });
      handleCLose();
    } catch (error) {
      setIsDeleting(false);
      notification.error({
        className: 'notification-error',
        message: t('declareGasTax.delete.error'),
        description: createErrorMessage(error),
      });
      logger.error(createErrorMessage(error, true));
    }
  };

  const convertToMWh = value => new Decimal(0.001).mul(value);

  const getMeteringSiteName = () => (meteringSites || [])
    .find(meteringSite => meteringSite.id === meteringPointId).name;

  const getPeriod = () => moment(declarationToDelete.period).format('MMMM YYYY');

  const renderAmounts = () => declarationToDelete.amounts
    .map(item => (
      <p>
        <b>
          {`${t(`declareGasTax.table.information.${item.type}`)}: `}
        </b>
        {`${convertToMWh(item.amount)} MWh`}
      </p>
    ));

  const renderDeletionInfo = () => (
    <div>
      <p>
        <b>
          {`${t('declareGasTax.delete.meteringSite')}: `}
        </b>
        {`[${declarationToDelete.meteringPointId}] ${getMeteringSiteName()}`}
      </p>
      <p>
        <b>
          {`${t('declareGasTax.delete.period')}: `}
        </b>
        {getPeriod()}
      </p>
      {renderAmounts()}
    </div>
  );

  const actionButtons = [
    <Button onClick={() => handleCLose()} disabled={isDeleting}>
      {t('common.button.cancel')}
    </Button>,
    <Button type="primary" onClick={handleDelete} loading={isDeleting} disabled={!declarationToDelete}>
      {t(isDeleting ? 'common.button.deleting' : 'common.button.delete')}
    </Button>,
  ];

  return (
    <ModalWrapper
      title={t('declareGasTax.delete.title')}
      actionButtons={actionButtons}
      handleClose={handleCLose}
    >
      <h2>{t('declareGasTax.delete.confirm')}</h2>
      <div>
        {declarationToDelete && renderDeletionInfo()}
      </div>
    </ModalWrapper>
  );
};

export default DeleteDeclaration;
