import { ROLES } from '../constants/marketParties';
import { ALLOWED_MARKET_PARTY_RELATIONSHIPS } from '../constants/relationships';

const hasRole = (marketParty, checkForRoles) => !marketParty.name.startsWith('_') && marketParty.roles.some(role => checkForRoles.includes(role));

const isShipper = marketParty => hasRole(marketParty, [ROLES.SHIPPER]);
// Temporary solution to detect if market party is just a "Terminal User" with no other roles
const isExternal = marketParty => hasRole(marketParty, [ROLES.EXTERNAL]);

const getMarketPartyFromContextBy = (context, key) => (value) => {
  const { marketPartyIndex, marketParties } = context;
  let marketParty = marketPartyIndex && marketPartyIndex.find((mp) => mp[key] === value);
  if (!marketParty) {
    // fetch own marketparty info from marketParties
    // because marketPartyIndex doesn't inclued own market party
    marketParty = marketParties && marketParties
      .find(marketparty => marketparty[key] === value);
    // Fail safe for debugging purposes
    if (!marketParty) {
      // id, roles, name is must.
      const name = key === 'id' ? `[id#${value.slice(-7)}]` : value;
      return {
        id: key === 'id' ? value : 'id-unknown',
        name: key === 'eic' ? '' : name,
        eic: key === 'eic' ? value : '',
        roles: [],
      };
    }
  }
  return marketParty;
};

const getMarketPartyFromContextById = (context, marketPartyId) => (
  getMarketPartyFromContextBy(context, 'id')(marketPartyId)
);

const getMarketPartyPropertyBy = (context, property, key) => (value) => {
  const marketParty = getMarketPartyFromContextBy(context, key)(value);
  return marketParty[property];
};

const getMarketPartyNameByEic = (context) => getMarketPartyPropertyBy(context, 'name', 'eic');
const getMarketPartyNameById = (context) => getMarketPartyPropertyBy(context, 'name', 'id');

const getMarketPartyEICById = (context) => getMarketPartyPropertyBy(context, 'eic', 'id');

/**
   * Find market party by id and return EIC.
   *
   * @param {String} id - Market party's id.
   * @returns {String} - Market party's EIC.
   */
const getMarketPartyEIC = (context, id) => (
  getMarketPartyEICById(context)(id)
);

const filterRelationshipPointAndDirection = (point, direction) => ALLOWED_MARKET_PARTY_RELATIONSHIPS
  .includes(`${point}-${direction}`);

export {
  isExternal,
  isShipper,
  hasRole,
  getMarketPartyFromContextBy,
  getMarketPartyFromContextById,
  getMarketPartyEIC,
  getMarketPartyPropertyBy,
  getMarketPartyNameByEic,
  getMarketPartyNameById,
  filterRelationshipPointAndDirection,
};
