/* eslint-disable react/prop-types */

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Layout,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  useHistory,
  withRouter,
} from 'react-router-dom';

import './index.less';

import {
  getDataForCapacityAndNominations,
  getDateString,
  getCardClass,
  updateDataForCapacityAndNominations,
} from '../../utils/capacityAndNominationsHelper';
import { formatNumber } from '../../utils/i18n';
import { minutes } from '../../utils/interval';
import PageHeaderBar from '../../components/PageHeaderBar';
import CardContainer from '../../components/CardContainer';
import HourlyImbalances from '../../components/HourlyImbalances';
import CardRow from '../../components/CardRow';
import { CAPACITY_TYPES } from '../../constants/capacities';
import Context from '../../context';
import StatsModal from './statsModal';
import ImatraStatsModal from './imatraStatsModal';
import ButtonSelector from '../../components/ButtonSelector';
import {
  UNITS_EXTENDED,
  UNIT_EXTENDED_NAMES,
} from '../../constants/units';
import { startOfOngoingGasDay } from '../../utils/gasday';
import StatsDatePicker from '../../components/Stats/StatsDatePicker';

const BASE_PATH = '/capacityandnominations';
const CARD_AMOUNT = 5;
const UPDATE_INTERVAL = minutes(5);

const CapacityAndNominations = () => {
  const history = useHistory();
  const context = useContext(Context);
  const { t } = useTranslation();
  const [intervalValue, setIntervalValue] = useState();
  const [selectedUnit, setSelectedUnit] = useState(UNITS_EXTENDED.GWH);
  const [selectedDate, setSelectedDate] = useState(startOfOngoingGasDay().subtract(1, 'days'));

  const updateInitialData = () => {
    const date = selectedDate.clone();
    updateDataForCapacityAndNominations(context, CARD_AMOUNT, date);

    const interval = setInterval(
      () => updateDataForCapacityAndNominations(context, CARD_AMOUNT, date),
      UPDATE_INTERVAL,
    );

    setIntervalValue(interval);
  };

  useEffect(() => {
    if (intervalValue) clearInterval(intervalValue);
    updateInitialData();

    return () => { clearInterval(intervalValue); };
  }, [selectedDate]);

  const formatValue = value => (
    value === null
      ? t('monitoring.cardInfo.noData')
      : formatNumber(value)
  );

  const showDetailedInfo = (type, date) => {
    history.push(`${BASE_PATH}/${type}/${date}`);
  };

  const summaryContent = ({
    date,
    bcEntry,
    bcExit,
    biogas,
    lngHamina,
    lngInkoo,
    consumption,
    imatra,
    imbalance,
    imbalancePercentage,
    nomresEntry,
    totalEntry,
    totalExit,
  }) => (
    <>
      <h3>{getDateString(date, t)}</h3>
      <Row className="ant-row__heading">
        <Col span={5} />
        <Col align="right" span={5}>{t('common.table.header.entry')}</Col>
        <Col align="right" span={5}>{t('common.table.header.exit')}</Col>
        <Col align="right" span={5}>{t('common.table.header.imbalance')}</Col>
        <Col align="right" span={4}>%</Col>
      </Row>
      <Row>
        <Col span={5}>{t('common.table.header.total')}</Col>
        <Col align="right" span={5}>{formatNumber(totalEntry, 0, 2)}</Col>
        <Col align="right" span={5}>{formatNumber(totalExit, 0, 2)}</Col>
        <Col align="right" span={5}>{formatNumber(imbalance, 0, 2)}</Col>
        <Col align="right" span={4}>{imbalancePercentage}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-1">{t('common.table.header.nomres')}</Col>
        <Col align="right" span={5}>{formatNumber(nomresEntry, 0, 2)}</Col>
        <Col align="right" span={5}>{formatNumber(bcExit, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-2">{t('common.table.header.imatra')}</Col>
        <Col align="right" span={5}>{formatNumber(imatra, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-2">{t('common.table.header.bc')}</Col>
        <Col align="right" span={5}>{formatNumber(bcEntry, 0, 2)}</Col>
        <Col align="right" span={5}>{formatNumber(bcExit, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-2">{t('common.table.header.lngHamina')}</Col>
        <Col align="right" span={5}>{formatNumber(lngHamina, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-2">{t('common.table.header.lngInkoo')}</Col>
        <Col align="right" span={5}>{formatNumber(lngInkoo, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-1">{t('common.table.header.biogas')}</Col>
        <Col align="right" span={5}>{formatNumber(biogas, 0, 2)}</Col>
      </Row>
      <Row>
        <Col span={5} className="ant-col--indent-1">
          <Link to={`${BASE_PATH}/consumption/${date}`}>
            {t('common.table.header.consumption')}
          </Link>
        </Col>
        <Col span={5} />
        <Col align="right" span={5}>{formatNumber(consumption, 0, 2)}</Col>
      </Row>
    </>
  );

  const imatraContent = ({
    availableCapacity,
    bookedCapacity,
    date,
    nomint,
    nomres,
  }) => (
    <>
      <h3>{getDateString(date, t)}</h3>
      <CardRow
        label={t('monitoring.cardLabel.bookedCapacity')}
        value={formatValue(bookedCapacity)}
      />
      <CardRow
        label={t('monitoring.cardLabel.availableCapacity')}
        value={formatValue(availableCapacity)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomint')}
        value={formatValue(nomint)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomres')}
        value={formatValue(nomres)}
      />
      <Button
        className="link"
        type="link"
        onClick={() => showDetailedInfo(CAPACITY_TYPES.IMATRA, date)}
      >
        {t('monitoring.capacityAndNominations.cardLink')}
      </Button>
    </>
  );

  const balticConnectorContent = ({
    availableCapacityEntry,
    availableCapacityExit,
    date,
    nomintEntry,
    nomintExit,
    nomintNet,
    nomresEntry,
    nomresExit,
    nomresNet,
  }) => (
    <>
      <h3>{getDateString(date, t)}</h3>
      <h4>{t('monitoring.cardSubHeading.entry')}</h4>
      <CardRow
        label={t('monitoring.cardLabel.availableCapacity')}
        value={formatValue(availableCapacityEntry)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomint')}
        value={formatValue(nomintEntry)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomres')}
        value={formatValue(nomresEntry)}
      />
      <h4>{t('monitoring.cardSubHeading.exit')}</h4>
      <CardRow
        label={t('monitoring.cardLabel.availableCapacity')}
        value={formatValue(availableCapacityExit)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomint')}
        value={formatValue(nomintExit)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomres')}
        value={formatValue(nomresExit)}
      />
      <h4>{t('monitoring.cardSubHeading.net')}</h4>
      <CardRow
        label={t('monitoring.cardLabel.nomint')}
        value={formatValue(nomintNet)}
      />
      <CardRow
        label={t('monitoring.cardLabel.nomres')}
        value={formatValue(nomresNet)}
      />
    </>
  );

  const exitZoneContent = ({ date, bookedCapacity }) => (
    <>
      <h3>{getDateString(date, t)}</h3>
      <CardRow
        label={t('monitoring.cardLabel.bookedCapacity')}
        value={formatValue(bookedCapacity)}
      />
      <Button
        className="link"
        type="link"
        onClick={() => showDetailedInfo(CAPACITY_TYPES.EXITZONE, date)}
      >
        {t('monitoring.capacityAndNominations.cardLink')}
      </Button>
    </>
  );

  const biogasContent = ({ date, bookedCapacity }) => (
    <>
      <h3>{getDateString(date, t)}</h3>
      <CardRow
        label={t('monitoring.cardLabel.bookedCapacity')}
        value={formatValue(bookedCapacity)}
      />
    </>
  );

  const renderCardContainers = () => {
    const {
      summary,
      imatra,
      balticConnector,
      exitZone,
      biogas,
    } = getDataForCapacityAndNominations(context, selectedUnit);

    return (
      <>
        <CardContainer
          renderCard={summaryContent}
          dataSource={summary}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.physicalBalanceSummary')}
          wide
        />
        <CardContainer
          renderCard={imatraContent}
          dataSource={imatra}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.imatra')}
        />
        <CardContainer
          renderCard={balticConnectorContent}
          dataSource={balticConnector}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.balticConnector')}
        />
        <CardContainer
          renderCard={exitZoneContent}
          dataSource={exitZone}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.exitZone')}
        />
        { /*
        <CardContainer
          renderCard={biogasContent}
          dataSource={biogas}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.lngHamina')}
        />
        <CardContainer
          renderCard={biogasContent}
          dataSource={biogas}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.lngInkoo')}
        />
        */ }
        <CardContainer
          renderCard={biogasContent}
          dataSource={biogas}
          cardClass="monitoring-card"
          cardClassFn={getCardClass}
          title={t('monitoring.capacityAndNominations.cardContainerTitle.biogas')}
        />
      </>
    );
  };

  const handleUnitSelection = ({ target: { value } }) => { setSelectedUnit(value); };
  const handleChangeSelectedDate = (date) => { setSelectedDate(date); };

  const renderActions = () => (
    <>
      <Col span={4}>
        <ButtonSelector
          t={t}
          options={UNIT_EXTENDED_NAMES}
          defaultValue={selectedUnit}
          onChange={handleUnitSelection}
          title="unit"
        />
      </Col>
      <Col span={6}>
        <StatsDatePicker
          selectedDate={selectedDate}
          onSelectedDateChange={handleChangeSelectedDate}
          title={t('monitoring.capacityAndNominations.datepicker.title')}
        />
      </Col>
    </>
  );

  const { physicalBalanceSummary } = context;

  return (
    <>
      <PageHeaderBar
        title={t('monitoring.capacityAndNominations.title', { unit: selectedUnit })}
        data-testid="page__header-bar"
      />
      <Layout.Content className="layout__container__page__content capacity-and-nominations">
        <Row className="capacity-and-nominations__action-header">
          { renderActions() }
        </Row>
        <Row className="capacity-and-nominations__content">
          { renderCardContainers() }
        </Row>
      </Layout.Content>
      <Route path={`${BASE_PATH}/imatra/:date`}>
        <ImatraStatsModal basePath={BASE_PATH} unit={selectedUnit} />
      </Route>
      <Route path={`${BASE_PATH}/exitZone/:date`}>
        <StatsModal basePath={BASE_PATH} />
      </Route>
      <Route path={`${BASE_PATH}/consumption/:date`}>
        <HourlyImbalances
          imbalanceType="consumption"
          physicalBalanceSummary={physicalBalanceSummary}
          unit={selectedUnit}
        />
      </Route>
    </>
  );
};

CapacityAndNominations.displayName = 'CapacityAndNominations';

export default withRouter(CapacityAndNominations);
export {
  CapacityAndNominations as PureComponent,
};
