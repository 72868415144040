import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';

import ButtonSelector from '../../components/ButtonSelector';
import PageHeaderBar from '../../components/PageHeaderBar';
import RelationshipsTable from '../../components/RelationshipsTable';
import { TYPES } from '../../constants/relationships';
import { STATUS_NAMES } from '../../constants/status';

const Retailers = ({ t }) => {
  const [statusFilter, setStatusFilter] = useState('all');
  return (
    <>
      <PageHeaderBar
        title={t('deliveryRelationship.title.received')}
        actionItems={(
          <ButtonSelector
            t={t}
            options={['all'].concat(STATUS_NAMES)}
            defaultValue={statusFilter}
            title="status"
            onChange={({ target: { value } }) => setStatusFilter(value)}
          />
        )}
      />
      <Layout.Content className="layout__container__page__content">
        <RelationshipsTable statusFilter={statusFilter} type={TYPES.RETAILERS} />
      </Layout.Content>
    </>
  );
};

Retailers.propTypes = {
  t: PropTypes.func.isRequired,
};

Retailers.displayName = 'Retailers';
export default withTranslation()(Retailers);

export {
  Retailers as PureComponent,
};
