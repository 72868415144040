const DIRECTIONS = {
  ENTRY: 'entry',
  EXIT: 'exit',
};
const DIRECTION_NAMES = Object.values(DIRECTIONS);

const CONNECTION_POINTS = {
  IMATRA: 'imatra',
  EXITZONE: 'exitZone',
  BIOGAS: 'biogas', // virtual
  LNG_HAMINA: 'lngHamina', // virtual
  LNG_INKOO: 'lngInkoo', // virtual
};
const CONNECTION_POINT_NAMES = Object.values(CONNECTION_POINTS);

const LNG_LOCATION_POINTS = {
  LNG_HAMINA: 'lngHamina',
  LNG_INKOO: 'lngInkoo',
};
const LNG_LOCATION_POINT_NAMES = Object.values(LNG_LOCATION_POINTS);

const LOCATION_POINTS = {
  IMATRA: 'imatra',
  BALTIC_CONNECTOR: 'balticConnector',
  VTP: 'vtp',
  ...LNG_LOCATION_POINTS,
};
const LOCATION_POINT_NAMES = Object.values(LOCATION_POINTS);

const RELATIONSHIP_LOCATION_POINT = {
  IMATRA: 'imatra',
  BALTIC_CONNECTOR: 'balticConnector',
  VTP: 'vtp',
  LNG_HAMINA: 'lngHamina',
  LNG_INKOO: 'lngInkoo',
};

const METERING_POINT_TYPES = {
  ...CONNECTION_POINTS,
  CITYGATE: 'cityGate',
  BIOGAS_DELIVERY_ENTRY: 'biogasDeliveryEntry',
};
const METERING_POINT_TYPE_NAMES = Object.values(METERING_POINT_TYPES);

const RELATIONSHIP_LOCATION_POINT_NAMES = Object.values(RELATIONSHIP_LOCATION_POINT);

const NOMINATION_POINTS_DIRECTIONS = [
  'imatra-entry',
  'balticConnector-entry',
  'balticConnector-exit',
  'vtp-entry',
  'vtp-exit',
  'lngHamina-entry',
  'lngInkoo-entry',
];

export {
  DIRECTIONS,
  DIRECTION_NAMES,
  LOCATION_POINTS,
  LOCATION_POINT_NAMES,
  LNG_LOCATION_POINTS,
  LNG_LOCATION_POINT_NAMES,
  CONNECTION_POINTS,
  CONNECTION_POINT_NAMES,
  METERING_POINT_TYPES,
  METERING_POINT_TYPE_NAMES,
  RELATIONSHIP_LOCATION_POINT,
  RELATIONSHIP_LOCATION_POINT_NAMES,
  NOMINATION_POINTS_DIRECTIONS,
};
