const TYPES = {
  IMATRA: 'imatra',
  EXITZONE: 'exitZone',
  BIOGAS: 'biogas',
  CITYGATE: 'cityGate',
  LNG_HAMINA: 'lngHamina',
  LNG_INKOO: 'lngInkoo',
};

const TYPES_NAMES = Object.values(TYPES);

const RESOLUTIONS = {
  HOUR: 'hour',
  DAY: 'day',
  MONTH: 'month',
};

const RESOLUTION_NAMES = Object.values(RESOLUTIONS);

const DATE_TYPES = {
  START: 'start',
  END: 'end',
};

const VALUE_TYPES = {
  ENERGY: 'energy',
  HEAT: 'heat',
  VOLUME: 'volume',
};
const VALUE_TYPE_NAMES = Object.values(VALUE_TYPES);

const MAX_DATE_RANGE = '31';
const MAX_MONTH_RANGE = '12';

export {
  TYPES,
  TYPES_NAMES,
  RESOLUTIONS,
  RESOLUTION_NAMES,
  DATE_TYPES,
  MAX_DATE_RANGE,
  MAX_MONTH_RANGE,
  VALUE_TYPES,
  VALUE_TYPE_NAMES,
};
