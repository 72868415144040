import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider } from 'antd';
import classnames from 'classnames';

import Loading from '../Loading';

import './index.less';

const modalConfirmClose = (t, handler) => {
  Modal.confirm({
    centered: true,
    okText: t('common.button.yes'),
    cancelText: t('common.button.no'),
    title: t('common.dialog.close'),
    onOk: handler,
  });
};

const modalConfirmSave = (t, okHandler, title, cancelHandler, content) => {
  Modal.confirm({
    centered: true,
    className: 'confirm-modal-save',
    okText: t('common.button.yes'),
    cancelText: t('common.button.no'),
    title: title || t('common.dialog.save'),
    content,
    onOk: okHandler,
    onCancel: cancelHandler,
  });
};

const ModalWrapper = (props) => {
  const {
    modalClassName,
    title,
    actionButtons,
    handleClose,
    width,
    isLoading,
    children,
    visible,
  } = props;

  return (
    <Modal
      className={classnames(
        'modal-wrapper',
        modalClassName,
      )}
      title={title}
      visible={visible}
      width={width}
      closable={false}
      onCancel={handleClose}
      footer={[
        <Divider key="dialog" />,
        ...actionButtons,
      ]}
    >
      {isLoading ? <Loading /> : children}
    </Modal>
  );
};


ModalWrapper.displayName = 'ModalWrapper';
ModalWrapper.propTypes = {
  modalClassName: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.element,
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.element,
  visible: PropTypes.bool,
};
ModalWrapper.defaultProps = {
  modalClassName: '',
  width: '40rem',
  isLoading: false,
  children: null,
  visible: true,
};

export default ModalWrapper;
export {
  modalConfirmClose,
  modalConfirmSave,
};
