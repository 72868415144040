import React, {
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import { Button, Layout } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import CapacityRightsTransfersTable from '../../components/CapacityRightsTransfersTable';
import CapacityTransfer from '../../components/CapacityTransfer';
import PageHeaderBar from '../../components/PageHeaderBar';
import { CAPACITY_WRITE_PERMISSIONS } from '../../constants/users';
import { userIsAllowedToWrite } from '../../utils/userHelpers';

const CapacityRightsTransfers = ({
  history,
}) => {
  const {
    selectedMarketPartyId,
    currentUser,
  } = useContext(Context);
  const [newCapacityTransfer, setNewcCapacityTransfer] = useState(null);

  const { t } = useTranslation();

  const handleCloseCapacityTransfer = (capacityTransfer) => {
    if (capacityTransfer !== undefined) {
      setNewcCapacityTransfer(capacityTransfer);
    }
    history.push('/capacityrightstransfers');
  };

  /**
   * Transfer <Button /> click handler.
   */
  const handleNewTransferClick = () => {
    history.push('/capacityrightstransfers/new-transfer');
  };

  const renderButtons = () => (
    userIsAllowedToWrite({ selectedMarketPartyId, currentUser }, CAPACITY_WRITE_PERMISSIONS)
      ? (
        <Button
          className="page__header__buttons__transfer"
          type="primary"
          onClick={handleNewTransferClick}
        >
          {t('capacity.button.newCapacityRightsTransfer')}
        </Button>
      )
      : null
  );

  const renderCapacityTransfer = () => (
    userIsAllowedToWrite({ selectedMarketPartyId, currentUser }, CAPACITY_WRITE_PERMISSIONS)
      ? <CapacityTransfer onClose={handleCloseCapacityTransfer} />
      : null
  );

  return (
    <>
      <PageHeaderBar
        title={t('capacityRightsTransfer.title.yourCapacityRightsTransfer')}
        actionItems={renderButtons()}
      />
      <Layout.Content className="layout__container__page__content capacityrightstransfers">
        <CapacityRightsTransfersTable
          separatePendingTransfers
          actionsVisible
          newCapacityTransfer={newCapacityTransfer}
          includeTimestamps
        />
      </Layout.Content>
      <Route
        path="/capacityrightstransfers/new-transfer"
        render={renderCapacityTransfer}
      />
    </>
  );
};

CapacityRightsTransfers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
CapacityRightsTransfers.displayName = 'CapacityRightsTransfers';

export default withRouter(CapacityRightsTransfers);
export {
  CapacityRightsTransfers as PureComponent,
};
