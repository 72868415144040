import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Layout,
  Row,
} from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';
import Logo from '../Logo';

const RegistrationSuccess = (props) => {
  const { t } = props;

  return (
    <Layout className="register-success__layout">
      <Layout className="register-success__layout__wrapper">
        <Logo dark />
        <Row className="register-success__layout__text">
          <Col span={3} />
          <Col
            span={18}
            align="center"
          >
            <h1>
              { t('register.successNotification.title') }
            </h1>
            <p>
              { t('register.successNotification.bodyText') }
            </p>
          </Col>
          <Col span={3} />
        </Row>
        <Row className="register-success__layout__button">
          <Col>
            <Button
              type="primary"
              href="/"
            >
              { t('register.successNotification.button') }
            </Button>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};
RegistrationSuccess.displayName = 'RegistrationSuccess';
RegistrationSuccess.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RegistrationSuccess);
