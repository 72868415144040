
import React from 'react';
import StatusIcon from '../StatusIcon';

import { formatDateTimeStr } from '../../utils/i18n';

const getTableHeaders = (t, { includeTimestamps, isPendingTable }) => [
  ...includeTimestamps ? [{
    title: 'created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: formatDateTimeStr,
  }] : [],
  ...includeTimestamps && !isPendingTable ? [{
    title: 'updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: formatDateTimeStr,
  }] : [],
  {
    title: 'connectionPoint',
    dataIndex: 'type',
    key: 'type',
    render: type => t(`capacity.point.${type}`),
  },
  {
    title: 'transfer',
    dataIndex: 'transfer',
    key: 'transfer',
  },
  {
    title: 'reservedFor',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'capacity',
    dataIndex: 'capacity',
    key: 'capacity',
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    render: status => <StatusIcon status={status} />,
    className: 'content--centered',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
  },
];

export default getTableHeaders;
