import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Layout,
  Row,
} from 'antd';
import { withTranslation } from 'react-i18next';

import './index.less';

import Context from '../../context';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import NewPasswordForm from '../../components/NewPasswordForm';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: null,
      passwordReset: false,
    };
  }

  setUserEmail = (email) => {
    this.setState({
      userEmail: email,
    });
  }

  setPasswordReset = (boolean) => {
    this.setState({
      passwordReset: boolean,
    });
  }

  renderForms = () => {
    const { userEmail } = this.state;
    return (
      !userEmail
        ? <ForgotPasswordForm setUserEmail={this.setUserEmail} />
        : (
          <NewPasswordForm
            email={userEmail}
            isPasswordReset={this.setPasswordReset}
          />
        )
    );
  }

  render = () => {
    const { t } = this.props;
    const { userEmail, passwordReset } = this.state;

    const descriptionKey = (!userEmail && 'sendVerificationCode')
      || (userEmail && !passwordReset && 'sendNewPassword')
      || (userEmail && passwordReset && 'returnToLogin');

    return (
      <Layout className="forgot-password__layout">
        <Layout className="forgot-password__layout__form-wrapper">
          <Row className="forgot-password__layout__form-header">
            <Col span={5} />
            <Col
              span={14}
              align="center"
            >
              <h1>{t('forgotPassword.title')}</h1>
              <p>{t(`forgotPassword.description.${descriptionKey}`)}</p>
            </Col>
            <Col span={5} />
          </Row>
          {!passwordReset
            ? this.renderForms()
            : (
              <p className="forgot-password__login-container">
                <a href="/">
                  {t('common.button.backToSignIn')}
                </a>
              </p>
            )}
        </Layout>
      </Layout>
    );
  }
}

ForgotPassword.displayName = 'ForgotPassword';
ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
};
ForgotPassword.contextType = Context;

export default withTranslation()(ForgotPassword);
export {
  ForgotPassword as PureComponent,
};
