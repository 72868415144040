import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Icon,
  Row,
  Select,
} from 'antd';

import Context from '../../context';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import './index.less';

const SelectMarketParty = ({ history }) => {
  const {
    marketPartyIndex,
    selectMarketParty,
  } = useContext(Context);
  const { t } = useTranslation();
  const [selectedMarketParty, setSelectedMarketParty] = useState('');

  const isSaveButtonDisabled = () => (
    marketPartyIndex.length === 0 || selectedMarketParty === ''
  );

  const closeModal = () => history.goBack();

  const handleCancel = () => (
    isSaveButtonDisabled()
      ? closeModal()
      : modalConfirmClose(t, closeModal)
  );

  const handleSearch = (inputValue, option) => (
    option.key.includes(inputValue.toLowerCase())
  );

  const handleSave = () => {
    selectMarketParty(selectedMarketParty);
    closeModal();
  };

  const renderMarketPartySelector = () => (
    <Select
      className="select-market-party__selectOrSearch"
      placeholder={t('selectMarketParty.label.selectOrSearch')}
      onChange={id => setSelectedMarketParty(id)}
      filterOption={handleSearch}
      showSearch
      suffixIcon={<Icon type="search" />}
    >
      {marketPartyIndex.map(item => (
        <Select.Option
          className="select-market-party-dropdown-item"
          key={`${item.code} ${item.name}`.toLowerCase()}
          value={item.id}
        >
          <span className="code">
            {item.code}
          </span>
          &nbsp;
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );

  const renderActionButtons = () => [
    <Button
      className="select-market-party__cancel-button"
      key="close"
      onClick={handleCancel}
    >
      {t('common.button.cancel')}
    </Button>,
    <Button
      className="select-market-party__save-button"
      key="save"
      type="primary"
      disabled={isSaveButtonDisabled()}
      onClick={handleSave}
    >
      {t('common.button.save')}
    </Button>,
  ];

  return (
    <ModalWrapper
      modalClassName="select-market-party"
      title={t('selectMarketParty.title')}
      handleClose={handleCancel}
      actionButtons={renderActionButtons()}
    >
      <Row>
        <Col span={7}>
          {`${t('selectMarketParty.label.marketParties')} :`}
        </Col>
        <Col span={17}>
          {renderMarketPartySelector()}
        </Col>
      </Row>
    </ModalWrapper>

  );
};

SelectMarketParty.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

SelectMarketParty.displayName = 'SelectMarketParty';

export default withRouter(SelectMarketParty);
export {
  SelectMarketParty as PureComponent,
};
