/*
  Tax classes are for Finnish Verovirasto

  https://www.vero.fi/en/businesses-and-corporations/taxes-and-charges/excise-taxation/sahkovero/Tax-rates-on-electricity-and-certain-fuels/

  Codes are short from:

  H = Heating
  R = RED II (Renewable Energy Directive II)
  T = Trash (Produced from unedible trash sources) (old meaning before 2020 T(uplalaskettavat))

  CHP = Combined Heat and Power

  PG* = Tax office (P)roduct (G)roup code

*/
const GAS_TAX_CLASSES_2022 = {
  // Standard codes
  PG02_NATURAL_GAS: 'natural_gas',
  PG05_BIOGAS: 'biogas',
  PG06_BIOGAS_R: 'biogas_r',
  PG07_BIOGAS_T: 'biogas_t',
  PG07A_BIOGAS_HEATING: 'biogas_heating', // reclassed as PG07A
  // PG05A_BIOGAS_CHP: 'pg05a_biogas_chp',
  // PG06A_BIOGAS_CHP_R: 'pg06a_biogas_chp_r',
  // PG07A_BIOGAS_CHP_T: 'pg07a_biogas_chp_t',
};
const GAS_TAX_CLASS_NAMES_2022 = Object.values(GAS_TAX_CLASSES_2022);

const GAS_TAX_CLASSES = {
  // Standard codes
  PG02_NATURAL_GAS: 'pg02_natural_gas',
  PG05_BIOGAS: 'pg05_biogas',
  PG06_BIOGAS_R: 'pg06_biogas_r',
  PG07_BIOGAS_T: 'pg07_biogas_t',
  PG08_BIOGAS_HEATING: 'pg08_biogas_heating',
  PG09_BIOGAS_HEATING_R: 'pg09_biogas_heating_r',
  PG10_BIOGAS_HEATING_T: 'pg10_biogas_heating_t',
  // combined heat and power codes
  PG02A_NATURAL_GAS_CHP: 'pg02a_natural_gas_chp',
  PG08A_BIOGAS_CHP: 'pg08a_biogas_chp',
  PG09A_BIOGAS_CHP_R: 'pg09a_biogas_chp_r',
  PG10A_BIOGAS_CHP_T: 'pg10a_biogas_chp_t',
};
const GAS_TAX_CLASS_NAMES = Object.values(GAS_TAX_CLASSES);

// Remap old codes to current codes
const GAX_TAX_CLASS_MAP = {
  [GAS_TAX_CLASSES_2022.PG02_NATURAL_GAS]: GAS_TAX_CLASSES.PG02_NATURAL_GAS,
  [GAS_TAX_CLASSES_2022.PG05_BIOGAS]: GAS_TAX_CLASSES.PG05_BIOGAS,
  [GAS_TAX_CLASSES_2022.PG06_BIOGAS_R]: GAS_TAX_CLASSES.PG06_BIOGAS_R,
  [GAS_TAX_CLASSES_2022.PG07_BIOGAS_T]: GAS_TAX_CLASSES.PG07_BIOGAS_T,
  [GAS_TAX_CLASSES_2022.PG07A_BIOGAS_HEATING]: GAS_TAX_CLASSES.PG10_BIOGAS_HEATING_T,
};

const mapGasTaxClass = (classType) => (GAX_TAX_CLASS_MAP[classType] || classType);

export {
  mapGasTaxClass,
  GAS_TAX_CLASSES,
  GAS_TAX_CLASS_NAMES,
  GAS_TAX_CLASSES_2022,
  GAS_TAX_CLASS_NAMES_2022,
};
