import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Row,
  Col,
} from 'antd';

import './index.less';

const PageHeaderBar = (props) => {
  const {
    title,
    actionItems,
    className,
    span,
  } = props;

  return (
    <Row
      className={
        classnames(
          'page-header-bar',
          className,
        )
      }
    >
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        className="page-header-bar__title"
        span={span.title}
      >
        <h1>{ title }</h1>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        span={span.action}
        className="page-header-bar__action-items"
      >
        { actionItems }
      </Col>
    </Row>
  );
};

PageHeaderBar.displayName = 'PageHeaderBar';
PageHeaderBar.propTypes = {
  title: PropTypes.string.isRequired,
  actionItems: PropTypes.node,
  className: PropTypes.string,
  span: PropTypes.shape({
    title: PropTypes.number,
    action: PropTypes.number,
  }),
};
PageHeaderBar.defaultProps = {
  actionItems: null,
  className: null,
  span: {
    title: 18,
    action: 6,
  },
};

export default PageHeaderBar;
