import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
  Select,
  Row,
  Col,
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import { API, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import { NETWORK_STATUS_NAMES } from '../../constants/networkstatus';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import './setNetworkStatus.less';

const logger = new Logger('components:SetNetworkStatus');

class SetNetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      status: '',
    };
  }

  componentDidMount = () => {
    const { status: { status } } = this.context;
    this.setState({ status });
  }

  isSaveButtonDisabled = () => {
    const { status: { status: currentStatus } } = this.context;
    const { status } = this.state;

    return status === currentStatus || !status;
  }

  closeModal = () => {
    const { history } = this.props;

    return history.goBack();
  }

  handleCancel = () => {
    const { t } = this.props;

    return this.isSaveButtonDisabled()
      ? this.closeModal()
      : modalConfirmClose(t, this.closeModal);
  }

  handleSelectChange = value => this.setState({ status: value });

  handleSave = async (event) => {
    event.preventDefault();

    this.setState({
      isSaving: true,
    });

    const { t } = this.props;
    const { status } = this.state;
    const { setStatus } = this.context;

    try {
      const response = await API.patch('FINTSO', '/admin/status', {
        body: {
          status,
        },
      });

      notification.success({
        message: t('transmissionNetworkState.message.savedSuccessfully'),
        description: '',
      });

      setStatus(response);

      this.closeModal();
    } catch (err) {
      notification.error({
        className: 'notification-error',
        message: t('transmissionNetworkState.message.errorSaving'),
        // TODO: translatable error message
        description: createErrorMessage(err),
      });
      logger.error(createErrorMessage(err, true));
      this.setState({ isSaving: false });
    }
  }

  renderContent = () => {
    const { t } = this.props;
    const { status } = this.state;
    const { Option } = Select;
    const field = 'network-status';

    return (
      <Row>
        <Col span={7}>
          {`${t('transmissionNetworkState.label.networkStatus')} :`}
        </Col>
        <Col span={17}>
          <Select
            className="set-network-status__select"
            data-testid="set-network-status__select"
            value={status}
            name={field}
            id={field}
            onChange={this.handleSelectChange}
          >
            {
              NETWORK_STATUS_NAMES.map(networkStatus => (
                <Option key={`${field}-${networkStatus}`} value={networkStatus}>
                  {t(`transmissionNetworkState.label.${networkStatus}`)}
                </Option>
              ))
            }
          </Select>
        </Col>
      </Row>
    );
  }

  renderActionButtons = () => {
    const {
      isSaving,
    } = this.state;
    const { t } = this.props;

    return [
      <Button
        className="set-network-status__cancel-button"
        data-testid="set-network-status__cancel-button"
        key="close"
        onClick={this.handleCancel}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="set-network-status__save-button"
        data-testid="set-network-status__save-button"
        key="save"
        type="primary"
        disabled={this.isSaveButtonDisabled()}
        onClick={this.handleSave}
        loading={isSaving}
      >
        {t('common.button.save')}
      </Button>,
    ];
  }

  render = () => {
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="set-network-status"
        title={t('transmissionNetworkState.title.setNetworkStatus')}
        handleClose={this.handleCancel}
        actionButtons={this.renderActionButtons()}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

SetNetworkStatus.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

SetNetworkStatus.displayName = 'SetNetworkStatus';
SetNetworkStatus.contextType = Context;

const SetNetworkStatusTranslated = withTranslation()(SetNetworkStatus);

export default withRouter(SetNetworkStatusTranslated);
export {
  SetNetworkStatus as PureComponent,
};
