import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, Logger } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Icon,
  InputNumber,
  notification,
  Select,
} from 'antd';

import './SetBalanceGasPrice.less';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import ModalWrapper, { modalConfirmClose, modalConfirmSave } from '../../components/ModalWrapper';
import { BALANCE_PRICE_TYPES, BALANCE_PRICE_TYPE_NAMES } from '../../constants/balance';
import { getGasDay } from '../../utils/gasday';
import { formatDate } from '../../utils/i18n';
import { IMBALANCE_STATUS, NETWORK_STATUSES } from '../../constants/networkstatus';

const { BUY, SELL } = BALANCE_PRICE_TYPES;
const { SHORT, LONG, NOMINAL } = IMBALANCE_STATUS;
const { YELLOW } = NETWORK_STATUSES;

const log = new Logger('SetBalanceGasPrice');

const SetBalanceGasPrice = ({ visible, handleClose }) => {
  const { t } = useTranslation();
  const {
    balancePrices,
    status,
    updateBalancePrices,
  } = useContext(Context);
  const [priceType, setPriceType] = useState(SELL);
  const [value, setValue] = useState(0);
  const [saving, setSaving] = useState(false);
  const now = getGasDay();
  const balancePrice = (balancePrices || []).find(({ date }) => date === now);
  const { override = {} } = balancePrice || {};
  const defaultValue = balancePrice ? (override[priceType] || balancePrice[priceType]) : 0;

  useEffect(() => {
    setValue(defaultValue);
  }, [balancePrices, priceType]);

  const handleSave = async () => {
    setSaving(true);

    try {
      const body = {
        date: now,
        [priceType]: value,
      };
      await API.put('FINTSO', '/admin/prices/balance', { body });
      updateBalancePrices();
      handleClose();
    } catch (error) {
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('balanceGasPriceDialog.errorMessage'),
        description,
      });
      log.error(description);
    }

    setSaving(false);
  };

  const disabled = value === defaultValue || value === 0;

  const actionButtons = [
    <Button
      key="closeButton"
      onClick={() => (disabled ? handleClose() : modalConfirmClose(t, handleClose))}
    >
      {t('common.button.close')}
    </Button>,
    <Button
      key="saveButton"
      disabled={disabled}
      loading={saving}
      type="primary"
      onClick={() => modalConfirmSave(t, handleSave)}
    >
      {t('common.button.save')}
    </Button>,
  ];

  const renderWarning = () => {
    if (!status) return null;

    const warningMessage = (() => {
      if (status.status !== YELLOW) {
        return t('balanceGasPriceDialog.warning.statusNotYellow');
      }
      if (status.imbalanceStatus === NOMINAL) {
        return t('balanceGasPriceDialog.warning.statusNominal');
      }
      if (status.imbalanceStatus === SHORT && priceType !== SELL) {
        return t('balanceGasPriceDialog.warning.statusShort');
      }
      if (status.imbalanceStatus === LONG && priceType !== BUY) {
        return t('balanceGasPriceDialog.warning.statusLong');
      }
      return '';
    })();

    return warningMessage && (
      <div className="balance-gas-price-modal__warning">
        <Icon type="warning" />
        <span>{warningMessage}</span>
      </div>
    );
  };

  return (
    <ModalWrapper
      title={t('balanceGasPriceDialog.title', { date: formatDate(getGasDay()) })}
      visible={visible}
      actionButtons={actionButtons}
      handleClose={handleClose}
    >
      <div className="balance-gas-price-modal">
        <Form>
          <Form.Item label={t('balanceGasPriceDialog.priceToUpdate')}>
            <Select value={priceType} onSelect={setPriceType}>
              {BALANCE_PRICE_TYPE_NAMES.map(
                type => (
                  <Select.Option
                    key={type}
                    value={type}
                  >
                    {t(`balanceGasPriceDialog.${type}`)}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          <Form.Item label={t('balanceGasPriceDialog.newPrice')}>
            <InputNumber
              min={0}
              precision={3}
              step={0.1}
              value={value}
              onChange={setValue}
            />
          </Form.Item>
        </Form>
        {renderWarning()}
      </div>
    </ModalWrapper>
  );
};

SetBalanceGasPrice.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SetBalanceGasPrice;
